import { Spin } from "antd";
import React from "react";

function SchoolContentTile({
  number,
  text,
  loading,
  setOpenTeachersList,
  setOpenConfirmAccount,
  type,
}) {
  return (
    <button
      onClick={() => {
        if (type === "confirmed") setOpenTeachersList(true);
        if (type === "unconfirmed") setOpenConfirmAccount(true);
      }}
      // className={`min-w-min p-2 h-16 mx-2 bg-white shadow-lg text-[#1D6DAE] rounded-lg flex flex-col justify-center items-center cursor-pointer `}
      className="bg-slate-700 rounded text-white px-2 py-1"
      size="small"
    >
      {!loading ? (
        <>
          {/* <p className="text-3xl">{number}</p>
          <p className="text-xs"> {text}</p> */}

     {text}
        </>
      ) : (
        <Spin size="small" />
      )}
    </button>
  );
}

export default SchoolContentTile;
