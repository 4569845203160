import {
  CloseCircleTwoTone,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Popover } from "antd";
import AxiosInstance from "../../../AxiosInstance/AxiosInstance";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshCampaign } from "../../../redux/slices/campaignSlice";
import Toast from "../../../util/toast";

// import Toast from "../../../util/toast";

function SchoolSelectedForProcessing({ setShowSelectedSchool, stekaSchool }) {
  const dispatch = useDispatch();
  const [showSchoolContact, setShowSchoolContact] = useState(false);
  const [selectedGoals, setSelectedGoals] = useState(null);
  const [loading, setLoading] = useState(false);

  const campaign = useSelector((state) => state.campaign.refreshCampaign);
  console.log("Refresh campaign:", campaign);

  const markAsProcessed = async (stekaId) => {
    setLoading(true);
    // const route = `api/steka/processed`;
    try {
      const { data } = await AxiosInstance.post("/api/steka/processed", { stekaId });
      console.log(data);
      if (data.error) {
        Toast(data.message, "error");
      } else {
        Toast("Action Completed successfully!", "success");
        dispatch(setRefreshCampaign(!campaign));
        setShowSelectedSchool(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  ///////////
  const goals = [
    { key: "goal1", text: "Goal 1: Promoting child literacy", services: "EDN" },
    {
      key: "goal2",
      text: "Goal 2: developing pupils appreciation shared positive values",
      services: "EDN, SGN",
    },
    {
      key: "goal3",
      text: "Goal 3: demystifying science and raising tech consciousness",
      services: "EDN",
    },
    {
      key: "goal4",
      text: "Goal 4: tilting higher education to career interest",
      services: "ESGN, LNP",
    },
    {
      key: "goal5",
      text: "Goal 5: enhancing teacher competence",
      services: "ESGN, LNP, SGN",
    },
    {
      key: "goal6",
      text: "Goal 6: enhancing teacher competence",
      services: "ESGN, LNP, SGN",
    },
    {
      key: "goal7",
      text: "Goal 7: enhancing teacher competence",
      services: "ESGN, LNP, SGN",
    },
    {
      key: "goal8",
      text: "Goal 8: enhancing teacher competence",
      services: "ESGN, LNP, SGN",
    },
    {
      key: "goal9",
      text: "Goal 9: enhancing teacher competence",
      services: "ESGN, LNP, SGN",
    },
    {
      key: "goal10",
      text: "Goal 10: enhancing teacher competence",
      services: "ESGN, LNP, SGN",
    },
    {
      key: "goal11",
      text: "Goal 11: enhancing teacher competence",
      services: "ESGN, LNP, SGN",
    },
    {
      key: "goal12",
      text: "Goal 12: enhancing teacher competence",
      services: "ESGN, LNP, SGN",
    },
    {
      key: "goal13",
      text: "Goal 13: enhancing teacher competence",
      services: "ESGN, LNP, SGN",
    },
    {
      key: "goal14",
      text: "Goal 14: enhancing teacher competence",
      services: "ESGN, LNP, SGN",
    },
    {
      key: "goal15",
      text: "Goal 15: enhancing teacher competence",
      services: "ESGN, LNP, SGN",
    },
    {
      key: "goal16",
      text: "Goal 16: enhancing teacher competence",
      services: "ESGN, LNP, SGN",
    },
    {
      key: "goal17",
      text: "Goal 17: enhancing teacher competence",
      services: "ESGN, LNP, SGN",
    },
  ];

  const filteredDesiredGoals = Object.entries(stekaSchool).filter(
    (attribute) =>
      String(attribute[0]).includes("goal") && attribute[1] === true
  );

  const filteredValues = [];
  filteredDesiredGoals.forEach((pair) => filteredValues.push(pair[0])); // push in the keys of the filtered goals E,g goal1, goal2
  console.log(filteredValues);
  return (
    <div
      className="w-full h-full flex bg-white flex-col"
      style={{ zIndex: 200 }}
    >
      <div className="h-[20%] pb-1 w-full">
        <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
          <CloseCircleTwoTone
            onClick={() => setShowSelectedSchool(false)}
            color="red"
          />
        </div>
        <div className="flex justify-between items-end w-full h-[60%] border-b-2 pb-3 border-b-[#1D6DAE] ">
          <div className="text-3xl font-semibold  w-full">
            {stekaSchool?.user?.name}
          </div>
        </div>
      </div>
      {/* list of schools  */}
      <div className="font-semibold">
        We would like supply of tools and services of STEKA for:
      </div>
      <div className="bg-gray-200  my-2 py-2 text-gray-600 font-bold px-4 flex justify-between">
        <p className="">Target Sub-goals of interest</p>

        <div className="">Tools and Services</div>
      </div>
      <div className="h-[42%] overflow-y-scroll">
        {goals.map((goal, index) => {
          if (!filteredValues.includes(goal.key)) return;
          return (
            <div
              key={index.toString()}
              className="odd:bg-gray-200 mt-1 h-8 text-gray-600 font-semibold px-4 flex justify-between"
            >
              <p className="w-[80%]">{goal.text}</p>

              <div className="font-bold text-left w-[13%]">{goal.services}</div>
            </div>
          );
        })}
      </div>

      <button
        onClick={() => markAsProcessed(stekaSchool._id)}
        disabled={loading || stekaSchool.processed}
        className="px-3 w-full py-2 my-2 rounded-lg font-semibold border-2 bg-[#1D6DAE] disabled:bg-gray-400 disabled:animate-pulse text-white focus:outline-none "
      >
        Mark as processed
      </button>

      <div className="flex h-[20%] bg-white justify-end items-center border-t  ">
        <div className="w-[85%] mx-auto h-full flex items-center justify-between">
          <img src="/esgn.PNG" className="w-15 h-[92%] cursor-pointer" alt="" />
          <img src="/sgn.PNG" className="w-15 h-[92%] cursor-pointer" alt="" />
          <img src="/lnp.PNG" className="w-15 h-[92%] cursor-pointer" alt="" />
          <img src="/edn.PNG" className="w-15 h-[92%] cursor-pointer" alt="" />
          <img src="wksp.PNG" className="w-15 h-[92%] cursor-pointer" alt="" />
        </div>
      </div>
    </div>
  );
}

export default SchoolSelectedForProcessing;
