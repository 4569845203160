import React, { useLayoutEffect, useState } from "react";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { Rate } from "antd";
import Toast from "../../../util/toast";
import AxiosInstance from "../../../AxiosInstance/AxiosInstance";
import { useSelector } from "react-redux";

function FollowUpApplications({ history, toggleFollowUpApplications }) {
  // get-all-applications-for-a-job

  const [selectedJob, setSelectedJob] = useState({});
  const [apllicationsForAJob, setApllicationsForAJob] = useState([]);
  const [allUserApllications, setAllUserApllications] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user.user);

  const dummyData = [
    { name: "John Huxley", skillRate: 4.5, PEA: 88 },
    { name: "Kenneth Oloche", skillRate: 3.5, PEA: 56 },
    { name: "John Huxley", skillRate: 2, PEA: 56 },
    { name: "Kenneth Oloche", skillRate: 4.5, PEA: 56 },
    { name: "John Huxley", skillRate: 5, PEA: 58 },
    { name: "John Huxley", skillRate: 0.5, PEA: 0 },
    { name: "Kenneth Oloche", skillRate: 4.5, PEA: 56 },
    { name: "John Huxley", skillRate: 4.5, PEA: 156 },
    { name: "John Huxley", skillRate: 1, PEA: 10 },
    { name: "John Huxley", skillRate: 4.5, PEA: 100 },
  ];

  const getAllApplicationsForAJob = async (e) => {
    if (!Boolean(e.target.value)) {
      setApllicationsForAJob([]);
      return;
    }
    const selectedJobId = e.target.value;
    setLoading(true);

    try {
      const { data } = await AxiosInstance.post(
        `/api/application/get-all-applications-for-a-job?job=${selectedJobId}`
      );
      console.log("All Appls for a Jo:", data.applications);
      if (data && data.applications.length > 0) {
        setApllicationsForAJob(data.applications);
      }
    } catch (error) {
      return Toast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    // get all the jobs that the logged in user has applid to
    const getAllUserApplications = async () => {
      setLoading(true);

      try {
        const { data } = await AxiosInstance.post(
          "/api/application/get-all-user-applications"
        );
        console.log("All User Applications:", data.applications);
        if (data && data.applications?.length > 0) {
          setAllUserApllications(data.applications);
        }
      } catch (error) {
        return Toast(error.message, "error");
      } finally {
        setLoading(false);
      }
    };

    getAllUserApplications();
  }, []);

  return (
    <div className="absolute -top-[75px] -left-4 w-full">
      <div
        className="w-[90%] h-full mx-auto bg-white  rounded-xl shadow-lg p-4"
        style={{ minHeight: 500, zIndex: 500 }}
      >
        <div className="w-full flex justify-end mr-2">
          <CloseCircleTwoTone onClick={toggleFollowUpApplications} />
        </div>
        {/* <div className="my-2 border-b "></div> */}
        <div className="w-full px-3 mt-3 h-full">
          {/* section one === top row  */}
          <div className="w-full flex justify-between items-center">
            <div className="w-[60%] flex justify-start space-x-2 items-center">
              <div className="flex text-lg text-esgrown px-3 py-1 mr-3 rounded-lg relative">
                Messages
                <span className=" absolute -top-2 -right-2 h-[24px] w-[24px] ml-1 mt-[1px] rounded-full flex items-center justify-center text-xs text-red-500 font-bold bg-slate-100">
                  16
                </span>
              </div>
              <div>|</div>
              <div className="flex text-lg text-esgrown px-3 py-1 mr-3 rounded-lg relative">
                Engagement Notifications
                <span className=" absolute -top-2 -right-2 h-[24px] w-[24px] ml-1 mt-[1px] rounded-full flex items-center justify-center text-xs text-red-500 font-bold bg-slate-100">
                  1
                </span>
              </div>
            </div>
            <div className="w-[38%] flex justify-between items-center">
              <select
                onChange={getAllApplicationsForAJob}
                className="w-[98%] px-2 py-2 border-2 rounded-lg focus:border-esgrown "
              >
                <option value="">My Applications</option>
                {allUserApllications.map((application) => {
                  return (
                    <option
                      name={application.job._id}
                      value={application.job._id}
                    >
                      {application?.job.title} - {application?.job.location}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {/* section 2: table */}
          <div
            className={` ${
              apllicationsForAJob.length < 1 ? "hidden" : ""
            } w-full mt-3 px-2`}
          >
            <table className="w-full px-3 rounded-lg">
              <thead className=" bg-sky-200 text-lg font-bold">
                <th>Applicant</th>
                <th>Skill Rate</th>
                <th>PEA Score</th>
              </thead>
              <tbody className="text-lg ">
                {apllicationsForAJob.length > 0 &&
                  apllicationsForAJob.map((application, index) => {
                    return (
                      <tr className="mt-6  even:bg-sky-100">
                        <td>{application.user.name}</td>
                        {/* <td>{application.skillRate}</td> */}
                        <td>
                          <Rate
                            //   allowClear
                            //   autoFocus
                            allowHalf={true}
                            disabled={true}
                            tooltips={["1", "2", "3", "4", "5"]}
                            defaultValue={application.skillRate}
                            //   onChange={(value) => setPedagogy(value)}
                          />
                        </td>
                        <td>{application.PEA}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          {/* Fotter  */}
          <div
            className={`${
              apllicationsForAJob.length < 1 ? "hidden" : ""
            } w-full flex  justify-between items-center mt-10 px-2`}
          >
            <div className="font-semibold">
              My Apllication Ranking: {getMyRanking(apllicationsForAJob, user)}
              <span className="mx-1">
                <sup>th</sup>
              </span>
              of {apllicationsForAJob.length}
            </div>
            <div className="flex space-x-2">
              <button className="px-1 py-1 bg-sky-300 rounded-lg text-gray-500 hover:bg-esgrown hover:text-white transition-all duration-500">
                Previous
              </button>
              <button className="px-2 py-1 bg-sky-300 rounded-lg text-gray-500 hover:bg-esgrown hover:text-white transition-all duration-500">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FollowUpApplications;

function getMyRanking(applications, user) {
  let myRanking = 0;
  applications.map((application, i) => {
    if (application.user.email === user.email) {
      myRanking = i + 1;
    }
  });
  return myRanking;
}
