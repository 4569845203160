import {
  InfoCircleOutlined,
  LogoutOutlined,
  UserOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function DropDownMenu({ user, show, bgColor }) {
  const [hasCamp,setHasCamp]=useState(false);
  const getData = async (id="")=>{
    const nid = (id===""?null:id);
    const data = await AxiosInstance.post("/api/campaign/data",{id:nid});
    console.log(data.data.data,"hello");
    if(data.data.data !== undefined){
      setHasCamp(true)
    }
    }
  useEffect(()=>{
    getData();
  },[])
  return (
    <div
      style={{ zIndex: 1000 }}
      className={`absolute min-w-min min-h-min ${
        bgColor ? bgColor : "bg-white"
      }  border top-[66px] right-2 shadow-lg transition-all duration-700 cursor-pointer`}
    >
      <div className={`h-full w-full p-2 ${!show && "hidden"}`}>
        <div className="flex space-x-2 menu-item  items-center h-8 px-2">
          <UserOutlined size={20} />
          <Link to="/profile" className="hover:text-white">
            Profile
          </Link>
        </div>
        <hr />
        {(user?.userType === "student" ||
          user?.userType === "teacher" ||
          user?.userType === "private" ||
          user?.userType === "company" ||
          user?.userType === "school") && (
          <>
            <div className="flex space-x-2 menu-item  items-center h-8 px-2">
              <InfoCircleOutlined size={20} />
              <Link to="/sdg-campaigns" className="hover:text-white">
                Campaigns
              </Link>
            </div>
            <hr />
          </>
        )}
        {(user?.userType === "private" && user.sdg4 === true && user.rank!=null && user.rank!==undefined) && (
          <>
            <div className="flex space-x-2 menu-item  items-center h-8 px-2">
              <DashboardOutlined size={20} />
              <Link to="/associate-dashboard" className="hover:text-white">
                My Campaigns
              </Link>
            </div>
            <hr />
          </>
        )}

        <div className="flex space-x-2 menu-item  items-center h-8 px-2">
          <LogoutOutlined size={20} />
          <Link to="/logout" className="hover:text-white">
            Logout
          </Link>
        </div>
        <hr />
      </div>
    </div>
  );
}

export default DropDownMenu;
