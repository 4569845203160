import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../redux/slices/userSlice";

const Logout = ({ history }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const logout = () => {
    window && window.localStorage.removeItem("persist:root");
    dispatch(signOut());
    localStorage.clear();
    history.push("/");
  };

  const redirecToProfile = () => {
    if (user && user.isAdmin === true) return history.push("/adminDashboard");
    return history.push("/home");
  };
  return (
    <div className="logout-container">
      <p>Are you sure you want to close this session?</p>
      <p>
        <button className="logout-btn" onClick={logout}>
          Yes
        </button>
        <button className="logout-btn" onClick={redirecToProfile}>
          No
        </button>
      </p>
    </div>
  );
};

export default Logout;
