import React from "react";
import { Image } from "react-bootstrap";
import img_src from "../../../img/testimonials_bg.jpg";
// import 'testimonial_slider.css';
import "./testimonial_slider.css";

export default function Slide() {
  return (
    <React.Fragment>
      <section class="testimonial text-center">
        <div class="container">
          <div class="heading white-heading">Testimonial</div>
          <div
            id="testimonial4"
            className="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x"
            dataRide="carousel"
            dataPause="hover"
            dataInterval="5000"
            dataDuration="2000"
          >
            <div className="carousel-inner" role="listbox">
              {/* 1st Slide */}
              <div class="carousel-item active">
                <div class="">
                  {/* <img src="https://i.ibb.co/8x9xK4H/team.jpg" class="img-circle img-responsive" /> */}
                  <div className="main_text d-flex justify-content-center mb-3">
                    "[Your] entire programs set is a complete tool kit for
                    sustainable economic growth and development that African
                    countries really need. They are very serviceable to
                    America's need to sustain its global socioeconomic
                    leadership. The Chamber would really be very pleased for an
                    opportunity to partner with you to extend your programs to
                    the US."{" "}
                  </div>
                  <h4 className="title">USAFRICA Chamber of Commerce</h4>
                </div>
              </div>

              {/* 2nd Slide */}
              <div class="carousel-item">
                <div class="">
                  {/* <img src="https://i.ibb.co/8x9xK4H/team.jpg" class="img-circle img-responsive" /> */}
                  <div className="main_text d-flex justify-content-center mb-3">
                    "Your programme is in tandem with the mandate of [SMEDAN],
                    and would trigger the change required for a viable and
                    sustainable MSME subsector in Nigeria. The Agency is
                    desirous of partnering with you in advancing and driving
                    [your] innovative programmes, them to other stakeholders."
                  </div>
                  <h4 className="title">
                    Small and Medium Scale Enterprise Development Agency
                    <br /> (Presidency)
                    <br />
                    Nigeria{" "}
                  </h4>
                </div>
              </div>

              {/* 3rd Slide */}
              <div class="carousel-item">
                <div class="">
                  {/* <img src="https://i.ibb.co/8x9xK4H/team.jpg" class="img-circle img-responsive" /> */}
                  <div className="main_text d-flex justify-content-center mb-3">
                    "We identify with your strategies for reversing the trend of
                    declining interest of Nigerian children in science and
                    technology education through the Science and Technology
                    Knowledge Base Advancement Program. And we endorse Brain
                    Wealth Projects as wholly proportionate to the effect of
                    leveraging knowledge, technology and innovation into
                    socioeconomic development."
                  </div>
                  <h4 className="title">
                    National Office for Technology Acquisition and Promotion
                    <br /> (Federal Ministry of Science and Technology)
                    <br />
                    Nigeria{" "}
                  </h4>
                </div>
              </div>

              {/* <div class="carousel-item">
                                <div class="testimonial4_slide">
                                    <img src="https://i.ibb.co/8x9xK4H/team.jpg" class="img-circle img-responsive" />
                                    <p className="test_slider_text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                    <h4>Client 3</h4>
                                </div>
                            </div> */}
            </div>
            <a
              class="carousel-control-prev"
              href="#testimonial4"
              data-slide="prev"
            >
              <span class="carousel-control-prev-icon"></span>
            </a>
            <a
              class="carousel-control-next"
              href="#testimonial4"
              data-slide="next"
            >
              <span class="carousel-control-next-icon"></span>
            </a>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
