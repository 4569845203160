import { CheckCircleTwoTone } from "@ant-design/icons";
import AxiosInstance from "../../../AxiosInstance/AxiosInstance";
import React from "react";
import { usePaystackPayment } from "react-paystack";
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/slices/userSlice";
import Toast from "../../../util/toast";

function ActivateStaffUCBI({ user, amount,  triggerPayStack, setTriggerPayStack }) {
  const dispatch = useDispatch();
  const fixedUCBIActivationCost = amount;
  const config = {
    reference: new Date().getTime().toString(),
    email: user.email,
    amount: fixedUCBIActivationCost * 100, //* 100 converts sub amount from naira to to kobo
    publicKey: "pk_test_7b545e0d7a1aaa0e39782e7d5aa7e9595a8082fc",
  };

  // you can call this function anything
  const paystackOnClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    setTriggerPayStack(false)
  };

  const paystackOnSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    AxiosInstance.post("/api/ucbi/activate-staff-ucbi")
      .then((response) => {
        if (response.data && response.data?.user) {
          const user = { ...response.data.user, loggeIn: true };
          dispatch(setUser(user));
          Toast("UCBI Successfully activated for staff!", "success");
        }
      })
      .catch((error) => Toast(error.message, "error"));
  };

  const initializePayment = usePaystackPayment(config);
 const paystackCheckout = async (service) => {
    try {
      initializePayment(paystackOnSuccess, paystackOnClose);
    } catch (error) {
      Toast(error.message, "error");
    }
  };

  if(triggerPayStack) { paystackCheckout() }

  return (
    <div className="flex items-center min-w-min space-x-2">
      {!user.UCBIActive ? (
        <>
          {/* <button
            onClick={paystackCheckout}
            className="bg-esgrown text-sm text-white rounded-lg px-2 flex items-center transition-all hover:translate-x-1 duration-500"
          >
            activate
          </button> */}
        </>
      ) : (
        <div className="flex items-center bg-esgrown px-2 rounded-lg text-white">
          UCBI is active <CheckCircleTwoTone className="ml-2" />
        </div>
      )}
    </div>
  );
}

export default ActivateStaffUCBI;
