import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowStekaForm } from "../../redux/slices/formSlice";
import CaptureSchool from "../steka_modals/CaptureSchool";
import JoinSDG4Campaign from "./modals/JoinSDG4Campaign";

function SDG4({}) {
  const dispatch = useDispatch();
  const [joinCampaign, setJoinCampaign] = useState(false);
  const [openCapSchoolModal, setopenCapSchoolModal] = useState(false);
  const toggleCapSchoolModal = () => setopenCapSchoolModal((prev) => !prev);
  const toggleJoinCampaign = () => setJoinCampaign((prev) => !prev);

  const user = useSelector((state) => state.user.user);
  const showStekaForm = useSelector((state) => state.form.showStekaForm);

  useEffect(() => {
    if (showStekaForm) {
      window && window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, []);

  if (showStekaForm) {
    return <CaptureSchool toggleCapSchoolModal={toggleCapSchoolModal} />
  }
  return (
    <div className="w-full h-full relative">
      <section className="w-full flex justify-between items-center px-1.5">
        <div className="text-xl text-[#00AFEF] font-bold">
          UN Sustainable Development Goal on Education
        </div>
        {user.userType !== "company" && (
          <button
            onClick={() => {
              if (user.userType === "school") {
                dispatch(setShowStekaForm(true));
              } else {
                toggleJoinCampaign();
              }
            }}
            disabled={user.sdg4 === true}
            className={` ${
              user.sdg4 === true && "hidden"
            } border text-lg font-bold px-2 py-1 rounded-lg  transition-all duration-700  hover:bg-[#00AFEF] hover:text-white shadow-sm  focus:outline-none`}
          >
            {user.userType == "school" ? "Join Campaign" : "Join Campaign"}
          </button>
        )}
      </section>
      {/* // second section */}

      <section className="w-full h-full bg-white p-4 rounded-xl mt-3 relative">
        <div className="w-full h-24 mb-2 flex justify-start items-center">
          <img src="/sdgs.PNG" alt="" className="w-[100] h-full object-cover" />
          <div className="text-xl font-bold ml-4">
          We Support the UN Sustainable Development Goal on Quality Education
          </div>
        </div>
        <div className="text-justify text-xs md:text-sm">
          This is a campaign to raise awareness on need for strong quality and
          functional education system that equips students passing through
          school with true state of the world knowledge and skills needed for
          true life productivity in transforming raw materials resources and
          their intellect into distributed products, services and fixed
          infrastructure that serve the needs of their country, with emphasis on
          Africa, and respond to global demands, while build their appreciation
          for share positive values, and to capture schools as units of your
          country’s education system on program designed to address that
          concern, in line with the United Nations Sustainable Development for
          all countries on quality education that meets knowledge and skill
          needs for addressing contemporary world economic, social and
          environmental concerns encompassed by the entire 17 global goals. We
          provide a digital framework for associates advisors from various
          partnering public and private sector organizations in different
          countries, including our parent company BWP Utvecklingsbolag, to
          capture schools in their communities on the UN SDG 4 aligned program
          functional quality education through this campaign.
        </div>
        {/* absolutly positioned on the this scetion  */}
        <div className="w-fit px-2 py-1 absolute -bottom-6 right-4 ">
          {user.userType !== "company" && (
            <button
              onClick={() => {
                if (user.userType === "school") {
                  dispatch(setShowStekaForm(true));
                } else {
                  toggleJoinCampaign();
                }
              }}
              disabled={user.sdg4 === true}
              className={` ${
                user.sdg4 === true && "hidden"
              } bg-gray-200 font-semibold rounded-lg shadow px-3 py-2 focus:outline-none transition-all  disabled:translate-x-0 duration-700 hover:translate-x-1 `}
            >
              {user.userType == "school" ? "Join Campaign" : "Join Campaign"}
            </button>
          )}
        </div>
        {joinCampaign && (
          <JoinSDG4Campaign toggleJoinCampaign={toggleJoinCampaign} />
        )}
      </section>

      {/* Tird scection  */}

      <section className="w-full h-full bg-white p-4 rounded-xl mt-10 relative">
        <div className="w-full flex justify-start items-center ">
          <div
            className="w-20 h-20  border-[#1D6DAE] text-[#00AFEF] rounded-full mr-2 flex justify-center items-center font-bold "
            style={{ borderWidth: 10 }}
          >
            SDG4
          </div>
          <div className="ml-4 font-bold text-xl">
          Strategic Knowledge Base Advancement Program
          </div>
        </div>
        <div className="text-justify text-xs md:text-sm mt-3 pb-3 border-b border-b-gray-300">
          Endorsed and adopted by federal government authorities in countries
          like Nigeria as a strategic sustainable national development program,
          the Science and Technology and technology Base Advancement program is
          a UN Sustainable Development Goal no. 4 aligned program designed by to
          rouse interest in of your people in science and technology education
          and career, without bias to other areas, and strengthen the overall
          functionality and quality education from the very basic to the
          tertiary level in equipping students passing through school with
          knowledge and skills needs needed real productivity in transforming
          raw material resources and their intellect into distributed products,
          services and fixed infrastructure that serve the needs of their
          country, create jobs, generate wealth and create livelihood
          opportunities, while build their appreciation for shared positive
          values and healthy communal instincts. The program involves a
          combination of tools as products and processes as service to deliver
          17 target sub-goals. We provide schools in countries around the world
          a platform for joining this program. You can get your school captured
          on this program by simply selecting any and all of its 17 target
          sub-goals of interest to you. You would receive free updates on the
          program target sub-goals of concern to your school and how they met
          through different tools and services supplied by different public and
          private sector partnering entities on this program, including Esgrown
        </div>
      </section>
    </div>
  );
}

export default SDG4;
