import React, { useLayoutEffect, useState } from "react";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { Rate, Popover } from "antd";
import EngageApplicantsPopoverContent from "../popover/EngageApplicantsPopoverContent";
import CreateNewExercise from "./CreateNewExercise";
import AxiosInstance from "../../../AxiosInstance/AxiosInstance";
import Toast from "../../../util/toast";

function EngageApplicants({ history, toggleEngageApplicants }) {
  const [createExercise, setCreateExercise] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allJobsPostedByUser, setAllJobsPostedByUser] = useState([]);
  const [applicationsForAJob, setApplicationsForAJob] = useState([]);
  const toggleCreateExercise = () => setCreateExercise((prev) => !prev);

  const dummyData = [
    { name: "John Huxley", skillRate: 4.5, PEA: 88 },
    { name: "Kenneth Oloche", skillRate: 3.5, PEA: 56 },
    { name: "John Huxley", skillRate: 2, PEA: 56 },
    { name: "Kenneth Oloche", skillRate: 4.5, PEA: 56 },
    { name: "John Huxley", skillRate: 5, PEA: 58 },
    { name: "John Huxley", skillRate: 0.5, PEA: 0 },
    { name: "Kenneth Oloche", skillRate: 4.5, PEA: 56 },
    { name: "John Huxley", skillRate: 4.5, PEA: 156 },
    { name: "John Huxley", skillRate: 1, PEA: 10 },
    { name: "John Huxley", skillRate: 4.5, PEA: 100 },
  ];

  const getAllApplicationsForAJob = async (e) => {
    if (!Boolean(e.target.value)) {
      setApplicationsForAJob([]);
      return;
    }
    const selectedJobId = e.target.value;
    setLoading(true);

    try {
      const { data } = await AxiosInstance.post(
        `/api/application/get-all-applications-for-a-job?job=${selectedJobId}`
      );
      console.log("All Appls for a Job:", data.applications);
      if (data && data.applications.length > 0) {
        setApplicationsForAJob(data.applications);
      }
    } catch (error) {
      return Toast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    // get all the jobs that the logged in user has applid to
    const getAllVacanciesPostedByUser = async () => {
      setLoading(true);

      try {
        const { data } = await AxiosInstance.post(
          "/api/jobs/get-all-vacancies-posted-by-user"
        );
        console.log("All vancancies posted by user:", data.jobs);
        if (data && data.jobs?.length > 0) {
          setAllJobsPostedByUser(data.jobs);
        }
      } catch (error) {
        return Toast(error.message, "error");
      } finally {
        setLoading(false);
      }
    };

    getAllVacanciesPostedByUser();
  }, []);

  return (
    <div className="absolute -top-[80px] -left-4 w-full h-screen">
      <div
        className="w-[90%] mx-auto bg-white  rounded-xl shadow-lg p-4"
        style={{ minHeight: 450, zIndex: 500 }}
      >
        <div className="w-full flex justify-end mr-2">
          <CloseCircleTwoTone onClick={toggleEngageApplicants} />
        </div>
        {/* <div className="my-2 border-b "></div> */}
        <div className="w-full px-3 mt-3">
          {/* section one === top row  */}
          <div className="w-full flex justify-between items-center">
            <div className="w-[60%] flex justify-start space-x-2 items-center">
              <button className="flex text-lg text-esgrown px-3 py-1 mr-3 rounded-lg hover:text-white hover:bg-sky-400 transition-all duration-700 focus:outline-none ">
                Post Update
              </button>
              <div>|</div>
              <button
                onClick={toggleCreateExercise}
                className="flex text-lg text-esgrown px-3 py-1 mr-3 rounded-lg hover:text-white hover:bg-sky-400 transition-all duration-700 focus:outline-none"
              >
                Create PEA Exercise
              </button>
            </div>
            <div className="w-[38%] flex justify-between items-center">
              <select
                onChange={getAllApplicationsForAJob}
                className="w-[98%] px-2 py-2 border-2 rounded-lg focus:border-esgrown "
              >
                <option value="">Your Vacancies</option>
                {allJobsPostedByUser.map((job) => {
                  return (
                    <option name={job._id} value={job._id}>
                      {job?.title}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {/* section 2: table */}
          <div
            className={`w-full mt-3 px-2 ${
              applicationsForAJob.length > 0 ? "" : "hidden"
            }`}
          >
            <table className="w-full px-3 rounded-lg">
              <thead className=" bg-sky-200 text-lg font-bold">
                <th>Applicant</th>
                <th>Skill Rate</th>
                <th>PEA Score</th>
                <th>Manage</th>
              </thead>
              <tbody className="text-lg ">
                {applicationsForAJob.map((application, index) => {
                  return (
                    <tr className="mt-6  even:bg-sky-100">
                      <td>{application.user.name}</td>
                      {/* <td>{application.skillRate}</td> */}
                      <td>
                        <Rate
                          //   allowClear
                          //   autoFocus
                          allowHalf={true}
                          disabled={true}
                          tooltips={["1", "2", "3", "4", "5"]}
                          defaultValue={application.skillRate}
                          //   onChange={(value) => setPedagogy(value)}
                        />
                      </td>
                      <td>{application.PEA}</td>
                      <td>
                        <Popover
                          placement="left"
                          trigger="click"
                          content={
                            <EngageApplicantsPopoverContent
                              applicant={application}
                            />
                          }
                        >
                          <button className="px-2 py-1 bg-inherit rounded-lg text-gray-500 hover:translate-x-1 focus:outline-none transition-all duration-500">
                            actions
                          </button>
                        </Popover>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Fotter  */}
          <div
            className={`w-full flex justify-between items-center mt-10 px-2 ${
              applicationsForAJob.length > 0 ? "" : "hidden"
            }`}
          >
            <div className="font-semibold">
              Apllicants: {applicationsForAJob.length}
            </div>
            <div className="flex space-x-2">
              <button className="px-1 py-1 bg-gray-300 rounded-lg text-white hover:bg-esgrown hover:text-white focus:outline-none transition-all duration-500">
                Previous
              </button>
              <button className="px-2 py-1 bg-gray-300 rounded-lg text-white hover:bg-esgrown hover:text-white focus:outline-none transition-all duration-500">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      {createExercise && (
        <CreateNewExercise toggleCreateExercise={toggleCreateExercise} />
      )}
    </div>
  );
}

export default EngageApplicants;
