// import { Email } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
// import axios from "axios";
import { Modal, Spinner } from "react-bootstrap";

const RMForm = () => {
  const [user, setUser] = useState();
  const [amount, setAmount] = useState();
  const [plan, setPlan] = useState();
  const [payModal, setPayModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [proceedBtn, setProceedBtn] = useState(true);

  return (
    <>
      <form className="container py-4">
        <div className="row justify-content-center">
          <div className="text-center">
            <h3 className="text-bold">Subscription Details</h3>
          </div>
        </div>
        {/* Highest Level of Education */}
        <div className="row mt-3">
          <div className="col">
            <select className="form-control form-1">
              <option>Highest Level of Education</option>
              <option value="degree">Degree</option>
              <option value="masters">Masters</option>
              <option value="phd">Ph.D</option>
            </select>
          </div>
        </div>

        {/* Field of Training */}
        <div className="row mt-3">
          <div className="col">
            <input
              label=""
              type="text"
              // value={FT}
              // onChange={onChangeFT}
              placeholder="Field of Training"
              required
              className="form-control form-1"
            />
            {/* <select className="form-control" onChange={onChangeFT}>
                                    <option> Select Field of Training</option>
                                    <option value="VR">Virtual Reality</option>
                                    <option value="Data Science">Data Science</option>
                                    <option value="Computer Science">Computer Science</option>
                                    <option value="Medicine">Medicine</option>
                                    <option value="AI">Artificial Intelligence</option>
                                    <option value="Robotics">Robotics</option>
                                </select> */}
          </div>
        </div>

        {/* Subscription Plan */}

        <div className="row mt-3">
          <div className="col">
            <select className="form-control form-1">
              <option>Select Subscription Plan</option>
              <option value="PLN_9nw7wabu22gil79/460000">
                Biannual (six Months) 4600
              </option>
              <option value="PLN_w4frh2gjw4su74s/785000">
                Annual (one Year) 7850
              </option>
            </select>
          </div>
        </div>

        {/* submit button */}
        <div className="row mt-3">
          <div className="col">
            <button
              type="submit"
              className="btn font-weight-light btn-primary sub-but mt-3 py-2 w-100 border-0"
              disabled={spinner}
            >
              Proceed{" "}
              {spinner ? (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                ""
              )}{" "}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default RMForm;
