import React, { useEffect, useState } from "react";
// import { home } from "../components/svg/Home.svg";
// import { Logo } from ".././img/logoo.png";
import { Link } from "react-router-dom";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Layout, Menu, Tabs, Drawer } from "antd";
import {
  HomeOutlined,
  ScheduleOutlined,
  LoginOutlined,
} from "@ant-design/icons";

import UcbiHistory from "./ucbi-history";
import UcbiPayment from "./ucbi-payment";
import OnlineUser from "../../reusable/OnlineUser";
import { useSelector } from "react-redux";
import AxiosInstance from "../../../AxiosInstance/AxiosInstance";
import { useLayoutEffect } from "react";

const { Header, Sider, Content } = Layout;
const { TabPane } = Tabs;

const Full = (state) => {
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [processedPayments, setProcessedPayments] = useState([]);
  const [open, setOpen] = useState(true);
  const user = useSelector((state) => state.user.user);

  function onClose() {
    setOpen(false);
  }

  useLayoutEffect(() => {
    const fetchrRequets = async () => {
      setLoading(true);
      const resp = await AxiosInstance.post("/api/ucbi/get-all-payment-requests");
      if (resp.data && resp.data.requests) {
        setRequests(resp.data.requests);
      }
      setLoading(false);
    };
    fetchrRequets();
  }, [requests.length]);

  useLayoutEffect(() => {
    const fetchAllProcessedPayments = async () => {
      setLoading(true);
      const resp = await AxiosInstance.post("/api/ucbi/get-all-processed-requests");
      if (resp.data && resp.data.processedRequests) {
        setProcessedPayments(resp.data.processedRequests);
      }
      setLoading(false);
    };
    fetchAllProcessedPayments();
  }, [requests.length]);

  return (
    <Layout
      style={{ minHeight: "100vh", maxHeight: "100%", flexWrap: "nowrap" }}
    >
      <Drawer
        placement="left"
        onClose={onClose}
        closable={false}
        visible={collapsed}
        className="hideOnDesktop"
        width="250px"
        bodyStyle={{
          backgroundColor: "#1D6DAE",
          padding: "0",
        }}
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <Menu
          mode="inline"
          className="sidebar-bg f-2"
          defaultSelectedKeys={["5"]}
        >
       <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
            <Link to="/AdminDashboard" />
            <span>Home</span>
          </Menu.Item>

          <Menu.Item key="2" icon={<ShoppingCartOutlined />} className="font-2 mt-2">
            <Link to="/adminStore" />
            <span>Store</span>
          </Menu.Item> 
          <Menu.Item key="3" icon={<ShoppingCartOutlined />}>
            <Link to="/adminOrders" />
            <span>Store Orders</span>
          </Menu.Item> 
          <Menu.Item key="4" icon={<ScheduleOutlined />}>
            <Link to="/Admin_Page" />
            <span>Admin</span>
          </Menu.Item>
          <Menu.Item key="5" icon={<ScheduleOutlined />}>
            <Link to="/UCBI" />
            <span>UCBI</span>
          </Menu.Item>
          <Menu.Item key="6" icon={<ScheduleOutlined />}>
            <Link to="/update" />
            <span>Updates</span>
          </Menu.Item>
          {/* <Menu.Item
            onClick={toggleCreateServiceModal}
            key="7"
            icon={<ScheduleOutlined />}
          >
            <span>create service</span>
          </Menu.Item> */}
          <Menu.Item key="8" icon={<ScheduleOutlined />}>
            <Link to="/campaigns" />
            <span>Campaigns</span>
          </Menu.Item>
          <Menu.Item key="10" icon={<ScheduleOutlined />}>
            <Link to="/exercises" />
            <span>Exercises</span>
          </Menu.Item>

          <Menu.Item key="9" icon={<LoginOutlined />}>
            <Link to="/logout" />
            <span>Logout</span>
          </Menu.Item>
        </Menu>
      </Drawer>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onBreakpoint={(broken) => setCollapsed(broken)}
        className="hideOnMobile sidebar-bg"
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <Menu
          mode="inline"
          className="sidebar-bg f-2"
          defaultSelectedKeys={["5"]}
        >
         <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
            <Link to="/AdminDashboard" />
            <span>Home</span>
          </Menu.Item>

          <Menu.Item key="2" icon={<ShoppingCartOutlined />} className="font-2 mt-2">
            <Link to="/adminStore" />
            <span>Store</span>
          </Menu.Item> 
          <Menu.Item key="3" icon={<ShoppingCartOutlined />}>
            <Link to="/adminOrders" />
            <span>Store Orders</span>
          </Menu.Item> 
          <Menu.Item key="4" icon={<ScheduleOutlined />}>
            <Link to="/Admin_Page" />
            <span>Admin</span>
          </Menu.Item>
          <Menu.Item key="5" icon={<ScheduleOutlined />}>
            <Link to="/UCBI" />
            <span>UCBI</span>
          </Menu.Item>
          <Menu.Item key="6" icon={<ScheduleOutlined />}>
            <Link to="/update" />
            <span>Updates</span>
          </Menu.Item>
          {/* <Menu.Item
            onClick={toggleCreateServiceModal}
            key="7"
            icon={<ScheduleOutlined />}
          >
            <span>create service</span>
          </Menu.Item> */}
          <Menu.Item key="8" icon={<ScheduleOutlined />}>
            <Link to="/campaigns" />
            <span>Campaigns</span>
          </Menu.Item>
          <Menu.Item key="10" icon={<ScheduleOutlined />}>
            <Link to="/exercises" />
            <span>Exercises</span>
          </Menu.Item>

          <Menu.Item key="9" icon={<LoginOutlined />}>
            <Link to="/logout" />
            <span>Logout</span>
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout>
        <Header className="row pt-2 head">
          <div className="ml-auto flex items-center ">
            <div>
              <OnlineUser user={user} />
            </div>
            <div className="ml-auto transition hover:translate-x-1 hover:duration-500">
              {user && user.name}
            </div>
          </div>
        </Header>

        <Content
          style={{
            margin: "24px 16px",
            padding: 10,
            // background: "#fff",
            minHeight: 280,
          }}
        >
          <div className="tab-but">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Payment request" key="1">
                <UcbiPayment requests={requests} setRequests={setRequests} />
              </TabPane>
              <TabPane tab="History" key="2">
                <UcbiHistory processedPayments={processedPayments} />
              </TabPane>
            </Tabs>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Full;
