import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import AxiosInstance from "../AxiosInstance/AxiosInstance";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Row, Col, Card } from "react-bootstrap";
import "../App.css";
import allcountries from "../util/allcountries";
import toast from "../util/toast";
import circle from "../img/check-circle.svg";
import logo from "../img/logo_esgrown.png";
import logo_dash from "../img/esgrown_dash.png";

import { useDispatch } from "react-redux";
import { setUser } from "../redux/slices/userSlice";
import Axios from "axios";

const CorporateSignUp = ({ history }) => {
  const dispatch = useDispatch();
  const [corpData, setCorpData] = useState({
    org_name: "",
    email: "",
    password: "",
    confirmpassword: "",
    phone: "",
    doi: "",
    country: "",
    state: "",
    status: "corporate",
    org_type: "",
    numberOfStudents: "",
  });

  function onChangeOrgname(e) {
    // setState({
    //   org_name: e.target.value,
    // });
    setCorpData({ ...corpData, org_name: e.target.value });
  }

  function onChangeEmail(e) {
    // setState({
    //   email: e.target.value,
    // });
    setCorpData({ ...corpData, email: e.target.value });
  }

  function onChangePassword(e) {
    // setState({
    //   password: e.target.value,
    // });
    setCorpData({ ...corpData, password: e.target.value });
  }

  function onChangePhone(e) {
    // setState({
    //   phone: e.target.value,
    // });
    setCorpData({ ...corpData, phone: e.target.value });
  }

  function onChangeDOI(date) {
    // this.setState({
    //   doi: date.target.value,
    // });
    setCorpData({ ...corpData, doi: date.target.value });
  }

  function onChangeCountry(e) {
    setCorpData({ ...corpData, country: e.target.value });
  }

  function onChangeState(e) {
    setCorpData({ ...corpData, state: e.target.value });
  }

  function onChangeType(e) {
    setCorpData({ ...corpData, org_type: e.target.value });
    console.log("corpdata", corpData);
  }

  function onChangePasswordConfirm(e) {
    setCorpData({ ...corpData, confirmpassword: e.target.value });
  }

  function onChangeNumberOfStudents(e) {
    setCorpData({ ...corpData, numberOfStudents: e.target.value });
  }

  function onSubmit(e) {
    e.preventDefault();

    if (corpData.confirmpassword === corpData.password) {
      const user = {
        email: corpData.email,
        name: corpData.org_name,
        password: corpData.password,
        phone: corpData.phone,
        country: corpData.country,
        state: corpData.state,
        userType: corpData.org_type,
        org_name: corpData.org_name,
        numberOfStudents: Number(corpData.numberOfStudents)
      };

      console.log("user", user);

      AxiosInstance
        .post(`/api/user/signup`, { data: user })
        .then((res) => {
          if (!res.data.error) {
            const Global_CorpUser = {
              isLogged: true,
              ...res.data.user,
              token: res.data.token,
            };

            dispatch(setUser(Global_CorpUser));
            Axios.defaults.headers.common = {
              Authorization: "Bearer " + Global_CorpUser.token,
            };
            toast("Registration successful!", "success");
            history.push("/home");
          } else {
            // server respond with error
            setCorpData({ ...corpData, loading: false });
            return toast(res.data.message, "error");
          }
        })
        .catch((err) => {
          setCorpData({ ...corpData, loading: false });
          toast(err.message, "error");
        });

      setCorpData({
        org_name: "",
        email: "",
        password: "",
        phone: "",
        doi: new Date(),
        country: "",
        state: "",
        numberOfStudents: ""
      });
    } else {
      return toast("Passwords must match!", "error");
    }
  }

  return (
    <div className="w-full h-full flex flex-col md:flex-row-reverse space-y-4 md:space-y-0">
      {/* /////////////////Sign Up form////////////////       */}
      <div className="w-full md:w-1/2 bg-white ">
        {/* <div> */}
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div className="md:hidden">
            <img src={logo_dash} className="mx-auto" />
          </div>
          <h3 className="mt-3 mb-1 text-lg font-bold">
            Create new Corporate Account
          </h3>
          <p className="mb-2">
            Already have an account? <Link to="/">Sign in</Link>
          </p>
        </div>
        <div>
          <div className="justify-center flex mb-4">
            <div className="in row">
              <Link to="/signup_individual" className="in-3 ml-3">
                Individual
              </Link>
              <div className="in-2 ml-3">
                <div className="in-1">Coporate</div>
              </div>
            </div>
          </div>
          <form onSubmit={onSubmit}>
            <div className="w-11/12 mx-4">
              {/* <label>Full Name:</label> */}
              <div className="input-group">
                <input
                  className="form-control"
                  required
                  type="text"
                  onChange={onChangeOrgname}
                  value={corpData.org_name}
                  placeholder="Enter name of Organization..."
                ></input>
              </div>
              <div className="w-full flex mt-3 space-x-8">
                <div className="flex  w-1/2">
                  <div className="form-group mb-3 w-full">
                    <select
                      class="custom-select"
                      id="inputGroupSelect01"
                      required
                      onChange={onChangeType}
                    >
                      <option>Select Account Type</option>
                      <option value="school">School</option>
                      <option value="company">Company</option>
                    </select>
                  </div>
                </div>
                <div className="flex w-1/2 ">
                  <div className="form-group mb-3 w-full">
                    <input
                      className="form-control"
                      required
                      type="email"
                      onChange={onChangeEmail}
                      value={corpData.email}
                      placeholder="Enter Email..."
                    ></input>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <input
                  className="form-control"
                  required
                  type="password"
                  onChange={onChangePassword}
                  value={corpData.password}
                  placeholder="Enter Password"
                ></input>
              </div>
              <div className="row mt-3">
                <div className={`col`}>
                  <input
                    className="form-control"
                    required
                    type="password"
                    onChange={onChangePasswordConfirm}
                    value={corpData.confirmpassword}
                    placeholder="Confirm Password"
                  ></input>
                </div>
              </div>

              <br />
              <Button
                className="btn btn-primary text-[#53A6E7] w-100"
                type="submit"
              >
                {corpData.loading ? (
                  <i className="fa fa-spinner fa-spin ml-2"></i>
                ) : (
                  "Sign Up"
                )}
              </Button>
              <br />
              {/* </div> */}
            </div>
          </form>
          <div className="w-full p-2 flex justify-center items-center">
            Have an account ?
            <button
              onClick={() => {
                history.push("/home");
              }}
              className="ml-1 px-2 rounded-lg focus:outline-none hover:border border-[#53A6E7]"
            >
              Login
            </button>
          </div>
        </div>
        {/* </div> */}
      </div>
      {/* Info section  */}
      <div className="w-full md:w-1/2 ind-bg h-full px-20 py-6">
        <img src={logo} className="hidden md:block" />
        <div className="mt-10">
          <h1 className="s-1">
            <span className="free">Premium</span> Services
          </h1>
          <p className="text-white text-2xl">on Esgrown</p>
        </div>
        {/* first row  */}
        {/* third row  */}
        <div className="flex mt-4">
          <div className="mr-4">
            <img src={circle} />
          </div>
          <div>
            <h3 className="text-white">Recruitment management</h3>
            <p className="text-white">
              Build workforce that meets knowledge and skills needs for your
              business success
            </p>
          </div>
        </div>

        {/* second row  */}

        <div className="flex mt-4">
          <div className="mr-4">
            <img src={circle} />
          </div>
          <div>
            <h3 className="text-white">Personnel Competence Management</h3>
            <p className="text-white">
              Optimize staff efficiency and motivation in leveraging knowledge
              and skills into meeting goals;
            </p>
          </div>
        </div>

        {/* third row  */}
        <div className="flex mt-4">
          <div className="mr-4">
            <img src={circle} />
          </div>
          <div>
            <h3 className="text-white">
              Teachers Competence and Performance Management
            </h3>
            <p className="text-white">
              Boost capacity, motivation and performance of teachers in
              functional education delivery;
            </p>
          </div>
        </div>
        {/* Fourth row  */}
        <div className="flex mt-4">
          <div className="mr-4">
            <img src={circle} />
          </div>
          <div>
            <h3 className="text-white">Leadership Advisory</h3>
            <p className="text-white">
              Stay up to on key principles of business leadership and
              organizational success
            </p>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};
export default CorporateSignUp;
// Render all Countries
function renderCountries() {
  return allcountries.map((item) => {
    return <option value={item.country}>{item.country}</option>;
  });
}
