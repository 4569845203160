import {
    CloseCircleTwoTone,
  } from "@ant-design/icons";
  import { Popover } from "antd";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import Toast from "../../../../util/toast";
  import AxiosInstance from "../../../../AxiosInstance/AxiosInstance";
  import RemoveOrganizationConfirmationModal from "./RemoveOrganizationConfirmationModal";
  import {setUcbiUserDetails,
          setShowUcbiDetailModal,
          getAllTeachersWitUcbis,
          setMarkedAsProcessed
   } from "../../../../redux/slices/ucbiSlice"; 
import CreateAdmin from "../../../admin/dashboards/admin/modal/createAdminModal";
import RemoveAdminConfirmationModal from "./RemoveAdminConfirmationModal";
  
  function UCBIDetailsModal({setRequests}) {
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState([]);
    
    const {
      ucbiUserDetails
    } = useSelector((state) => state.ucbiSlice);

     const markAsProcessed = async (request) => {
      const route = `api/ucbi/mark-payment-request-as-processed?id=${request._id}`;
      try {
        setLoading(true);
        const resp = await AxiosInstance.post(route);
        if (!resp.data.error) {
          setRequests(resp.data.requests);
          Toast("Action completed successfully!", "success");
        } else Toast(resp.data.message, "error");
      } catch (error) {
        Toast(error.message, "error");
      } finally {
        setLoading(false);
      }
    };

    useEffect(()=>{
      dispatch(getAllTeachersWitUcbis())
    },[request])

    return (
      <div
        className="w-full h-full flex bg-white flex-col items-end relative z-auto"
        // style={{ zIndex: 200 }}
      >
        <div className="h-[20%] pb-1 w-full">
          <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
            <CloseCircleTwoTone
              onClick={() => {
                dispatch(setShowUcbiDetailModal(false))
                // dispatch(setShowListOfAdmins(true))
            }}
              color="red"
            />
          </div>
          <div className="flex flex-col min-h-min  ">
            {/* section 2*/}
            <div class="font-bold text-lg border-b border-b-[#1D6DAE]">{ ucbiUserDetails?.user?.userType == "teacher" ? "Conditional Earned Basic Income Payout Request  Processing ": "SDP Associate Advisor Conditional Earned Basic Income Payout Request  Processing"} </div>
            <div className=" mt-1 flex p-2 justify-between border-b border-b-[#1D6DAE]">
              <div className="w-[20%] rounded-lg bg-green-400 font-semibold text-base flex row items-center justify-center">
                {/* Image  */}
                <img
                  // rounded
                  src="{}"
                  className="w-full h-full object-cover rounded-lg"
                  // style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div className="w-[86%] px-[10%]  font-semibold text-base">
                {/* details  */}
                <div className="w-full flex justify-between ">
                  <div>Name:</div>
                  <div className="text-left w-1/2">
                    {ucbiUserDetails?.user?.name}
                  </div>
                </div>

                { ucbiUserDetails?.user?.userType == "teacher" && ucbiUserDetails?.user?.school
                ? 
                <div className="w-full flex justify-between ">
                  <div>School:</div>
                  <div className="text-left w-1/2">
                    {ucbiUserDetails?.user?.school}
                  </div>
                </div>
                :
                <div className="w-full flex justify-between ">
                  <div>SDG Support Organization:</div>
                  <div className="text-left w-1/2">
                    {ucbiUserDetails?.user?.orgName}
                  </div>
                </div>}

                <div className="w-full flex justify-between ">
                  <div>City:</div>
                  <div className="text-left w-1/2">{ucbiUserDetails?.user?.city}</div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>State/Province:</div>
                  <div className="text-left w-1/2">
                    {ucbiUserDetails?.user?.state}
                  </div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>Country:</div>
                  <div className="text-left w-1/2">
                    {ucbiUserDetails?.user?.country}
                  </div>
                </div>
                {  ucbiUserDetails?.user?.userType == "teacher" ?
                  <div className="w-full flex justify-between ">
                    <div>TIDC:</div>
                    <div className="text-left w-1/2">
                      {ucbiUserDetails?.user?.uniqueId}
                    </div>
                  </div>
                  :
                  <div className="w-full flex justify-between ">
                    <div>AIDC:</div>
                    <div className="text-left w-1/2">
                      {ucbiUserDetails?.user?.uniqueId}
                    </div>
                  </div>
                  }
                  <div className="w-full flex justify-between ">
                    <div>Contact:</div>
                    <div className="text-left w-1/2">
                      {ucbiUserDetails?.user?.phone}
                    </div>
                  </div>
              </div>
            </div>
            
            <div>
            { ucbiUserDetails?.user?.userType != "teacher" && ucbiUserDetails?.rank
              ?  <div className="w-full flex justify-between font-semibold px-1">
                    <div>Rank:   {ucbiUserDetails?.rank}</div>
              </div>
              :""}
              <div className="w-full flex justify-between mt-1 p-1">
                <button className="px-6 bg-[#8CBF1C] border-2 border-[#1D6DAE] border-solid text-black py-2 rounded-xl  px-20 text-md font-semibold">
                  Request: N{ucbiUserDetails?.amount}
                </button>

                <button className="px-6 text-sm bg-[#CED4E2] border-2 border-[#1D6DAE] border-solidtext-black py-1 rounded-xl font-semibold ">
                  Vault Balance: N{ucbiUserDetails?.balance}
                </button>
              </div>
            </div> 
            <div class="mt-1 font-semibold p-1">
              <div className="w-full flex justify-start ">
                  <div>Bank:</div>
                  <div className="text-left w-1/2 ml-4">
                    {ucbiUserDetails?.bankName}
                  </div>
              </div>
              <div className="w-full flex justify-start ">
                  <div>Account Name:</div>
                  <div className="text-left w-1/2 ml-4">
                    {ucbiUserDetails?.accountName}
                  </div>
              </div>
              <div className="w-full flex justify-start ">
                  <div>Account Number:</div>
                  <div className="text-left w-1/2 ml-4">
                    {ucbiUserDetails?.accountNumber}
                  </div>
              </div>
            </div> 

            {/* section 3 */}
            <div>
              <div className="w-full flex justify-end mt-[-1]">
                <Popover
                    placement="left"
                    trigger="click"
                    content={
                      <div className="w-full flex space-x-2 py-4">
                        <button
                          onClick={() =>{
                            markAsProcessed(ucbiUserDetails)
                            dispatch(setShowUcbiDetailModal(false))
                            dispatch(setMarkedAsProcessed(true))
                          }
                          }
                          className="px-2 outline-none focus:outline-none  rounded-full transition-all duration-500 hover:-translate-y-1 hover:bg-green-600 hover:text-white"
                        >
                          confirm
                        </button>
                        <button className="px-2 outline-none focus:outline-none  rounded-full transition-all duration-500 hover:-translate-y-1 hover:bg-red-500 hover:text-white">
                          cancel
                        </button>
                      </div>
                    }
                  >
                    <button className="px-4 text-sm font-semibold rounded-xl border-2 border-black border-solid  bg-[#1D6DAE] text-white py-2 hover:translate-y-1 transition-all duration-700 removeButton">
                      Mark as processed
                    </button>
                  </Popover>
              </div>
            </div> 
            </div>
          </div>

          {/* {removeOrganizationPrompt && (<RemoveOrganizationConfirmationModal/>)} */}
          {/* {editAdminDetailsModal && (
          <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}> */}
            <CreateAdmin
            show={modal}
            onHide={() => setModal(false)}
            />
          {/* </div>
          )} */}
          
      </div>
    );
  }
  
  export default UCBIDetailsModal;
  