import {
  CloseCircleTwoTone,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Popover } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import SchoolSelectedForProcessing from "./SchoolSelectedForProcessing";

function SchoolsInCountry({
  setSetShowListOfSchools,
  filterSTEKADataOfACountry,
}) {
  const [showSchoolContact, setShowSchoolContact] = useState(false);
  const [showSelectedSchool, setShowSelectedSchool] = useState(false);
  const [schoolSelected, setSchoolSelected] = useState(null);

  const stekasInCountry = useSelector(
    (state) => state.campaign.stekasInCountry
  );

  const campaign = useSelector((state) => state.campaign.refreshCampaign);
  const country = useSelector((state) => state.campaign.country);

  useLayoutEffect(() => {
    window &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  }, [campaign]);

  useEffect(() => {
    if (country) {
      filterSTEKADataOfACountry(country);
    }
  }, [campaign]);

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="h-[20%] pb-1 border-b border-b-[#1D6DAE] w-full">
        <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
          <CloseCircleTwoTone
            onClick={() => setSetShowListOfSchools(false)}
            color="red"
          />
        </div>
        <div className="flex justify-between items-end pb-1 w-full h-[60%] ">
          <div className="text-xl font-semibold  w-[10%]">
            {/* //All the schools that made it here are in the same county, */}
            {stekasInCountry &&
              stekasInCountry.length > 0 &&
              stekasInCountry[0]?.country}
            :
          </div>
          {/* State  */}
          <div className="w-[20%] ">
            <select
              name=""
              id=""
              className="w-full py-1 focus:border-[#1D6DAE] rounded-lg focus:outline-none border-2"
            >
              <option value="">All States</option>
              <option value="">Abia</option>
              <option value="">Adamawa</option>
            </select>
          </div>
          {/* City  */}
          <div className="w-[20%]">
            <select
              name=""
              id=""
              className="w-full py-1 focus:border-[#1D6DAE] rounded-lg  focus:outline-none border-2"
            >
              <option value="">All Cities</option>
              <option value="">City 1</option>
              <option value="">City 2</option>
            </select>
          </div>
          {/* Search  */}
          <div className="w-[30%] relative">
            <input
              type="text"
              className="w-full py-1 rounded-full pl-3 pr-6 focus:outline-none  focus:border-[#1D6DAE] border-2"
            ></input>
            <span
              className="absolute top-0  right-2 cursor-pointer"
              style={{ zIndex: 120 }}
            >
              <SearchOutlined className="text-lg transition-all hover:-translate-y-0.5" />
            </span>
          </div>
        </div>
      </div>
      {/* list of schools  */}
      <div className="h-[70%] border-b border-b-blue-500">
        {Array.from(stekasInCountry).length > 0 ? (
          Array.from(stekasInCountry).map((steka, index) => (
            <div
              key={index.toString()}
              className="odd:bg-gray-200 mt-1 h-10 text-gray-600 font-semibold px-4 flex justify-between"
            >
              <p className=" ">{steka.user?.name}</p>
              <Popover
                placement="left"
                trigger="click"
                content={
                  <div className="min-w-min min-h-min flex flex-col items-start justify-start space-y-2 ">
                    <button
                      onClick={() => {
                        setShowSelectedSchool(true);
                        setSchoolSelected(steka);
                      }}
                      className="px-2 outline-none  focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                    >
                      Process Request
                    </button>
                    <button
                      onClick={() => {
                        setShowSchoolContact(!showSchoolContact);
                      }}
                      className="flex items-center px-2 outline-none focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                    >
                      Contact School
                      <span className="mb-1 ml-0.5">
                        <DownOutlined size="small" style={{ fontSize: 10 }} />
                      </span>
                    </button>
                    {showSchoolContact && (
                      <div className="w-[80%] min-h-min mx-auto">
                        <div className="flex flex-col">
                          <p className="font-semibold">Email:</p>
                          <p className="text-xs">{steka?.user.email}</p>
                        </div>
                        <div className="flex flex-col">
                          <p className="font-semibold">Phone:</p>
                          <p className="text-xs">{steka?.user.phone}</p>
                        </div>
                        <div className="flex flex-col">
                          <p className="font-semibold">PTA Chairman:</p>
                          <p className="text-xs">
                            {steka.user.ptaChairman
                              ? steka.user.ptaChairman
                              : "NIL"}
                          </p>
                        </div>
                      </div>
                    )}
                    <button
                      // onClick={() => removeStaff(teacher.email)}
                      className="px-2 outline-none focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                    >
                      View Profile
                    </button>
                  </div>
                }
              >
                <button className="text-[#1D6DAE] focus:outline-none transition-all hover:translate-x-1 duration-500">
                  Actions
                </button>
              </Popover>
            </div>
          ))
        ) : (
          <div className="w-full flex justify-center h-10 mt-1.5 ">
            <div>All requests have been processed 👏 </div>
          </div>
        )}
      </div>
      <div className="flex h-[10%] justify-end items-center ">
        <button className="px-3 py-1 rounded-lg font-semibold border-2 border-[#1D6DAE] transition-all duration-700 text-[#1D6DAE] hover:bg-[#1D6DAE] hover:text-white ">
          Next
        </button>
      </div>
      {/* Selected School  */}
      {showSelectedSchool && (
        <div className="w-full h-full absolute inset-0" style={{ zIndex: 200 }}>
          <SchoolSelectedForProcessing
            setShowSelectedSchool={setShowSelectedSchool}
            stekaSchool={schoolSelected}
          />
        </div>
      )}
    </div>
  );
}

export default SchoolsInCountry;
