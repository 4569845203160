import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseCircleTwoTone, EditOutlined, PlusOutlined } from "@ant-design/icons";
import AxiosInstance from "../../../../AxiosInstance/AxiosInstance";
import Toast from "../../../../util/toast";
import ctries from "../../../../../src/util/ctryModified.json";
// import Checkbox from "antd/lib/checkbox/Checkbox";
import {
  setShowOrganizationCreateModal,
  setShowListOfOrganizations,
  setShowOrganizationPopOver,
  setEditSupportingOrganizationDetailsModal
 } from "../../../../redux/slices/sponsoringOrganizationSlice";
import { setCountry } from "../../../../redux/slices/campaignSlice";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

function SponsoringOrg({ toggleAddSponsoringOrg }) {
  const [country, setCountry] = useState(null);
  const [name, setName] = useState(null);
  const [field, setField] = useState([]);
  const [field2, setField2] = useState([]);
  const [state, setState] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [phone, setPhone] = useState(null);
  const [address, setAddress] = useState(null);
  const [organizationType, setOrganizationType] = useState(null);
  const [area, setArea] = useState(null);
  const [campaignType, setCampaignType] = useState(null);
  const [statesInSelCountry, setStatesInSelCountry] = useState(null);
  const [edit, setEdit] = useState(false);
  const [campaignSupport, setCampaignSupport] = useState(null);
  const [fieldsFromDb, setfieldsFromDb] = useState(null);

  // console.log("organizationType", organizationType);
  const MenuProps = {
    PaperProps: {
      style: {
        // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
      },
    },
  };

  const subGoals = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
   
    setField(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const dispatch = useDispatch()
  const {
    organizationDetail,
  } = useSelector(state => state.sponsoringOrganizationSlice)
  const {
    editSupportingOrganizationDetailsModal,

  } = useSelector(state => state.sponsoringOrganizationSlice.componentsStates)
  
  useState(()=>{
    if(editSupportingOrganizationDetailsModal){
      setCountry(organizationDetail.country);
      setName(organizationDetail.name);
      setField(organizationDetail.organizationType === "Business Franchise" ? organizationDetail.field.split(",") : organizationDetail.field);
      setState(organizationDetail.state);
      setEmail(organizationDetail.email);
      setPassword(organizationDetail.password)
      setPhone(organizationDetail.phone);
      setAddress(organizationDetail.address);
      setOrganizationType(organizationDetail.organizationType);
      setArea(organizationDetail.area);
      setCampaignType(organizationDetail.campaignSupport);
      setCampaignSupport(organizationDetail.campaignSupport)
    }
  }, [editSupportingOrganizationDetailsModal])

  useEffect(()=>{
    // if(country){
      for(let i = 0; i < ctries.length; i++){
        if (ctries[i].country == country){
          setStatesInSelCountry(ctries[i]['states'])
        }
      // }
    }
  }, [country])

  useEffect(() => {
    const fetchOrgs = async() => {
        const reps = await AxiosInstance.post("/api/user/fetch-sponsoring-org");
        if(reps.data) {
          console.log(reps.data);
          setfieldsFromDb(reps.data.orgs);
          console.log(reps.data.orgs);
        }
      }
    fetchOrgs();
  }, []);

  const editOrganization = async() => {
    const data = {
      name,
      field: String(field),
      email,
      password,
      phone,
      address,
      organizationType,
      area,
      country,
      state,
      loading: false,
      // campaignType,
    };
    console.log("Edited Data", data);
    const resp = await AxiosInstance.post(`api/user/edit-sponsoring-org/${organizationDetail._id}`, data);
    console.log(resp.data);
    if (resp.data.error) {
      return Toast(resp.data.message, "error");
    } else {
      // toggleAddSponsoringOrg();
      return Toast(resp.data.message, "success");
    } 
  }

  const addOrganization = async() => {
    const data = {
      name,
      field: String(field),
      email,
      password,
      phone,
      address,
      organizationType,
      area,
      country: organizationType === "Global" ? "" : country,
      state,
      loading: false,
      // campaignType,
    };
    console.log("data", data);
    const resp = await AxiosInstance.post("/api/user/add-sponsoring-org", data);
    if (resp.data.error) {
      return Toast(resp.data.message, "error");
    } 
    if (resp.data.status=="error") {
      return Toast(resp.data.message, "error");
    } 
    else {
      // toggleAddSponsoringOrg();
      setCountry("");
      setName("");
      setField([]);
      setState("");
      setEmail("");
      setPassword("")
      setPhone("");
      setAddress("");
      setOrganizationType("");
      setArea("");
      setCampaignType("");
      setCampaignSupport("")
      return Toast(resp.data.message, "success");
    }
  };

  return (
    <div className="absolute top-12 z-50 md:left-[20%] p-8 pb-20 md:w-[70%] w-10/12 h-min bg-white text-gray-600 shadow-md rounded-lg ">
      <div className="w-full h-10 flex justify-between pr-2">
        <div className="pl-[1%] text-base font-semibold text-[#1D6DAE] flex items-center">
        UN SDG 4 Support Organizations
          {/* <EditOutlined 
            className="ml-2"
            onClick={() => {
              setEdit(!edit);
              //setStatesInSelCountry(null);
            }}
          /> */}
        </div>
        <CloseCircleTwoTone onClick={()=>{
          // toggleAddSponsoringOrg()
          dispatch(setShowOrganizationCreateModal(false))
          dispatch(setEditSupportingOrganizationDetailsModal(false))
          dispatch(setShowOrganizationPopOver(false))
          // dispatch(setShowListOfOrganizations(false))
          }} />
      </div>
      <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
        {!edit && <input
          onChange={(e)=> setName(e.target.value)}
          value = {name}
          required
          name="name"
          placeholder="Name of Organization"
          className="w-full border border-slate-300 outline-none rounded-lg px-2 py-2 "
        />}
      </div>
      <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
        {/* <select
          onChange={handleChange}
          value = {data.campaignType}
          required
          name="campaignType"
          className="w-full bg-gray-200 outline-none rounded-lg px-2 py-2 "
        >
          <option value="">Select Campaign</option>
          <option value="sdg4">SDG4</option>
          <option value="sdg8">SDG8</option>
        </select> */}
        <input
          onChange={(e)=>setPhone(e.target.value)}
          value = {phone}
          required
          name="phone"
          placeholder="Phone no of Organization"
          className="w-full border border-slate-300 outline-none rounded-lg px-2 py-2 "
        />
        <input
          onChange={(e)=>setEmail(e.target.value)}
          value = {email}
          required
          name="email"
          placeholder="email"
          className="w-full border border-slate-300 outline-none rounded-lg px-2 py-2 "
        />
      </div>
      <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
          <select 
            className="w-full border border-slate-300 outline-none rounded-lg px-2 py-2"
            name = 'Organization Type'
            // onChange={handleChange}
            // onChange={(e)=>{setOrganizationType(e.target.value)}}
            onChange={(e) => setOrganizationType(e.target.value)}
            value = {organizationType}
          >
            <option>Organization Type</option>
            <option>Government Organization</option>
            <option>Business Franchise</option>
          </select>
      </div>
      {organizationType == "Organization Type" || organizationType == "Government Organization" || organizationType == null?
      <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
        <input
          onChange={(e)=>setField(e.target.value)}
          name="field"
          value = {field}
          disabled={organizationType == null}
          placeholder={`${organizationType == "Organization Type" || organizationType == null ?
              "Mandate or Supported SDP Sub-goals in comma separate numbers" :
              "Mandate"}`}
          className="w-full border border-slate-300 outline-none rounded-lg px-2 py-2 "
        />
        </div>
        :
        <div className="flex px-2 mt-2 w-full space-x-3 justify-between"> 
              <FormControl sx={{ width: "100%", color: "grey" }} size="small">
                {/* <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel> */}
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  displayEmpty
                  value={field}
                  onChange={handleChange}
                  input={<OutlinedInput />}
                  // renderValue={(selected) => selected.join(', ')}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em className="selectPlaceholder">Supported SDP target sub-goals in comma separated numbers 1 to 17 (Example,1,2,3,4,5,6,7,10)</em>;
                    }
        
                    return selected.join(', ');
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem disabled value="">
                    <em className="selectPlaceholder">Supported SDP target sub-goals in comma separated numbers 1 to 17 (Example,1,2,3,4,5,6,7,10)</em>
                  </MenuItem>
                  {subGoals.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={field.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        </div>
        }
      {/* <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
        <textarea
          onChange={handleChange}
          required
          value = {data.address}
          name="address"
          placeholder="Address of Organization"
          className="w-full resize-none bg-gray-200 outline-none rounded-lg px-2 py-2 "
        ></textarea>
      </div> */}
      <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
          <select 
            className="w-full border border-slate-300 outline-none rounded-lg px-2 py-2"
            name = 'area'
            value = {area}
            onChange={(e)=> setArea(e.target.value)}
          >
            <option value="">Campaign Support Area</option>
            <option >Global</option>
            <option >National</option>
            <option >State/Provincial</option>
          </select>
          <select 
            className="w-full border border-slate-300 outline-none rounded-lg px-2 py-2"
            name = 'country'
            value = {area === "Global" ? "" : country}
            disabled = {area === "Global"}
            onChange={(e) => {
              // const _item = JSON.parse(e.target.value);
              // setCountry(_item.country);
              setCountry(e.target.value);
              // setStatesInSelCountry(_item.states);
            }}
          >
            <option value="">All Countries</option>
            {/* {renderCountries()} */}
            {ctries.length > 0 && ctries.map((country,i) =>{
              return <option key={i}>{country.country}</option>
            })}
          </select>
      </div>
      {/* <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
          <select
            name = 'state'
            //value = {data.state}
            onChange={(e) => {
              setState(e.target.value);
              setData({...data,
                state: e.target.value
              })
            }}
            disabled={!statesInSelCountry}
            className="w-full bg-gray-200 mx-auto rounded-lg border-2 outline-none px-2 py-1"
          >
            <option value="">Select state</option>
            {renderStates(statesInSelCountry)}
          </select>
      </div> */}
      <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
        {area == "State/Provincial" && (
          <select
                name = 'state'
                value = {state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
                disabled={!statesInSelCountry}
                className=" w-full border border-slate-300 mx-auto rounded-lg border-2 outline-none px-2 py-1"
              >
                <option value="">Select state</option>
                {renderStates(statesInSelCountry)}
            </select>
              )}
        <input
          onChange={(e)=>setPassword(e.target.value)}
          required
          type = 'password'
          name="password"
          value = {password}
          placeholder="Passcode"
          className={`${area== 'State/Provincial' ? 'w-full' : 'w-50'} border border-slate-300 outline-none rounded-lg px-2 py-2` }
        ></input>
      </div>
      <div className="flex px-2 mt-2 w-full justify-end mt-4">
        {!editSupportingOrganizationDetailsModal && <button type = 'submit' onClick={addOrganization} className="custom-primary-btn w-full">
            Create Campaign Sponsorship Account
        </button>}
        {editSupportingOrganizationDetailsModal &&
          <button type = 'submit' onClick={editOrganization} className="custom-primary-btn w-full">
            Edit Campaign Sponsorship Account
          </button>
        }
      </div>
    </div>
  );
}

export default SponsoringOrg;

function renderCountries() {
  return ctries.map((item) => {
    // console.log("item", item.country);
    setCountry(JSON.stringify(item))
    return <option>{item.country}</option>;
  });
}

// Render all states for the seleted country
function renderStates(states) {
  //console.log(states);
  if (states === null) return <></>;
  return states.map((state) => {
    return <option value={state}>{state}</option>;
  });
}

