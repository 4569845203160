import React, { useState, useEffect, useRef } from "react";
import ClickAwayListener from "react-click-away-listener";
import { useDispatch } from "react-redux";
// import { home } from "../svg/Home.svg";
// import { Logo } from "../../img/logoo.png";
import { Link } from "react-router-dom";

import { Layout, Menu, Dropdown, Space, Image } from "antd";
import Icon, {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  DatabaseOutlined,
  ScheduleOutlined,
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  EditOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import EditAccount from "./EditAccount";
import DropDownMenu from "./reusable/DropDownMenu";
import OnlineUser from "./reusable/OnlineUser";

////////////////////
import stekaImg1 from "../img/steka/IMG-20220924-WA0000.jpg";
import stekaImg2 from "../img/steka/steka.png";
import DarkOverlay from "./DarkOverlay";
import CaptureSchool from "./steka_modals/CaptureSchool";
/////////////////

// import { MediaPath } from "../../App";
import { MediaPath } from "../App";
import Toast from "../util/toast";
import { setUser } from "../redux/slices/userSlice";
import SmallScreenDrawer from "./SmallScreenDrawer";
// import { useUser } from "../../context/UserContext";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const Steka2 = ({ history }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [editAcct, setEditAcct] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stekaReadmore, setStekaReadmore] = useState(false);
  const [showSmallScreenDrawer, setShowSmallScreenDrawer] = useState(false);
  const user = useSelector((state) => state.user.user);

  const [openCapSchoolModal, setopenCapSchoolModal] = useState(false);
  const toggleCapSchoolModal = () => setopenCapSchoolModal(!openCapSchoolModal);

  const handleClick = ({ key }) => {
    //you can perform setState here
  };

  return (
    <Layout
      style={{ minHeight: "100vh", maxHeight: "100%", flexWrap: "nowrap" }}
    >
      <Sider
        trigger={null}
        className="sidebar-bg hidden md:block"
        collapsible
        collapsed={collapsed}
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <Menu
          mode="inline"
          // theme="dark"
          className="sidebar-bg f-2"
          defaultSelectedKeys={["2"]}
          onClick={handleClick}
        >
          <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
            <Link to="/home" />

            <span>Home</span>
          </Menu.Item>

          <Menu.Item key="2" icon={<ProfileOutlined />} className="font-2 mt-2">
            <Link to="/profile" />
            <span>Profile</span>
          </Menu.Item>
          <Menu.Item key="3" icon={<FileDoneOutlined />}>
            <Link to="/subscribe" />
            <span>Subscription</span>
          </Menu.Item>
          {/* <SubMenu
            key="sub3"
            title={<span>Services</span>}
            icon={<DatabaseOutlined />}
          >
            <Menu.Item key="6">
              <Link to="/EAS" />
              EAS Services
            </Menu.Item>
            <Menu.Item key="7">
              <Link to="/EFA" />
              EFA Services
            </Menu.Item>
            <Menu.Item key="8">
              <Link to="/RM" />
              RM Services
            </Menu.Item>
            <Menu.Item key="9">
              <Link to="/LM" />
              LM Services
            </Menu.Item>
            <Menu.Item key="10">
              <Link to="/CM" />
              CM Services
            </Menu.Item>
          </SubMenu> */}
          {/* <Menu.Item key="4" icon={<HomeOutlined />}>
              <span>Services</span>
            </Menu.Item> */}
          <Menu.Item key="5" icon={<ScheduleOutlined />}>
            <Link to="/exercises" />
            <span>Exercises</span>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className="row pt-2 head">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3 hidden md:block",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          {/* This will only show for small devices  ////////////////////////*/}
          <div
            onClick={() => {
              setShowSmallScreenDrawer(!showSmallScreenDrawer);
            }}
            className="min-w-min h-full flex items-center cursor-pointer md:hidden"
          >
            <MenuFoldOutlined className="text-lg mb-2 mr-2" />
          </div>
          {/* ////////////////////////////////////////////////////// */}

          <div className="mt-3 text-sm md:text-xl font-bold ">{user.name}</div>

          <ClickAwayListener onClickAway={() => setShowAccountMenu(false)}>
            <div className="ml-auto">
              <DropDownMenu user={user} show={showAccountMenu} />
              <div onClick={() => setShowAccountMenu(!showAccountMenu)}>
                <OnlineUser user={user} />
              </div>
            </div>
          </ClickAwayListener>
        </Header>
        <Content
          className="w-full  overflow-y-scroll relative"
          style={{
            margin: "24px 16px",
            padding: 10,
            // background: "#fff",
            minHeight: 280,
          }}
        >
          <div className={`w-full h-full `}>
            {openCapSchoolModal && (
              <DarkOverlay density={70}>
                <CaptureSchool
                  toggleCapSchoolModal={toggleCapSchoolModal}
                  // modaRef={captureSchoolRef}
                />
              </DarkOverlay>
            )}
            {/* <div className="bg-[#244060] w-3/12 h-full fixed"></div> */}
            <div
              className={[
                "w-11/12 mx-auto bg-white px-4 pt-24 flex flex-col rounded-lg pb-20",
              ]}
            >
              {/* first secton */}
              <div className="flex flex-col mb-8 items-center bg-white w-full h-28 shadow-md rounded-lg">
                <div className="w-full flex flex-col items-center">
                  <div className="md:text-2xl font-bold border-gray-700 ">
                    Global Knowledge Base Advancement Progrom and UN SD4
                  </div>
                  <div className="border-b border-gray-600 mt-2 w-11/12"></div>
                  <div className="w-full pr-10 mt-6 flex justify-end">
                    <button
                      onClick={toggleCapSchoolModal}
                      className="bg-blue-500 px-3 py-2 rounded-lg text-white font-bold transition-all hover:-translate-y-1 duration-500"
                    >
                      Capture School
                    </button>
                  </div>
                </div>
              </div>
              {/* second section  */}
              <div className="flex flex-col-reverse md:flex-row bg-white mb-8 w-full min-h-min pb-8  shadow-md rounded-lg">
                <div className="w-full md:w-1/2  h-[60%] mr-4 text-gray-600 italic text-sm md:text-lg px-3 py-2 text-justify">
                  Described by the United Nations as a shared blueprint for
                  peace and prosperity for people and the planet, now and into
                  the future, the Sustainable Development Goals is a set 17
                  interlinked agendas for sustainable development adopted by the
                  General Assembly of the United Nations in 2015 to succeed the
                  Millennium Development Goals in a holistic approach
                  sustainable development for nations around world. Quality
                  education no. 4 of these goals.
                </div>
                <div className="w-full md:w-1/2 h-[40%] md:h-full">
                  <img src={stekaImg2} alt="steka" />
                </div>
              </div>
              {/* third section  */}
              <div className="flex flex-col md:flex-row bg-white mb-8 w-full min-h-min md:py-6 space-x-4 shadow-md rounded-lg">
                <div className="w-[98%] md:w-3/12 h-[40%] mx-auto md:ml-2">
                  <img
                    src={stekaImg1}
                    alt="steka"
                    className="w-full h-48 object-fill bg-transparent rounded-lg"
                  />
                </div>
                <div className="w-[96%] md:w-3/4 sm:mt-2 mx-2 h-[60%] py-3 text-justify text-sm md:text-lg">
                  Esgrown was developed as one of the tool components of a
                  program designed by our parent entity, Brain Wealth Projects
                  Utvecklingsbolag, to strengthen quality and functionality of
                  education in equipping students passing through school with
                  true state-of-the-world knowledge and skills need needs real
                  life productivity in transforming raw material resources and
                  intellect into distributed products, services and fixed
                  infrastructure that serve domestic needs of their countries
                  and respond to global demands, while also building their
                  appreciation for shared positive values and healthy communal
                  instinct. That is the foundation on which economic prosperity
                  and peace depends. &nbsp;
                  <button
                    onClick={() => setStekaReadmore(true)}
                    className={`px-3 ${
                      stekaReadmore && "hidden"
                    } bg-blue-400 rounded-full text-white transition-all duration-500 hover:translate-x-1`}
                  >
                    Read More
                  </button>
                  <span
                    className={`${
                      !stekaReadmore && "hidden"
                    } transition-all duration-700`}
                  >
                    The program called Knowledge Base Advancement Program is
                    line with Sustainable Development Goal no. 4. That is,
                    quality education that meets knowledge and skills needs for
                    address contemporary world economic, social and
                    environmental concerns that the remaining 16 SDGs encompass
                    Knowledge Base Advancement Program (STEKA), originally
                    created as Science and Technology Knowledge Base Advancement
                    program involves a combination of tools and services to
                    deliver 16 target global sub-goals that would transform
                    world education system. Some of these goals are served by
                    Esgrown. This program has been adopted by the governments of
                    countries like Nigeria, as a strategic national program to
                    addressing
                  </span>
                  <button
                    onClick={() => setStekaReadmore(false)}
                    className={`px-3 mx-auto transition-all duration-500 hover:translate-x-1 ${
                      !stekaReadmore && "hidden"
                    } bg-blue-400 rounded-full text-white`}
                  >
                    Read Less
                  </button>
                </div>
              </div>
              {/* Fourth section  */}
              <div className="flex flex-col mb-16 relative bg-[#30859C] w-full min-h-min py-6 space-x-4 shadow-md rounded-3xl">
                <div className=" mx-auto mb-0 md:mb-8 ">
                  <div className="text-lg font-semibold md:text-3xl text-white">
                    Reimagine Education Campaign
                  </div>
                </div>
                <div className="mx-auto p-6 sm:pt-0">
                  <div className=" text-sm md:text-lg text-justify text-white">
                    We are supporting a campaign to raise of awareness on the
                    need for strong quality and functional education that meets
                    knowledge and skills need for addressing contemporary world
                    economic social and environment concerns, in line with the
                    United Nations SDG 4, and to capture every schools as unit
                    of country's education system on the Knowledge Base
                    Advancement Program, as means of meeting that need. Schools
                    are captured on the program through this campaign via their
                    subscription to the tools and processes through which its 16
                    target global sub-goals are met. You can join the campaign
                    and work with us in capturing schools in our community of
                    the UN SDG aligned Knowledge Base Advancement Program. **
                    and to capture every schools as unit of country's education
                    system on the Knowledge Base Advancement Program, as means
                    of meeting that need. Schools are captured on the program
                    through this campaign via their subscription to the tools
                    and processes through which its 16 target global sub-goals
                    are met. You can join the campaign and work with us in
                    capturing schools in our community of the UN SDG aligned
                    Knowledge Base Advancement Program.
                  </div>
                </div>
                <div className="absolute -bottom-4 md:right-6 w-full  md:w-[40%] h-12">
                  <div className="flex space-x-4 mt-2">
                    <button
                      onClick={() => {
                        setopenCapSchoolModal(!openCapSchoolModal);
                        // captureSchoolRef?.current?.focus();
                      }}
                      className="sm:text-sm px-2 md:px-6 py-2 bg-blue-500 text-white font-bold rounded-lg shadow-lg transition-all duration-700 hover:-translate-y-1"
                    >
                      Capture School
                    </button>
                    {user?.userType !== "school" && (
                      <button className="sm:text-sm px-2 md:px-6 py-2 sm:ml-6 bg-[#205868] text-white font-bold rounded-lg shadow-lg transition-all duration-700 hover:-translate-y-1">
                        Join Campaign
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/* Fift section  */}
              <div className="flex flex-col mb-8 items-center w-full min-h-min py-6 shadow-lg rounded-lg text-gray-600">
                <div className="text-lg md:text-4xl pb-2 border-b border-[#244060]">
                  Global Community of Shared Properity
                </div>
                <div className=" text-justify text-sm md:text-lg px-4 py-2 italic">
                  As an enterprise within an ecosystem of BWP a global public
                  private/partnership framework for addressing problems that
                  affect communities and individuals created by our parent
                  company, Brain Wealth Projects Utvecklingsbolag, known as
                  Shared Shield for Humanity of Common Needs and Shared Future,
                  we shared commitment in building a global community of shared
                  prosperity through a Universal Conditional Basic Income (UCBI)
                  framework that provide means of earning income to individual
                  members of using Esgrown as tool component of Sustainable
                  Development Program implemented in their community by sharing
                  our business revenue on performance of basic tasks that
                  contributing to our efforts in addressing problems of society.
                  Our UCBI opportunity currently captures teachers as means of
                  increasing their earnings to reduce their financial anxiety.
                  Increasing income of teachers so as to reduce their financial
                  anxieties which may affect their commitment to their very
                  important jobs is one of the 16 target sub-goals of STEKA. You
                  can make a one-off activation of UCBI on Esgrown for teachers
                  in your schools for just N10,000.
                </div>
                <button className="self-start ml-4 px-4 py-2 font-bold hover:bg-blue-500 border-[#244060] text-white rounded-full bg-[#244060] hover:translate-x-2 transition-all duration-700 ">
                  Activate
                </button>
              </div>
            </div>
          </div>
          {/* // contents  */}
        </Content>
      </Layout>
      {editAcct && <EditAccount user={user} setEditAcct={setEditAcct} />}
      {showSmallScreenDrawer && (
        <SmallScreenDrawer
          setShowSmallScreenDrawer={setShowSmallScreenDrawer}
          user={user}
        />
      )}
    </Layout>
  );
};

export default Steka2;
