import React, { useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeInput } from "../../redux/slices/formSlice";

function StepOne({ setActiveStep, activeStep }) {
  const dispatch = useDispatch();
  const stekaData = useSelector((state) => state.form);
  const disableNextBtn =
    stekaData.goal1 ||
    stekaData.goal2 ||
    stekaData.goal3 ||
    stekaData.goal4 ||
    stekaData.goal5 ||
    stekaData.goal6 ||
    stekaData.goal7 ||
    stekaData.goal8 ||
    stekaData.goal9 ||
    stekaData.goal10 ||
    stekaData.goal11 ||
    stekaData.goal12 ||
    stekaData.goal13 ||
    stekaData.goal14 ||
    stekaData.goal15 ||
    stekaData.goal16 ||
    stekaData.goal17;

  const subGoals = [
    {
      goalNo: 1,
      goal: "Developing appreciation of pupils for shared posperity and healthy communal instincts on which civility and social harmony depends.",
    },
    {
      goalNo: 2,
      goal: "Developing and promoting child literacy, to ensure that every child graduating from elementary/primary school can read very fluently and understands the purpose of his/her education not as credentialing; but the building of knowledge, skills and ethics needed for real life productivity in any field of his/her passion.",
    },
    {
      goalNo: 3,
      goal: "Demystifying science, making easy and exciting for pupils, and ingraining technology consciousness into the minds of children from the very basic level of education, so as to set them on the path to relevance in future knowledge based driven world economy.",
    },
    {
      goalNo: 4,
      goal: "Enhancing the pedagogic competence and performance of teachers, to ensure that they have the requisite capacity to adequately equip pupils and students for life outside the classroom, and effectively prepare them for further advanced learning and for meeting future challenges.",
    },
    {
      goalNo: 5,
      goal: "Increasing the earning of teachers so as to reduce their financial anxiety which may and often do affect their commitment to their jobs.",
    },
    {
      goalNo: 6,
      goal: "Getting parents into greater involvement in their children’s education, and provide them means of earning income to provide better support for it.",
    },
    {
      goalNo: 7,
      goal: "Motivating teachers to greater commitment to their important job of equipping students with knowledge, skills and healthy attitude needed for advance the frontiers of future economic development and social progress.",
    },
    {
      goalNo: 8,
      goal: "Inspiring students to pursuit of excellence in learning, with an understanding of the goal of education as the perfection of expertise necessary for real economic productivity and development of healthy attitudes that drive social progress.",
    },
    {
      goalNo: 9,
      goal: "Expanding teachers/students’ knowledge exchange environment beyond classroom space and time, so as to enhance education accessibility.",
    },
    {
      goalNo: 10,
      goal: "Enhancing capacity of administrators in supporting teachers and managing their performance for functional education delivery.",
    },
    {
      goalNo: 11,
      goal: "Helping schools, education boards and departments to access funding for education infrastructure development, study and research equipment procurement and institutional advancement.",
    },
    {
      goalNo: 12,
      goal: "We support the UN Sustainable Development Goal on Quality Education",
    },
    {
      goalNo: 13,
      goal: "Tilting higher education and career interests of students above basic education level towards areas of industry knowledge and skills needs, especially in technology, science and engineering.",
    },
    {
      goalNo: 14,
      goal: "Strategic Knowledge Base Advancement Program.",
    },
    {
      goalNo: 15,
      goal: "Strengthening and reinforcing students’ appreciation for healthy values and strong positive ethics acquired at the basic education level, to ensure that educated citizens truly internalize learned healthy norms on which social order and civility depends.",
    },
    {
      goalNo: 16,
      goal: "Developing partnership and collaboration for higher education and research between higher education institutions in Africa, towards evolving a unified framework for meeting evolving knowledge and skill needs of global competitiveness of Africa’s emerging continent free trade area.",
    },
    {
      goalNo: 17,
      goal: "Creating synergy between academia and industry, to ensure that education delivered at higher education institutions is tailor to, and meets industry cutting edge knowledge and skill needs for doing business.",
    },
  ];
  const handleChange = (e) => {
    dispatch(changeInput({ name: e.target.name, value: e.target.checked }));
  };

  return (
    <div className="w-full h-[70%] text-xs flex flex-col items-center ">
      {/* {alert(activeStep)} */}
      <div className="text-sm md:text-base font-bold text-gray-700 mb-2">
        Select all the program sub-goals of your interest
      </div>

      <div className="flex justify-between items-center w-full px-12">
        <button
          // onClick={() => setActiveStep(1)}
          className={`bg-blue-500 px-3 py-2.5 rounded-[50%] text-white hover:shadow-xl hover:translate-x-1 duration-500`}
        >
          1
        </button>
        <hr className="w-1/4 border-b text-gray-700 "></hr>
        <button
          // onClick={() => setActiveStep(2)}
          className={`bg-gray-300 px-3 py-2.5 rounded-[50%] text-white hover:shadow-xl hover:translate-x-1 duration-500`}
        >
          2
        </button>
        <hr className="w-1/4 border-b"></hr>
        <button
          // onClick={() => setActiveStep(3)}
          className="bg-gray-300 px-3 py-2.5 rounded-[50%] text-white hover:shadow-xl hover:translate-x-1 duration-500"
        >
          3
        </button>
        <hr className="w-1/4 border-b"></hr>
        <button
          // onClick={() => setActiveStep(4)}
          className="bg-gray-300 px-3 py-2.5 rounded-[50%] text-white hover:shadow-xl hover:translate-x-1 duration-500"
        >
          4
        </button>
      </div>
      <div className="text-sm md:text-lg font-bold text-gray-700 mb-2 mt-2">
        We would like to subscribe to tools and service components of STEKA for:
      </div>
      {/* check boxes (sub goals to select from) */}
      <div className="overflow-y-scroll h-[60%]  md:h-full w-full pr-3">
        {subGoals.map((item) => (
          <div
            key={item.goalNo}
            className="flex flex-col  bg-slate-100 self-start items-start border p-3 rounded-xl my-3 mb-0 md:mb-2 "
          >
            <div className="flex items-center w-[100%] h-12 border-b-2  justify-between">
              <div className="text-lg font-bold">Sub-goal:{item.goalNo}</div>
              <input
                onChange={handleChange}
                checked={stekaData["goal" + item.goalNo]}
                name={"goal" + item.goalNo}
                type="checkbox"
                className="border-2 w-12 h-6 mt-2"
              />
            </div>
            <div className="text-base text-justify self-start mt-3">
              {item.goal}
            </div>
          </div>
        ))}
      </div>
      <div className="w-full flex flex-col justify-end">
        <div className="w-full border-t border-t-gray-200 my-1"></div>
        <button
          onClick={() => setActiveStep(2)}
          disabled={!disableNextBtn}
          class={`self-end mr-2 px-3 py-2 rounded-lg ${
            !disableNextBtn ? "bg-gray-300" : "bg-[#244060] "
          }  text-xs text-white hover:translate-x-1 duration-500`}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default StepOne;
