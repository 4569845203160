import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { signOut } from "../../../../redux/slices/userSlice";
import MenuItem from "./MenuItem";

const { Header, Sider, Content } = Layout;

const Programs = ({ history }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const user = useSelector((state) => state.user.user);
  //   const country = useSelector((state) => state.user.country);

  const logout = () => {
    window && window.localStorage.removeItem("persist:root");
    dispatch(signOut());
    history.push("/");
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        maxHeight: "100%",
        flexWrap: "nowrap",
        position: "relative",
      }}
    >
      <Sider
        trigger={null}
        className="sidebar-bg hidden md:block"
        collapsible
        collapsed={collapsed}
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <MenuItem logout={logout} defaultKey="3" />
      </Sider>
      <Layout>
        <Header className="row pt-2 head">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3 hidden md:block",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          <h3 className="mt-1 ">{user.name}</h3>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 10,
            // background: "#fff",
            minHeight: 280,
          }}
        >
          <div className="w-full mx-auto md:w-[94%] h-full flex flex-col bg-white rounded-xl ">
            {/* top section */}
            <div className="w-full flex justify-start items-center ">
              <div className="w-12 h-[44px] ml-8 rounded-full border-4 border-esgrown "></div>
              <div className="w-full border-b pb-4 mt-3 text-2xl font-bold flex ml-10">
                <div className="text-esgrown">
                  Knowledge Base Advancement Program
                </div>
              </div>
            </div>

            <div className="w-[92%] mt-4 text-sm md:text-base italic text-justify mx-auto">
              The Knowledge Base Advancement Program is an open blueprint for
              strengthening quality and functionality of education in equipping
              students passing through school with through state-of-the-world
              knowledge and skills needed for real life productivity in
              transforming raw material resources into products, services and
              fixed infrastructure that serve domestic needs, respond to global
              demands, generate wealth and create jobs in production processes
              and operations, while also building their appreciations for shared
              positive values and healthy communal instincts. This is in line
              with the United Nations Sustainable Development Goal no. 4 for all
              countries on quality education that meets state of the world
              knowledge and skills needs for addressing contemporary world
              economic, social and environment concerns.
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Programs;
