import React, { useState, useEffect } from "react";
import Icon, {
    StarFilled
  } from "@ant-design/icons";

import './store.css'
import AxiosInstance from "../../AxiosInstance/AxiosInstance";
import StoreDetails from "./storeItemDialog";
import { addToCart, countCart } from "./storeFunction";
import Checkout from "./storeCheckout";

const Store = ()=>{
    const [showItem,setShowItem]= useState({show:false,id:"0"});
    const [items,setItems] = useState([]);
    const [total,setTotal] = useState(0);
    const [showCheckout,setShowCheckout] = useState(false);
    const [theme,setTheme] = useState("");
    const [category,setCategory] = useState("");

    const getItems = async () => {
        const params = new URLSearchParams();
    
        if (theme !== '') {
            params.append('type', theme);
        }
    
        if (category !== '') {
            params.append('category', category);
        }
        const url = `/api/store/all-items?${params.toString()}`;
    
        try {
            const response = await AxiosInstance.get(url);
            console.log(response);
            setItems(response.data.data);
        } catch (error) {
            // Handle error
            console.error("Error fetching items:", error);
        }
    };
    
    const sitem = () =>{
        

        var item=[];
        items.forEach((itemx)=>{
        
        var images = []; 
        itemx.images.forEach((image)=>{
            images.push(<img src={image} alt="" />)
        })
       
       
        item.push(
            (
                <div className="s-item"  >
     <img src={itemx?.images[0]} alt=""  />
    <div className="iname">{itemx?.name}</div>
    <div className="iprice">NGN {itemx.cost}</div>
    <div className="irate"><StarFilled/><StarFilled/></div>
    <div className="bbt">
    <button className="acart" onClick={()=>{
        addToCart(itemx?._id);
        setTotal(countCart());
    }}>Add to Cart</button>
    <button className="details" onClick={()=>{
                   setShowItem({show:true,id:itemx?._id});
                   window.scrollTo({
                    top:0
                   })
                }}>View Details</button>
    </div>
    </div>
            )
        )
        })
        return item;
    }
    
    useEffect(()=>{
        getItems()
        setTotal(countCart())
    },[])
return (
    <>
    <div className={'store-page'}>
        
        <div className="w-full bg-white rounded-md  text-justify mt-3 text-sm px-10" style={{padding:"30px 30px"}}> 
                  <div className="mt-4 w-full flex schoolUCBI">
                   <div className="checkout-div">
                   <div style={{fontSize:"1.7em",fontWeight:"bold",color:"#1890ff"}}>Bespoke Merchandise</div>
                   <button onClick={()=>{
                    setShowCheckout(true)
                   }}>Checkout ({total})</button>
                   </div>
                    {/* <ActivateStaffUCBI user={user} /> */}
                    <div className="flex space-x-6">
                    
                    </div>
                  </div>
                  <div className="mt-2 mb-2"></div>
                  We offer a fine selection of education and business goods and bespoke merchandise from world sustainable development programmes on
quality education that meets knowledge and skill needs for address present world concerns and for helping individuals rise to prosperity or
sustain their wealth, and economic growth that provide decent jobs and opportunities for better livelihood to individuals and families.
                <div className="filterx">
                    <select onChange={(e)=>{
                        setTheme(e.target.value)
                        getItems();
                    }}>
                        <option selected disabled>Theme</option>
                        <option>Education</option>
                        <option>Business</option>
                    </select>
                    <select onChange={(e)=>{
                        setCategory(e.target.value)
                        getItems();
                    }}>
                        <option selected disabled>Category</option>
                        <option>Books</option>
                        <option>T-shirts</option>
                        <option>Mugs</option>
                        <option>Backpacks</option>
                    </select>
                </div>
                </div>

<div className="store-items w-full bg-white rounded-md  text-justify mt-3 text-sm px-10" style={{padding:"30px 30px"}}>


{sitem()}





</div>
    </div>
    {showItem.show && (<StoreDetails showState={setShowItem} id={showItem.id} cart={ setTotal} />)}
    {showCheckout && (<Checkout showState={setShowCheckout} cart={ setTotal} />)}
    </>
)
}

export default Store;