import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { signOut } from "../../../../redux/slices/userSlice";
import MenuItem from "./MenuItem";

const { Header, Sider, Content } = Layout;

const Objectives = ({ history }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const user = useSelector((state) => state.user.user);
  const country = useSelector((state) => state.user.country);

  const logout = () => {
    window && window.localStorage.removeItem("persist:root");
    dispatch(signOut());
    history.push("/");
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        maxHeight: "100%",
        flexWrap: "nowrap",
        position: "relative",
      }}
    >
      <Sider
        trigger={null}
        className="sidebar-bg hidden md:block"
        collapsible
        collapsed={collapsed}
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <MenuItem logout={logout} defaultKey="2" />
      </Sider>
      <Layout>
        <Header className="row pt-2 head">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3 hidden md:block",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          <h3 className="mt-1 ">{user.name}</h3>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 10,
            // background: "#fff",
            minHeight: 280,
          }}
        >
          <div className="w-full mx-auto md:w-[94%] h-full flex flex-col bg-white rounded-xl ">
            {/* top section */}
            <div className="w-full flex justify-start items-center ">
              <div className="w-12 h-[44px] ml-8 rounded-full border-4 border-esgrown "></div>
              <div className="w-full border-b pb-4 mt-3 text-2xl font-bold flex ml-10">
                <div className="text-esgrown">
                  Reimagine Education Campaign Objectives
                </div>
              </div>
            </div>
            <div className="w-[92%] mt-10 text-lg font-bold mx-auto">
              Objectives: 1
            </div>
            <div className="w-[92%] mt-4 text-sm md:text-base italic text-justify mx-auto">
              Raise awareness of all stakeholders on need for quality and
              functional education that meets knowledge and skill needs for real
              life productivity in transforming raw materials resources and
              intellect into distributed products, services and fixed
              infrastructure that serve domestic needs, respond to global
              demands, while also building appreciation for shared positive
              values, and to capture every school in {country} on a UN SDG 4
              aligned program designed to address that need.
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Objectives;
