import React, { useEffect, useState } from "react";
import { CloseCircleTwoTone } from "@ant-design/icons";
import Toast from "../../../../../util/toast";
import AxiosInstance from "../../../../../AxiosInstance/AxiosInstance";
import ctries from "../../../../../util/ctryModified.json";

function AddSubventionCode({ setShowAddSubventionCodeModal }) {
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(null);
  const [country, setCountry] = useState(null);
  const [countries, setCounties] = useState([]);
  const [state, setState] = useState(null);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [viewClicked, setViewClicked] = useState(false);
  const [generateClicked, setGenerateClicked] = useState(true);
  const [allSubventionCodes, setAllSubventionCodes] = useState([]);
  const [subventionCodeAdded, setSubventionCodeAdded] = useState(false);
  const [statesInSelCountry, setStatesInSelCountry] = useState(null);


  // console.log("allSubventionCodes", allSubventionCodes);
  const handleGenerateSubventionCode = async () => {
    setLoading(true);
    if (!percentage) {
      return Toast("Please Enter a Percentage", "error");
    }

    if (percentage === 0) {
      return Toast("Percentage Cannot be Zero", "error");
    }

    try {
      const { data } = await AxiosInstance.post(`/api/update/generate-promo-code`, {
        percentage: percentage,
        country:country,
        state: state,
        city:city,
      });
      if (data.error) {
        Toast(data.message, "error");
      } else {
        setPercentage("")
        setSubventionCodeAdded(true)
        Toast("Subvention Code Has Been Generated Successfully", "success");
      }
    } catch (error) {
      Toast(error.message, "error");
    } finally {
      setLoading(false);
      setSubventionCodeAdded(false)
    }
  };

  const getSubventionCodes = async () => {
    setLoading(true);
    try {
      const { data } = await AxiosInstance.get(`/api/update/valid-promo-codes`);
      setAllSubventionCodes(data.promoCodes)
    } catch (error) {
      Toast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getSubventionCodes()
  }, [subventionCodeAdded])
  const getCities= async (country,state)=>{
    const { data } = await AxiosInstance.post(`/api/province/get-all-cities`, {
      country: country,
      state:state
    });
 

    //console.log(cities)
    setCities(data.cities)
  }
  function renderCountries() {
    return ctries.map((item) => {
      return <option value={JSON.stringify(item)}>{item.country}</option>;
    });
  }
  function renderStates(states) {
    //console.log(states);
    if (states === null) return <></>;
    return states.map((state) => {
      return <option value={state}>{state}</option>;
    });
  }

  return (
    <div className="flex-col w-[400px] h-[500px] fixed top-4 left-1/2  -translate-x-1/2 border-2 border-esgrown shadow-xl rounded-md overflow-x-hidden z-50 bg-white text-gray-600 py-2">
      <div className="flex flex-col h-full justify-around items-center">
        <div className="w-full flex justify-end mr-2 mt-2">
          <CloseCircleTwoTone onClick={() => setShowAddSubventionCodeModal(false)} />
        </div> 
        <div className="w-full flex justify-center items-center">
          <div className="text-md text-esgrown flex items-center">
            <p className={`cursor-pointer mr-1 text-esgrown font-extrabold ${generateClicked ? "border-b-2 border-esgrown" : ""}`}
              onClick={() => {
                setGenerateClicked(true)
                setViewClicked(false)
              }}
            >Generate</p> |
            <p
              className={`cursor-pointer ml-1 text-esgrown font-extrabold ${viewClicked ? "border-b-2 border-esgrown" : ""}`}
              onClick={() => {
                setViewClicked(true)
                setGenerateClicked(false)
              }}
            >View</p>
          </div>
        </div>

        {generateClicked &&
          <div className="w-full flex flex-col items-center justify-center my-auto " style={{padding:"20px"}}>
            <div className="w-auto flex flex-col items-center">
              <div className="w-full" >
                <input
                  placeholder="Enter A Percentage e.g 50"
                  value={percentage}
                  rows={5}
                  onChange={(e) => {
                    setPercentage(e.target.value);
                  }}
                  className="w-full border-2 text-center border-blue-200 outline-blue-200 px-4 mt-3 py-1 rounded-md"
                  style={{marginBottom:"10px"}}
                />
                
              
                <select
            onChange={(e) => {
              const item = (JSON.parse(e.target.value));
              setCountry(item.country);
              setStatesInSelCountry(item.states);
            }}
            className="w-full mx-auto rounded-lg border-2 border-blue-200 outline-blue-200 px-2 py-1"
          >
            <option value="">Select Country</option>
            {renderCountries()}
          </select>
          <select
              onChange={(e) => {
                setState(e.target.value);
                getCities(country,e.target.value)
              }}
              className="w-full mx-auto rounded-lg border-2 border-blue-200 outline-blue-200 px-2 mt-3 py-1"
            >
              <option value="">Select state</option>
              {renderStates(statesInSelCountry)}
            </select>
          <select
              onChange={(e) => {
                setCity(e.target.value);
              }}
              className="w-full mx-auto rounded-lg border-2 border-blue-200 outline-blue-200 px-2 mt-3 py-1"
            >
              <option value="">Select city</option>
              {renderStates(cities)}
            </select>
              </div>
              <br/>
              <div
                disabled={loading}
                onClick={()=>{
                  handleGenerateSubventionCode()
                }}
                className={` ${loading && "animate-pulse"
                  } bg-esgrown cursor-pointer w-full disabled:bg-gray-300 text-white focus:outline-none py-1 rounded-md px-1 mt-1 justify-self-center text-center`}
              >
                Generate Subvention Code
              </div>
            </div>

            
          </div>
        }

        {viewClicked &&
          <div className="w-full flex flex-col items-center justify-center my-auto overflow-y-scroll">
            <table class="align-center table-auto w-full h-full py-2 text-sm text-left text-gray-500 dark:text-gray-400 table table-hover table-striped">
              <thead>
                <tr className="">
                  <th className="sticky top-0 text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">Code</th>
                  <th className="sticky top-0 text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">Percentage</th>
                </tr>
              </thead>
              <tbody>
                {allSubventionCodes?.length > 0 && allSubventionCodes.map((code, index) => {
                  return (<tr key={index}>
                    <td>{code?.code} </td>
                    <td>{code?.percentage}</td>
                  </tr>)
                })}
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  );
}

export default AddSubventionCode;
