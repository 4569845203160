import { createSlice } from "@reduxjs/toolkit";

const initState = {
  subService: null,
};

export const serviceSlice = createSlice({
  name: "service",
  initialState: initState,
  reducers: {
    setSubService: (state, action) => {
      state.subService = action.payload;
    },
    // signOut: (state) => {
    //   state.user = null;
    //   state.loggeIn = false;
    // },
  },
});
// Expoted actions
export const { setSubService } = serviceSlice.actions;

//Expoted selectors
export const selectSubService = (state) => state.subService;

export default serviceSlice;
