import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";
export const removeCareerField = createAsyncThunk("removeCareerField", async (fieldId)=>{
    const url = `/api/user/remove-field/${fieldId}`;
    const { data } = await AxiosInstance.delete(url);
    return data
})

export const getCareerFields = createAsyncThunk("get/CareerFields",  async (countryQuery) => {
          const url = `/api/user/get-fields?country=${countryQuery}`;
          try {
            const { data } = await AxiosInstance.get(url);
            return data.fields
          }catch(err){

          }    
})
export const getCareerFieldsByCountry = createAsyncThunk("get/CareerFieldsByCountry",  async () => {
          const url = `/api/user/get-fields-by-country`;
          try {
            const { data } = await AxiosInstance.get(url);
            return data.fields
          }catch(err){

          }    
})


const initState = {
  componentsStates: {
    removeCareerFieldPrompt: false,
    showCareerFieldPopOver: false,
    showSelectedSchool: false,
    showListOfCareerFields: false,
    showViewProcessedSchoolModal: false,
    showListOfCareerFieldsSubjects: false,
    showUpdateFieldModal: false
  },
  allCareerFields: [],
  allCareerFieldsByCountry: [],
  careerFieldCountry:"",
  isLoading: false,
  careerFieldDetail: ""
};

export const careerFieldSlice = createSlice({
  name: "careerField",
  initialState: initState,
  reducers:{
    setShowCareerFieldPopOver:(state, action)=>{
        state.componentsStates.showCareerFieldPopOver = action.payload
    },
    setRemoveCareerFieldPrompt:(state, action)=>{
        state.componentsStates.removeCareerFieldPrompt = action.payload
    },
    setShowSelectedSchool:(state, action)=>{
        state.componentsStates.showSelectedSchool = action.payload
    },
    setShowListOfCareerFields:(state, action)=>{
        state.componentsStates.showListOfCareerFields = action.payload
    },
    setCareerFieldCountry:(state, action)=>{
        state.careerFieldCountry = action.payload
    },
    setShowListOfCareerFieldsSubjects:(state, action)=>{
        state.componentsStates.showListOfCareerFieldsSubjects = action.payload
    },
    setCareerFieldDetail:(state, action)=>{
        state.careerFieldDetail = action.payload
    },
    setShowUpdateFieldModal:(state, action)=>{
        state.componentsStates.showUpdateFieldModal = action.payload
    },
  },
  extraReducers:(builder) => {
    builder
      .addCase(removeCareerField.pending, (state) => {
          state.isLoading = true
      })
      .addCase(removeCareerField.fulfilled, (state, action) => {
          state.allCareerFields = state.allCareerFields.filter(field=> field._id != action.payload.deletedField._id) 
        //   state.allFilteredProcessedassociates = state.allFilteredProcessedassociates.filter(associate=> associate._id != action.payload.deletedAssociate._id) 
          state.isLoading = false

      })
      .addCase(getCareerFields.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getCareerFields.fulfilled, (state, action) => {
          state.allCareerFields = action.payload
          state.isLoading = false
      })
      .addCase(getCareerFieldsByCountry.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getCareerFieldsByCountry.fulfilled, (state, action) => {
          state.allCareerFieldsByCountry = action.payload
          state.isLoading = false
      })
    }
  },
);

export const {
              setShowCareerFieldPopOver,
              setRemoveCareerFieldPrompt,
              setShowSelectedSchool,
              setShowListOfCareerFields,
              setShowViewProcessedSchoolModal,
              setCareerFieldCountry,
              setShowListOfCareerFieldsSubjects,
              setCareerFieldDetail,
              setShowUpdateFieldModal
            } = careerFieldSlice.actions
export default careerFieldSlice;
