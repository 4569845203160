
import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { CloseCircleTwoTone, DownOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import RemoveCareerFieldsModal from "../modals/RemoveCareerFieldsModal";
import CareerFieldDetails from "./CareerFieldDetails";
import UpdateCareerFieldModal from "./UpdateCareerFieldModal";

import { 
  getCareerFields,
  setRemoveCareerFieldPrompt,
  setShowListOfCareerFields,
  getCareerFieldsByCountry,
  setCareerFieldCountry,
  setShowListOfCareerFieldsSubjects,
  setShowCareerFieldPopOver,
  setCareerFieldDetail,
  setShowUpdateFieldModal
 } from "../../../../redux/slices/careerFieldSlice";

function CareerPathView() {
  
  const [country, setCountry] = useState("");
  
  const { 
    showCareerFieldPopOver,
    removeCareerFieldPrompt,
    showListOfCareerFieldsSubjects,
    showUpdateFieldModal
   } = useSelector(state => state.careerFieldSlice.componentsStates )
  
   const { 
    allCareerFields,
    allCareerFieldsByCountry,
    isLoading,
    careerFieldCountry
   } = useSelector(state => state.careerFieldSlice )
   
   const dispatch = useDispatch()


  useEffect(() => {
    window &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  }, [allCareerFields?.length]);

  useEffect(()=>{
    dispatch(getCareerFieldsByCountry())
  },[dispatch, getCareerFields, getCareerFieldsByCountry])

  useEffect(()=>{
    dispatch(getCareerFields(careerFieldCountry))
  },[careerFieldCountry])

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="h-[20%] pb-1 border-b border-b-[#1D6DAE] w-full">
        <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
          <CloseCircleTwoTone
            onClick={() => {
              dispatch(setShowListOfCareerFields(false))
              dispatch(setRemoveCareerFieldPrompt(true))
              dispatch(setShowCareerFieldPopOver(false))
            }}
            color="red"
          />
        </div>
        <div className="flex justify-between items-end pb-1 w-full h-[60%] ">
          <div className="w-[20%] ">
            <select
                name=""
                id=""
                value={careerFieldCountry}
                onChange={(e) => {
                  dispatch(setCareerFieldCountry(e.target.value));
                }}
                className="w-full py-1 focus:border-[#1D6DAE] rounded-lg focus:outline-none border-2"
                >
                <option value="">
                    All Countries
                  </option>
                {!isLoading &&
                  allCareerFieldsByCountry.map((field, index) => (
                    <>
                        {field?._id?
                          <option key={index} >
                            {field._id}
                          </option>
                      :""}
                    </>
                  ))}
              </select>
            </div>
            <div className="w-full ml-6">
                <p class="text-[#1D6DAE] font-bold text-lg">Higher Education and Career Paths/Areas of Knowledge and Skill Needs</p>
             </div> 
          {/* State  */}
          {/* <div className="w-[20%] ">
            <select
              name=""
              id=""
              className="w-full py-1 focus:border-[#1D6DAE] rounded-lg focus:outline-none border-2"
            >
              <option value="">All States</option>
              <option value="">Abia</option>
              <option value="">Adamawa</option>
            </select>
          </div> */}
          {/* City  */}
          {/* <div className="w-[20%]">
            <select
              name=""
              id=""
              className="w-full py-1 focus:border-[#1D6DAE] rounded-lg  focus:outline-none border-2"
            >
              <option value="">All Cities</option>
              <option value="">City 1</option>
              <option value="">City 2</option>
            </select>
          </div> */}
          {/* Search  */}
          {/* <div className="w-[30%] relative">
            <input
              type="text"
              className="w-full py-1 rounded-full pl-3 pr-6 focus:outline-none  focus:border-[#1D6DAE] border-2"
            ></input> */}
            {/* <span
              className="absolute top-0  right-2 cursor-pointer"
              style={{ zIndex: 120 }}
            >
              <SearchOutlined className="text-lg transition-all hover:-translate-y-0.5" />
            </span> */}
          {/* </div> */}
        </div>
      </div>
      {/* list of Associates  */}
      <div className="h-[70%] border-b border-b-blue-500 overflow-y-scroll">
        {allCareerFields?.length > 0 ? (
          allCareerFields?.map((field, index) => (
            <div
              key={index.toString()}
              className="odd:bg-gray-200 mt-1 h-10 text-gray-600 font-semibold px-4 flex justify-between"
            >
              <p className=" ">{field.name}</p>

              {!isLoading && !showCareerFieldPopOver && (
              <Popover
                placement="left"
                trigger="click"
                content={
                  <div className="min-w-min min-h-min flex flex-col items-start justify-start space-y-2 ">
                    <button
                      onClick={() => {
                        dispatch(setCareerFieldDetail(field));
                        dispatch(setShowListOfCareerFieldsSubjects(true))
                        dispatch(setShowCareerFieldPopOver(true))
                        // setProcessedStekaSchoolsModal(true);
                      }}
                      className="px-2 outline-none  focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                    >
                      View Details
                    </button>
                    <button
                      onClick={() => {
                        dispatch(setCareerFieldDetail(field));
                        dispatch(setShowUpdateFieldModal(true))
                        dispatch(setShowCareerFieldPopOver(true))
                      }}
                      className="px-2 outline-none  focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                    >
                      Edit
                    </button>
                    
                    <button
                      onClick={() => {
                        dispatch(setCareerFieldDetail(field));
                        dispatch(setRemoveCareerFieldPrompt(true))
                        dispatch(setShowCareerFieldPopOver(true))
                      }
                      }
                      className="px-2 outline-none focus:outline-none rounded-full text-red-400 transition-all duration-500 hover:-translate-y-1 hover:bg-red-300 hover:text-white"
                    >
                      Delete
                    </button>
                  </div>
                }
              >
                <button className="text-[#1D6DAE] focus:outline-none transition-all hover:translate-x-1 duration-500">
                  Actions
                </button>
              </Popover>
              )}
            </div>
          ))
        ) : (
          <div className="w-full flex justify-center h-10 mt-1.5 ">
            <div>No Career Path👏 </div>
          </div>
        )}
      </div>
      <div className="flex h-[10%] justify-end items-center ">
        <button className="px-3 py-1 rounded-lg font-semibold border-2 border-[#1D6DAE] transition-all duration-700 text-[#1D6DAE] hover:bg-[#1D6DAE] hover:text-white ">
          Next
        </button>
      </div>
      {removeCareerFieldPrompt && (
      <RemoveCareerFieldsModal/>
      )}

    {showListOfCareerFieldsSubjects && (
      <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
        <CareerFieldDetails 
        />
        </div>
      )}

    {showUpdateFieldModal && (
      <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
        <UpdateCareerFieldModal 
        />
        </div>
      )}
    </div>
  );
}


export default CareerPathView;


