import React from "react";
import { useSelector } from "react-redux";
import ExercisesCorperate from "./ExercisesCorperate";
import ExercisesIndiviual from "./ExercisesIndiviual";

function Exercises() {
  const user = useSelector((state) => state.user.user);

  return user.accountType === "individual" ? (
    <ExercisesIndiviual />
  ) : (
    <ExercisesCorperate />
  );
}

export default Exercises;
