
import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { CloseCircleTwoTone, DownOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import RemoveCareerFieldsModal from "./RemoveCareerFieldsModal";
import UpdateCareerFieldModal from "./UpdateCareerFieldModal";

import { 
  getCareerFields,
  setRemoveCareerFieldPrompt,
  setShowListOfCareerFields,
  getCareerFieldsByCountry,
  setCareerFieldCountry,
  setShowListOfCareerFieldsSubjects,
  setShowUpdateFieldModal,
  setShowCareerFieldPopOver
 } from "../../../../redux/slices/careerFieldSlice";

function CareerFieldDetails() {
  const [showSchoolContact, setShowSchoolContact] = useState(false);
  const [country, setCountry] = useState("");
  
  const { 
    showCareerFieldPopOver,
    removeCareerFieldPrompt,
    showListOfCareerFields,
    showUpdateFieldModal
   } = useSelector(state => state.careerFieldSlice.componentsStates )
  
   const { 
    allCareerFields,
    allCareerFieldsByCountry,
    isLoading,
    careerFieldDetail,
    careerFieldCountry
   } = useSelector(state => state.careerFieldSlice )
   
   const dispatch = useDispatch()

  useEffect(() => {
    window &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  }, [allCareerFields?.length]);

  useEffect(()=>{
    dispatch(getCareerFields(country))
    dispatch(getCareerFieldsByCountry())
  },[dispatch, getCareerFields, getCareerFieldsByCountry])

  useEffect(()=>{
    dispatch(getCareerFields(careerFieldCountry))
  },[careerFieldCountry])

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="h-[20%] pb-1 border-b border-b-[#1D6DAE] w-full">
        <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
          <CloseCircleTwoTone
            onClick={() => {
              dispatch(setShowListOfCareerFieldsSubjects(false))
              dispatch(setRemoveCareerFieldPrompt(false))
              dispatch(setShowCareerFieldPopOver(false))
            }}
            color="red"
          />
        </div>
        <div className="flex justify-between items-end pb-1 w-full h-[60%] ">
            <div className="w-full ml-6">
                <p class="text-[#1D6DAE] font-bold text-lg">{careerFieldDetail?.name}</p>
             </div> 
         
        </div>
      </div>
      {/* list of Associates  */}
      <div className="h-[70%] border-b border-b-blue-500 overflow-y-scroll">
        {careerFieldDetail?.subjects?.length > 0 ? (
          careerFieldDetail?.subjects.map((subject, index) => (
            <div
              key={index.toString()}
              className="odd:bg-gray-200 mt-1 h-10 text-gray-600 font-semibold px-4 flex justify-between"
            >
              <p className=" ">{subject}</p>
            
            </div>
          ))
        ) : (
          <div className="w-full flex justify-center h-10 mt-1.5 ">
            <div>All requests have been processed 👏 </div>
          </div>
        )}
      </div>
      <div className="flex h-[10%] justify-end items-center ">
        <button onClick={() => {
                        dispatch(setShowUpdateFieldModal(true))
                      }}
                      className="px-3 py-1 rounded-lg font-semibold mr-5 transition-all duration-700 bg-[#1D6DAE] hover:bg-[#1D6DAE] hover:text-white text-white">
          Edit
        </button>
        <button  onClick={() => {
                        // dispatch(setCareerFieldDetail(field));
                        dispatch(setRemoveCareerFieldPrompt(true))
                      }}
                      className="px-3 py-1 rounded-lg font-semibold transition-all duration-700 text-white bg-[red] hover:text-[red] hover:border-b-[red]">
          Remove
        </button>
      </div>
      {removeCareerFieldPrompt && (
      <RemoveCareerFieldsModal
      />
      )}
      {showUpdateFieldModal && (
      <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
        <UpdateCareerFieldModal 
        />
        </div>
      )}
    </div>
  );
}


export default CareerFieldDetails;


