import React, { useEffect, useState,useCallback } from "react";
import { useDispatch } from "react-redux";
import AxiosInstance from "../AxiosInstance/AxiosInstance";
import {
  CloseCircleTwoTone,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Toast from "../util/toast";
import { Spin } from "antd";
import { setUser } from "../redux/slices/userSlice";
// import allcountries from "../util/allcountries";
import ctries from "../util/ctryModified.json";
import educationLevels from "../util/educationLevelData.json";

function EditAccount({ user, setEditAcct }) {
  const dispatch = useDispatch();
  const [corpUsers, setCorpUsers] = useState(null);
  const [allSchools, setAllSchools] = useState(null);
  const [noOfClassesTakenByTeacher, setNoOfClassesTakenByTeacher] = useState(1);
  const [states, setStates] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subjectOne, setSubjectOne] = useState(null);
  const [subjectTwo, setSubjectTwo] = useState(null);
  const [classOne, setClassOne] = useState(null);
  const [classTwo, setClassTwo] = useState(null);
  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [userDataState,setUserData]=useState();


  const [updateData, setUpdateData] = useState({
    orgName: "",
    phone: "",
    province: "",
    country: "",
    state: "",
    //description: "",
    educationLevel: null,
    year: "",
    ptaChairman: "",
    highestQualification: "",
    subjectClassCombination: [],
    numberOfStudents: null,
    staffStrength: null,
  });
  const handleChange = async (e) => {
    if (e.target.name === "country") {
      const _item = JSON.parse(e.target.value);
      setStates(_item.states);
      setCities([]);
      return setUpdateData({ ...updateData, country: _item.country});
    }
    if (e.target.name === "state") {
      setLoadingCities(true);
      try {
        const { data } = await AxiosInstance.post(
          `/api/province/get-all-cities`, {
          state: e.target.value
        });
        if (!data.error) {
          setCities(data.cities);
        } else {
          setCities([]);
        }
        
      } catch (error) {
        Toast("Could not fetch cities", "error");
      } finally {
        setLoadingCities(false);
      }
    }
    setUpdateData({ ...updateData, [e.target.name]: e.target.value});
    if (e.target.name === "state") {
      fetchCorpusers();
    }
  };
  const triggerCallb = useCallback((userData) => {
    console.log(userData.country)
    if (userData.country !== null && userData.country !== "" && userData.country!==undefined) {
      console.log("here")
      const foundItem = ctries.find((item) => item.country === userData.country);
      handleChange({
        target: { name: "country", value: JSON.stringify(foundItem) },
      });
    }
    if (userData.state !== null && userData.state !== "" && userData.state!==undefined) {
      handleChange({ target: { name: "state", value: userData.state } });
    }
  }, []);
useEffect(()=>{
  let userData = user;
  setUserData(userData)
  triggerCallb(userData)
},[setUserData,triggerCallb,user])

  

  const handleChangeSubTakenAndClass = (e) => {
    const label = String(e.target.value).split("=")[0];
    const index = String(e.target.value).split("=")[1];
    const value = String(e.target.value).split("=")[2];

    if (String(index) === "0" && String(label) === "subject") {
      setSubjectOne(value);
    }
    if (String(index) === "0" && String(label) === "class") {
      setClassOne(value);
    }
    if (String(index) === "1" && String(label) === "subject") {
      setSubjectTwo(value);
    }
    if (String(index) === "1" && String(label) === "class") {
      setClassTwo(value);
    }
  };

  //check if any field is empty then delete such field from the update data object
  const updateUserData = async () => {
   
    setLoading(true);
    Object.keys(updateData).forEach((key) => {
      if (updateData[key] === "" || updateData[key] == null) {
        delete updateData[key];
      }
    });

    //  [classOne, subjectOne],[classTwo, subjectTwo],
    if (classOne && subjectOne) {
      updateData.subjectClassCombination.push(classOne + "=" + subjectOne);
    }
    if (classTwo && subjectTwo) {
      updateData.subjectClassCombination.push(classTwo + "=" + subjectTwo);
    }

    //If all fields are empty, no need for update, throw an error
    const allFieldsEmpty = Object.values(updateData).every(
      (value) => value === "" || Boolean(value) === null
    );

    if (allFieldsEmpty) {
      setLoading(false);
      return Toast("Update fields are empty. No update made!", "error");
    }

    //All fine, then ...
    try {
      const { data } = await AxiosInstance.post(`/api/user/user/update`, updateData);
      const _user = data.user;
      console.log(data,"---")
      const updatedUser = {
        isLogged: true,
        token:userDataState.token,
        ..._user,
      };
    
        console.log(updatedUser)
      //data.user['token']=userDataState.token;
      
      //window.localStorage.setItem("persist:user",JSON.stringify(data.user));
      dispatch(setUser(updatedUser));
      setEditAcct(false);
      Toast("Data has been updated!", "success");
    } catch (error) {
      Toast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchCorpusers = async () => {
    const resp = await AxiosInstance.post(`/api/user/user/corp`,updateData);
    const corpUsers = resp.data;

    if (corpUsers && corpUsers.users) {
      setCorpUsers(corpUsers.users);
    }
  };
  useEffect(() => {
   
    fetchCorpusers();
  }, []);
  useEffect(() => {
    const fetchSchools = async () => {
      const resp = await AxiosInstance.post(`/api/user/user/schools`);
      const schools = resp.data;

      if (schools && schools.schools) {
        setAllSchools(schools.schools);
      }
    };
    fetchSchools();
  }, []);
  return (
    <div className="absolute w-screen h-[100vh] inset-0 bg-black/70 z-50">
      <div className="w-11/12 lg:w-[65%] mx-auto min-h-min py-10 px-4 mt-20 rounded-lg bg-white ">
        <div className="pl-[40%] text-md font-semibold text-[#1D6DAE] flex items-center justify-between">
          Update your details
          <CloseCircleTwoTone onClick={() => setEditAcct(false)} />
        </div>
        <div className="w-full flex flex-wrap justify-between gap-4 mt-4">
          <select
            name="country"
            className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
            onChange={handleChange}
          >
            {userDataState!=null && userDataState.country!==undefined && <option selected value={JSON.stringify(ctries.find((item) => item.country ===userDataState.country))}>{userDataState.country}</option>}
            <option value="">Select Country</option>
            {renderCountries()}
          </select>
          <select
            name="state"
            className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
            disabled={states === null}
            onChange={handleChange}
          >
            {userDataState!=null && userDataState.state!==undefined && <option selected value={userDataState.state}>{userDataState.state}</option>}
            <option value="">Select State/Province</option>
            {renderStates(states)}
          </select>

          {user.userType !== "schoolx" ?
            <select
              name="province"
              disabled={cities.length < 1}
              className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
              onChange={handleChange}
            >
              {!loadingCities ? (
                // <option value="">Select City</option>
                <></>
              ) : (
                <span className="animate-pulse">...</span>
              )}
              {userDataState!=null && userDataState.province!=null && userDataState.province!=undefined && <option selected value={userDataState.province}>{userDataState.province}</option>}
              <option value="">Select City</option>
              {cities && renderCities(cities)}
            </select>

            : ""
          }

          {user.accountType !== "corporate" && user?.userType !== "teacher" && user?.userType !== "student" && user?.userType !== "private" && (
            <select
              onChange={(e) => {
                setUpdateData({
                  ...updateData,
                  orgName: e.target.value,
                });
               
              }}
              disabled={user?.accountType !== "individual"}
              className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
            >
              <option value=""> --school--</option>
              {userDataState!=null && userDataState.orgName!=="" && <option selected value={userDataState.orgName}>{userDataState.orgName}</option>}
              {corpUsers?.map((corpAccount, index) => (
                <option key={index.toString()} value={corpAccount?.email}>
                  {corpAccount?.name}
                </option>
              ))}
            </select>
          )}
          {user?.userType === "teacher" || user?.userType === "student"  && (
            <select
              onChange={(e) => {
                setUpdateData({
                  ...updateData,
                  orgName: e.target.value,
                });
              }}
              disabled={user?.accountType !== "individual"}
              className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
            >
              <option value="">School</option>
              {allSchools?.map((school, index) => (
                <option key={index.toString()} value={school?.email}>
                  {school?.name}
                </option>
              ))}
            </select>
          )}
          {/* Phone number  */}
          <input
            name="phone"
            placeholder="phone number"
            className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
            type="text"
            onChange={handleChange}
          />
          {/* PTA chairman */}
          {user.userType === "school" && (
            <input
              name="ptaChairman"
              placeholder="Mobile Number of PTA Chairman"
              className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
              type="text"
              onChange={handleChange}
            />
          )}
          {user.userType === "school" && (
            <input
              name="numberOfStudents"
              placeholder="Numbers of Students"
              className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
              type="number"
              maxLength={100000}
              onChange={handleChange}
            />
          )}
          {user.userType === "school" && (
            <input
              name="staffStrength"
              placeholder="Numbers of Teachers"
              className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
              type="number"
              maxLength={100000}
              onChange={handleChange}
            />
          )}

          {/* Education level  */}
          {user.userType === "student" && (
            <select
              name="educationLevel"
              className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
              onChange={handleChange}
            >
              <option>--Education Level--</option>

              {renderEducationLevel()}
            </select>
          )}
          {user.userType === "student" && (
            <select
              name="year"
              className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
              disabled={updateData.educationLevel === null}
              onChange={handleChange}
            >
              <option>--Class/Year--</option>
              {rederClassOrYear(updateData.educationLevel)}
            </select>
          )}
          {/* Teachers highest qualifications and city */}

          {user.userType === "teacher" && (
            <select
              name="highestQualification"
              className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
              onChange={handleChange}
            >
              <option>--Highest Qualification--</option>
              <option value="Msc">Msc</option>
              <option value="Bsc">Bsc</option>
              <option value="HND">HND</option>
              <option value="NCE">NCE</option>
            </select>
          )}

          {user.userType === "teacher" && (
             <input
              name="institutionAttended"
              placeholder="Institution Attended"
              className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
              type="text"
              onChange={handleChange}
          />
          )}

          {user.userType === "teacher" && (
             <input
              name="course"
              placeholder="Course"
              className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
              type="text"
              onChange={handleChange}
          />
          )}

          {user.userType === "teacher" && (
             <input
              name="skills"
              placeholder="Skills, eg Team Player, Exceptional Planner, Analytical Thinker"
              className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
              type="text"
              onChange={handleChange}
          />
          )}

          {/* {user.userType === "teacher" && (
            <select
              name="class"
              className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
              onChange={handleChange}
            >
              <option>--Highest Qualification--</option>
              <option value="Msc">Msc</option>
              <option value="Bsc">Bsc</option>
              <option value="HND">HND</option>
              <option value="NCE">NCE</option>
            </select>
          )} */}
          {/* classes taken by teacher and subjects  */}
          {user.userType === "teacher" &&
            new Array(noOfClassesTakenByTeacher).fill(0).map((value, index) => (
              <>
                <select
                  name="classTaken"
                  className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
                  onChange={handleChangeSubTakenAndClass}
                >
                  <option>--Class--</option>

                  {renderClasses(index)}
                </select>
                <select
                  name="subjectTaken"
                  className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
                  // disabled={updateData.educationLevel === null}
                  onChange={handleChangeSubTakenAndClass}
                >
                  <option>--Subject--</option>
                  {renderSubjects(index)}
                </select>
              </>
            ))}
          {/*<textarea
            name="description"
            onChange={handleChange}
            placeholder="Enter Organization description ..."
            className="shadow-lg px-2 py-2 w-[48%] rounded-lg outline-[#1D6DAE]"
            ></textarea>*/}
        </div>
        {user.userType === "teacher" && (
          <div className="w-full flex mt-3 space-x-3 ">
            <PlusCircleOutlined
              onClick={() => {
                setNoOfClassesTakenByTeacher(
                  noOfClassesTakenByTeacher > 1
                    ? noOfClassesTakenByTeacher
                    : noOfClassesTakenByTeacher + 1
                );
              }}
              color="#2689"
              className="text-xl text-green-500 cursor-pointer"
            />
            <MinusCircleOutlined
              onClick={() => {
                setNoOfClassesTakenByTeacher(
                  noOfClassesTakenByTeacher < 2
                    ? noOfClassesTakenByTeacher
                    : noOfClassesTakenByTeacher - 1
                );
              }}
              color="#2689"
              className="text-xl text-red-500 cursor-pointer"
            />
          </div>
        )}

        <div className="w-full flex mt-8 items-center justify-end ">
          <button
            onClick={updateUserData}
            className="px-3 py-2 text-white rounded-lg transition-all hover:translate-x-1 hover:shadow-md hover:shadow-[#1D6DAE] bg-[#1D6DAE]"
          >
            {loading ? <Spin /> : "update"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditAccount;
// Render all Countries
function renderCountries() {
  return ctries.map((item) => {
    return <option value={JSON.stringify(item)}>{item.country}</option>;
  });
}

// Render all states for the seleted country
function renderStates(states) {
  if (states === null) return <></>;
  return states.map((state) => {
    return <option value={state}>{state}</option>;
  });
}
function renderCities(cities) {
  if (cities === null) return <></>;
  return cities.map((city) => {
    return <option value={city}>{city}</option>;
  });
}

function renderEducationLevel() {
  const edLevels = ["Primary", "Secondary", "Tertiary"];
  return edLevels.map((level) => {
    return <option value={level}>{level}</option>;
  });
}

function renderClasses(value) {
  const classes = [
    "Primary One",
    "Primary Two",
    "Primary Three",
    "Primary Four",
    "Primary Five",
    "Primary Six",
    "JSS One",
    "JSS Two",
    "JSS Three",
    "SS One",
    "SS Two",
    "SS Three",
    // "100 Level",
    // "200 Level",
    // "300 Level",
    // "400 Level",
    // "500 Level",
  ];
  return classes.map((cl) => {
    return <option value={"class" + "=" + value + "=" + cl}>{cl}</option>;
  });
}
function renderSubjects(value) {
  const subjects = [
    "Basic Science",
    "Mathematics",
    "English",
    "Quantitative  Reasoning",
    "verbal Reasoning",
    "Computer Studies",
    "Physics",
    "Biology",
    "Chemistry",
    "Further Mathematics",
    "Biology",
    // "100 Level",
    // "200 Level",
    // "300 Level",
    // "400 Level",
    // "500 Level",
  ];
  return subjects.map((s) => {
    return <option value={"subject" + "=" + value + "=" + s}>{s}</option>;
  });
}

function rederClassOrYear(level) {
  if (level == false) return <></>;
  let years = [];
  if (level == "Primary")
    years = [
      "Primary One",
      "Primary Two",
      "Primary Three",
      "Primary Four",
      "Primary Five",
      "Primary Six",
    ];

  if (level == "Secondary")
    years = ["JSS One", "JSS Two", "JSS Three", "SS One", "SS Two", "SS Three"];
  if (level == "Tertiary")
    years = [
      "100 Level",
      "200 Level",
      "300 Level",
      "400 Level",
      "500 Level",
      "Masters Degree",
      "Doctoral Degree",
    ];
  return years.map((year) => {
    return <option value={year}>{year}</option>;
  });
}
