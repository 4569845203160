import React from "react";
import { Popover } from "antd";
import PaymentRequestModal from "./modals/PaymentRequestModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import currencyFormatter from 'currency-formatter';
import { 
  getTotalUCBIAmountForTeachers,
  getAllTeachersWitUcbis,
  getAllAssociatesWitUcbis,
  setUcbiUserDetails,
  setShowUcbiDetailModal,
  getTotalUCBIAmountForAssociates
 } from "../../../redux/slices/ucbiSlice";
import { useEffect } from "react";
import UCBIDetailsModal from "../../campaigns/steka/modals/UcbiDetailModal";

export default function UcbiPayment({ requests, setRequests }) {
  const [openUCBIPaymentReqModal, setOpenUCBIPaymentReqModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [loading, setLoading] = useState(false);
  const [month, setmonth] = useState("");
  const [month2, setmonth2] = useState("");

  const dispatch = useDispatch()
  const {totalUCBIAmountForTeachers,
         allTeachersWitUcbis,
         allAssociatesWitUcbis,
         totalUCBIAmountForAssociates
  } = useSelector((state) => state.ucbiSlice);

  const {showUcbiDetailModal,
         markedAsProcessed
  } = useSelector((state) => state.ucbiSlice.componentsStates);

  const months = [  "January", "February", "March", "April", 
                    "May", "June", "July", "August", "September", 
                    "October", "November", "December" ]

  useEffect(()=>{
    dispatch(getTotalUCBIAmountForTeachers())
    dispatch(getAllTeachersWitUcbis(month))
  },[getTotalUCBIAmountForTeachers, getAllTeachersWitUcbis, markedAsProcessed])
  
  useEffect(()=>{
    dispatch(getAllTeachersWitUcbis(month))
  },[month, showUcbiDetailModal, markedAsProcessed])

  useEffect(()=>{
   dispatch(getAllAssociatesWitUcbis(month2))
   dispatch(getTotalUCBIAmountForAssociates())
  },[month2, showUcbiDetailModal, markedAsProcessed])

  return (
    <div className="w-full h-full relative ">
      <div className="">
        <div className="">
          <div className="">
            <div className="">
              <div className="col-lg-12 mt-4">
                <div className="mt-3">
                  <div class="flex justify-between p-3 items-center">
                    <div class="h-2 mb-3 font-bold text-lg">
                      Conditional Earned Basic Income Payout Requests for Teachers
                    </div>
                    <div >
                      <select 
                          className="form-control"
                          required
                          type = 'text'
                          name = "month"
                          value = {month}
                          onChange={(e)=>setmonth(e.target.value)}
                        >
                          <option value="">All Months</option>
                          {months &&
                            months.map((month, i) => (
                              <option 
                                key={i.toString()}
                                value={month}
                                className="font-semibold text-gray"
                              >
                                {`${month}`}
                              </option>
                            ))

                          }
                        </select>
                    </div>
                    <div class="font-bold text-lg">({currencyFormatter.format(totalUCBIAmountForTeachers, { locale: 'en-NG' })})</div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped table-auto">
                      <thead className="bg-success1 text-white">
                        <tr>
                          <th className="w-2/4">Name</th>
                          <th className="w-2/4">Amount</th>
                          <th className="">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allTeachersWitUcbis && allTeachersWitUcbis.length > 0 ? (
                          allTeachersWitUcbis.map((teacher) => (
                            <tr key={teacher._id}>
                              <td className="w-2/5">{teacher.user?.name}</td>
                              <td className="w-2/5">
                                {currencyFormatter.format(teacher.amount, { locale: 'en-NG' })}
                              </td>
                              <td className="w-1/5">
                                      <button
                                        onClick={() => {
                                          dispatch(setUcbiUserDetails(teacher));
                                          dispatch(setShowUcbiDetailModal(true));
                                        }}
                                        className="outline-none focus:outline-none text-blue rounded-full transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                                      >
                                        Process
                                      </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="bg-white ">
                            <td></td>
                            <td className="ml-7">No withdrawal request from Teachers 👏</td>
                            <td></td>
                            <div> </div>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="">
          <div className="">
            <div className="">
              <div className="col-lg-12 mt-4">
                <div className="mt-3">
                  <div class="flex justify-between p-3 items-center">
                    <div class="h-2 mb-3 font-bold text-lg">
                     Earned Income Payout Requests  for Associate SDP Advisors
                    </div>
                    <div >
                      <select 
                          className="form-control"
                          required
                          type = 'text'
                          name = "month2"
                          value = {month2}
                          onChange={(e)=>setmonth2(e.target.value)}
                        >
                          <option value="">All Months</option>
                          {months &&
                            months.map((month, i) => (
                              <option 
                                key={i.toString()}
                                value={month}
                                className="font-semibold text-gray"
                              >
                                {`${month}`}
                              </option>
                            ))
                          }
                        </select>
                    </div>
                    <div class="font-bold text-lg">({currencyFormatter.format(totalUCBIAmountForAssociates, { locale: 'en-NG' })})</div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-hover table-striped">
                      <thead className="bg-success1 text-white">
                        <tr>
                          <th className="w-2/4">Name</th>
                          <th className="w-2/4">Amount</th>
                          <th className="">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allAssociatesWitUcbis && allAssociatesWitUcbis.length > 0 ? (
                          allAssociatesWitUcbis.map((associates) => (
                            <tr key={associates._id}>
                              <td>{associates.user?.name}</td>
                              <td>
                                {currencyFormatter.format(associates.amount, { locale: 'en-NG' })}
                              </td>
                              <td>
                                      <button
                                        onClick={() => {
                                          console.log("associate", associates);
                                          dispatch(setUcbiUserDetails(associates));
                                          dispatch(setShowUcbiDetailModal(true));
                                        }}
                                        className="px-2 outline-none focus:outline-none text-blue rounded-full transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                                      >
                                        Process
                                      </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="bg-white">
                            <td></td>
                            <td>No withdrawal request from Associates 👏</td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {openUCBIPaymentReqModal && (
        <PaymentRequestModal
          setOpenUCBIPaymentReqModal={setOpenUCBIPaymentReqModal}
          request={selectedRequest}
        />
      )} */}

      {showUcbiDetailModal && (
      <div
         className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}
      >
        <UCBIDetailsModal setRequests={setRequests}
        />
      </div>
      )}
    </div>
  );
}
