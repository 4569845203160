import React, { useState, useLayoutEffect, useEffect } from "react";
import { CloseCircleTwoTone, ArrowDownOutlined } from "@ant-design/icons";
import stekaImg2 from "../../img/steka/IMG-20220924-WA0000.jpg";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import { useDispatch } from "react-redux";
import { resetForm, setShowStekaForm } from "../../redux/slices/formSlice";

function CaptureSchool({ toggleCapSchoolModal }) {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(1);

  useEffect(()=>{
    dispatch(resetForm())
  },[])

  return (
    <div
      className="mx-auto my-2 w-[94%]  md:w-10/12 min-h-min rounded-xl bg-white z-50"
      // ref={captureSchoolRef}
    >
      <div className="w-full flex-col items-center flex p-3">
        <div className="flex w-full justify-end mr-2 items-center">
          <CloseCircleTwoTone
            onClick={() => {
              dispatch(setShowStekaForm(false));
            }}
          />
        </div>
        <div className="w-full mb-4 ">
          <div className="w-full text-xl font-bold text-center my-2">
          We support the UN Sustainable Development Goal on Quality Education
          </div>
          <div className="w-full px-10 py-3 text-center border rounded-lg">
            Get your school captured on this UN SDG4 aligned program for
            strengthening quality and functionality of education through the
            Reimagine Education Campaign by selecting all its target sub-goals
            would want to subscribe to supply the tools and services through
            which they meet to your school.
          </div>
        </div>

        {activeStep === 1 && (
          <StepOne activeStep={activeStep} setActiveStep={setActiveStep} />
        )}
        {activeStep === 2 && (
          <StepTwo activeStep={activeStep} setActiveStep={setActiveStep} />
        )}
        {activeStep === 3 && (
          <StepThree activeStep={activeStep} setActiveStep={setActiveStep} />
        )}
        {activeStep === 4 && (
          <StepFour
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            toggleCapSchoolModal={toggleCapSchoolModal}
          />
        )}
      </div>

      {/* //Animate arrow scroll down */}
      {/* <div className="p-2 rounded-lg absolute bottom-[440px] right-0.5">
        <ArrowDownOutlined className="text-lg animate-bounce animate-pulse" />
      </div> */}
    </div>
  );
}

export default CaptureSchool;
