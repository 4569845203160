import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";

export const removeProcessedSchools = createAsyncThunk("removeProcessedSchools", async (schoolId)=>{
    const url = `/api/steka/processed-schools/${schoolId}`;
    const { data } = await AxiosInstance.delete(url);
    return data
})

export const getProcessedStekaSchools = createAsyncThunk("get/ProcessedStekaSchools",  async () => {
          const url = `/api/steka/get-processed-requests`;
          try {
            const { data } = await AxiosInstance.post(url);
            return data.steka
          }catch(err){

          }    
})

export const getProcessedStekaSchoolsCountries = createAsyncThunk("get/ProcessedStekaSchoolsCountries",  async () => {
          const url = `/api/steka/get-country-captures`;
          try {
            const { data } = await AxiosInstance.post(url);
            return data.schools
          }catch(err){

          }    
})


const initState = {
  componentsStates: {
    removeProcessedSchoolsPrompt: false,
    hidePopOver: false,
    showSelectedSchool: false,
    showListOfProccessedSchools: false,
    showViewProcessedSchoolModal: false
  },
  processedStekaSchoolsCountries: [],
  processedSchools: [],
  isLoading: false,
};

export const stekaSlice = createSlice({
  name: "steka",
  initialState: initState,
  reducers:{
    setHidePopOver:(state, action)=>{
        state.componentsStates.hidePopOver = action.payload
    },
    setRemoveProcessedSchoolsPrompt:(state, action)=>{
        state.componentsStates.removeProcessedSchoolsPrompt = action.payload
    },
    setShowSelectedSchool:(state, action)=>{
        state.componentsStates.showSelectedSchool = action.payload
    },
    setShowListOfProccessedShools:(state, action)=>{
        state.componentsStates.showListOfProccessedSchools = action.payload
    },
    setShowViewProcessedSchoolModal:(state, action)=>{
        state.componentsStates.showViewProcessedSchoolModal = action.payload
    },
  },
  extraReducers:(builder) => {
    builder
      .addCase(removeProcessedSchools.pending, (state, action) => {
          state.isLoading = true
      })
      .addCase(removeProcessedSchools.fulfilled, (state, action) => {
          state.processedSchools = state.processedSchools.filter(school=> school._id != action.payload.deletedSchool._id) 
        //   state.allFilteredProcessedassociates = state.allFilteredProcessedassociates.filter(associate=> associate._id != action.payload.deletedAssociate._id) 
          state.isLoading = false

      })
      .addCase(getProcessedStekaSchools.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getProcessedStekaSchools.fulfilled, (state, action) => {
          state.processedSchools = action.payload
          state.isLoading = false
      })
      .addCase(getProcessedStekaSchoolsCountries.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getProcessedStekaSchoolsCountries.fulfilled, (state, action) => {
          state.processedStekaSchoolsCountries = action.payload
          state.isLoading = false
      })
    }
  },
);

export const {
              setHidePopOver,
              setRemoveProcessedSchoolsPrompt,
              setShowSelectedSchool,
              setShowListOfProccessedShools,
              setShowViewProcessedSchoolModal
            } = stekaSlice.actions
export default stekaSlice;
