import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import {
  HomeOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { signOut } from "../../../../redux/slices/userSlice";

function MenuItem({ handleClick, history, defaultKey = "1" }) {
  const dispatch = useDispatch();
  const logout = () => {
    window && window.localStorage.removeItem("persist:root");
    dispatch(signOut());
    history.push("/");
  };
  return (
    <Menu
      mode="inline"
      // theme="dark"
      className="sidebar-bg f-2"
      defaultSelectedKeys={[defaultKey]}
      //   onClick={handleClick}
    >
      <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
        <Link to="/sponsoring-orgs" />

        <span>Home</span>
      </Menu.Item>

      <Menu.Item key="2" icon={<ProfileOutlined />} className="font-2 mt-2">
        <Link to="/objectives" />
        <span>Objectives</span>
      </Menu.Item>
      <Menu.Item key="3" icon={<FileDoneOutlined />}>
        <Link to="/programs" />
        <span>Programs</span>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          logout();
        }}
        key="4"
        icon={<UserOutlined />}
      >
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );
}

export default MenuItem;
