import { createSlice } from "@reduxjs/toolkit";

const initState = {
  teacherToRate: null,
};

export const reviewSlice = createSlice({
  name: "review",
  initialState: initState,
  reducers: {
    setTeacherToRate: (state, action) => {
      state.teacherToRate = action.payload;
    },
  },
});
// Expoted actions
export const { setTeacherToRate } = reviewSlice.actions;

//Expoted selectors
// export const selectTeacherToRate = (state) => state.teacherToRate;

export default reviewSlice;
