import AxiosInstance from "../../../../../AxiosInstance/AxiosInstance";
import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux"
import { Modal } from "react-bootstrap";
import Toast from "../../../../../util/toast";
import ctries from "../../../../../util/ctryModified.json";
import {
  getCitiesAndFilter
 } from "../../../../../redux/slices/adminSlice";



export default function CreateAdmin(props) {
  // const [adminType, setAdminType] = useState("");
  const [checkedStd, setCheckedStd] = useState(false);
  const [checkedCtry, setCheckedCtry] = useState(false);
  const [error, setError] = useState("");
  const [country, setCountry] = useState(null);
  const [subcountry, setSubcountry] = useState(null);
  // const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [statesInSelCountry, setStatesInSelCountry] = useState([]);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [adminRole, setAdminRole] = useState("");
  const [adminRank, setAdminRank] = useState("");
  const [adminType, setAdminType] = useState("");
  const [email, setEmail] = useState("");
  const [province, setProvince] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [campaignSupportUnit, setCampaignSupportUnit] = useState("");
  const [targetObjective, setTargetObjective] = useState("");
  const [state, setState] = useState("");
  const dispatch = useDispatch()
  
  const {
    adminDetails,
    allCities
   } = useSelector(state => state.adminSlice )
  
   const { 
    editAdminDetailsModal
   } = useSelector(state => state.adminSlice.componentsStates )

  useState(()=>{
    let firstname = adminDetails?.name?.split(" ")[0]
    let lastname = adminDetails?.name?.split(" ")[1]
    if(editAdminDetailsModal){
        setFirstname(firstname)
        setLastname(lastname)
        setAdminType(adminDetails?.adminType)
        setAdminRole(adminDetails?.adminRole)
        setAdminRank(adminDetails?.adminRank)
        setEmail(adminDetails?.email)
        setCountry(adminDetails?.country)
        setCampaignSupportUnit(adminDetails?.campaignSupportUnit)
        setTargetObjective(adminDetails?.targetObjective)
        setState(adminDetails?.state)

    }
  }, [editAdminDetailsModal])

  useEffect(()=>{
      for(let i = 0; i < ctries.length; i++){
        if (ctries[i].country == country){
          setStatesInSelCountry(ctries[i]['states'])
        }
    }
  }, [country])

  useEffect(()=>{
      dispatch(getCitiesAndFilter(state))
  }, [state])

  const notify = (message, type) => {
    return Toast(message, type);
  };

  /**const handleSelect = (e) => {
    setAdmin({...admin, [e.target.name]: e.target.value});
    if(admin.adminType == 'operations'){
      setAdtype('operations');
    }
    if(admin.adminType == 'advisory') {
      setAdtype('advisory');
    }
    if(admin.adminType == 'exchequer') {
      setAdtype('exchequer');
    }
  };*/
  const onChangeStd = (e) => {
    setCheckedStd(e.target.checked);
    // setAdminType(e.target.checked && e.target.value);
    setAdminRole(e.target.checked && e.target.value);
    
  };
  const onChangeCtry = (e) => {
    setCheckedCtry(e.target.checked);
    // setAdminType(e.target.checked && e.target.value);
    setAdminRole(e.target.checked && e.target.value);
  };

  ////////////////////////////////
  const addAdmin = async (e) => {
    e.preventDefault();

    setLoading(true);
    // adminRole = adminType;
    if (password == "" || confirmPassword == "") {
      return notify("Password cannot be empty!", "error");
    } 

    if (password != confirmPassword) {
      return notify("Password must match!", "error");
    }
    try {
      const adminRoute =
        adminType === "otherAdmin"
          ? "api/user/create-standard-admin"
          : "api/user/create-country-admin";
      const data = {
        name: firstname + " " + lastname,
        email: email,
        country: country,
        state: state,
        province: province,
        password: password,
        isAdmin: true,
        adminRole: adminRole,
        adminType: adminType,
        userType: "companyStaff",
        adminRank: adminRank,
        campaignSupportUnit: campaignSupportUnit,
        targetObjective: targetObjective,
        state: state,
      };
      const res = await AxiosInstance.post(adminRoute, { data });

      if (res.data.error) {
        setLoading(false);
        setError(res.data.message);
        console.log(res.data);
        // Toast(JSON.stringify(res.data.messsage), "success");
        // notify(res.data.messsage, "error");
        notify(JSON.stringify(res.data.message), "error");
      } else {
        setLoading(false);
        setFirstname("")
        setLastname("")
        setAdminType("")
        setEmail("")
        setCountry("")
        setPassword("")
        setConfirmPassword("")
        setCampaignSupportUnit("")
        setTargetObjective("")
        setState("")

        notify("Admin added successfully", "success");
      }
    } catch (error) {
      console.log(error);
      setError(
        error?.response?.data?.error
          ? error.response.data.error
          : error.messsage
      );
      alert(JSON.stringify(error));
    } finally {
      setLoading(false);
      setError("");
    }
  };

  const updateAdmin = async (e) => {
    e.preventDefault();

    setLoading(true);

    // const resp = Object.values(admin).some((value) => value == "");

    /*if (resp) {
      return notify("All fields are required!", "error");
    }*/
    if (password == "" || confirmPassword == "") {
      return notify("Password cannot be empty!", "error");
    } 

    if (password != confirmPassword) {
      return notify("Password must match!", "error");
    }
    try {
      const adminRoute = `api/user/admin/${adminDetails?._id}`;
      console.log("adminDetails._id", adminDetails._id);
      const data = {
        name: firstname + " " + lastname,
        email: email,
        country: country,
        state: state,
        province: province,
        password: password,
        isAdmin: true,
        adminRole: adminRole,
        adminType: adminType,
        userType: "companyStaff",
        adminRank: adminRank,
        campaignSupportUnit: campaignSupportUnit,
        targetObjective: targetObjective,
        state: state,
      };
      const res = await AxiosInstance.patch(adminRoute, { data });

      if (res.data.error) {
        setLoading(false);
        setError(res.data.message);
        console.log(res.data);
        // Toast(JSON.stringify(res.data.messsage), "success");
        // notify(res.data.messsage, "error");
        notify(JSON.stringify(res.data.message), "error");
      } else {
        notify("Admin updated successfully", "success");
      }
    } catch (error) {
      console.log(error);
      setError(
        error?.response?.data?.error
          ? error.response.data.error
          : error.messsage
      );
      alert(JSON.stringify(error));
    } finally {
      setLoading(false);
      setError("");
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // maskClosable="false"
      // closeable="false"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="text-center col ml-2">{!editAdminDetailsModal ? "Create Admin" : "Re-assign Admin"}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-4">
        {/* <div className="container ">
        {/* onSubmit={this.onSubmit} */}
        <div className="">
          <div className="col">
            <form className="col-lg-12">
              <div className="row"></div>

              <div className="row text-xs">
                <div className="col-lg-3">
                  <input
                    type="checkbox"
                    id="countryAdmin"
                    name="countryAdmin"
                    value="countryAdmin"
                    disabled={checkedStd}
                    onChange={onChangeCtry}
                  />
                  <label for="countryAdmin" className="ml-2">
                    Country Manager
                  </label>
                </div>
                <div className="col-lg-4 mb-3">
                  <input
                    type="checkbox"
                    id="otherAdmin"
                    name="otherAdmin"
                    value="otherAdmin"
                    disabled={checkedCtry}
                    onChange={onChangeStd}
                  />
                  <label for="otherAdmin" className="ml-2">
                    Other Admin
                  </label>
                </div>
                {checkedStd ?
                  <div className="w-full flex flex-row justify-between">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <select
                          className="form-control text-small"
                          name = "adminType"
                          value={adminType}
                          onChange={(e)=>setAdminType(e.target.value)}
                        >
                          <option value="">Admin Type</option>
                          <option >Operations Management</option>
                          <option >Content Curration</option>
                          <option >Content Moderation</option>
                          <option >Accounts and Finance</option>
                          <option >adverts Management</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <select
                          className="form-control text-small"
                          name = "adminRank"
                          value={adminRank}
                          onChange={(e)=>setAdminRank(e.target.value)}
                        >
                          <option value="">Admin Rank</option>
                          <option value="legate">Legate</option>
                          <option value="praetor">Praetor</option>
                          <option value="tribune">Tribune</option>
                        </select>
                      </div>
                    </div>
                  </div>
                : ""}
                <div className="form-group col-lg-6">
                  <select
                    className="form-control text-small"
                    value={country}
                    onChange={(e) => {
                      // const _item = JSON.parse(e.target.value);
                      // setCountry(_item.country);
                      // setSubcountry(_item.states);
                      setCountry(e.target.value)
                    }}
                    required
                  >
                    <option value="">Select a Country</option>
                    {/* {renderCountries()} */}
                    {ctries.length > 0 && ctries.map((country,i) =>{
                      return <option key={i} >{country.country}</option>
                    })}
                  </select>
                </div>
                {adminType == "otherAdmin" && adminRank == "praetor" || adminRank == "tribune" ?
                <div className="form-group col-lg-6">
                  <select 
                    className="form-control text-small"
                    value = {state}
                    onChange={async(e) => {
                      setState(e.target.value)
                      // setAdmin({state: e.target.value});
                      // console.log(e.target.value);
                      // await AxiosInstance.post('/api/province/get-all-cities', {
                      //   state: e.target.value
                      // }).then((res) => {
                      //       setCity(res.data.cities);
                      //       console.log(res.data.cities);
                      //   });
                    }}
                    disabled={!statesInSelCountry} 
                    >
                      <option value="">Select a State</option>
                      {renderStates(statesInSelCountry)}
                  </select>
                </div>
                : ""}
                {/* {adminType == "otherAdmin" && adminRank == "tribune" ?  */}
                {adminRole == "otherAdmin" && adminRank == "tribune" ? 
                <div className="col-lg-6">
                    <div className="form-group">
                      <select 
                        className="form-control"
                        required
                        type = 'text'
                        name = "province"
                        value = {province}
                        onChange={(e)=>setProvince(e.target.value)}
                      >
                        <option value="">Select a City</option>
                        {allCities && allCities.length > 0 &&
                          allCities.map((cities, i) => (
                            <option 
                              key={i.toString()}
                              value={cities}
                              className="font-semibold text-gray"
                            >
                              {`${cities}`}
                            </option>
                          ))

                        }
                      </select>
                    </div>
                  </div>: ""}
                  {adminRole == "otherAdmin" ?
                <div className="col-lg-6">
                    <div className = 'form-group'>
                        <input 
                          type="text" 
                          name="campaignSupportUnit"
                          value={campaignSupportUnit}
                          onChange={(e)=>setCampaignSupportUnit(e.target.value)}
                          placeholder="Campaign Support Unit"
                          className="form-control"
                        />
                    </div>
                </div>
                :""}
                {adminRole == "otherAdmin" && adminRank == "praetor" ?
                <div className="col-lg-6">
                    <div className = 'form-group'>
                        <input 
                          type="number" 
                          min={0}
                          max={50000}
                          name="targetObjective"
                          value={targetObjective}
                          onChange={(e)=>setTargetObjective(e.target.value)}
                          placeholder="Target Entity Capture Objective (1 to  50,000)"
                          className="form-control"
                        />
                    </div>
                </div>
                :""}
                  <div className="col-lg-6">
                    <div className = 'form-group'>
                        <input 
                          type="text" 
                          name="email"
                          value={email}
                          onChange={(e)=>setEmail(e.target.value)}
                          placeholder="Admin Email"
                          className="form-control"
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group ">
                    {/* <label>Email:</label> */}
                    <input
                      className="form-control"
                      required
                      type="text"
                      name="firstname"
                      value={firstname}
                      onChange={(e)=>setFirstname(e.target.value)}
                      placeholder="First Name"
                    ></input>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    {/* <label>Password:</label> */}
                    <input
                      className="form-control"
                      required
                      type="text"
                      name="lastname"
                      onChange={(e)=>setLastname(e.target.value)}
                      value={lastname}
                      placeholder="Last Name"
                    ></input>
                  </div>
                </div>

                {/* <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      className="form-control"
                      required
                      type="text"
                      name="country"
                      onChange={handleChange}
                      value={admin.country}
                      placeholder="Country"
                    ></input>
                  </div>
                </div> */}

                {/* //////////////////////////////// */}
                {/* //////////////////////////////// */}
                <div className="col-lg-6">
                  <div className="form-group">
                    {/* <label>Confirm Password:</label> */}
                    <input
                      className="form-control"
                      required
                      type="password"
                      name="password"
                      onChange={(e)=>setPassword(e.target.value)}
                      value={password}
                      placeholder="Password"
                    ></input>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    {/* <label>Confirm Password:</label> */}
                    <input
                      className="form-control"
                      required
                      type="password"
                      name="confirmPassword"
                      onChange={(e)=>setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                      placeholder="Re-type admin password"
                    ></input>
                  </div>
                </div>

                <div className="col-lg-12">
                  <button
                    className="btn btn-primary w-100  mt-3 text-[#1D6DAE]"
                    type="submit"
                    onClick={editAdminDetailsModal ? updateAdmin : addAdmin}
                  >
                    {editAdminDetailsModal ? "Re-assign" : "Confirm"}
                  </button>
                </div>

                <br />
              </div>
            </form>
          </div>
        </div>
        {/* </div>
        </div> */}
      </Modal.Body>
    </Modal>
  );
}

function renderCountries() {
  return ctries.map((item) => {
    return <option value={JSON.stringify(item)}>{item.country}</option>;
  });
}

function renderStates(statesInSelCountry) {
  //console.log(states);
  if (statesInSelCountry === null) return <></>;
  return statesInSelCountry.map((state) => {
    return <option value={state}>{state}</option>;
  });
}