export const paystackConfig = (userEmail, amount) => {
  try{
    return {
      reference: new Date().getTime().toString(),
      email: userEmail,
      amount: amount * 100, //* 100 converts sub amount from naira to to kobo
      publicKey: "pk_test_7b545e0d7a1aaa0e39782e7d5aa7e9595a8082fc",
    };
  }catch(err){
    console.log(err);
  }
  
};
