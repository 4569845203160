import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowStekaForm } from "../../redux/slices/formSlice";

function SDG8() {
  const dispatch = useDispatch();
  const [joinCampaign, setJoinCampaign] = useState(false);

  const toggleJoinCampaign = () => setJoinCampaign((prev) => !prev);
  const user = useSelector((state) => state.user.user);
  return (
    <div className="w-full h-full">
      <section className="w-full flex justify-between items-center px-1.5">
        <div className="text-lg text-[#00AFEF] font-bold">
          UN Sustainable Development Goal on Decent Work and Economic Growth
        </div>
        {/* <button className="border text-lg font-bold px-2 py-1 shadow-sm">
          Capture Company
        </button> */}
        <button className="border text-lg font-bold px-2 py-1 rounded-lg  transition-all duration-700  hover:bg-[#00AFEF] hover:text-white shadow-sm  focus:outline-none">
          {user.userType == "company" ? "Capture Company" : "Join Program"}
        </button>
      </section>
      {/* // second section */}

      <section className="w-full h-full bg-white p-4 rounded-xl mt-3">
        <div className="w-full h-24 mb-2 flex justify-start items-center">
          <img src="/sdgs.PNG" alt="" className="w-[100] h-full object-cover" />
          <div className="text-xl font-bold ml-4">
            Rethink Business Campaign
          </div>
        </div>
        <div className="text-justify text-xs md:text-sm">
          This is a campaign to raise awareness on need for sustainable
          enterprise growth as a bottom-line for growth of all sectors of
          economies, and while ensuring that jobs of individuals involved in
          operations of enterprises are satisfactory, and to capture businesses
          on a program designed to address that concern, in line with the United
          Nations Sustainable Development Goal No. 8 for all countries on decent
          work and economic growth. We provide a digital framework for
          associates advisors from various partnering public and private sector
          organizations in different countries, including our parent company BWP
          Utvecklingsbolag, to capture businesses in their communities on the UN
          SDG 8 aligned program on decent job and economic growth through this
          campaign.
        </div>
      </section>

      {/* Third section  */}

      <section className="w-full h-full bg-white p-4 rounded-xl mt-10 relative">
        <div className="w-full flex justify-start items-center ">
          <div
            className="w-20 h-20  border-[#1D6DAE] text-[#00AFEF] rounded-full mr-2 flex justify-center items-center font-bold "
            style={{ borderWidth: 10 }}
          >
            SDG8
          </div>
          <div className="ml-4 font-bold text-xl">
            Enterprise Growth Boost and Development Program
          </div>
        </div>
        <div className="text-justify text-xs md:text-sm mt-3 pb-3 border-b border-b-gray-300">
          Aligned to the United Nations Sustainable Development Goal no. 8 for
          countries on decent work and economic growth, Enterprise Growth Boots
          and Development Support Program is strategic sustainable development
          program designed to help private sector enterprises by optimize
          efficiency in engagement of manpower based on competences that meet
          their knowledge and skill needs for doing business , strengthen
          effectiveness of personnel already involved in their operation in
          leveraging knowledge, skills and ethics acquired through education and
          continues learning into decisions and actions that deliver operational
          goals and core values of businesses; while supporting them to
          sustainably grow and develop as units o f the economy of every country
          into successful ventures that create jobs, contribute to the GDP and
          the overall economic growth and prosperity of communities. The is
          program involves a combination tools and services to deliver 9 target
          sub-goals. You can captured in your community on this program by
          simply introducing them the this program and helping them select any
          and all of its 17 target sub-goals of their interest, using your
          associate identification code. Captured schools would receive free
          updates on the program target sub-goals of their interest, and how
          they are met through different tools and services supplied by
          different public and private sector partnering entities on this
          program, including Esgrown.
        </div>
      </section>
    </div>
  );
}

export default SDG8;
