import { configureStore } from "@reduxjs/toolkit";
// import { composeWithDevTools } from "redux-devtools-extension";
import { combineReducers } from "@reduxjs/toolkit";
import logger from "redux-logger";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
// import { actionHookMiddleware } from "./middlewares/actionHookMiddleware";
import userSlice from "./slices/userSlice";
import serviceSlice from "./slices/serviceSlice";
import reviewSlice from "./slices/reviewSclice";
import formSlice from "./slices/formSlice";
import campaignSlice from "./slices/campaignSlice";
import associateSlice from "./slices/associateSlice";
import stekaSlice from "./slices/stekaSlice";
import careerFieldSlice from "./slices/careerFieldSlice";
import sponsoringOrganizationSlice from "./slices/sponsoringOrganizationSlice";
import adminSlice from "./slices/adminSlice";
import ucbiSlice from "./slices/ucbiSlice";
import postSlice from "./slices/postSlice";
import thunk from 'redux-thunk';


const reducers = combineReducers({
  user: userSlice.reducer,
  service: serviceSlice.reducer,
  form: formSlice.reducer,
  review: reviewSlice.reducer,
  campaign: campaignSlice.reducer,
  associateProcessed: associateSlice.reducer,
  stekaSlice: stekaSlice.reducer,
  careerFieldSlice: careerFieldSlice.reducer,
  sponsoringOrganizationSlice: sponsoringOrganizationSlice.reducer,
  adminSlice: adminSlice.reducer,
  ucbiSlice: ucbiSlice.reducer,
  postSlice: postSlice.reducer,
});

// store config
const persistConfig = {
  key: "root",
  storage,
  blacklist: ['form', 'service']
};
const persistedReducer = persistReducer(persistConfig, reducers);
const middlewares = [logger]; //actionHookMiddleware

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: middlewares,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk),
});
const persiststore = persistStore(store);

export { persiststore, store };
