import React, { useEffect, useState } from "react";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import Toast from "../../../util/toast";
import AxiosInstance from "../../../AxiosInstance/AxiosInstance";

function CreateNewExercise({ history, toggleCreateExercise }) {
  const [correctAns, setCorrectAns] = useState();
  const [jobOpening, setJobOpening] = useState();
  const [service, setService] = useState("RecruitmentManagementService");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allJobsPostedByUser, setAllJobsPostedByUser] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      question: "",
      optionA: "",
      optionB: "",
      optionC: "",
      optionD: "",
      allotedTime: "",
      allotedScore: "",
    },
  });

  useEffect(() => {
    getAllVacanciesPostedByUser();
  }, []);
  // get all the jobs that the logged in user has applid to
  const getAllVacanciesPostedByUser = async () => {
    setLoading(true);

    try {
      const { data } = await AxiosInstance.post(
        "/api/jobs/get-all-vacancies-posted-by-user"
      );
      console.log("All vancancies posted by user:", data.jobs);
      if (data && data.jobs?.length > 0) {
        setAllJobsPostedByUser(data.jobs);
      }
    } catch (error) {
      return Toast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const onSubmit = async (formData) => {
    if (!correctAns) {
      Toast("You must select correct answer to continue", "error");
      return;
    }
    if (!jobOpening) {
      Toast("You must select a job opening to continue", "error");
      return;
    }
    formData.correctAnswer = correctAns;
    formData.jobId = jobOpening;
    formData.service = service;
    try {
      setIsLoading(true);
      const { data } = await AxiosInstance.post(`/api/excercise/create`, formData);
      if (data.status === "Success") {
        Toast("Excercises created successfully", "success");
        toggleCreateExercise();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleCorrecAnsChange = (e) => setCorrectAns(e.target.value);
  const handleJobOpeningChange = (e) => setJobOpening(e.target.value);
  const handleServiceChange = (e) => setService(e.target.value);
  return (
    <div className="absolute top-6 left-4 w-full">
      <div
        className="w-[90%] mx-auto bg-white  rounded-xl shadow-lg p-4"
        style={{ minHeight: 450, zIndex: 500 }}
      >
        <div className="w-full flex justify-end mr-2">
          <CloseCircleTwoTone onClick={toggleCreateExercise} />
        </div>
        <div className="w-full flex justify-center text-xl text-esgrown font-bold">
          Create New Exercise
        </div>
        {/* <div className="my-2 border-b "></div> */}
        <div className="w-full px-3 mt-3">
          <div className="w-full flex justify-center align-center">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <input
                  type="text"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder="Enter question here .."
                  className="w-full px-2 py-2 border-2 rounded-lg outline-esgrown "
                />
              )}
              name="question"
            />
            {errors.question && (
              <h1 style={{ color: "red", fontSize: 25 }}>*</h1>
            )}
          </div>
          {/* option  */}
          <div className="w-full flex justify-start space-x-2 items-center mt-3">
            <div className="bg-sky-50 py-2.5 px-4 rounded-lg">A</div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <input
                  type="text"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder="Enter option A text"
                  className="w-full px-2 py-2 border-2 rounded-lg outline-esgrown "
                />
              )}
              name="optionA"
            />
            {errors.optionA && (
              <h1 style={{ color: "red", fontSize: 25 }}>*</h1>
            )}
          </div>
          {/* option  */}
          <div className="w-full flex justify-start space-x-2 items-center mt-3">
            <div className="bg-sky-50 py-2.5 px-4 rounded-lg">B</div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <input
                  type="text"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder="Enter option B text"
                  className="w-full px-2 py-2 border-2 rounded-lg outline-esgrown "
                />
              )}
              name="optionB"
            />
            {errors.optionB && (
              <h1 style={{ color: "red", fontSize: 25 }}>*</h1>
            )}
          </div>
          {/* option  */}
          <div className="w-full flex justify-start space-x-2 items-center mt-3">
            <div className="bg-sky-50 py-2.5 px-4 rounded-lg">C</div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <input
                  type="text"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder="Enter option C text"
                  className="w-full px-2 py-2 border-2 rounded-lg outline-esgrown "
                />
              )}
              name="optionC"
            />
            {errors.optionC && (
              <h5 style={{ color: "red", fontSize: 25 }}>*</h5>
            )}
          </div>
          {/* option  */}
          <div className="w-full flex justify-start space-x-2 items-center mt-3">
            <div className="bg-sky-50 py-2.5 px-4 rounded-lg">D</div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <input
                  type="text"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder="Enter option D text"
                  className="w-full px-2 py-2 border-2 rounded-lg outline-esgrown "
                />
              )}
              name="optionD"
            />
            {errors.optionD && (
              <h5 style={{ color: "red", fontSize: 25 }}>*</h5>
            )}
          </div>
        </div>
        <div className="my-3 border-b border-b-esgrown w-[96%] mx-auto "></div>
        <div className="flex w-full justify-between items-center px-3">
          <select
            name=""
            onChange={handleCorrecAnsChange}
            className="px-3 py-2 mr-3 border-2 focus:border-esgrown rounded-lg"
          >
            <option value="">Select Correct Answer</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
          </select>
          <select
            name=""
            onChange={handleServiceChange}
            className="px-3 py-2 mr-3 border-2 w-[17%] focus:border-esgrown rounded-lg"
          >
            <option value="RecruitmentManagementService">
              Recruitment Management Service
            </option>
          </select>
          <select
            name=""
            onChange={handleJobOpeningChange}
            className="px-3 py-2 mr-3 border-2 focus:border-esgrown rounded-lg"
          >
            <option value="">Job opening</option>
            {allJobsPostedByUser.map((job) => {
              return (
                <option name={job._id} value={job._id}>
                  {job?.title}
                </option>
              );
            })}
          </select>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <input
                placeholder="Alloted time in seconds E.g 25"
                type="number"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                className="w-[24%] px-2 py-2 border-2 rounded-lg outline-esgrown "
              />
            )}
            name="allotedTime"
          />
          {errors.allotedTime && (
            <h5 style={{ color: "red", fontSize: 25 }}>*</h5>
          )}
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <input
                placeholder="Alloted score"
                type="number"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                className="w-[15%] px-2 py-2 border-2 rounded-lg outline-esgrown"
              />
            )}
            name="allotedScore"
          />
          {errors.allotedScore && (
            <h5 style={{ color: "red", fontSize: 25 }}>*</h5>
          )}
        </div>
        <button
          onClick={handleSubmit(onSubmit)}
          className="px-3 py-1.5 mr-1 m-3 text-xl text-white rounded-lg bg-esgrown font-bold"
        >
          {isLoading ? "Creating Excercise..." : "Create Excercise"}
        </button>
      </div>
    </div>
  );
}

export default CreateNewExercise;
