import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeInput } from "../../redux/slices/formSlice";

function StepTwo({ setActiveStep, activeStep }) {
  const dispatch = useDispatch();
  const stekaData = useSelector((state) => state.form);
  const disableNextBtn = stekaData.TCAccepted;
  const handleChange = (e) => {
    dispatch(changeInput({ name: e.target.name, value: e.target.checked }));
  };
  return (
    <div className="w-full h-[65%] flex flex-col items-center mt-2 md:mt-4">
      <div className=" text-sm md:text-xl font-bold text-gray-700 mb-2 px-12">
      His Grace’s Earth Future and Shared Prosperity $535,000 State and Country Best School and Teachers Challenge
      </div>
      <div className="flex justify-between items-center w-full px-12">
        <button
          // onClick={() => setActiveStep(1)}
          className={` bg-blue-500 px-3 py-2.5 rounded-[50%] text-white hover:shadow-xl hover:translate-x-1 duration-500`}
        >
          1
        </button>
        <hr className="w-1/4 border-b border-blue-500 text-blue-500 "></hr>
        <button
          // onClick={() => setActiveStep(2)}
          className="bg-blue-500 px-3 py-2.5 rounded-[50%] text-white hover:shadow-xl hover:translate-x-1 duration-500"
        >
          2
        </button>
        <hr className="w-1/4 border-b"></hr>
        <button
          // onClick={() => setActiveStep(3)}
          className="bg-gray-300 px-3 py-2.5 rounded-[50%] text-white hover:shadow-xl hover:translate-x-1 duration-500"
        >
          3
        </button>
        <hr className="w-1/4 border-b"></hr>
        <button
          // onClick={() => setActiveStep(4)}
          className="bg-gray-300 px-3 py-2.5 rounded-[50%] text-white hover:shadow-xl hover:translate-x-1 duration-500"
        >
          4
        </button>
      </div>

      <div className="overflow-y-scroll w-[95%] h-full mt-5">
        <div className="text-sm md:text-md text-justify pl-2 ">
          (Your school enters Företaget $535,000 Reimagine Education Best School
          and Teachers Challenge set by His Ducal Highness, The Grand Duke of
          Eddaland once captured on the Knowledge Base Advancement Program.
          School qualifies for state prize once up to 50% of schools in your
          state in captured on the program, and you qualify for country prize
          once 50% of your schools in your country are captured.)
        </div>

        <div className="flex items-center space-x-4 mt-3 md:mt-6">
          <div className="">
            <input
              onChange={handleChange}
              checked={stekaData.TCAccepted}
              name="TCAccepted"
              type="checkbox"
              className="border-2 w-12 h-6 mt-2"
            />
          </div>
          <div className="text-md text-justify text-sm md:text-md">
            We have read the terms and conditions for participation in this
            challenge, and would we would like to enter this challenge.
          </div>
        </div>
      </div>
      <div className="flex mt-5 mx-10 w-[90%] justify-between">
        <button
          onClick={() => setActiveStep(1)}
          class="self-end mr-2 px-1 py-2 rounded-lg bg-[#244060]  text-xs text-white hover:translate-x-1 duration-500"
        >
          previous
        </button>
        <button
          onClick={() => setActiveStep(3)}
          disabled={!disableNextBtn}
          class={`self-end mr-2 px-3 py-2 rounded-lg ${
            !disableNextBtn ? "bg-gray-300" : "bg-[#244060] "
          }  text-xs text-white hover:translate-x-1 duration-500`}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default StepTwo;
