import {
    CloseCircleTwoTone,
  } from "@ant-design/icons";
  import { Avatar, Checkbox, Dropdown, Image, Popover, Spin } from "antd";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import Toast from "../../../../util/toast";
  import { MediaPath } from "../../../../App";
  import ctries from "../../../../util/allcountries";
  import RemoveOrganizationConfirmationModal from "./RemoveOrganizationConfirmationModal";
  import {
          setRemoveOrganizationPrompt , 
          setShowOrganizationCreateModal,
          setShowOrganizationPopOver,
          setShowSupportingOrganizationDetailsModal,
          setEditSupportingOrganizationDetailsModal,
          setOrganizationDetail
          } from "../../../../redux/slices/sponsoringOrganizationSlice";
  
  function SupportingOrganizationDetailsModal() {
    const dispatch = useDispatch();
    
    const {showSupportingOrganizationDetailsModal,
          removeOrganizationPrompt
    } = useSelector(state => state.sponsoringOrganizationSlice.componentsStates )
    
    const {organizationDetail
    } = useSelector(state => state.sponsoringOrganizationSlice )
  
    return (
      <div
        className="w-full h-full flex bg-white flex-col items-end"
        style={{ zIndex: 200 }}
      >
        <div className="h-[20%] pb-1 w-full">
          <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
            <CloseCircleTwoTone
              onClick={() => {
                // setShowSelectedAssociate(false)
                // dispatch(setShowViewAssociateModal(false))
                dispatch(setShowOrganizationPopOver(false))
                dispatch(setShowSupportingOrganizationDetailsModal(false))
            }}
              color="red"
            />
          </div>
          <div className="flex flex-col min-h-min  ">
            {/* section 1 */}
            <div className="text-3xl font-semibold  w-full flex items-center justify-center pb-2 border-b border-b-[#1D6DAE] ">
            UN SDG 4 Support Organizations
            </div>
            {/* section 2*/}
            <div className=" mt-2 flex justify-between">
              <div className="w-[20%] rounded-lg bg-green-400">
                {/* Image  */}
                <img
                  // rounded
                  src="{}"
                  className="w-full h-full object-cover rounded-lg"
                  // style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div className="w-[86%] px-[10%] mt-3 font-semibold text-base">
                {/* details  */}
                <div className="w-full flex justify-between ">
                  <div>Name:</div>
                  <div className="text-left w-1/2">
                    {organizationDetail?.name}
                  </div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>Phone:</div>
                  <div className="text-left w-1/2">
                    {organizationDetail?.phone}
                  </div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>Email:</div>
                  <div className="text-left w-1/2">{organizationDetail?.email}</div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>Organization Type:</div>
                  <div className="text-left w-1/2">
                    {organizationDetail?.organizationType}
                  </div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>Campaign Support Area:</div>
                  <div className="text-left w-1/2">
                    {organizationDetail?.campaignType}
                  </div>
                </div>
                { organizationDetail?.organizationType == "Government Organization" && (
                <>
                  <div className="w-full flex justify-between ">
                    <div>Country:</div>
                    <div className="text-left w-1/2">
                      {organizationDetail?.country}
                    </div>
                  </div>
                  <div className="w-full flex justify-between ">
                    <div>State:</div>
                    <div className="text-left w-1/2">
                      {organizationDetail?.state}
                    </div>
                  </div>
                  <div className="w-full flex justify-between ">
                    <div>Mandate:</div>
                    <div className="text-left w-1/2">
                      {organizationDetail?.field}
                    </div>
                  </div>
                </>
                )}
                {organizationDetail?.organizationType == "Business Franchise" && (
                <div className="w-full flex justify-between ">
                    <div>Supported SDP target sub-goals:</div>
                    <div className="text-left w-1/2">
                      {organizationDetail?.supportedSDP}
                    </div>
                </div>
                )}
              </div>
            </div>
            <div className="mt-2 schoolDivider"></div>
            {/* section 3 */}
            <div>
              <div className="w-full flex justify-end mt-5">
                <button className="px-6 text-sm outline outline-gray-300 text-white bg-[black] py-1 rounded-lg transition-all duration-700 reAssignButton"
                onClick={()=>{
                        dispatch(setShowOrganizationPopOver(true))
                        dispatch(setOrganizationDetail(organizationDetail))
                        dispatch(setEditSupportingOrganizationDetailsModal(true))
                        dispatch(setShowOrganizationCreateModal(true))
                }}
                >
                  Edit Campaign Support Area
                </button>
                <button className="px-6 text-sm bg-red-500 text-white py-1 rounded-lg hover:translate-y-1 transition-all duration-700 removeButton"
                onClick={()=>{
                  dispatch(setShowOrganizationPopOver(true))
                  dispatch(setRemoveOrganizationPrompt(true))
                  dispatch(setOrganizationDetail(organizationDetail))
                }
                }
                >
                  Remove
                </button>
              </div>
              </div> 
            </div>
          </div>

          {/* {removeOrganizationPrompt && (<RemoveOrganizationConfirmationModal/>)} */}
      </div>
    );
  }
  
  export default SupportingOrganizationDetailsModal;
  
  function renderStates(country) {
    console.log("Country supplied: " + country);
    let states = null;
    for (const obj of ctries) {
      if (obj.country === country) {
        console.log(obj.states.length);
        states = obj.states;
      }
    }
    if (states === null) return <></>;
    return states.map((state, i) => {
      return (
        <option
          key={i.toString()}
          value={`League = ${i + 1} = ${state}`}
        >{`League ${i + 1} (${state})`}</option>
      );
    });
  }
  