import React, { useState, useEffect } from "react";
import Checkbox from "antd/lib/checkbox/Checkbox";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";

function QuestionCard({ exercise, toggleStart }) {
  const [timeLeft, setTimeLeft] = useState(Number(exercise.allotedTime));
  const [isLoading, setIsLoading] = useState(false);
  const [choosenAnswer, setChoosenAnswer] = useState();

  useEffect(() => {
    let timeOutId;
    if (timeLeft > 0) {
      timeOutId = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else {
      handleAnswer()
    }
    return () => clearTimeout(timeOutId);
  }, [timeLeft]);

  const handleAnswer = async () => {
    try {
      setIsLoading(true);
      const score =
        choosenAnswer === exercise.correctAnswer
          ? Number(exercise.allotedScore)
          : 0;
      const { data } = await AxiosInstance.post("/api/application/score-user", {
        score,
        excerciseId: exercise._id,
        applicationId: exercise.applicationId,
      });
      if (data.status === "success") {
        console.log("resposne", data);
        toggleStart();
      }
    } catch (error) {
      console.log("err", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-[94%] mx-auto py-4 bg-slate-100 text-esgrown rounded-xl">
      <div className="w-full px-4 py-2 flex justify-between">
        <div className="text-xl font-bold">
          Question: {exercise.exerciseNo}
        </div>
        <div className="text-xl font-bold">{timeLeft}</div>
      </div>
      <div className="w-[94%] mx-auto border-b-2"></div>
      <div className="w-full my-3 mx-10 text-xl">{exercise.question}</div>
      {["A", "B", "C", "D"].map((option, index) => {
        return (
          <div
            key={index.toString()}
            className="flex mx-10 mt-3 text-lg w-full justify-start items-center space-x-2"
          >
            <div>{option}</div>
            <Checkbox
              checked={choosenAnswer === option}
              onClick={() => setChoosenAnswer(option)}
            />
            <div>{exercise[`option${option}`]}</div>
          </div>
        );
      })}
      <button
        onClick={handleAnswer}
        className="w-[96%] mt-3 flex self-center bg-esgrown text-white justify-center py-2 rounded-lg mx-auto"
      >
        {isLoading ? "Submitting" : "Answer"}
      </button>
    </div>
  );
}

export default QuestionCard;
