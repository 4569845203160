import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import Toast from "../util/toast";
import AxiosInstance from "../AxiosInstance/AxiosInstance";

function ConfirmStaffStatus({
  openConfirmAccount,
  setOpenConfirmAccount,
  user,
  setRefresh,
  refresh,
}) {
  const [teachers, setTeachers] = useState([]);
  const [idsOfConfirmedTeacher, setIdsOfConfirmedTeacher] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChangeSelection = (e) => {
    // if checkbox is checked and userId is not added to list the add it
    if (e.target.checked && !idsOfConfirmedTeacher.includes(e.target.name)) {
      idsOfConfirmedTeacher.push(e.target.name);
    }

    //if checkbox is checked and userId is added to list the, remove it
    if (
      e.target.checked === false &&
      idsOfConfirmedTeacher.includes(e.target.name)
    ) {
      //   idsOfConfirmedTeacher.push(e.target.name);
      const filteredIds = idsOfConfirmedTeacher.filter(
        (id) => id !== e.target.name
      );
      setIdsOfConfirmedTeacher(filteredIds);
    }
  };

  const confirmSatusOfSelectedTeachers = async () => {
    if (idsOfConfirmedTeacher.length < 1) {
      return Toast("No staff is selected!", "error");
    }
    try {
      setLoading(true);
      let route = null;
      if (user.userType === "school") route = "/api/user/confirm-teachers";
      if (user.userType === "company") route = "/api/user/confirm-compstaff";
      if (!route) return Toast("Confirm Staff route can not be null!", "error");
      const { data } = await AxiosInstance.post(route, { data: idsOfConfirmedTeacher });
      setRefresh(!refresh); // refresh parent component
      return Toast("Status modified successfully!", "success");
    } catch (error) {
      return Toast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  // Fetch my school (org) Teachers
  useEffect(() => {
    const fetchTeachers = async () => {
      setLoading(true);
      const route = "/api/user/get-unconfirmed-teachers";
      const { data } = await AxiosInstance.post(route);
      if (data && data.staff) {
        setTeachers(data.staff);
        setLoading(false);
      }
    };
    const fetchCompStaff = async () => {
      setLoading(true);
      const route = "/api/user/get-unconfirmed-compstaff";
      const { data } = await AxiosInstance.post(route);
      if (data && data.staff) {
        setTeachers(data.staff); //this will rather hold list of company staff
        setLoading(false);
      }
    };
    if (user?.userType === "school") fetchTeachers();
    if (user?.userType === "company") fetchCompStaff();
  }, [refresh]);
  return (
    <div className="w-[80%] md:w-[30%] h-full absolute top-0 right-0">
      <Drawer
        title="Confrim Teachers' membership"
        placement="right"
        onClose={() => {
          setOpenConfirmAccount(false);
          //   refreshParent(true);
        }}
        open={openConfirmAccount}
      >
        <div className="flex flex-col space-y-3 w-full ">
          {Array.from(teachers).length > 0 &&
            Array.from(teachers).map((teacher, i) => (
              <div key={i.toString()} className="flex w-full space-x-3">
                <div className="w-[30%]">
                  <p>{teacher.name}</p>
                </div>
                <input
                  type="checkbox"
                  name={teacher._id}
                  onChange={onChangeSelection}
                />
              </div>
            ))}
        </div>
        {teachers && teachers.length > 0 ? (
          <div className="mt-4 min-w-min">
            <button
              onClick={confirmSatusOfSelectedTeachers}
              className={`bg-blue-400 text-white rounded px-3 py-1.5 flex focus:outline-none items-center transition-all hover:translate-x-1 duration-500`}
            >
              confirm selected staff
            </button>
          </div>
        ) : (
          <div> All org teachers are confirmed! </div>
        )}
      </Drawer>
    </div>
  );
}

export default ConfirmStaffStatus;
