import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
// import { useUser } from "./context/UserContext";

const AdminProtectedRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.user.user);
  // console.log("=============", user);
  // const { user } = useUser();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user && user.isAdmin === true) {
          return <Component {...props} />;
        } else if (user && user.sponsoringOrg === true) {
          return (
            <Redirect
              to={{
                pathname: "/sponsoring-orgs",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default AdminProtectedRoute;
