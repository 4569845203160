import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import NavBar from "./navbar";
import toast from "../util/toast";
import "../img/bg1.jpg";
import TestimonialSlider from "./reusable/testimonial_slider/testimonial_slider";
import "../App.css";
// import Card from "./reusable/home_card/card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import {
  Col,
  Row,
  Form,
  Button,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";

// import student from "../img/Student_Education_Advisory.jpg";
// import teacher from "../img/businesswoman-4126489_1280.jpg";
// import school from "../img/Education_Advisory.jpg";
// import company from "../img/woman-2773007_1280.jpg";
// import applicant from "../img/mediensturmer-aWf7mjwwJJo-unsplash.jpg";
// import employee from "../img/Personnel_Competence_Management.jpg";

import slide1 from "../img/slides/Slide1.PNG";
import slide2 from "../img/slides/Slide2.PNG";
import slide3 from "../img/slides/Slide3.PNG";
import slide4 from "../img/slides/Slide4.PNG";
import slide5 from "../img/slides/Slide5.PNG";
import slide6 from "../img/slides/Slide6.PNG";
import slide7 from "../img/slides/Slide7.PNG";
import slide8 from "../img/slides/slide8.png";
import slide9 from "../img/slides/Slide9.png";

// import { useUser } from "../context/UserContext";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setUser } from "../redux/slices/userSlice";
import prices from "../util/prices.json";
import SponsoringOrgLoginModal from "./admin/dashboards/sponsoringOrg/SponsoringOrgLoginModal";
import DarkOverlay from "./DarkOverlay";
import { useLayoutEffect } from "react";
import Axios from "axios";
import AxiosInstance from "../AxiosInstance/AxiosInstance";

export default function Home({ history }) {
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [loading, setloading] = useState("");
  const [showWelcomeMsg, setShowWelcomeMsg] = useState(false);
  const [showSponsoringOrgLogin, setShowSponsoringOrgLogin] = useState(false);
  const toggleSponsoringOrgLogin = () =>
    setShowSponsoringOrgLogin((prev) => !prev);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  function onChangeEmail(e) {
    setEmail(e.target.value);
  }

  function setLoading() {
    setloading(true);
  }

  function onChangePassword(e) {
    setpassword(e.target.value);
  }

  function notify(message, type) {
    return toast(message, type);
  }

  //Styles
  const styles = {
    color: "black",
  };
  const formStyle = {
    "border-radius": "5px",
    "background-color": "white",
    //   opacity: "0.8",
    color: "white",
    padding: "2.5rem",
  };
  var sett;
  var settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
  };

  const login = (e) => {
    e.preventDefault();
    const user = {
      email: email,
      password: password,
    };
    // ==========================================
    //Make a post to the api route for login
    try {
      setLoading(true);
      AxiosInstance.post(`api/user/login`, { data: user }).then((res) => {
        // debugger
        setLoading(true);
        if (!res.data.error) {
          // Register the Corporate User in Session storage
          const Global_CorpUser = {
            isLogged: true,
            ...res.data.user,
            token: res.data.token,
          };
          // notify("Login successfull!", "success");

          setEmail("");
          setpassword("");
          setloading(false);
          dispatch(setUser(Global_CorpUser));
          Axios.defaults.headers.common = {
            Authorization: "Bearer " + Global_CorpUser.token,
          };
          window.localStorage.setItem(
            "persist:user",
            JSON.stringify(Global_CorpUser)
          );

          if (res.data.user?.isAdmin === true) {
            return history.push("/adminDashboard");
          }

          history.push("/home");

          setloading(false);
        } else {
          notify("Email or Password is wrong", "error");
          setloading(false);
        }
      });
    } catch (e) {
      setloading(false);
    } finally {
      setloading(false);
    }

    // =======================
  };

  // Popover's rendering
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3" style={{ "font-family": "gothic" }}>
        Which account type do want to create?
      </Popover.Title>
      <Popover.Content>
        <Row>
          <Col>
            <Link to="/signup_individual">
              <Button variant="info" className=" btn-sm text-[#53A6E7] ">
                Individual
              </Button>
            </Link>
          </Col>
          <Col>
            <Link to="/signup_corporate">
              <Button variant="info" className="btn-sm text-[#53A6E7]">
                Corporate
              </Button>
            </Link>
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  useLayoutEffect(() => {
    if (user && user.name) {
      if (user.isAdmin) {
        return history.push("/adminDashboard");
      }
      if (user.sponsoringOrg) {
        return history.push("/sponsoring-orgs");
      }

      history.push("/home");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowWelcomeMsg(true);
    }, 1000);
  }, [showWelcomeMsg]);

  // showWelcomeMsg && alert("Welcome to Esgrown!");

  return (
    <div className="w-full relative">
      <div className="w-full bg mb-4">
        <NavBar />
        <div className="w-full flex flex-col md:flex-row  space-x-20">
          {/* <Row> */}
          {/* <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"> */}
          {/* <div className=""> */}
          <div
            className={`pt-0 w-full md:mt-28 md:w-1/2 flex flex-col justify-center pl-[6%] md:pl-[11%]`}
            style={{ height: "100%" }}
          >
            <div>
              <h1
                style={{
                  color: "white",
                  fontSize: "46px",
                  fontWeight: "700",
                }}
                className={`${showWelcomeMsg ? "translate-x-0" : "-translate-x-full "
                  } ease-in-out transition-all duration-1000`}
              >
                <span className="inline-block mr-2 text-4xl md:block md:text-5xl">Welcome to </span>
                <span className="inline-block mr-2 text-4xl md:block md:text-5xl">ESGROWN</span>
              </h1>
              <br />
              <h2
                style={{
                  color: "white",
                  fontSize: "20px",
                  marginTop: "2rem",
                }}
                className={`${showWelcomeMsg
                    ? "translate-x-0"
                    : "translate-x-full opacity-0 "
                  } ease-in-out transition-all duration-1000`}
              >
                Creating synergy between education and industry; learning and
                business
              </h2>
            </div>
            <div
              className={` ${showWelcomeMsg && "hidden"
                } md:ml-24 md:-mt-24 bg-white w-10 h-10 rounded-full transition-all animate-ping`}
            ></div>
          </div>

          <div
            className=" mx-auto w-11/12 md:w-1/3 mt-4 md:mt-0 shadow-md"
            style={formStyle}
          >
            <br />
            <label
              style={{
                fontWeight: "bold",
                fontSize: "25px",
                color: "#180A39",
                marginBottom: "2rem",
              }}
            >
              Sign in to your account
            </label>
            {/* <hr /> */}
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label style={{ color: "#180A39" }}>
                  Email address:
                </Form.Label>
                <Form.Control
                  type="email"
                  required
                  placeholder="Enter email"
                  onChange={onChangeEmail}
                  value={email}
                />
                <Form.Text
                  className="text-muted"
                  style={{ color: "white" }}
                ></Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label style={{ color: "#180A39" }}>Password:</Form.Label>
                <Form.Control
                  type="password"
                  required
                  placeholder="Password"
                  onChange={onChangePassword}
                  value={password}
                />
              </Form.Group>
              <Button
                type="submit"
                className="submit2 mb-2"
                disabled={loading}
                onClick={login}
              >
                Login
                {loading ? <i className="fa fa-spinner fa-spin ml-2"></i> : ""}
              </Button>

              <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                {/* variant="primary btn-sm" */}
                <Button className="btn_submit1 mb-4">
                  Create an Esgrown Account
                </Button>
              </OverlayTrigger>
              <br />
              <div className="flex space-x-1" style={{ color: "#180a39" }}>
                <div> Forgot password?</div>
                <div className="">
                  <Link className="mr-2" to="/forgotPassword">
                    Reset
                  </Link>
                </div>
              </div>
            </Form>
          </div>

          <br />
        </div>

        <div className="w-10/12 md:w-[62%] mx-auto bg-white px-4 py-16 mt-[4rem] shadow-lg text-center rounded-xl">
          <h1 className="b1 mb-3">Built for</h1>
          <p className="b2 italic">
            students, teachers, schools, applicants, companies, employees and
            business leaders.
          </p>
        </div>
        <div className="w-full flex flex-col  md:justify-center md:flex-row mt-20 mb-5 ">
          <div className="w-full md:w-[40%]  flex flex-col px-4  ">
            {/* <h1 className="jumb mb-4">We help you to:</h1> */}
            <div className="text-justify text-lg">
            Adopted by as a shared tool component world sustainable development programs aligned UN Sustainable Development Goal No. 4 for all countries on quality education and UN Sustainable Developments Goal No. 8 and 9 on economic growth and decent jobs, Esgrown is a toolkit in a wider global commitment strengthening quality of education in meeting knowledge and skill needs for addressing present world economic social and environment concerns that affect just about everyone, and for strengthening the capacity of individuals leveraging knowledge, skills and ethics gained through learning decisions and actions that meets goals of businesses as units of every country’s economy, still stimulating the development and growth of business as the building blocks of economies of society at different levels
            </div>
          </div>
          {/* carousel  */}
          <div className="w-full md:w-[40%] px-4 mt-10 md:mt-0">
            <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
              <div className="rounded-lg">
                <img className="rounded-lg" src={slide1} alt="" />
                {/* <p className="legend">Teachers</p> */}
              </div>
              <div className="rounded-lg">
                <img className="rounded-lg" src={slide2} alt="" />
                {/* <p className="legend">Students</p> */}
              </div>
              <div className="rounded-lg">
                <img className="rounded-lg" src={slide3} alt="" />
                {/* <p className="legend">Schools</p> */}
              </div>
              <div className="rounded-lg">
                <img className="rounded-lg" src={slide4} alt="" />
                {/* <p className="legend">Applicants</p> */}
              </div>
              <div className="rounded-lg">
                <img className="rounded-lg" src={slide5} alt="" />
                {/* <p className="legend">Companies</p> */}
              </div>
              <div className="rounded-lg">
                <img className="rounded-lg" src={slide6} alt="" />
                {/* <p className="legend">Employees</p> */}
              </div>
              <div className="rounded-lg">
                <img className="rounded-lg" src={slide7} alt="" />
                {/* <p className="legend">Employees</p> */}
              </div>
              <div className="rounded-lg">
                <img className="rounded-lg" src={slide8} alt="" />
                {/* <p className="legend">Employees</p> */}
              </div>
              <div className="rounded-lg">
                <img className="rounded-lg" src={slide9} alt="" />
                {/* <p className="legend">Employees</p> */}
              </div>
            </Carousel>
          </div>
        </div>
        <div className="mt-8 mb-1 flex flex-col justify-start items-center text-esgrown font-bold text-2xl">Premium Services</div>
        <div className="flex flex-col justify-center items-center text-lg text-center w-[100%] mx-auto mb-[-2%] bg-slate-100 p-3 rounded-none">
          <div  className="ml-8 text-justify w-[81%]">
          Within the context of our larger commitment to making the world better and addressing needs of individuals, businesses and institutions, we offer these premium services:
          </div>
          <ul
            className="ml-8 text-justify text-lg w-[75%]"
            style={{
              listStyleType: "square",
              listStyleImage: "url(li-img.png)",
            }}
          >
            <li>
              align your pursuit of education to real world knowledge and skills
              needs for success in your career;
            </li>
            <li>
              access financing for education through grants and scholarships;
            </li>
            <li>
              enhance the capacity and performance of your teachers in delivery
              of functional education;
            </li>
            <li>
              connect with industry and join the workforce of a company where
              knowledge and skills you have acquired through your education are
              needed for productivity;
            </li>
            <li>
              build a workforce that meets your industry knowledge and skill
              needs for business
            </li>
            <li>
              optimize the effectiveness of your staff in leveraging knowledge
              and skills into delivery of your core business goals and
              contributing to economic progress;
            </li>
            <li>
              keep up to speed on principles for high performance, effective
              leadership and good management of your organization
            </li>
          </ul>
        </div>

        {/* Bottom Slider */}

        <div className="mt-4 relative">
          <TestimonialSlider />

          {/* //Sponsoring organizations loging button  */}
          <div className="absolute bottom-0 right-4 bg-transparent text-gray-400 hover:text-gray-200">
            <button
              onClick={toggleSponsoringOrgLogin}
              className="text-xs focus:outline-none"
            >
              SDG support Organizations
            </button>
          </div>
          {showSponsoringOrgLogin && (
            <>
              <SponsoringOrgLoginModal
                toggleSponsoringOrgLogin={toggleSponsoringOrgLogin}
              />
              <DarkOverlay />
            </>
          )}
        </div>

        {/* Footter */}
        <div className="footer">
          <div className="d-flex justify-content-center align-items-center">
            <div className="mt-[18px]">
              Copyright &#169; 2023 - Kungliga Utvecklingsbolag. All rights reserved.
            </div>
          </div>
        </div>
        {/* main content  */}
      </div>
    </div>
  );
}
