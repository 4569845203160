
import { CloseCircleTwoTone } from "@ant-design/icons";

function DeletePostConfirmationModal(props) {

  
  return (
    // <div class="">
      <div class="self-center content-center removeAssociateModal border-solid border-2 border-black relative">
        <div class=" bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 md:relative">
          <div class="md:flex items-center">
            <div class=" md:mt-0 md:ml-6 text-center md:text-left">
              <p class="font-bold">Delete Post</p>
              <p class="text-sm text-gray-700 mt-1">Do you want to delete this post?
              </p>
            </div>
          </div>
          <div class="text-center md:text-right mt-4 md:flex md:justify-end">
            {/* <button class="block w-full md:inline-block md:w-auto px-4 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">Remove Associate</button> */}
            <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm mt-4
          md:mt-0 md:order-1 mr-2"
              onClick={() => {
                props.handlePostDelete(props.id)
                props.setIsPostDeleted(!props.isPostDeleted)
                props.setShowDeletePostModal(false)
              }}
            >Delete Post</button>
            <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-[#1D6DAE] rounded-lg text-white font-semibold text-sm mt-4
          md:mt-0 md:order-1" onClick={() => {
                props.setShowDeletePostModal(false)
              }}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    // </div>
  )
}

export default DeletePostConfirmationModal