import React, { useEffect, useState } from "react";
import { CloseCircleTwoTone, EditOutlined } from "@ant-design/icons";
import ctries from "../../../../../util/ctryModified.json";
import Toast from "../../../../../util/toast";
import AxiosInstance from "../../../../../AxiosInstance/AxiosInstance";

function AddCities({ toggleAddCitiesModal }) {
  const [country, setCountry] = useState(null);
  const [statesInSelCountry, setStatesInSelCountry] = useState(null);
  const [state, setState] = useState(null);
  // const [countryDb, setCountryDb] = useState(null);
  const [cities, setCities] = useState(null);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [city, setCity] = useState(null);

  // useEffect(() => {
  //   const fetchCities = async() => {
  //     setLoading(true);
  //     try {
  //       const reps = await AxiosInstance.post('/api/province/get-all-countries')
  //       console.log(reps.data);
  //       if(reps.data && reps.data.country) {
  //         console.log("data.country", data.country);
  //         setCountryDb(reps.data.country);
  //         console.log(reps.data.country);
  //         setLoading(false);
  //       }
  //       return;
  //     } catch (error) {
  //       setLoading(false);
  //       return Toast(error.message, "error");
  //     }
  //   };
  //   fetchCities();
  // }, []);

  const handleEditCities = async() => {
    setLoading(true);
    if(!city || city.length < 2) {
      return Toast("Please Enter comma separated list of cities!", "error");
    }
    if(!state || state.length < 1) {
      return Toast("Please Select a State to Edit", "error");
    }
    if(!country || country.length < 1) {
      return Toast("Please select a country!", "error");
    }

    let _citiies = String(city).split(",");
    _citiies = _citiies.filter((c) => c.length > 1);
    try {
      const { data } = await AxiosInstance.post(`/api/province/edit`, {
        country: country,
        cities: _citiies,
        state
      });
      if (data.error) {
        console.log(data);
        Toast(data.message, "error");
      } else {
        // success
        Toast("Cities Edited successfully!", "success");
      }
    } catch (error) {
      Toast(error.message, "error");
    } finally {
      setLoading(false);
    }
  }

  const handleAddCities = async() => {
    setLoading(true);
    if (!cities || cities.length < 2) {
      return Toast("Please Enter comma separated list of cities!", "error");
    }
    if (!state || state.length < 1) {
      return Toast("Please select a state!", "error");
    }
    if(!country || country.length < 1) {
      return Toast("Please select a country!", "error");
    }

    let _citiies = String(cities).split(",");
    _citiies = _citiies.map((city) => city.trim());
   _citiies = _citiies.filter((city) => city.trim().length > 1);
    try {
      const { data } = await AxiosInstance.post(`/api/province/edit`, {
        country: country,
        cities: _citiies,
        state
      });
      console.log("error",data)
      if (data.error) {
        Toast(data.message, "error");
      } else {
        // success
        Toast("Cities Edited successfully!", "success");
      }
    } catch (error) {
      console.log("error",error)
      Toast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };
  const getCities= async (country,state)=>{
    const { data } = await AxiosInstance.post(`/api/province/get-all-cities`, {
      country: country,
      state:state
    });
    const cities = data.cities.map(item => item.split(/\r?\n/).join(',')).join(',') || '';

    //console.log(cities)
    setCities(cities)
  }
  return (
    <div className="absolute top-12 z-50 md:left-[40%] p-8 pb-20 md:w-1/3 w-10/12 h-min bg-white text-gray-600 shadow-md rounded-lg ">
      <div className="w-full h-10 flex justify-between pr-2">
        <div className="pl-[25%] text-md font-semibold text-[#1D6DAE] flex items-center">
          Add Cities To A State or Edit
          <EditOutlined 
            className="ml-2"
            onClick={() => {
              //setEdit(!edit);
              // setStatesInSelCountry(null);
            }}
          />
        </div>
        <CloseCircleTwoTone onClick={toggleAddCitiesModal} />
      </div>
      <div className="w-[80%] justify-center flex flex-col mx-auto ">
        <div className="w-full min-h-min ">
          {!edit && <select
            onChange={(e) => {
              const _item = JSON.parse(e.target.value);
              setCountry(_item.country);
              setStatesInSelCountry(_item.states);
            }}
            className="w-full mx-auto rounded-lg border-2 border-blue-200 outline-blue-200 px-2 py-1"
          >
            <option value="">Select Country</option>
            {renderCountries()}
          </select>}
          {edit && 
            <select
              className="w-full mx-auto rounded-lg border-2 border-blue-200 outline-blue-200 px-2 py-1"
              onChange={(e) => {
                const item = (JSON.parse(e.target.value));
                setCountry(item.country);
                setStatesInSelCountry(item.states);
              
              }}
            >
              <option value="">-- Select Country to Edit City --</option>
              {renderCountries()}
              
            </select>
          }
        </div>
        <div className="w-full min-h-min ">
          {!edit && 
            <select
              onChange={(e) => {
                setState(e.target.value);
                getCities(country,e.target.value)
              }}
              disabled={!statesInSelCountry}
              className="w-full mx-auto rounded-lg border-2 border-blue-200 outline-blue-200 px-2 mt-3 py-1"
            >
              <option value="">Select state</option>
              {renderStates(statesInSelCountry)}
            </select>
          }
          {edit && 
            <select
              onChange={(e) => {
                setState(e.target.value);
              }}
              disabled={!statesInSelCountry}
              className="w-full mx-auto rounded-lg border-2 border-blue-200 outline-blue-200 px-2 mt-3 py-1"
            >
              <option value="">---Select state to Edit---</option>
              {renderStates(statesInSelCountry)}
            </select>
          }
        </div>
        <div className="w-full min-h-min ">
          {edit && <textarea
            placeholder="Enter comma separated list of cities..."
            rows={5}
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
              
            }}
            // defaultValue={city}
            disabled={!state}
            className="w-full mx-auto rounded-lg border-2 border-blue-200 outline-blue-200 px-2 mt-3 py-1"
          />}
          {!edit &&
            <textarea
            placeholder="Enter comma separated list of cities..."
            rows={5}
            value={cities}
            onChange={(e) => {
              setCities(e.target.value);
            }}
            disabled={!state}
            className="w-full mx-auto rounded-lg border-2 border-blue-200 outline-blue-200 px-2 mt-3 py-1"
          />
          }
        </div>
        <div className="w-full min-h-min mt-3 ">
          {!edit && <button
            disabled={!cities || !state || loading}
            onClick={handleAddCities}
            className={`w-full ${
              loading && "animate-pulse"
            } bg-blue-500  disabled:bg-gray-300 text-white rounded-lg focus:outline-none py-1`}
          >
            Save
          </button>}
          {edit && 
            <button
              disabled={!city || !state || loading}
              onClick={handleEditCities}
              className={`w-full ${
                loading && "animate-pulse"
              } bg-blue-500  disabled:bg-gray-300 text-white rounded-lg focus:outline-none py-1`}
            >
              Edit Cities
          </button>
          }
        </div>
      </div>
    </div>
  );
}

export default AddCities;
// Render all Countries
function renderCountries() {
  return ctries.map((item) => {
    return <option value={JSON.stringify(item)}>{item.country}</option>;
  });
}

// Render all states for the seleted country
function renderStates(states) {
  //console.log(states);
  if (states === null) return <></>;
  return states.map((state) => {
    return <option value={state}>{state}</option>;
  });
}
