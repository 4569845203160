import { CloseCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Toast from "../util/toast";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setUser } from "../redux/slices/userSlice";
import AxiosInstance from "../AxiosInstance/AxiosInstance";

function UCBIPayementModal({ setOpenUCBI, ucbi }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [ucbiData, setUcbiData] = useState({
    bankName: null,
    accountName: null,
    accountNumber: null,
    bankAccountType: null,
    amount: 0,
  });

  const handleChange = (e) => {
    setUcbiData({ ...ucbiData, [e.target.name]: e.target.value });
  };

  const requestUCBIPayment = async () => {
    setLoading(true);
    const someFieldsAreEmpty = Object.values(ucbiData).some(
      (value) => value === null
    );
    if (someFieldsAreEmpty) {
      return Toast("All fields are required!", "error");
    }
    if (ucbiData.amount === 0) {
      return Toast("Please Enter amount to withdraw!", "error");
    }

    if (ucbiData.amount < 500) {
      return Toast("You can not withdraw amount less than 500 naira!", "error");
    }
    if (ucbiData.amount > ucbi.balance * 1) {
      return Toast("Insufficient balance!", "error");
    }
    try {
      const { data } = await AxiosInstance.post("/api/ucbi/request-payment", {
        data: ucbiData,
      });
      dispatch(setUser(data.user));
      setOpenUCBI(false);
      Toast("Request successful!", "success");
    } catch (error) {
      Toast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  }, []);
  return (
    <div className="w-screen h-screen bg-black/70 absolute inset-0 z-50">
      <div className="w-[80%] md:w-1/2 mx-auto mt-10 min-h-min bg-white rounded-lg border py-10  px-4">
        <div className="flex justify-between w-full pl-[30%] min-h-min text-gray-500">
          <div className=" text-lg font-bold">Enter Your BanK Details</div>
          <div className="text-red-500 mr-2">
            <CloseCircleOutlined onClick={() => setOpenUCBI(false)} />
          </div>
        </div>
        <div className="flex justify-between w-[90%] mx-auto space-x-2  py-2 mt-3">
          <input
            name="bankName"
            onChange={handleChange}
            type="text"
            placeholder="Bank Name"
            className="w-[48%] px-2 py-2 shadow-md rounded-lg focus:outline-2 focus:outline-blue-500"
          />
          <input
            type="text"
            name="accountName"
            onChange={handleChange}
            placeholder="Account Name"
            className="w-[48%] px-2  py-2 shadow-md rounded-lg focus:outline-2 focus:outline-blue-500"
          />
        </div>
        <div className="flex justify-between w-[90%] mx-auto space-x-2  py-2 mt-3">
          <input
            type="text"
            name="accountNumber"
            onChange={handleChange}
            placeholder="Account Number"
            className="w-[48%] px-2 py-2 shadow-md rounded-lg focus:outline-2 focus:outline-blue-500"
          />
          <select
            name="bankAccountType"
            onChange={handleChange}
            type="text"
            placeholder="Bank Account Type E.g Savings"
            className="w-[48%] px-2  py-2 shadow-md rounded-lg focus:outline-2 focus:outline-blue-500"
          >
            <option>--account type-- </option>
            <option value="Savings Account">Savings </option>
            <option value="Current Account">Current</option>
          </select>
        </div>
        <div className="flex justify-between w-[90%] mx-auto space-x-2  py-2 mt-3">
          <input
            type="number"
            name="amount"
            onChange={handleChange}
            placeholder="Amount to withdraw"
            className="w-[48%] px-2 py-2 shadow-md rounded-lg focus:outline-2 focus:outline-blue-500"
          />

          <button
            onClick={requestUCBIPayment}
            className="bg-blue-500 w-[48%] px-2 py-2 text-white rounded-lg transition-all duration-500 hover:translate-x-1"
          >
            Request Withdrawal
          </button>
        </div>
      </div>
    </div>
  );
}

export default UCBIPayementModal;
