
const Classes = {
    level: [
        {"id": 1, "name": "primary", "text": "primary school"},
        {"id": 2, "name": "secondary", "text": "secondary school"},
        {"id": 3, "name": "tertiary", "text": "tertiary institution"}
      ],
    
      primary: [
        {"id": 1, "name": "one", "text": "primary one"},
        {"id": 2, "name": "two", "text": "primary two"},
        {"id": 3, "name": "three", "text": "primary three"},
        {"id": 4, "name": "four", "text": "primary four"},
        {"id": 5, "name": "five", "text": "primary five"},
        {"id": 6, "name": "six", "text": "primary six"}
      ],
      secondary: [
        {"id": 1, "name": "one", "text": "JSS1"},
        {"id": 2, "name": "two", "text": "JSS2"},
        {"id": 3, "name": "three", "text": "JSS3"},
        {"id": 4, "name": "four", "text": "SSS1"},
        {"id": 5, "name": "five", "text": "SSS2"},
        {"id": 6, "name": "six", "text": "SSS3"}
      ],
      tertiary: [
        {"id": 1, "name": "one", "text": "First Year"},
        {"id": 2, "name": "two", "text": "Second Year"},
        {"id": 3, "name": "three", "text": "Third Year"},
        {"id": 4, "name": "four", "text": "Fourth Year"},
        {"id": 5, "name": "five", "text": "Fifth Year"},
        {"id": 6, "name": "six", "text": "Sixth Year"},
        {"id": 7, "name": "seven", "text": "Final Year"}
      ]
}

export default Classes