import React, { useState, useEffect } from "react";
import ClickAwayListener from "react-click-away-listener";
import { useDispatch } from "react-redux";
import { BiCircle } from 'react-icons/bi';
import { Link } from "react-router-dom";
import { Popover } from "antd";
import moment from "moment";
import _ from 'lodash';
import { Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  ScheduleOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

import DropDownMenu from "../reusable/DropDownMenu";
import OnlineUser from "../reusable/OnlineUser";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";
import Toast from "../../util/toast";
import { setUser } from "../../redux/slices/userSlice";
import UCBIPayementModal from "../UCBIPayementModal";
import SmallScreenDrawer from "../SmallScreenDrawer";
import {
  imgCompany,
  imgGeneric,
  imgPrivate,
  imgSchool,
  imgStudent,
  imgTeacher,
} from "../../img";
import ApplyForJob from "./modals/ApplyForJob";
import { scrollUserToTop } from "../../util/services";
import FollowUpApplications from "./modals/FollowUpApplications";
import CustomSiderMenu from "../reusable/CustomSiderMenu";

import RMServiceInd from "./RMServicesInd";
const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const JobAndEmployment = ({ history }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [openUCBI, setOpenUCBI] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [applyForJob, setApplyForJob] = useState(false);
  const [followUpApplication, setFollowUpApplication] = useState(false);
  const [updatesClicked, setUpdatesClicked] = useState(true);
  const [addOnClicked, setAddOnClicked] = useState(false);
  const [allUpdates, setAllUpdates] = useState([]);
  const [allReviews, setAllReviews] = useState([]);
  const toggleApplyForJob = () => setApplyForJob((prev) => !prev);
  const toggleFollowUpApplications = () =>
    setFollowUpApplication((prev) => !prev);

  const [showSmallScreenDrawer, setShowSmallScreenDrawer] = useState(false);

  const user = useSelector((state) => state.user.user);

  const handleClick = ({ key }) => {
    //you can perform setState here
  };

  const getReviews = async () => {
    const url = "/api/review/get-reviews"
    const allReviews = await AxiosInstance.get(url)
    const { data } = allReviews
    setAllReviews(data?.reviews)
  };


  const getUpdates = async () => {
    const url = "/api/update/get-all-updates"
    const allUpdates = await AxiosInstance.get(url)
    const { data } = allUpdates
    setAllUpdates(data?.updates)
  };

  useEffect(() => {
    getUpdates()
    getReviews()
  }, [])

  console.log("allReviews", allReviews);

  const defaultProfilePix =
    "https://esgrown.fra1.digitaloceanspaces.com/esgrown%201669801277885.jpg";

  return (
    <Layout
      style={{
        minHeight: "100vh",
        maxHeight: "100%",
        flexWrap: "nowrap",
        position: "relative",
      }}
    >
      {openUCBI && (
        <UCBIPayementModal setOpenUCBI={setOpenUCBI} ucbi={user.ucbi[0]} />
      )}

      <Sider
        trigger={null}
        className="sidebar-bg hidden md:block"
        collapsible
        collapsed={collapsed}
      >
        <div className="logo mt-3 mb-2 ml-5" />
        <CustomSiderMenu
          collapsed={collapsed}
          handleClick={handleClick}
          user={user}
        />
      </Sider>
      <Layout>
        <div className="flex justify-between  bg-white p-2 shadow-md px-5">
          {/* <Header className="row pt-2 head"> */}


          {/* This will only show for small devices  ////////////////////////*/}
          <div className="flex items-center">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger nav-but mr-3 hidden md:block",
                onClick: () => setCollapsed(!collapsed),
              }
            )}
            <div
              onClick={() => {
                setShowSmallScreenDrawer(!showSmallScreenDrawer);
              }}
              className="min-w-min h-full flex items-center cursor-pointer md:hidden"
            >
              <MenuFoldOutlined className="text-lg mb-3" />
            </div>
            {/* ////////////////////////////////////////////////////// */}

            <div className="mt-2 text-3xl font-bold">Job And Employment Management</div>
          </div>

          <div className="flex items-center">
            <div className=" mt-2 mr-4">
              <span className={`mx-2 cursor-pointer font-bold ${updatesClicked ? 'border-b-2 border-esgrown text-esgrown' : ""}`}
                onClick={() => {
                  setUpdatesClicked(true)
                  setAddOnClicked(false)
                }}
              >
                Updates
              </span>
              <span className={`mx-2 cursor-pointer font-bold ${addOnClicked ? 'border-b-2 border-esgrown text-esgrown' : ""}`}
                onClick={() => {
                  setAddOnClicked(true)
                  setUpdatesClicked(false)
                }}
              >
                Add-ons
              </span>
            </div>

            <ClickAwayListener onClickAway={() => setShowAccountMenu(false)}>
              <div className="ml-auto mt-2">
                <DropDownMenu
                  user={user}
                  show={showAccountMenu}
                  bgColor="bg-white/80"
                />
                <div onClick={() => setShowAccountMenu(!showAccountMenu)}>
                  <OnlineUser user={user} />
                </div>
              </div>
            </ClickAwayListener>
          </div>
        </div>
        {/* </Header> */}
        <Content
          style={{
            margin: "24px 20px",
            padding: 10,
            // minHeight: 280,
            position: "relative",
            display: "flex",
          }}
          className="justify-between"
        >
          <div className="mx-auto w-full h-min px-2 pt-2 pb-10">
            {
              updatesClicked && allUpdates.map(update => {
                return (
                  <div className="bg-white rounded h-full w-full flex-col pt-2">
                    <div className="mb-[70px]">
                      <div className="flex justify-between items-center mx-3 my-3 border-b border-[#244060]">
                        <div className="flex justify-start items-center">
                          <div className="mr-2"><BiCircle size={35} color="#1D6DAE" strokeWidth={2} /></div>
                          <div className="flex-cols">
                            <div className="flex text-lg font-semibold text-[#1D6DAE]">
                              <div>{_.startCase(update?.title)}</div>
                              <p className="font-semibold mx-1"> :: </p>
                              <div>{_.startCase(update?.subject)}</div>
                            </div>
                            <div className="flex text-xs text-slate-400">{update?.postCategory} :: {update?.serviceId?.title} </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end items-center mx-3 mb-2 italic text-xs">{moment(update?.createdAt).fromNow()}</div>
                      <div className="mt-3 mx-3">{update?.postDescription}</div>
                      <div className="mt-1">
                        {update?.images?.map(image => {
                          return <img className="w-full h-[400px] border-b-2 border-red-500 mt-2" src={image} alt={image} />
                        })
                        }
                        {update?.videos?.map(video => {
                          return (
                            <video controls className="w-full mt-2">
                              <source src={video} type="video/mp4" />
                              <source src={video} type="video/webm" />
                            </video>
                          )
                        })
                        }
                      </div>
                      {update?.externalURLS === null || update?.externalURLS === "" ? "" : (<div className="mt-3 mx-3"> <i>reference: <a href={update?.externalURLS} target="_blank"><span className="text-esgrown">{update?.externalURLS}</span></a></i></div>)}
                      <div className="mt-2 border-4 mx-[300px] border-b border-[#244060]"></div>
                    </div>
                  </div>
                )
              })
            }
            {addOnClicked ? 
            <RMServiceInd/>
            //  <div className="flex justify-center items-center h-full border-1 border-red-500 p-4 italic text-lg">No Add-on for This Service at The Moment</div>
          //   <div className="">
          //   <div className="table-responsive bg-white border-b-2 border-b-blue-800">
          //     <table className="table table-hover table-striped">
          //       <thead className="bg-success1 text-white">
          //         <tr>
          //           <th className="w-[40%]">Staff Name</th>
          //           <th className="w-[30%]">Rating</th>
          //           <th className="w-[30%]">Rank</th>
          //           <th className="w-[30%]">Organization</th>
          //           {/* <th colSpan={3}>Manage</th> */}
          //         </tr>
          //       </thead>
          //       <tbody>
          //         {allReviews && allReviews.length > 0 && allReviews.map((review, i) => (
          //           <tr key={i}>
          //               <td>
          //                 {review?.user?.name}
          //               </td>
          //               <td className="capitalize text-left">{review?.rating}</td>
          //               <td className="capitalize text-left">{i+1}</td>
          //               <td className="capitalize text-left">{review?.author?.name}</td>
          //               {/* <td>
          //                 <button className="outline-none text-blue-800 font-bold bg-transparent" 
          //                   onClick = {() => alert()
          //                   (
          //                     setChoice(review), 
          //                     setDisplay(true)
          //                   ) 
          //                   }>View Profile</button>
          //               </td> */}
          //           </tr>
          //         ))}
          //       </tbody>
          //     </table>
          //   </div>
          // </div>
            :""}
          </div>
          <div className=" w-[30%] h-[320px] hidden lg:flex flex-col top-5 right-0 sticky">
            <img
              src={
                user?.userType === "student"
                  ? imgStudent
                  : user.userType === "company"
                    ? imgCompany
                    : user.userType === "private"
                      ? imgPrivate
                      : user.userType === "teacher"
                        ? imgTeacher
                        : user.userType === "school"
                          ? imgSchool
                          : imgGeneric
              }
              className="w-full  h-full object-fill rounded-md"
            />
            <div className="w-full text-center align-middle h-[80px] p-3 bg-white rounded mt-3">
              <div className="">BWP Utvecklingsbolag &copy;2019</div>
              <div className="text-xs">
                All rights reserved. Campaigns | Term | Policy
              </div>
            </div>
          </div>

          {applyForJob && <ApplyForJob toggleApplyForJob={toggleApplyForJob} />}
          {followUpApplication && (
            <FollowUpApplications
              toggleFollowUpApplications={toggleFollowUpApplications}
            />
          )}
        </Content>
      </Layout>
      {showSmallScreenDrawer && (
        <SmallScreenDrawer
          setShowSmallScreenDrawer={setShowSmallScreenDrawer}
          user={user}
        />
      )}
    </Layout>
  );
};

export default JobAndEmployment;
