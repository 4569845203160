import React, { useState, useEffect, useRef } from "react";
import ClickAwayListener from "react-click-away-listener";
import { useDispatch } from "react-redux";
import currencyFormatter from 'currency-formatter';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { Layout, Menu, Avatar, Spin } from "antd";
import Icon, {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  ScheduleOutlined,
  EditOutlined,
  CameraOutlined,
  UserAddOutlined,
  FundOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import EditAccount from "../EditAccount";
import DropDownMenu from "../reusable/DropDownMenu";
import OnlineUser from "../reusable/OnlineUser";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";
import Toast from "../../util/toast";
import { setUser } from "../../redux/slices/userSlice";
import UCBIPayementModal from "../UCBIPayementModal";
import ActivateStaffUCBI from "../admin/ucbi/ActivateStaffUCBI";
import ConfirmStaffStatus from "../ConfirmStaffStatus";
import SchoolContentTile from "../reusable/SchoolContentTile";
import TeachersList from "../reviews/teachers/TeachersList";
import RatingModal from "../reviews/teachers/RatingModal";
import SmallScreenDrawer from "../SmallScreenDrawer";
import AddBtn from "../reusable/AddBtn";
import CustomSiderMenu from "../reusable/CustomSiderMenu";
import ActivationDropDown from "../reusable/ActivationDropDown";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const Full = ({ history }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [openUCBI, setOpenUCBI] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [activationMenu, setActivationMenu] = useState(false);
  const [openConfirmAccount, setOpenConfirmAccount] = useState(false);
  const [editAcct, setEditAcct] = useState(false);
  const [addStaff, setAddStaff] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingProfilePix, setLoadingProfilePix] = useState(false);
  const [showSmallScreenDrawer, setShowSmallScreenDrawer] = useState(false);

  const [confirmedTeachers, setConfirmedTeachers] = useState([]);
  const [unconfirmedTeachers, setUnconfirmedTeachers] = useState([]);

  //Reviews
  const [openTeachersList, setOpenTeachersList] = useState(false);
  const [openRatingModal, setOpenRatingModal] = useState(false);

  const user = useSelector((state) => state.user.user);
  const profilePixRef = useRef(null);
  const [aboutSchool,setAboutSchhol] = useState("");
  const [schoolsaving,setSchoolSaving] = useState(false);

  //Force useeffect to run fro the children trick
  const [refresh, setRefresh] = useState(false);

  const handleClick = ({ key }) => {
    //you can perform setState here
  };
  useEffect(()=>{
    //console.log(user)
    const getData = async ()=>{
     
      const { data } = await AxiosInstance.post(`/api/user/user?id=${user?._id}`, {});
      //console.log("fullabb",data);
      setAboutSchhol(data.user?.about)
    }
    getData();
    
  },[])
  const updateAboutSchool =  async () =>{
   setSchoolSaving(true);
    const { data } = await AxiosInstance.post(`/api/user/user/update`, {
      about:aboutSchool
    });
    const _user = data.user;
    console.log(data);
    Toast("Data has been updated!", "success");
    setSchoolSaving(false);
  }
  const defaultProfilePix =
    "https://esgrown.fra1.digitaloceanspaces.com/esgrown%201669801277885.jpg";

  const uploadProfilePix = async (userPix) => {
    setLoadingProfilePix(true);
    try {
      const formData = new FormData();
      // formData.append("photo", userPix);
      formData.append("upload", userPix);

      const { data } = await AxiosInstance.post(
        "/api/user/user/upload-profile-picture-aws",
        formData
      );
      if (data.status === "success") {
        const _user = data.user;
        const updatedUser = {
          isLogged: true,
          ..._user,
        };
        dispatch(setUser(updatedUser));
      }
    } catch (error) {
      // return Toast(error.message, "error");
    } finally {
      setLoadingProfilePix(false);
    }
  };

  useEffect(() => {
    const fetchTeachers = async () => {
      setLoading(true);
      let route = null;
      if (user.userType === "school")
        route = "/api/user/get-unconfirmed-teachers";
      if (user.userType === "company")
        route = "/api/user/get-unconfirmed-compstaff";
      if (!route)
        return Toast("unconfirmed Staff route can not be null!", "error");
      // const route = "/api/user/get-unconfirmed-teachers";
      const { data } = await AxiosInstance.post(route);
      if (data && data.staff) {
        setUnconfirmedTeachers(data.staff);
        setLoading(false);
      }
    };

    if (user.userType === "school" || user.userType === "company") {
      fetchTeachers();
    }
  }, [refresh]);

  useEffect(() => {
    const fetchTeachers = async () => {
      setLoading(true);
      let route = null;
      if (user.userType === "school")
        route = "/api/user/get-confirmed-teachers";
      if (user.userType === "company")
        route = "/api/user/get-confirmed-compstaff";
      if (!route)
        return Toast("Confirmed Staff route can not be null!", "error");
      // const route = "/api/user/get-confirmed-teachers";
      const { data } = await AxiosInstance.post(route);
      if (data && data.staff) {
        setConfirmedTeachers(data.staff);
        setLoading(false);
      }
    };

    if (user.userType === "school" || user.userType === "company") {
      fetchTeachers();
    }
  }, [refresh]);

  return (
    <Layout
      style={{
        minHeight: "100vh",
        maxHeight: "100%",
        flexWrap: "nowrap",
        position: "relative",
      }}
    >
      {openUCBI && (
        <UCBIPayementModal setOpenUCBI={setOpenUCBI} ucbi={user.ucbi[0]} />
      )}

      <Sider
        trigger={null}
        className="sidebar-bg hidden md:block"
        collapsible
        collapsed={collapsed}
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <CustomSiderMenu
          collapsed={collapsed}
          handleClick={handleClick}
          user={user}
        />
      </Sider>
      <Layout>
        <Header className="row pt-2 head">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3 hidden md:block",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          {/* This will only show for small devices  ////////////////////////*/}
          <div
            onClick={() => {
              setShowSmallScreenDrawer(!showSmallScreenDrawer);
            }}
            className="min-w-min h-full flex items-center cursor-pointer md:hidden"
          >
            <MenuFoldOutlined className="text-lg mb-3 mr-2" />
          </div>
          {/* ////////////////////////////////////////////////////// */}
          <h3 className="mt-1 ">Profile</h3>

          <ClickAwayListener onClickAway={() => setShowAccountMenu(false)}>
            <div className="ml-auto">
              <DropDownMenu user={user} show={showAccountMenu} />
              <div onClick={() => setShowAccountMenu(!showAccountMenu)}>
                <OnlineUser user={user} />
              </div>
            </div>
          </ClickAwayListener>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 10,
            // background: "#fff",
            minHeight: 280,
          }}
        >
          <div className="container-fluid relative">

            <div className="row">
              <div className="col-lg-12">
                <div className="bg-white a-1 rounded">
                  <div className="w-full flex ">
                    <div className=" mt-[-25px] absolute right-20">
                      <button
                        onClick={() => setEditAcct(true)}
                        className="bg-[#1D6DAE] px-2 py-1 text-white rounded"
                      >Edit</button>
                    </div>
                    <h5 className="mt-[-25px] ml-3 absolute">ACCOUNT DETAILS</h5>
                    <div className="col-lg-3">

                      <div className="  profile w-2/3 text-center relative max-h-[120px] ">

                        <img
                          preview={false}
                          alt="User profile image"
                          src={
                            user && user.profilePix
                              ? user.profilePix
                              : defaultProfilePix
                          }
                          className="object-fill w-full h-[120px] border-2 border-white rounded-lg"
                        />
                        {loadingProfilePix && (
                          <div className="absolute inset-0 w-full h-full bg-black/50 ">
                            <div className="w-full h-full flex justify-center items-center">
                              <Spin />
                            </div>
                          </div>
                        )}
                        <div
                          // style={{ zIndex: 50 }}
                          className="absolute -bottom-4 right-2 font-bold text-gray-600 min-w-min min-h-min cursor-pointer"
                        >
                          <CameraOutlined
                            onClick={() => profilePixRef.current.click()}
                          />
                          <input
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={(e) => {
                              const fileObj = validateFile(e.target.files[0]);
                              if (Boolean(fileObj.name)) {
                                // process further
                                uploadProfilePix(fileObj.file);
                              } else {
                                return Toast(
                                  "Profile picture can not be greater than 1MB",
                                  "error"
                                );
                              }
                            }}
                            ref={profilePixRef}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-[80%] sm:mt-4">
                      <div className="row ">
                        <p className="col-lg-6  font-semibold"> {user && user.accountType === "school" || user.userType === "school" ? "School Name" : "Name"} </p>
                        <p className="col-lg-6">{user?.name}</p>
                      </div>
                      {user && user.accountType === "individual" && (
                        <div className="row">
                          <p className="col-lg-6 font-semibold">
                            Date of birth
                          </p>
                          <p className="col-lg-6">
                            {new Date(user?.dob).toLocaleDateString()}
                          </p>
                        </div>
                        
                      )}
                      {user && user.userType === "private" && (<>
                        <div className="row">
                        <p className="col-lg-6 font-semibold">State</p>
                        <p className="col-lg-6">
                          {user?.state ? (
                            user.state
                          ) : (
                            <AddBtn setEditAcct={setEditAcct} />
                          )}
                        </p>
                      </div>
                      <div className="row">
                        <p className="col-lg-6 font-semibold">City</p>
                        <p className="col-lg-6">
                          {user?.province ? (
                            user.province
                          ) : (
                            <AddBtn setEditAcct={setEditAcct} />
                          )}
                        </p>
                      </div></>
                        
                      )}
                      <div className="row">
                        <p className="col-lg-6 font-semibold">Country</p>
                        <p className="col-lg-6">
                          {user?.country ? (
                            user.country
                          ) : (
                            <AddBtn setEditAcct={setEditAcct} />
                          )}
                        </p>
                      </div>
                      
                      {user && user.accountType === "school" || user.userType === "school" || user.userType === "student" || user.userType === "teacher" ?
                        <div className="row">
                          <p className="col-lg-6 font-semibold">School State</p>
                          <p className="col-lg-6">{user?.state}</p>
                        </div>
                        : ""
                      }
                      {user && user.accountType === "school" || user.userType === "school" || user.userType === "student" || user.userType === "teacher" ?
                        <div className="row">
                          <p className="col-lg-6 font-semibold">City</p>
                          <p className="col-lg-6">{user.province ? user.province : "Not available"}</p>
                        </div>
                        : ""
                      }
                      <div className="row">
                        <p className="col-lg-6 font-semibold">Phone number</p>
                        <p className="col-lg-6">
                          {user?.phone ? (
                            user.phone
                          ) : (
                            <AddBtn setEditAcct={setEditAcct} />
                          )}
                        </p>
                      </div>

                      <div className="row">
                        <p className="col-lg-6 font-semibold">Email Address</p>
                        <p className="col-lg-6">{user?.email}</p>
                      </div>

                      {user && user.accountType === "school" || user.userType === "school" ?
                        <div className="row">
                          <p className="col-lg-6 font-semibold">No. of Students</p>
                          <p className="col-lg-6">{user?.numberOfStudents}</p>
                        </div>
                        : ""
                      }
                      {user && user.accountType === "school" || user.userType === "school" ?
                        <div className="row">
                          <p className="col-lg-6 font-semibold">No. of Teachers</p>
                          <p className="col-lg-6">{user?.staffStrength}</p>
                        </div>
                        : ""
                      }
                      {/* <div className="row">
                        <p className="col-lg-6">User Type:</p>
                        <p className="col-lg-6">{user?.userType}</p>
                      </div> */}
                      {user.userType === "teacher" && (
                        <div className="row">
                          <p className="col-lg-6 font-semibold">
                            Teacher's Unique code:
                          </p>
                          <p className="col-lg-6">{user?.uniqueId}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {user && user.accountType === "individual" && (
                <div className="col-lg-12">
                  <div className="bg-white a-1 mt-3 rounded-md">
                    <div className="font-semibold mb-2">EDUCATION AND CAREER</div>
                    {user.userType === "student" && (
                      <div className="row">
                        <p className="col-lg-3">Desired field of study</p>
                        <p className="col-lg-3">
                          {user?.careerPath?.name
                            ? user?.careerPath?.name
                            : "Not Specified"}
                        </p>
                      </div>
                    )}
                    {user.userType !== "student" &&  (
                      <>
                        { user.userType !=="private" && <div className="flex justify-between">
                          <p className="">Profession:</p>
                          <p className="">{
                            user?.userType.split("")[0].toUpperCase() + user?.userType.split("").slice(1).join("")
                          }
                          </p>
                        </div>}
                        <div className="flex justify-between">
                          <p className="">Employer/School:</p>
                          <p className="">{user?.orgName || "Not Available"}</p>
                        </div>
                        {user.userType === "teacher" ?
                          <div className="flex justify-between">
                            <p className="">Class:</p>
                            <p className="">{user?.subjectClassCombination.map(subjectClassCombination => {
                              return <p className="">{subjectClassCombination?.split("=")[0]}</p>
                            })}
                            </p>
                          </div>
                          : ""}
                        <div className="flex justify-between">
                          <p className="">Institutions Attended:</p>
                          <p className="">{user?.institutionAttended || "Not Available"}</p>
                        </div>
                        <div className="flex justify-between">
                          <p className="">Programme:</p>
                          <p className="">{user?.programme || "Not Available"}</p>
                        </div>
                        <div className="flex justify-between">
                          <p className="">Course:</p>
                          <p className="">{user?.course || "Not Available"}</p>
                        </div>
                        <div className="flex justify-between">
                          <p className="">Skills:</p>
                          <p className="">{user?.skills}
                          </p>
                        </div>
                      </>
                    )}
                    {user.userType === "student" && (
                      <>
                        <div className="row">
                          <p className="col-lg-3">Current educational level</p>
                          <p className="col-lg-3">
                            {user.educationLevel
                              ? user.educationLevel
                              : "Requires update"}
                          </p>
                        </div>
                        <div className="row">
                          <p className="col-lg-3">Class/Year</p>
                          <p className="col-lg-3">
                            {user.year ? user.year : "Requires update"}
                          </p>
                        </div>
                      </>
                    )}

                    <div className="col-lg-4"></div>
                  </div>
                </div>
              )}
            </div>


            {/* school or company  */}
            {(user.userType === "school" || user.userType === "company") && (
              <>
                <div className="px-10 rounded-md pt-3 pb-6 w-full bg-white mx-auto mt-3 relative ">
                  {/* {confirmedTeachers && ( */}
                  <div>
                    <div className="flex">
                      <div className="">
                        <Typography >Faculty</Typography>
                      </div>
                      <div className="flex w-full justify-end">
                        <div>
                          <button
                            onClick={() => {
                              setOpenTeachersList(true);
                            }}
                            className="bg-slate-700 rounded text-white px-2 mr-1 py-1"
                          >
                            Confirmed Teachers
                          </button>
                        </div>

                        <div>
                          <button
                            onClick={() => {
                              setOpenConfirmAccount(true);
                            }}
                            className="bg-slate-700 rounded text-white px-2 py-1"
                          >Unconfirmed staff
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 schoolDivider"></div>
                    <div className=" mt-4 aboutSchool">
                      <Box
                        sx={{
                          width: '100%',
                          maxWidth: '100%',
                        }}
                      >
                        <TextField multiline
                          maxRows={4}
                          id="outlined-basic"
                          label="About School"
                          value={aboutSchool}
                          fullWidth
                          onChange={(e)=>{
                            setAboutSchhol(e.target.value)
                          }}
                          variant="outlined" />
                          <div style={{textAlign:"right",display:"flex",justifyContent:"flex-end",marginTop:"10px"}}>
                      {schoolsaving==false?(<button
                        onClick={() => {
                          updateAboutSchool();
                        }}
                        className="bg-[#1D6DAE] px-2 py-1 text-white rounded"
                        style={{width:"150px"}}
                      >Edit</button>):(
                        <button
                        onClick={() => {
                        }}
                        className="bg-[#FBFBFB] px-2 py-1 text-grey rounded"
                        style={{width:"150px"}}
                      >Saving...</button>
                      )}
                    </div>
                      </Box>
                    </div>
                  </div>
                  {/* )} */}


                  {/* {unconfirmedTeachers && (
                    <SchoolContentTile
                      number={unconfirmedTeachers.length}
                      text="Unconfirmed staff"
                      loading={loading}
                      setOpenConfirmAccount={setOpenConfirmAccount}
                      setOpenTeachersList={setOpenTeachersList}
                      type="unconfirmed"
                    />
                  )} */}
                </div>
              </>
            )}


            {/* Activate staff ucbi ** school */}
            {user?.userType === "teacher" && (
              <>

                <div className="w-full bg-white rounded-md  text-justify mt-3 text-sm px-10" style={{padding:"30px 30px"}}> 
                  <div className="mt-4 italic w-full flex schoolUCBI">
                    <div> UCBI (Universal Conditional Basic Income)</div>
                    {/* <ActivateStaffUCBI user={user} /> */}
                    <div className="flex space-x-6">
                      <div className="">{currencyFormatter.format(user?.ucbi[0]?.balance, { locale: 'en-NG' })}</div>

                      <button
                        onClick={() => setOpenUCBI(true)}
                        disabled={user?.ucbi[0]?.payRequested}
                        className="ml-1 flex items-center px-1 bg-blue-400  border rounded-lg hover:bg-green-300 text-white transition-all duration-500"
                      >
                        {user?.ucbi[0]?.payRequested
                          ? "Processing.."
                          : "withdraw"}
                      </button>
                    </div>
                  </div>
                  <div className="mt-2 mb-2 schoolDivider"></div>
                  Universal Condition Basic Income is a framework for building a global community of shared prosperity.
                   It was designed by Kungliga Utvecklingsbolag (royal development corporations), 
                   a holding company of an ecosystem of companies owned by the grand ducal family of 
                   Eddalands and their partners in the Scandinavia providing services and delivering products
                    that meets target sub-goals of world sustainable development programs, which Esgrown 
                    is part of. It is a means of providing income opportunity to individuals in communities
                     around the world where world sustainable development programs backed by Kungliga Utvecklingsbolag
                      are implemented, in line UN Sustainable Development Goals for all countries. We implement the UCBI
                       for teachers as a value add service that provides means of increasing earning of teachers, so as
                        to reduce their financial anxiety. That is one of the 22 target sub-goals Strategic Knowledge Base 
                        Advancement Program, world sustainable development program on quality education backed by Kungliga 
                        Utvecklingsbolag and implemented in partnership with governments and that national commissions
                         of UNESCO, in line with UN SDG no. 4 for all countries. Esgrown shares some of its business
                          revenue with verified teachers based on very basic conditions under its general terms and 
                          conditions of use for teachers. If your school activates UCBI on its account, 
                          the amount Esgrown pays through the UCBI framework would be increased by 33.33%
                </div>
              </>

            )}
            {/* Activate staff ucbi ** school */}
            {user?.userType === "school" && (
              <>
                <div className="w-full bg-white rounded-md text-justify mt-3 text-sm px-10" style={{paddingTop:"20px",paddingBottom:"30px"}}>
                  <div className="mt-4 w-full flex schoolUCBI">
                    <div style={{fontSize:"1.3]2em",fontWeight:"600"}}> UCBI (Universal Conditional Basic Income)</div>
                    {/* <ActivateStaffUCBI user={user} /> */}
                    <div className="flex space-x-6">
                      {!user?.UCBIActive ?
                        <Button variant="text" size="small" color="error" className="mr-3">Inactive</Button>
                        :
                        <Button variant="text" size="small" color="success" className="mr-3">Active</Button>
                      }
                      <ClickAwayListener onClickAway={() => setActivationMenu(false)}>
                        <div className="ml-auto">
                          <ActivationDropDown show={activationMenu} user={user} />
                          {/* <ActivateStaffUCBI user={user} amount={7000}/> */}
                          {!user?.UCBIActive && <Button variant="contained" size="small" onClick={() => setActivationMenu(!activationMenu)}>Activate</Button>}
                        </div>
                      </ClickAwayListener>
                    </div>
                  </div>
                  <div className="mt-2 mb-2 schoolDivider"></div>
                  Universal Condition Basic Income is a framework for building a global community of shared prosperity.
                   It was designed by Kungliga Utvecklingsbolag (royal development corporations), 
                   a holding company of an ecosystem of companies owned by the grand ducal family of 
                   Eddalands and their partners in the Scandinavia providing services and delivering products
                    that meets target sub-goals of world sustainable development programs, which Esgrown 
                    is part of. It is a means of providing income opportunity to individuals in communities
                     around the world where world sustainable development programs backed by Kungliga Utvecklingsbolag
                      are implemented, in line UN Sustainable Development Goals for all countries. We implement the UCBI
                       for teachers as a value add service that provides means of increasing earning of teachers, so as
                        to reduce their financial anxiety. That is one of the 22 target sub-goals Strategic Knowledge Base 
                        Advancement Program, world sustainable development program on quality education backed by Kungliga 
                        Utvecklingsbolag and implemented in partnership with governments and that national commissions
                         of UNESCO, in line with UN SDG no. 4 for all countries. Esgrown shares some of its business
                          revenue with verified teachers based on very basic conditions under its general terms and 
                          conditions of use for teachers. If your school activates UCBI on its account, 
                          the amount Esgrown pays through the UCBI framework would be increased by 33.33%
                   </div>
              </>

            )}
            {/* Teachers list for reviews  */}
            {openTeachersList && (
              <div className="w-full h-full absolute inset-0 bg-black/70 z-50">
                {/* Teachers list  */}
                <TeachersList
                  setOpenTeachersList={setOpenTeachersList}
                  setOpenRatingModal={setOpenRatingModal}
                  user={user}
                  setRefresh={setRefresh}
                  refresh={refresh}
                />
              </div>
            )}
            {openConfirmAccount && (
              <div className="w-full h-full absolute inset-0 bg-black/70 z-50">
                {/* Teachers list  */}
                <ConfirmStaffStatus
                    openConfirmAccount={openConfirmAccount}
                    setOpenConfirmAccount={setOpenConfirmAccount}
                    user={user} //loggeIn school account
                    setRefresh={setRefresh}
                    refresh={refresh}
                  />
              </div>
            )}
            {openRatingModal && (
              <div className="w-full h-full absolute inset-0 bg-black/0 z-50">
                {/* Rating Modal   */}
                <RatingModal setOpenRatingModal={setOpenRatingModal} />
              </div>
            )}
          </div>
        </Content>
      </Layout>
      {editAcct && <EditAccount user={user} setEditAcct={setEditAcct} />}
      {showSmallScreenDrawer && (
        <SmallScreenDrawer
          setShowSmallScreenDrawer={setShowSmallScreenDrawer}
          user={user}
        />
      )}
    </Layout>
  );
};

export default Full;

function validateFile(file) {
  const oneMB = 1024 * 1024;
  const fileName = file.name;
  const fileSize = file.size;
  if (fileSize > oneMB) return {};
  return { name: file.name, file };
}
