import React, { useState, useEffect } from "react";
import './store.css';
import Icon, {
    StarFilled,
    CloseCircleFilled,
    CloseOutlined
  } from "@ant-design/icons";
import AxiosInstance from "../../../../AxiosInstance/AxiosInstance";
import { toast } from "react-toastify";
const OrderInfo = ({
    showState,
    refresh,
    data
}) =>{

    const [userinfo,setUserInfo]=useState();
    const [itemss,setItems] = useState([]);
    const [status,setStatus]= useState("");

    const clearItem = async () => {
        const url = "/api/store/updateOrder"
        const req = await AxiosInstance.post(url,{
          id:data?.id,
          status:status
        })
        console.log(req);
        toast.success("Status changed successfully");
        showState(false);
        refresh();
      };
      const getItems = async (id) => {
        const url = "/api/store/item"
        const req = await AxiosInstance.post(url,{
            id:id
        })
        return req.data.data;
      };
      const renderItems = async () => {
    
        const promises = data?.items.map(async (id,index) => {
          const det = await getItems(id);
          console.log(det);

          return (
            <tr key={id+Math.random().toString()}>
              <td><img src={det?.images[0]} alt="" style={{width:"70px",height:"auto"}} /></td>
              <td><div>{det?.name}</div></td>
            </tr>
          );
        });
      
        const nitem = await Promise.all(promises);
        setItems(nitem);
      }
      
   
      const getUser = async () => {
        const url = "/api/user/userinfo"
        const req = await AxiosInstance.post(url,{
           id:data?.userId
        })
        console.log(req);
        setUserInfo(req.data.user)
      };
      useEffect(()=>{
        getUser();
        renderItems();
      },[])
    return (
        <div className="store-details">
            <div className="inner">
                <div style={{display:"flex",justifyContent:"space-between",width:"100%",marginBottom:"20px"}}>
                <h1 style={{fontSize:"20px",fontWeight:"bold"}}>Order Info</h1>
                <div><CloseOutlined onClick={()=>{
                    showState(false)
                }}/></div>
                </div>
                <div style={{width:"100%"}}>
                    <div style={{fontWeight:"bold"}}>User Details</div>
                    <div>Name : {userinfo!=null ? userinfo?.name : ''}</div>
                    <div>City : {userinfo!=null ? userinfo?.province : ''}</div>
                    <div>State : {userinfo!=null ? userinfo?.state : ''}</div>
                    <div>Country : {userinfo!=null ? userinfo?.country : ''}</div>
                    <br/>
                    <br/>
                    <div style={{fontWeight:"bold"}}>Order Info</div>
                    <br/>
                    <table className="table table-hover table-striped" style={{width:"100%"}}>
                    {itemss}
               </table>
                </div>
               <select onChange={(e)=>{
                setStatus(e.target.value);
               }}>
                <option selected disabled>Order Status</option>
                <option value={"PROCESSING"}>Processing</option>
                <option value={"SHIPPED"}>Shipped</option>
               </select>
                <button onClick={()=>{
                    clearItem()
                }}>Update Status</button>
            </div>
        </div>
    )
}
export default OrderInfo;