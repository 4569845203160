import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CreateAdmin from "./modal/createAdminModal";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useSelector, useDispatch } from "react-redux";
import { ShoppingCartOutlined } from "@ant-design/icons";
import {
  countAdminsByCountry,
  setAdminCountry,
  getAllAdmins,
  setRemoveAdminPrompt,
  setShowAdminDetailsModal,
  setShowListOfAdmins,
  getAdminTypes
} from "../../../../redux/slices/adminSlice";

import { Layout, Menu, Dropdown, Space, Tabs, Drawer } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  ScheduleOutlined,
  UserOutlined,
  LogoutOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { useUser } from "../../../../context/UserContext";
import OnlineUser from "../../../reusable/OnlineUser";
import StaffView from "./modal/staffView";
import RemoveAdminConfirmationModal from "../../../campaigns/steka/modals/RemoveAdminConfirmationModal";
import AdminDetailsModal from "../../../campaigns/steka/modals/AdminDetailsModal";
import Axios from "axios";
import AdminListModal from "../../../campaigns/steka/modals/AdminListModal";
import AxiosInstance from "../../../../AxiosInstance/AxiosInstance";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const { TabPane } = Tabs;
const onChange = (key) => {
  console.log(key);
};

const handleClick = ({ key }) => {
  //console.log(key);
  //you can perform setState here
};

// const [topRightModal, setTopRightModal] = useState(false);

// const toggleShow = () => setTopRightModal(!topRightModal);

const Admin = () => {
  const [open, setOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [key, setKey] = useState("");
  const [modal, setModal] = useState(false);
  const [prop, setProp] = useState(null);
  const [admins, setAdmins] = useState(null);
  const [country, setCountry] = useState(null);
  const [reps, setReps] = useState(0);
  const [raw, setRaw] = useState(null);
  const [roles, setRoles] = useState({
    operations: 0,
    advisory: 0,
    exchequers: 0
  });




  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user);

  const { allAdminsByCountry,
    adminCountry,
    isLoading,
    allAdmins,
    allAdminTypes
  } = useSelector(state => state.adminSlice);
  const {
    removeAdminPrompt,
    showAdminDetailsModal,
    showListOfAdmins
  } = useSelector(state => state.adminSlice.componentsStates);

  const separateOut = subs => {
    if (!subs.length) {
      return [];
    }
    const resp = {};
    const keys = Object.keys(subs[0]);
    keys.forEach(key => {
      subs.forEach(el => {
        if (resp.hasOwnProperty(key)) {
          resp[key].push(el[key])
        } else {
          resp[key] = [el[key]];
        }
      })
    });
    return resp;
  }

  console.log("AllAdminTypes", allAdminTypes);



  // const findValue = (arr, key, value) => arr.filter(x => x[key] === value);
  useEffect(() => {
    dispatch(countAdminsByCountry())
  }, [])

  useEffect(() => {
    dispatch(getAdminTypes())
  }, [getAdminTypes])

  useEffect(() => {
    const fetchCountryAdmins = async () => {
      const get = await AxiosInstance.post('/api/user/get-country-admins', {
        country: user.country,
        rank: user.adminRank,
        role: user.adminRole,
        state: user.state,
        province: user.province
      });
      if (get.data) {
        if (user.adminRank == 'legate' || user.adminRole == "superAdmin") {
          let find = get.data.admins;
          setRaw(find);
          console.log(get.data.admins);
          let found = (separateOut(find)).country;
          console.log(found);
          const count = {};
          for (var i = 0; i < found.length; i++) {
            var element = found[i];
            if (count[element]) {
              count[element] += 1;
            } else {
              count[element] = 1;
            }
          }
          const cnames = Object.keys(count);
          const cvalues = Object.values(count);
          setCountry(cnames);
          setReps(cvalues);
        }

        else if (user.adminRank == 'praetor') {
          let find = get.data.admins;
          setRaw(find);
          let found = (separateOut(find)).province;
          console.log(find);
          const count = {};
          for (var i = 0; i < found.length; i++) {
            var element = found[i];
            if (count[element]) {
              count[element] += 1;
            } else {
              count[element] = 1;
            }
          }
          console.log(count);
          const cnames = Object.keys(count);
          const cvalues = Object.values(count);
          setCountry(cnames);
          setReps(cvalues);
        }
        else if (user.adminRank == 'tribune') {
          let find = get.data.admins;
          setRaw(find);
          let found = (separateOut(find)).province;
          //console.log(found);

          const count = {};
          for (var i = 0; i < found.length; i++) {
            var element = found[i];
            if (count[element]) {
              count[element] += 1;
            } else {
              count[element] = 1;
            }
          }
          const cnames = Object.keys(count);
          const cvalues = Object.values(count);
          setCountry(cnames);
          setReps(cvalues);
        }
        //setCountry(get.data.admins);
        //console.log(get.data.admins);
        return;
      }
    }

    // fetchCountryAdmins();
    // const fetchAdmins = async() => {
    //   const get = await AxiosInstance.post('/api/user/get-admins', {
    //     country: user.country,
    //     rank: user.adminRank,
    //     role: user.adminRole,
    //     state: user.state,
    //     province: user.province
    //   });
    //   if(get.data) {
    //     setAdmins(get.data);
    //     //console.log(get.data.users);
    //     var users = get.data.users;
    //     //console.log(users);
    //     var numAdv = users.reduce(function (n, person) {
    //       return n + (person.adminType == 'advisory');
    //     }, 0);
    //     var numOps = users.reduce(function (n, person) {
    //       return n + (person.adminType == 'operations');
    //     }, 0);
    //     var numEx = users.reduce(function (n, person) {
    //       return n + (person.adminType == 'exchequer');
    //     }, 0);
    //     //console.log(numEx);
    //     setRoles({
    //       advisory: numAdv,
    //       operations: numOps,
    //       exchequers: numEx
    //     })
    //     return;
    //   }
    // }
    // fetchAdmins();
  }, []);

  const findAdminType = (type) => {
    const findType = allAdminTypes?.find(item => item?._id === type)
    return findType?.total
  }

  function toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }
  function toggle() {
    setCollapsed(!collapsed);
  }
  function onClose() {
    setCollapsed(false);
  }

  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="Profile" icon={<UserOutlined />} className="font-3 mt-2">
        <Link to="/profile" />
        Profile
      </Menu.Item>
      <hr />
      {/* <Menu.Item key="Line">
    </Menu.Item> */}

      <Menu.Item key="Logout" icon={<LogoutOutlined />} className="mb-2 font-3">
        <Link to="/logout" />
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout
      style={{ minHeight: "100vh", maxHeight: "100%", flexWrap: "nowrap" }}
    >
      <Drawer
        placement="left"
        onClose={onClose}
        closable={false}
        visible={collapsed}
        className="hideOnDesktop"
        width="250px"
        bodyStyle={{
          backgroundColor: "#1D6DAE",
          padding: "0",
        }}
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <Menu
          mode="inline"
          className="sidebar-bg f-2"
          defaultSelectedKeys={["5"]}
        >
          <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
            <Link to="/AdminDashboard" />
            <span>Home</span>
          </Menu.Item>

          <Menu.Item key="2" icon={<ShoppingCartOutlined />} className="font-2 mt-2">
            <Link to="/adminStore" />
            <span>Store</span>
          </Menu.Item> 
          <Menu.Item key="3" icon={<ShoppingCartOutlined />}>
            <Link to="/adminOrders" />
            <span>Store Orders</span>
          </Menu.Item> 
          <Menu.Item key="4" icon={<ScheduleOutlined />}>
            <Link to="/Admin_Page" />
            <span>Admin</span>
          </Menu.Item>
          <Menu.Item key="5" icon={<ScheduleOutlined />}>
            <Link to="/UCBI" />
            <span>UCBI</span>
          </Menu.Item>
          <Menu.Item key="6" icon={<ScheduleOutlined />}>
            <Link to="/update" />
            <span>Updates</span>
          </Menu.Item>
          {/* <Menu.Item
            onClick={toggleCreateServiceModal}
            key="7"
            icon={<ScheduleOutlined />}
          >
            <span>create service</span>
          </Menu.Item> */}
          <Menu.Item key="8" icon={<ScheduleOutlined />}>
            <Link to="/campaigns" />
            <span>Campaigns</span>
          </Menu.Item>
          <Menu.Item key="10" icon={<ScheduleOutlined />}>
            <Link to="/exercises" />
            <span>Exercises</span>
          </Menu.Item>

          <Menu.Item key="9" icon={<LoginOutlined />}>
            <Link to="/logout" />
            <span>Logout</span>
          </Menu.Item>
        </Menu>
      </Drawer>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onBreakpoint={(broken) => setCollapsed(broken)}
        className="hideOnMobile sidebar-bg"
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <Menu
          mode="inline"
          className="sidebar-bg f-2"
          defaultSelectedKeys={["4"]}
          onClick={handleClick}
        >
         <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
            <Link to="/AdminDashboard" />
            <span>Home</span>
          </Menu.Item>

          <Menu.Item key="2" icon={<ShoppingCartOutlined />} className="font-2 mt-2">
            <Link to="/adminStore" />
            <span>Store</span>
          </Menu.Item> 
          <Menu.Item key="3" icon={<ShoppingCartOutlined />}>
            <Link to="/adminOrders" />
            <span>Store Orders</span>
          </Menu.Item> 
          <Menu.Item key="4" icon={<ScheduleOutlined />}>
            <Link to="/Admin_Page" />
            <span>Admin</span>
          </Menu.Item>
          <Menu.Item key="5" icon={<ScheduleOutlined />}>
            <Link to="/UCBI" />
            <span>UCBI</span>
          </Menu.Item>
          <Menu.Item key="6" icon={<ScheduleOutlined />}>
            <Link to="/update" />
            <span>Updates</span>
          </Menu.Item>
          {/* <Menu.Item
            onClick={toggleCreateServiceModal}
            key="7"
            icon={<ScheduleOutlined />}
          >
            <span>create service</span>
          </Menu.Item> */}
          <Menu.Item key="8" icon={<ScheduleOutlined />}>
            <Link to="/campaigns" />
            <span>Campaigns</span>
          </Menu.Item>
          <Menu.Item key="10" icon={<ScheduleOutlined />}>
            <Link to="/exercises" />
            <span>Exercises</span>
          </Menu.Item>

          <Menu.Item key="9" icon={<LoginOutlined />}>
            <Link to="/logout" />
            <span>Logout</span>
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout>
        <Header className="row pt-2 head">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3",
              onClick: toggle,
            }
          )}
          <h3 className="mt-1 wel">Corporate Staff</h3>

          <div className="ml-auto flex items-center ">
            <div>
              <OnlineUser user={user} />
            </div>
            <div className="ml-auto transition hover:translate-x-1 hover:duration-500">
              {user && user.name}
            </div>
          </div>
        </Header>

        <Content
          style={{
            margin: "24px 16px",
            padding: 10,
            // background: "#fff",
            minHeight: 280,
          }}
        >
          <div className="container-fluid relative">
            {/* <div className="row flex flex-row justify-between bg-white w-full h-24 ml-0 mb-8 items-center">
              <label className="font-semibold text-base ml-4 flex flex-col">
                <label className="capitalize text-lg font-bold">{user.adminRank}</label>
                {user.adminRank == "tribune" && (
                    user.province + ", " + user.state + ", " + user.country
                  )
                }
                {user.adminRank == "praetor" && (
                    user.state + ", " + user.country
                  )
                }
                {user.adminRank == "legate" && (
                    user.country
                  )
                }
              </label>
              <label className="capitalize font-bold text-lg mr-4">
                {user.adminType == "advisory" && (
                    "Radgivande (Advisor)"
                  )
                }
                {user.adminType == "operations" && (
                    "Varksamheschef (Operations Management)"
                  )
                }
                {user.adminType == "exchequer" && (
                    "Staskassan (Exchequer)"
                  )
                }
              </label> 
            </div> */}
            <div className="row">
              <div className="col-lg-12 bg-white m-0 p-0">
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                      m: 1,
                      width: 180,
                      height: 80,
                    },
                  }}
                  className="paperContainer"
                >
                  <Paper elevation={2} >
                    <div className="adminCard">
                      <h6 className="font-bold text-white">Verksamheschef</h6>
                      <h6 className="text-white">(Operations Management)</h6>
                      <h5 className="text-white">{findAdminType('Operations Management') ? findAdminType('Operations Management') : 0}</h5>
                    </div>
                  </Paper>
                  <Paper elevation={2} >
                    <div className="adminCard">
                      <h6 className="font-bold text-white">Innehallsbehallare</h6>
                      <h6 className="text-white">(Content Curration)</h6>
                      <h5 className="text-white">{findAdminType('Content Curration') ? findAdminType('Content Curration') : 0}</h5>
                    </div>
                  </Paper>
                  <Paper elevation={2} >
                    <div className="adminCard">
                      <h6 className="font-bold text-white">Innehallsmoderering</h6>
                      <h6 className="text-white">(Content Moderation)</h6>
                      <h5 className="text-white">{findAdminType('Content Moderation') ? findAdminType('Content Moderation') : 0}</h5>
                    </div>
                  </Paper>
                  <Paper elevation={2} >
                    <div className="adminCard">
                      <h6 className="font-bold text-white">Bokforing och finansiera</h6>
                      <h6 className="text-white">(Accounts and Finance)</h6>
                      <h5 className="text-white">{findAdminType('Accounts and Finance') ? findAdminType('Accounts and Finance') : 0}</h5>
                    </div>
                  </Paper>
                  <Paper elevation={2} >
                    <div className="adminCard">
                      <h6 className="font-bold text-white">Annonshantering</h6>
                      <h6 className="text-white">(Adverts Management)</h6>
                      <h5 className="text-white">{findAdminType('Adverts Management') ? findAdminType('Adverts Management') : 0}</h5>
                    </div>
                  </Paper>
                </Box>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <div class="flex justify-content-between items-center">
                      <div className="justify-content-between">
                        {user?.adminRole === "superAdmin" && (
                          <button
                            className="addBtn btn btn-success1"
                            onClick={() => setModal(true)}
                          >
                            Add Admin
                          </button>
                        )}
                        <CreateAdmin
                          show={modal}
                          onHide={() => setModal(false)}
                        />
                      </div>

                      <div className="justify-content-between text-[#1D6DAE] font-bold">

                        <button>
                          [ {allAdminsByCountry
  .filter(item => item._id !== "")
  .map(item => Number(item.total))
  .reduce((prev, cur) => prev + cur, 0)} ]
                        </button>
                      </div>
                    </div>

                    <div className="mt-2">
                      <div className="table-responsive bg-white">
                        <div className="w-full h-full flex flex-col relative">

                          <div className="h-[50%] border-b border-b-blue-500 overflow-y-scroll">
                            {allAdminsByCountry?.length > 0 ? (
                              allAdminsByCountry?.filter(item => item._id !== "")
                                .filter(item => item._id !== null)
                                .map((country, index) => (
                                  <div
                                    key={index.toString()}
                                    className="odd:bg-gray-200 mt-1 h-10 text-gray-600 font-semibold px-4 flex justify-between"
                                  >
                                    <p className="w-[30%]">{country?._id}</p>
                                    <p className="w-[20%]">{country?.total}</p>
                                    <button
                                      onClick={() => {
                                        dispatch(setAdminCountry(country?._id))
                                        dispatch(setShowListOfAdmins(true))

                                      }}
                                      className="text-[#1D6DAE] focus:outline-none transition-all hover:translate-x-1 duration-500">
                                      View
                                    </button>
                                  </div>
                                ))
                            ) : (
                              <div className="w-full flex justify-center h-10 mt-1.5 ">
                                <div>No Admin👏 </div>
                              </div>
                            )}
                          </div>
                          {removeAdminPrompt && (
                            <RemoveAdminConfirmationModal />
                          )}
                          {showAdminDetailsModal && (
                            <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
                              <AdminDetailsModal />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showListOfAdmins && (
              <div className={`absolute left-0 top-0 w-full h-screen rounded-lg bg-white px-4 py-2`}>
                <AdminListModal />
              </div>
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Admin;
