import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function RenderStekaForm({ children }) {
  const show = useSelector((state) => state.form.showStekaForm);
  return (
    <div
      className={`w-screen h-screen bg-black/70 absolute inset-0 ${
        !show && "hidden"
      } `}
      style={{ zIndex: 2000 }}
    >
      {children}
    </div>
  );
}

export default RenderStekaForm;
