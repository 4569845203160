import {
  InfoCircleOutlined,
  LogoutOutlined,
  UserOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import AddCardIcon from '@mui/icons-material/AddCard';
import ActivateStaffUCBI from "../admin/ucbi/ActivateStaffUCBI";

import React, { useState } from "react";
import { Link } from "react-router-dom";

function ActivationDropDown({ show, bgColor, user }) {
  const [amount, setAmount] = useState()
  const [triggerPayStack, setTriggerPayStack] = useState(false)

  console.log("amount", amount);
  return (
    <div
      style={{ zIndex: 1000 }}
      className={`absolute min-w-min min-h-min ${
        bgColor ? bgColor : "bg-white/20"
      }  border bottom-[50px] right-[110px] shadow-lg transition-all duration-700 cursor-pointer`}
    >
      <div className={`z-10 h-full w-full p-2 ${!show && "hidden"}`}>
        <div className="flex space-x-2 menu-item  items-center bg-sky-200 mt-1 h-8 px-2">
              <AddCardIcon size={20} />
              <button to="#" className="hover:text-white" 
                onClick={() => {
                  setAmount(6000)
                  setTriggerPayStack(true)
                }
                }
              >
                Three Months      N6,000
              </button>
        </div>
        <div className="flex space-x-2 menu-item  items-center bg-sky-200 mt-1 h-8 px-2">
        
              <AddCardIcon size={20} />
              <button to="#" className="hover:text-white" 
                onClick={() => {
                  setAmount(12000) 
                  setTriggerPayStack(true)
                }
                }
              >
                 Six Months        N12,000
              </button>  
        </div>
        <div className="flex space-x-2 menu-item  items-center bg-sky-200 mt-1 h-8 px-2">
              <AddCardIcon size={20}/>
              <button to="#" className="hover:text-white" 
                onClick={() => {
                  setAmount(24000)
                  setTriggerPayStack(true)
                }
                }
              >
              1 Year            N24,000
              </button>
        </div>
      </div>
      {
      triggerPayStack &&
      <ActivateStaffUCBI 
       user={user} 
       amount={amount} 
       triggerPayStack={triggerPayStack}
       setTriggerPayStack={setTriggerPayStack}
       />
      }
    </div>
  );
}

export default ActivationDropDown;
