import AxiosInstance from "../../../AxiosInstance/AxiosInstance";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Toast from "../../../util/toast";
import { TimePicker, DatePicker, Checkbox, Progress } from "antd";
import moment from "moment";
import DOMPurify from "dompurify";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";

import {
  LoadingOutlined,
  UploadOutlined,
  FileImageFilled,
  VideoCameraOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  UpCircleTwoTone,
} from "@ant-design/icons";
import { Modal } from "react-bootstrap";

function UpdateCard(props) {
  const imgRef = useRef(null);
  const d = new Date();
  const t = d.getTime();
  const time = new Date(t).toISOString().slice(11,19);
  const user = useSelector((state) => state.user.user);
  //console.log(user);
  const [edit, setEdit] = useState(false);
  const [prop, setProp] = useState(props.props);
  const [services, setServices] = useState([
    {"id": 1, "name": "Education Advisory", "value": "education advisory"},
    {"id": 2, "name": "Digital Literacy / Life Skills", "value": "digital literacy / life skills"}
  ]);

  const level = [
    {"id": 1, "name": "primary", "text": "primary school"},
    {"id": 2, "name": "secondary", "text": "secondary school"},
    {"id": 3, "name": "tertiary", "text": "tertiary institution"}
  ];

  const primary = [
    {"id": 1, "name": "one", "text": "primary one"},
    {"id": 2, "name": "two", "text": "primary two"},
    {"id": 3, "name": "three", "text": "primary three"},
    {"id": 4, "name": "four", "text": "primary four"},
    {"id": 5, "name": "five", "text": "primary five"},
    {"id": 6, "name": "six", "text": "primary six"}
  ];
  const secondary = [
    {"id": 1, "name": "one", "text": "JSS1"},
    {"id": 2, "name": "two", "text": "JSS2"},
    {"id": 3, "name": "three", "text": "JSS3"},
    {"id": 4, "name": "four", "text": "SSS1"},
    {"id": 5, "name": "five", "text": "SSS2"},
    {"id": 6, "name": "six", "text": "SSS3"}
  ];
  const tertiary = [
    {"id": 1, "name": "one", "text": "First Year"},
    {"id": 2, "name": "two", "text": "Second Year"},
    {"id": 3, "name": "three", "text": "Third Year"},
    {"id": 4, "name": "four", "text": "Fourth Year"},
    {"id": 5, "name": "five", "text": "Fifth Year"},
    {"id": 6, "name": "six", "text": "Sixth Year"},
    {"id": 7, "name": "seven", "text": "Final Year"}
  ];

  const status = [
    {id: 1, text: "Ordinary"},
    {id: 2, text: "Premium"}
  ];

  const recipient = [
    {id: 1, option: "Student"}, 
    {id: 2, option: "Teacher"},
    {id: 3, option: "Praetor"},
    {id: 4, option: "Aedile"},
    {id: 5, option: "Private"},
    {id: 6, option: "Tribune"},
    {id: 7, option: "Legate"},
    {id: 8, option: "School"},
    {id: 9, option: "Company"},  
    {id: 10, option: "Associate"}, 
  ];

  const [imageFiles, setImageFiles] = useState(props.props ? props.props.images : null);
  const [videoFiles, setVideoFiles] = useState(props.props ? props.props.videos : null);
  const [detailedUpdate, setDetailedUpdate] = useState(false);
  const [careerPath, setCareerPath] = useState(null);
  const [fieldsFromdb, setFieldsFromdb] = useState(null);
  const [preferredPackage, setPreferredPackage] = useState(null);
  const [subject, setSubject] = useState();
  const [careerPathId, setCareerPathId] = useState(null);
  const [percentUploaded, setPercentUploaded] = useState(0);
  const [updateService, setUpdateService] = useState(null); // The service for which this updated is intended
  const [files, setFiles] = useState([{}]);
  const [subjects, setSubjects] = useState();
  const [content, setContent] = useState(props.props ? props.props.text: null);
  const [module, setModule] = useState();
  const [choice, setChoice] = useState(true);
  //Upload files urls

  const [update, setUpdate] = useState({
    title: props.props ? props.props.title: "",
    serviceId: props.props ? props.props.serviceId: "",
    text: props.props ? props.props.text : "",
    externalURLS: props.props ? props.props.externalURLS : "",
    date: "",
    time: "",
    updateType: props.props ? props.props.updateType: "",
    educationLevel: props.props ? props.props.educationLevel : "",
    framework: props.props ? props.props.framework : "",
    module: "",
    topic: props.props ? props.props.topic : "",
    educationClass: props.props ? props.props.educationClass : "",
    subject: "",
    subType: props.props ? props.props.subType : "",
    recipient: props.props ? props.props.recipient : "",
    year: "",
    loading: false,
  });

  const handImageChange = (event) => {
    setImageFiles(event.target.files);
  };
  
  const handVideoChange = (event) => {
    setVideoFiles(event.target.files);
  };

  useEffect(() => {
    if(props.props) {
      setEdit(true);
      handleLoad();
      return;
    } else {
      setEdit(false);
      return;
    }
  }, []);
  
  const separateOut = subs => {
    if(!subs.length) {
      return [];
    }
    const resp = {};
    const keys = Object.keys(subs[0]);
    keys.forEach(key => {
      subs.forEach(el => {
        if(resp.hasOwnProperty(key)){
          resp[key].push(el[key])
        } else {
          resp[key] = [el[key]];
        }
      })
    });
    return resp;
  }

  const removeDuplicates = arr => {
    return arr.filter((item, index) => arr.indexOf(item) === index)
  }

  const handleLoad = async() => {
      try {
        await AxiosInstance.post('/api/user/get-career', {
          field: update.serviceId
        }).then((res) => {
          //setSubjects((res.data.getField).flat());
          //setSubject((res.data.getField).flat());
          console.log(res);
          const subs = res.data.getField;
          console.log(subs);
          //console.log(subs);
          //console.log((res.data.getField).flat());
          //console.log([].concat(...res.data.getField));
          //console.log(separateOut(subs));
          const merge = (separateOut(subs)).subjects;
          const admerge = merge.toString().replace(/\s*\, \s*/g, ",").trim().split(",");
          const final = removeDuplicates(admerge);
          //console.log(final);
          setSubject(final);
          setSubjects(final);
        });  
        return;
      } catch (error) {
        console.log(error);
        return;
      }
  }

  const handleChange = async(event) => {
    //event.preventDefault();
    //console.log(event.target.name, event.target.value);
    setUpdate({ ...update, [event.target.name]: event.target.value });
    if(event.target.name === 'serviceId' && event.target.value === "education advisory") {
      await AxiosInstance.post('/api/user/get-career', {
        field: event.target.value
      }).then((res) => {
        //setSubjects((res.data.getField).flat());
        //setSubject((res.data.getField).flat());
        console.log(res);
        const subs = res.data.getField;
        console.log(subs);
        //console.log(subs);
        //console.log((res.data.getField).flat());
        //console.log([].concat(...res.data.getField));
        //console.log(separateOut(subs));
        const merge = (separateOut(subs)).subjects;
        const admerge = merge.toString().replace(/\s*\, \s*/g, ",").trim().split(",");
        const final = removeDuplicates(admerge);
        //console.log(final);
        setSubject(final);
        setSubjects(final);
      }); 
    }

    else if(event.target.name === "serviceId" && event.target.value === "digital literacy / life skills") {
      await AxiosInstance.post('/api/user/get-career', {
        field: event.target.value
      }).then((res) => {
        //setSubjects((res.data.getField).flat());
        //setSubject((res.data.getField).flat());
        const subs = res.data.getField;
        //console.log(subs);
        //console.log((res.data.getField).flat());
        //console.log([].concat(...res.data.getField));
        //console.log(separateOut(subs));
        const merge = (separateOut(subs)).name;
        setSubject(merge);
        setSubjects(merge);
      });
    }

    if(event.target.name === 'framework'){
        AxiosInstance.post('/api/user/get-modules', {
          field: event.target.value
        }).then((res) => {
          setModule(res.data.module[0].subjects);
          //console.log(res.data.module[0].subjects);
          return;
        })
    }
    //if serviceId is changed, check the newly selected service to see if you have to display
    //additional input fields. e.g for eas or dlds, you need additional input fields.
    /*if (event.target.name === "serviceId") {
      const serv = services.filter(
        (service) => service.serviceId === event.target.value
      );
      setUpdateService(serv[0]);
      // alert(serv[0].serviceType);
    }*/
  };

  const createUpdate = async (event) => {
    event.preventDefault();
    setUpdate({ ...update, loading: true });
    //console.log(update, careerPathId, subject);
    if (update.title == "" || update.serviceId == "" || update.text == "") {
      setUpdate({ ...update, loading: false });
      return notify("Some fields are blank!", "error");
    }

    // console.log(process.env);

    if (update.updateType === "scheduled" && update.date?.length) {
      setUpdate({ ...update, loading: false });
      return notify(
        "Date and time are required for scheduled update!",
        "error"
      );
    }
    //const finalContent = DOMPurify.sanitize(update.text);
    //console.log(finalContent);
    
    const data = new FormData();
    if(imageFiles.length > 0 && !edit) {
      for (let i = 0; i < imageFiles.length; i++) {
        data.append("files", imageFiles[i]);
      }  
    }
    if(edit) {
      data.append("_id", props.props._id);
    }
    if(update.updateType == 'instant') {
      data.append("date", d.getDate());
      data.append("time", time);
    } else {
      data.append("date", update.date);
      data.append("time", update.time);
    }
    data.append("files", videoFiles);
    data.append("title", update.title);
    data.append("serviceId", update.serviceId);
    data.append("text", update.text);
    data.append("externalURLS", update.externalURLS);
    data.append("updateType", update.updateType);
    data.append("subject", update.subject);
    data.append("educationLevel", update.educationLevel);
    data.append("framework", update.framework);
    data.append("module", update.module);
    data.append("topic", update.topic);
    data.append("educationClass", update.educationClass);
    data.append("year", d.getFullYear());
    data.append("subType", update.subType);
    data.append("recipient", update.recipient);
    if(props.props && props.props.id == user.id) {
      data.append("createdBy", user.id);
      data.append("country", user.country);
      data.append("state", user.state);
      data.append("province", user.province);
      data.append("adminRank", user.adminRank);
    } 
    else if (props.props && props.props.id !== user.id) {
      data.append("country", props.props.country);
      data.append("state", props.props.state);
      data.append("province", props.props.province);
      data.append("adminRank", props.props.adminRank);
      data.append("createdBy", props.props.id);
    }
    else {
      data.append("createdBy", user.id);
      data.append("country", user.country);
      data.append("state", user.state);
      data.append("province", user.province);
      data.append("adminRank", user.adminRank);
    }
    //////
    if(edit) {
      await AxiosInstance.post("/api/update/edit", data, {
        onUploadProgress: ({ loaded, total }) => {
          let percentComplete = loaded / total;
          percentComplete = parseInt(percentComplete * 100);
          setPercentUploaded(percentComplete);
        },
      }).then((res) => {
          setUpdate({
            updateType: "",
            title: "",
            serviceId: "",
            text: "",
            externalURLS: "",
            date: "",
            time: "",
            updateType: "",
            educationLevel: "",
            framework: "",
            module: "",
            subject: "",
            educationClass: "",
            topicName: "",
            year: "",
            loading: false,
          });
          notify("Update edited successfully!", "success");
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setUpdate({ ...update, loading: false });
        });  
    }

    await AxiosInstance.post("/api/update/formdata", data, {
      onUploadProgress: ({ loaded, total }) => {
        let percentComplete = loaded / total;
        percentComplete = parseInt(percentComplete * 100);
        setPercentUploaded(percentComplete);
      },
    })
      .then((res) => {
        setUpdate({
          updateType: "",
          title: "",
          serviceId: "",
          text: "",
          externalURLS: "",
          date: "",
          time: "",
          updateType: "",
          educationLevel: "",
          framework: "",
          module: "",
          subject: "",
          educationClass: "",
          topicName: "",
          year: "",
          loading: false,
        });
        notify("Update created successfully!", "success");
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setUpdate({ ...update, loading: false });
      });
    // const resp = await AxiosInstance.post("/api/update/formdata", data);
    // if (resp.data.error) {
    //   console.log(resp.data);
    //   setUpdate({ ...update, loading: false });
    //   notify(resp.data.message, "error");
    // } else {
    //   setUpdate({
    //     title: "",
    //     serviceId: "",
    //     shortDesc: "",
    //     text: "",
    //     externalURLS: "",
    //     time: "",
    //     date: "",
    //     updateType: "instant",
    //     educationLevel: null,
    //     year: "",
    //     loading: false,
    //   });
    //   console.log(resp.data);
    //   setUpdate({ ...update, loading: false });
    //   notify("Update created successfully!", "success");
    // }
  };

  const notify = (message, type) => {
    return Toast(message, type);
  };

  const onChangeTime = (time, timeString) => {
    console.log(timeString);
    setUpdate({ ...update, time: timeString });
  };

  const onChangeDate = (date, dateString) => {
    console.log(dateString);
    setUpdate({ ...update, date: dateString });
  };

  useEffect(() => {
    const fetchServices = async () => {
      const resp = await AxiosInstance.get("/api/service/get-services");
      if (resp.data && resp.data.services) {
        // console.log(resp.data.services);
        setServices(resp.data.services);
      }
    };
    fetchServices();
  }, []);

  useEffect(() => {
    const fetchFields = async () => {
      setUpdate({ ...update, loading: true });
      const resp = await AxiosInstance.get("/api/user/get-fields");
      if (resp.data && resp.data.fields) {
        setFieldsFromdb(resp.data.fields);
        //console.log("Fields:", resp.data.fields);
      }
    };
    fetchFields();
    setUpdate({ ...update, loading: false });
  }, []);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant = "white">
        <Modal.Title id = "contained-modal-title-vcenter">
          <div className="text-center w-full row ml-2">
            <div className = 'w-full bg-white h-16 flex flex-row justify-between items-center my-2 px-3 rounded'>
              <label className="font-bold text-xl capitalize">Rank: {user.adminRank}</label>
              {user.adminType == 'advisory' &&
                <label className="font-bold text-xl ml-40 capitalize">
                  Radgivande (Advisor)
                </label>
              }
              {user.adminType == 'operations' &&
                <label className="font-bold text-xl ml-40 capitalize">
                  Verksamheschef (Operations Management)
                </label>
              }
              {user.adminType == 'exchequer' &&
                <label className="font-bold text-xl ml-40 capitalize">
                  Staskassan (Exchequer)
                </label>
              }
            </div>
            <label className="text-black ml-3 font-bold text-2xl">Publish Updates</label>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" w-11/12  lg:w-[100%] h-full mx-auto  py-4 px-4 bg-gray-100 shadow-lg rounded-lg relative">
          <div className="w-full mt-2">
            <textarea
              className="custom-text-field w-full h-min mb-2 resize-none"
              value={update.text}
              onChange={handleChange}
              name="text"
              rows="12"
              placeholder="Enter Update text here..."
              required
            ></textarea>
          </div>
          <div className="w-full flex flex-row justify-start items-center mb-2 text-blue-800">
            <div className="flex flex-row justify-center items-center text-xl ">
                <FileImageFilled onClick={() => imgRef.current.click()} />
                  {imageFiles && (
                    <>
                      <CheckOutlined className="text-xs ml-1" />
                      <span className="text-xs">{imageFiles?.length}</span>
                    </>
                )} <label className="text-sm font-semibold mx-2">Add Images</label>
            </div>
            <div className="flex flex-row justify-center items-center text-xl mr-2">
                  <UploadOutlined onClick={() => imgRef.current.click()} />
                  
                  <label className="text-sm font-semibold mx-2">Add Videos</label>
                  {/* {videoFiles && <CheckOutlined className="text-xs ml-1" />} */}
            </div>
          </div>
          <div className="w-full flex justify-between  space-x-3">
            <select
              name="serviceId"
              className="custom-text-field text-gray-500"
              value={update.serviceId}
              onChange={handleChange}
              required
            >
              <option className="font-semibold">--Select service--</option>
              {services &&
                services.length > 0 &&
                services.map((service, i) => (
                  <option
                    key={i.toString()}
                    value={service.value}
                    className="font-semibold"
                  >
                    {`${service.name}`}
                  </option>
                ))}
            </select>
            <select
              name="updateType"
              className="custom-text-field text-gray-500"
              value={update.updateType}
              onChange={handleChange}
              required
            >
              <option>--Update Type--</option>
              <option value="instant" className="">
                Instant
              </option>
              <option value="scheduled" className="">
                Scheduled
              </option>
              <option value="ordered" className="">
                Ordered
              </option>
            </select>
          </div>
          <div className="w-full flex justify-between mt-3 space-x-3">
            <input
              type="text"
              name="title"
              value={update.title}
              onChange={handleChange}
              placeholder="Title of update"
              className="custom-text-field"
              required
            />
            <select
              name="subType"
              className="custom-text-field text-gray-500"
              value={update.subType}
              onChange={handleChange}
              required
            >
              <option>--Subscription Type--</option>
              {status &&
                status.length > 0 &&
                status.map((stat, i) => (
                  <option
                    key={i.toString()}
                    value={stat.text}
                    className="font-semibold"
                  >
                    {`${stat.text}`}
                  </option>
                ))}
            </select>
          </div>
          <div className="w-full mt-3">
            <select
              name="recipient"
              className="custom-text-field w-full text-gray-500"
              value={update.recipient}
              onChange={handleChange}
              required
            >
              <option>--Recipient Type--</option>
              {recipient &&
                recipient.length > 0 &&
                recipient.map((stat, i) => (
                  <option
                    key={i.toString()}
                    value={stat.option}
                    className="font-semibold"
                  >
                    {`${stat.option}`}
                  </option>
                ))}
            </select>
          </div>
          {/* URLS  */}
          <div className="w-full mt-3">
            <textarea
              className="custom-text-field w-full h-min resize-none"
              name="externalURLS"
              value={update.externalURLS}
              onChange={handleChange}
              rows="2"
              required
              placeholder="Enter comma separated list of URLs e.g http://example.com , or https://google.com"
            ></textarea>
          </div>
          {/* Education level and year/class  */}
          {update.serviceId == "education advisory" &&
            <div className="w-full flex justify-between mt-3 space-x-3">
              <select name="educationLevel" 
                value={update.educationLevel}
                onChange={handleChange}
                className="w-full capitalize custom-text-field text-gray-500"
              >
                <option value="">Education Level</option>
                {level &&
                level.length > 0 &&
                level.map((levels, i) => (
                  <option
                    key={i.toString()}
                    value={levels.name}
                    className="font-semibold capitalize"
                  >
                    {`${levels.text}`}
                  </option>
                ))}
              </select>
            </div>
          }
          {update.serviceId == "digital literacy / life skills" &&
            <div className="w-full flex justify-between mt-3 space-x-3">
              <select name="framework" 
                value={update.framework}
                onChange={handleChange}
                className="w-full capitalize custom-text-field text-gray-500"
              >
                <option value="">Framework</option>
                {subject &&
                subject.length > 0 &&
                subject.map((levels, i) => (
                  <option
                    key={i.toString()}
                    value={levels}
                    className="font-semibold capitalize"
                  >
                    {`${levels}`}
                  </option>
                ))}
              </select>
            </div>
          }
          {update.educationLevel == "primary" && update.serviceId == 'education advisory' &&
              <div className="w-full flex justify-between mt-3 space-x-3">
                <select name="educationClass" 
                  value={update.educationClass}
                  onChange={handleChange}
                  className="w-full capitalize custom-text-field text-gray-500"
                >
                  <option value="">Class</option>
                  {primary &&
                    primary.length > 0 &&
                    primary.map((primaries, i) => (
                      <option
                        key={i.toString()}
                        value={primaries.name}
                        className="font-semibold"
                      >
                        {`${primaries.text}`}
                      </option>
                  ))}
                </select>
              </div>
            }
            {update.educationLevel == "secondary" && update.serviceId == 'education advisory' &&
              <div className="w-full flex justify-between mt-3 space-x-3">   
                <select name="educationClass" 
                  value={update.educationClass}
                  onChange={handleChange}
                  className="w-full capitalize custom-text-field text-gray-500"
                >
                  <option value="">Class</option>
                  {secondary &&
                    secondary.length > 0 &&
                    secondary.map((second, i) => (
                      <option
                        key={i.toString()}
                        value={second.name}
                        className="font-semibold capitalize"
                      >
                        {`${second.text}`}
                      </option>
                  ))}
                </select>
              </div>
            }
            {update.educationLevel == "tertiary" && update.serviceId == 'education advisory' &&
              <div className="w-full flex justify-between mt-3 space-x-3">
                <select name="educationClass" 
                  value={update.educationClass}
                  onChange={handleChange}
                  className="w-full capitalize custom-text-field text-gray-500"
                >
                  <option value="">Level</option>
                  {tertiary &&
                    tertiary.length > 0 &&
                    tertiary.map((tert, i) => (
                      <option
                        key={i.toString()}
                        value={tert.name}
                        className="font-semibold text-gray-500 capitalize"
                      >
                        {`${tert.text}`}
                      </option>
                  ))}
                </select>
              </div>
            }
            {update.framework && update.serviceId == 'digital literacy / life skills' &&
              <div className="w-full flex flex-col justify-between mt-3 space-x-3">
                <select name="module" 
                  value={update.module}
                  onChange={handleChange}
                  className="w-full capitalize custom-text-field text-gray-500"
                >
                  <option value="">Module</option>
                  {module &&
                    module.length > 0 &&
                    module.map((tert, i) => (
                      <option
                        key={i.toString()}
                        value={tert}
                        className="font-semibold text-gray-500 capitalize"
                      >
                        {`${tert}`}
                      </option>
                  ))}
                </select>
              </div>
            }
            {update.serviceId == 'digital literacy / life skills' && update.framework &&
              <div className="w-full flex flex-col justify-between mt-3 space-x-3">
                <input
                  type="text"
                  name="topic"
                  value = {update.topic}
                  onChange={handleChange}
                  placeholder="Topic for the day"
                  className="custom-text-field w-full ml-0"
                />
              </div>
            }
            
            {update.educationClass && update.serviceId == 'education advisory' &&
              <div className="w-full flex justify-between mt-3 space-x-3">
                <select 
                  name = "subject" 
                  value = {update.subject}
                  onChange={handleChange}
                  className="w-full capitalize custom-text-field text-gray-500"
                >
                  <option value="">Subject</option>
                  {subject &&
                    subject.length > 0 &&
                    subject.map((sub, i) => (
                      <option
                        key={i.toString()}
                        value={sub}
                        className="font-semibold text-gray-500 capitalize"
                      >
                        {`${sub}`}
                      </option>
                  ))}
                </select>
              </div>
            }


          {detailedUpdate && (
            <div className="w-full flex justify-between mt-3 space-x-3 mb-3">
              <select
                name="educationLevel"
                className="shadow-lg px-2 py-2 w-1/2 rounded-lg outline-[#1D6DAE]"
                onChange={handleChange}
              >
                <option>--Education Level--</option>

                {renderEducationLevel()}
              </select>
              <select
                name="year"
                className="shadow-lg px-2 py-2 w-1/2 rounded-lg outline-[#1D6DAE]"
                disabled={update.educationLevel === null}
                onChange={handleChange}
              >
                <option>--Class/Year--</option>
                {rederClassOrYear(update.educationLevel)}
              </select>
            </div>
          )}

          {/* Additional careerPath & specific subject update  */}

          {detailedUpdate && (
            <div className="w-full flex justify-between  space-x-3 mt-2">
              <select
                className="custom-text-field text-gray-500"
                // value={update.serviceId}
                onChange={(e) => {
                  const cp = JSON.parse(e.target.value);
                  setCareerPath(cp);
                  setCareerPathId(cp?._id);
                }}
                required
              >
                <option value="">-- Field--</option>
                {/* <option value="Software Engineering">Software Engineering</option>
                <option value="Aeronautical Engineering">
                  Aeronautical Engineering
                </option>
                <option value="Medicine and Surgery">Medicine and Surgery</option>
                <option value="Civil Engineering">Civil Engineering</option> */}
                {fieldsFromdb?.map((field, index) => (
                  <option key={index.toString()} value={JSON.stringify(field)}>
                    {field?.name}
                  </option>
                ))}
              </select>
              <select
                className="custom-text-field text-gray-500"
                disabled={careerPath == null}
                // value={update.updateType}
                onChange={(e) => setSubject(e.target.value)}
                required
              >
                <option className="font-semibold">--Subject--</option>
                {careerPath &&
                  careerPath.name &&
                  careerPath.subjects?.map((subject, i) => (
                    <option key={i.toString()} value={subject}>
                      {subject}
                    </option>
                  ))}
              </select>
            </div>
          )}
          {/* EAS update type  */}
          {updateService && updateService.serviceType == "eas" && (
            <div className="w-full flex  mt-3 space-x-3">
              <Checkbox
                name="detailedUpdate"
                // value={update.title}
                onChange={() => setDetailedUpdate((prev) => !prev)}
                // placeholder="Title of update"
                // className="custom-text-field"
                required
              ></Checkbox>
              <p className="text-gray-500 text-xs flex items-center">
                Detailed Education Advisory Update ?
              </p>
            </div>
          )}
          {/* DLDS update type  */}
          {updateService && updateService.serviceType == "dlds" && (
            <div className="w-[58%] flex  mt-3">
              <select
                name="preferredPackage"
                className="custom-text-field text-gray-500"
                value={update.preferredPackage}
                onChange={(e) => setPreferredPackage(e.target.value)}
                required
              >
                <option value="">-- Selcet Package --</option>
                <option value="Package I">Package I</option>
                <option value="Package II">Package II</option>
                <option value="Package III">Package I</option>
              </select>
            </div>
          )}

          <div className="w-full mt-2 flex justify-between  ">
            <div className="w-[65%] flex justify-between space-x-2 text-blue-800">
              <div className="w-[20%] flex h-10 space-x-3 justify-center">
                {/* upload files  */}
                <input
                  type="file"
                  className="invisible"
                  ref={imgRef}
                  multiple={true}
                  onChange={handImageChange}
                />
              </div>
              <div
                className={`w-[80%] flex items-center space-x-2 ${
                  update.updateType === "scheduled" ? "visible" : "invisible"
                }`}
              >
                {/* selct date and time */}
                <DatePicker
                  onChange={onChangeDate} // style={{ width: 120 }}
                  className="py-2"
                />
                <TimePicker
                  onChange={onChangeTime}
                  defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                  // style={{ width: 120 }}
                  className="py-2"
                />
              </div>
            </div>
            <div className="w-[30%] flex justify-end mt-2">
              {edit ? <button
                disabled={update.loading}
                className={`custom-primary-btn ${
                  update.loading && "bg-gray-600"
                }  flex items-center py-2 hover:translate-y-1`}
                onClick={createUpdate}
                // onClick={uploadImage}
                // onClick={uploadVideo}
              >
                Edit Update
                {update.loading && (
                  <span className="ml-2">
                    <LoadingOutlined />
                  </span>
                )}
              </button> : 
              <button
                disabled={update.loading}
                className={`custom-primary-btn ${
                  update.loading && "bg-gray-600"
                }  flex items-center py-2 hover:translate-y-1`}
                onClick={createUpdate}
                // onClick={uploadImage}
                // onClick={uploadVideo}
              >
                Create Update
                {update.loading && (
                  <span className="ml-2">
                    <LoadingOutlined />
                  </span>
                )}
              </button>
              }
            </div>
          </div>
          {update.loading && (
            <div className="absolute inset-0 w-full h-full bg-black/50 ">
              <div className="w-full h-full flex justify-center items-center">
                <Progress type="circle" percent={percentUploaded} />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
    
  );
}

export default UpdateCard;

function renderEducationLevel() {
  const edLevels = ["Primary", "Secondary", "Tertiary"];
  return edLevels.map((level) => {
    return <option value={level}>{level}</option>;
  });
}

function rederClassOrYear(level) {
  console.log("selected level: " + level);
  if (level == false) return <></>;
  let years = [];
  if (level == "Primary")
    years = [
      "Primary One",
      "Primary Two",
      "Primary Three",
      "Primary Four",
      "Primary Five",
      "Primary Six",
    ];

  if (level == "Secondary")
    years = ["JSS One", "JSS Two", "JSS Three", "SS One", "SS Two", "SS Three"];
  if (level == "Tertiary")
    years = [
      "100 Level",
      "200 Level",
      "300 Level",
      "400 Level",
      "500 Level",
      "Masters Degree",
      "Doctoral Degree",
    ];
  return years.map((year) => {
    return <option value={year}>{year}</option>;
  });
}
