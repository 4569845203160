import React, { useState } from "react";
import { CloseCircleTwoTone } from "@ant-design/icons";
import AxiosInstance from "../../../../AxiosInstance/AxiosInstance";
import Toast from "../../../../util/toast";
import DarkOverlay from "../../../DarkOverlay";

function CreateService({ toggleCreateServiceModal }) {
  const [service, setService] = useState({
    name: "",
    title: "",
    cost: 0,
    duration: "",
    description: "",
    targetUsers: "",
    serviceType: "",
    careerPath: "",
  });

  const handleChange = (e) => {
    setService({ ...service, [e.target.name]: e.target.value });
  };
  const createService = async (e) => {
    e.preventDefault();
    // return alert(JSON.stringify(service));
    service.title = service.name;
    const resp = await AxiosInstance.post("/api/service/create", service);
    if (resp.data.error) {
      return Toast(resp.data.message, "error");
    } else {
      setService({
        name: "",
        title: "",
        cost: 0,
        duration: "",
        description: "",
        targetUsers: "",
        serviceType: "",
        careerPath: "",
      });
      return Toast("Service created successsfully!", "success");
    }
  };
  return (
    <React.Fragment>
      {" "}
      <div className="absolute top-12 z-50 md:left-[40%] p-8 pb-20 md:w-1/3 w-10/12 h-min bg-white text-gray-600 shadow-md rounded-lg ">
        <div className="w-full h-10 flex justify-between pr-2">
          <div className="pl-[30%] text-md font-semibold text-[#1D6DAE]">
            Create a new service
          </div>
          <CloseCircleTwoTone onClick={toggleCreateServiceModal} />
        </div>
        <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
          <select
            name="serviceType"
            className="w-1/2 bg-gray-200 outline-none rounded-lg px-2 py-2 "
            onChange={handleChange}
            required
          >
            <option className="font-semibold">--service type--</option>
            <option value="eas">Education Advisory Serv</option>
            <option value="rmsc">Recruitment MS corporate</option>
            <option value="rmsi">Recruitment MS Individual</option>
            <option value="pcms">Personel Competence Mgmt Serv</option>
            <option value="tcms">Teachers Competence Mgmt Serv</option>
            <option value="sfas">Study Finance Advisory Serv</option>
            <option value="dlds">Digital Literacy Development Serv</option>
            {/* <option value="cms">CMS</option> */}
            <option value="lms">Leadership Mgmt Serv</option>
            <option value="smas">School Mgmt Advisory Serv</option>
            {/* <option value=""></option> */}
          </select>
          <input
            type="text"
            name="name"
            placeholder="name e.g EAS Annual"
            onChange={handleChange}
            className="w-1/2 bg-gray-200 outline-none rounded-lg px-2 py-2 "
          />
        </div>
        {service.serviceType === "eas" && (
          <div className="flex px-2 mt-2 w-full ">
            <select
              name="careerPath"
              className="w-full bg-gray-200 outline-none rounded-lg py-2 pl-[25%]"
              onChange={handleChange}
              required
            >
              <option className="font-semibold">
                --preffered career path--
              </option>
              <option value="Aeronauttical Engineering">
                Aeronauttical Engineering
              </option>
              <option value="Medicine and Surgery">Medicine and Surgery</option>
              <option value="Software Engineering">Software Engineering</option>
              <option value="Mechanical Engineering">CMS</option>
              <option value="Law">Law</option>
              {/* <option value=""></option> */}
            </select>
          </div>
        )}
        <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
          <input
            type="number"
            name="cost"
            onChange={handleChange}
            placeholder="service cost"
            className="w-1/2 bg-gray-200 outline-none rounded-lg px-2 py-2 "
          />
          <input
            type="text"
            name="duration"
            onChange={handleChange}
            placeholder="duration e.g annual"
            className="w-1/2 bg-gray-200 outline-none rounded-lg px-2 py-2 "
          />
        </div>
        <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
          <textarea
            name="targetUsers"
            onChange={handleChange}
            rows="2"
            placeholder="Target users e.g student,teacher,company"
            className="w-full bg-gray-200 outline-none rounded-lg px-2 py-2 "
          ></textarea>
        </div>
        <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
          <textarea
            name="description"
            onChange={handleChange}
            rows="4"
            placeholder="Enter a catchy description of service here!"
            className="w-full bg-gray-200 outline-none rounded-lg px-2 py-2 "
          ></textarea>
        </div>

        <div className="flex px-2 mt-2 w-full justify-end">
          <button onClick={createService} className="custom-primary-btn">
            Create Service
          </button>
        </div>
      </div>
      <DarkOverlay density={70} z="z-0" />
    </React.Fragment>
  );
}

export default CreateService;
