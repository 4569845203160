import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Toast from "../../../util/toast";
import UpdateCard from "./UpdateCard";
import { TimePicker, DatePicker, Checkbox, Progress } from "antd";
import moment from "moment";

import {
  LoadingOutlined,
  UploadOutlined,
  FileImageFilled,
  VideoCameraOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  UpCircleTwoTone,
} from "@ant-design/icons";
import { Modal } from "react-bootstrap";

function UpdateView(props) {
  const imgRef = useRef(null);
  const d = new Date();
  const t = d.getTime();
  const time = new Date(t).toISOString().slice(11,19);
  const [data, setData] = useState(props.props);
  const [view, setView] = useState(false);
  const user = useSelector((state) => state.user.user);
    //console.log(user);
    // console.log(props);
    //Upload files urls
  const separateOut = subs => {
    if(!subs.length) {
      return [];
    }
    const resp = {};
    const keys = Object.keys(subs[0]);
    keys.forEach(key => {
      subs.forEach(el => {
        if(resp.hasOwnProperty(key)){
          resp[key].push(el[key])
        } else {
          resp[key] = [el[key]];
        }
      })
    });
    return resp;
  }

  const removeDuplicates = arr => {
    return arr.filter((item, index) => arr.indexOf(item) === index)
  }

  const notify = (message, type) => {
    return Toast(message, type);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant = "white">
        <Modal.Title id = "contained-modal-title-vcenter">
          <div className="text-center w-full row ml-2">
            <div className = 'w-full bg-white h-16 flex flex-row justify-between items-center my-2 px-3 rounded'>
              <label className="font-bold text-xl capitalize">Rank: {user.adminRank}</label>
              {user.adminType == 'advisory' &&
                <label className="font-bold text-xl ml-40 capitalize">
                  Radgivande (Advisor)
                </label>
              }
              {user.adminType == 'operations' &&
                <label className="font-bold text-xl ml-40 capitalize">
                  Verksamheschef (Operations Management)
                </label>
              }
              {user.adminType == 'exchequer' &&
                <label className="font-bold text-xl ml-40 capitalize">
                  Staskassan (Exchequer)
                </label>
              }
            </div>
            <label className="text-black ml-3 font-bold text-2xl">Title: {data.title}</label>
            <div className="ml-3 mt-4 table-responsive bg-white">
                <table className="table table-hover">
                    <tbody className="text-black text-sm capitalize text-start">
                        <tr>
                            <td className="p-1">Admin Rank</td>
                            <td className="p-1 text-blue-800">{data.adminRank}</td>
                        </tr>
                        <tr>
                            <td className="p-1">Country</td>
                            <td className="p-1 text-blue-800">{data.country}</td>
                        </tr>
                        <tr>
                            <td className="p-1">Subject</td>
                            <td className="p-1 text-blue-800">{data.subject}</td>
                        </tr>
                        <tr>
                            <td className="p-1">Program Type</td>
                            <td className="p-1 text-blue-800">{data.serviceId}</td>
                        </tr>
                        <tr>
                            <td className="p-1">Update Type</td>
                            <td className="p-1 text-blue-800">{data.updateType}</td>
                        </tr>
                        <tr>
                            <td className="p-1">Date of Publication</td>
                            <td className="p-1 text-blue-800">{new Date(data.createdAt).toISOString().slice(0,10)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>     
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" w-11/12  lg:w-[100%] h-full mx-auto  py-4 px-4 bg-gray-100 shadow-lg rounded-lg relative">
            <div className="w-full h-min mx-auto">
              <div className="w-full rounded text-justify font-normal text-sm text-slate-900 mt-4">
                {data.text}
              </div>
              <img className="w-full h-min rounded" src = {data.images[0]} alt = "header-img" />
            </div>  
            {user.country == data.country && user.adminRank == "legate" || user.adminRank == data.adminRank ?
                <button 
                    onClick = {() => (
                        setView(true)
                    )}
                    className="bg-success1 text-white rounded w-28 h-10 p-2 mt-4 outline-none outline-0">
                    Edit Article
                </button> : "" 
            }
            {view && <UpdateCard 
                show={view}
                props = {data}
                onHide = {() => setView(false)}
            />}
        </div>
        
      </Modal.Body>
    </Modal>
    
  );
}

export default UpdateView;

