import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import MenuItem from "./MenuItem";

const { Header, Sider, Content } = Layout;

const SponsoringOrgHome = ({ history }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const user = useSelector((state) => state.user.user);

  return (
    <Layout
      style={{
        minHeight: "100vh",
        maxHeight: "100%",
        flexWrap: "nowrap",
        position: "relative",
      }}
    >
      <Sider
        trigger={null}
        className="sidebar-bg hidden md:block"
        collapsible
        collapsed={collapsed}
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <MenuItem defaultKey="1" />
      </Sider>
      <Layout>
        <Header className="row pt-2 head">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3 hidden md:block",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          <h3 className="mt-1 ">{user.name}</h3>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 10,
            // background: "#fff",
            minHeight: 280,
          }}
        >
          <div className="w-full mx-auto md:w-[94%] h-full flex flex-col relative ">
            {/* top section */}
            <div className="w-full flex flex-col p-3  bg-white rounded-lg mb-4">
              <div className="w-full flex justify-start items-center space-x-3">
                <div className="w-[100px] h-[100px]">
                  <img
                    src="/sdgs.PNG"
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="w-[78%] border-b pb-4 mt-3 text-2xl font-bold text-esgrown ">
                  Your UN SDG 4 Campaign Status
                </div>
              </div>
              <div className="w-full flex justify-between px-4 mt-3">
                <div className="w-[28%] text-xl">
                  Country:{" "}
                  <span className="text-lg font-semibold">Nigeria</span>
                </div>
                <select className="w-[28%] px-2 py-2 border-2 rounded-lg focus:border-esgrown ">
                  <option value="">All States</option>
                </select>

                <select className="w-[28%] px-2 py-2 border-2 rounded-lg focus:border-esgrown ">
                  <option value="">All Cities</option>
                </select>
              </div>
              <div className="w-full border-b my-3"></div>
              <div className="w-full flex justify-between pr-4 pl-8">
                <div className="w-[30%] rounded-lg shadow border-2 border-black/10 h-[140px] flex flex-col items-center justify-between py-4">
                  <p className="text-xl font-bold">Captured Targets</p>
                  <p className="text-xl font-bold">1000 schools</p>
                </div>
                <div className="w-[60%] border  shadow-sm h-[140px] flex justify-around items-center">
                  <div className="w-[42%] shadow rounded-lg h-[90%] flex flex-col items-center justify-between py-4">
                    <p className="text-xl font-bold">Teachers</p>
                    <p className="text-xl font-bold">1000 </p>
                  </div>
                  <div className="w-[42%] shadow rounded-lg h-[90%] flex flex-col items-center justify-between py-4">
                    <p className="text-xl font-bold">Students</p>
                    <p className="text-xl font-bold">1000 </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-between pr-4 pl-8 mt-10">
                <div className="text-xl">
                  Sponsored SDG4 Advisors: <span className="font-bold">40</span>
                </div>
              </div>
            </div>
            {/* second section  */}
            <div className="w-full flex flex-col  bg-white rounded-lg p-3">
              <div className="w-full flex justify-start items-center space-x-3">
                <div className="w-12 h-[44px] ml-8 rounded-full border-4 border-esgrown "></div>
                <div className="w-full border-b pb-4 mt-3 text-2xl font-bold flex space-x-32">
                  <div className=" "> General Campaign Status</div>
                  <div className=" ">Participating Countries: 50</div>
                </div>
              </div>
              <div className="w-full flex justify-around py-3">
                {/* first panel  */}
                <div className="w-[46%] rounded-lg shadow-md h-[144px] flex flex-col pl-3 justify-between py-4">
                  <p className="text-xl font-bold text-esgrown mb-1 px-3">
                    Country Statistics
                  </p>
                  <div className="flex justify-between px-3">
                    <div className="text-xl font-semibold">
                      Captured Schools
                    </div>

                    <div className="text-xl font-semibold"> 125,000</div>
                  </div>
                  <div className="flex justify-between px-3">
                    <div className="text-xl font-semibold">Teachers</div>

                    <div className="text-xl font-semibold"> 5000,000</div>
                  </div>
                  <div className="flex justify-between px-3">
                    <div className="text-xl font-semibold">Students</div>

                    <div className="text-xl font-semibold"> 25,000,0000</div>
                  </div>
                </div>

                {/* second panel  */}

                <div className="w-[46%] rounded-lg shadow-md h-[144px] flex flex-col pl-3 justify-between py-4">
                  <p className="text-xl font-bold text-esgrown mb-1 px-3">
                    Global Statistics
                  </p>
                  <div className="flex justify-between px-3">
                    <div className="text-xl font-semibold">
                      Captured Schools
                    </div>

                    <div className="text-xl font-semibold"> 145,000</div>
                  </div>
                  <div className="flex justify-between px-3">
                    <div className="text-xl font-semibold">Teachers</div>

                    <div className="text-xl font-semibold"> 505,040</div>
                  </div>
                  <div className="flex justify-between px-3">
                    <div className="text-xl font-semibold">Students</div>

                    <div className="text-xl font-semibold"> 500,000,000</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SponsoringOrgHome;
