import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";

export const removeAssociate = createAsyncThunk("removeAssociate", async (associateId)=>{
    const route = `/api/user/remove-associate/${associateId}`;
    const { data } = await AxiosInstance.delete(route);
    return data
})

export const getTotalUCBIAmountForTeachers = createAsyncThunk("get/TotalAmountForTeachers",  async () => {
          const url = `/api/ucbi/get-total-ucbi-amount-for-teachers`;
          try {
            const { data } = await AxiosInstance.get(url);
            return data.totalUCBIAmountForTeachers
          }catch(err){
          }    
})

export const getTotalProcessedUCBIAmountForTeachers = createAsyncThunk("get/TotalProcessedUCBIAmountForTeachers",  async () => {
          const url = `/api/ucbi/get-total-processed-ucbi-amount-for-teachers`;
          try {
            const { data } = await AxiosInstance.get(url);
            return data.totalProcessedUCBIAmountForTeachers
          }catch(err){
          }    
})

export const getTotalProcessedUCBIAmountForAssociates = createAsyncThunk("get/TotalProcessedUCBIAmountForAssociates",  async () => {
          const url = `/api/ucbi/get-total-processed-ucbi-amount-for-associates`;
          try {
            const { data } = await AxiosInstance.get(url);
            return data.totalProcessedUCBIAmountForAssociates
          }catch(err){
          }    
})

export const getTotalUCBIAmountForAssociates = createAsyncThunk("get/TotalUCBIAmountForAssociates",  async (countryQuery="") => {
          const url = `/api/ucbi/get-total-ucbi-amount-for-associates`;
          try {
            const { data } = await AxiosInstance.get(url);
            return data.totalUCBIAmountForAssociates
          }catch(err){
          }    
})

export const getAllTeachersWitUcbis = createAsyncThunk("get/AssociatesProcessedByCountry", async (month="") => {
    const url = `/api/ucbi/get-teachers-with-ucbi?month=${month}`;
    try {
      const { data } = await AxiosInstance.get(url);
      return data.formattedUcbiTeachers
    } catch (error) {
    }
  });

export const getAllAssociatesWitUcbis = createAsyncThunk("get/AssociatesWitUcbis", async (month="") => {
    const url = `/api/ucbi/get-associates-with-ucbi?month=${month}`;
    try {
      const { data } = await AxiosInstance.get(url);
      return data.formattedUcbiAssociate
    } catch (error) {
    }
  });
export const getAllAssociatesWithProcessedUcbis = createAsyncThunk("get/AllAssociatesWithProcessedUcbis", async (month="") => {
    const url = `/api/ucbi/get-associates-with-processed-ucbi?month=${month}`;
    try {
      const { data } = await AxiosInstance.get(url);
      return data.formattedProcessedUcbiAssociate
    } catch (error) {
    }
  });
export const getAllTeachersWithProcessedUcbis = createAsyncThunk("get/AllTeachersWithProcessedUcbis", async (month="") => {
    const url = `/api/ucbi/get-teachers-with-processed-ucbi?month=${month}`;
    try {
      const { data } = await AxiosInstance.get(url);
      return data.formattedProcessedUcbiTeachers
    } catch (error) {
    }
  });
export const getUcbiHistory = createAsyncThunk("get/UcbiHistory", async (user_id) => {
    const url = `/api/ucbi/payment-history/${user_id}`;
    try {
      const { data } = await AxiosInstance.get(url);
      return data.formattedUcbis
    } catch (error) {
    }
  });



const initState = {
  componentsStates: {
    removeAssociatePrompt: false,
    showListOfProccessedAssociates: false,
    showViewAssociateModal: false,
    hidePopOver: false,
    openProcessedAssModal: false,
    showListOfInProcessingAssociates: false,
    showProcessedAssociateDetailsModal: false,
    associateContactDropDown: false,
    showUcbiDetailModal: false,
    showUcbiHistoryListModal: false,
    showUcbiTransactionModal: false,
    markedAsProcessed: false,
  },
  allTeachersWitUcbis: [],
  allAssociatesWitUcbis: [],
  ucbiUserDetails: "",
  associatesProcessedByCountry: [],
  allTeachersWithProcessedUcbis: [],
  isLoading: false,
  allAssociatesWithProcessedUcbis:[],
  ucbiHistory: [],
  totalUCBIAmountForTeachers: "",
  totalProcessedUCBIAmountForTeachers: "",
  totalUCBIAmountForAssociates: "",
  totalProcessedUCBIAmountForAssociates: "",
  ucbiTransactionDetails: ""
};

export const ucbiSlice = createSlice({
  name: "ucbi",
  initialState: initState,
  reducers:{
    setShowUcbiDetailModal: (state, action)=>{
        state.componentsStates.showUcbiDetailModal = action.payload
    },
    setRemoveAssociatePrompt:(state, action)=>{
        state.componentsStates.removeAssociatePrompt = action.payload
    },
    setShowUcbiHistoryListModal:(state, action)=>{
        state.componentsStates.showUcbiHistoryListModal = action.payload
    },
    setShowViewAssociateModal:(state, action)=>{
        state.componentsStates.showViewAssociateModal = action.payload
    },
    setHidePopOver:(state, action)=>{
        state.componentsStates.hidePopOver = action.payload
    },
    setOpenProcessedAssModal:(state, action)=>{
        state.componentsStates.openProcessedAssModal = action.payload
    },
    setShowListOfInProcessingAssociates:(state, action)=>{
        state.componentsStates.showListOfInProcessingAssociates = action.payload
    },
    setAssociatesCountry:(state, action)=>{
        state.associatesCountry = action.payload
    },
    setUcbiUserDetails:(state, action)=>{
        state.ucbiUserDetails = action.payload
    },
    setShowProcessedAssociateDetailsModal:(state, action)=>{
        state.associateDetails = action.payload
    },
    setAssociateContactDropDown:(state, action)=>{
        state.associateContactDropDown = action.payload
    },
    setShowSelectedAssociateDetails:(state, action)=>{
        state.showSelectedAssociateDetails = action.payload
    },
    setShowUcbiTransactionModal:(state, action)=>{
        state.componentsStates.showUcbiTransactionModal = action.payload
    },
    setUcbiTransactionDetails:(state, action)=>{
        state.ucbiTransactionDetails = action.payload
    },
    setMarkedAsProcessed:(state, action)=>{
        state.ucbiTransactionDetails = action.payload
    },
  },
  extraReducers:(builder) => {
    builder
      .addCase(getAllTeachersWitUcbis.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getAllTeachersWitUcbis.fulfilled, (state, action) => {
          state.allTeachersWitUcbis = action.payload
          state.isLoading = false
      })
      .addCase(getTotalUCBIAmountForTeachers.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getTotalUCBIAmountForTeachers.fulfilled, (state, action) => {
          state.totalUCBIAmountForTeachers = action.payload
          state.isLoading = false
      })
      .addCase(getTotalUCBIAmountForAssociates.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getTotalUCBIAmountForAssociates.fulfilled, (state, action) => {
          state.totalUCBIAmountForAssociates = action.payload
          state.isLoading = false
      })
      .addCase(getAllAssociatesWitUcbis.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getAllAssociatesWitUcbis.fulfilled, (state, action) => {
          state.allAssociatesWitUcbis = action.payload
          state.isLoading = false
      })
      .addCase(getTotalProcessedUCBIAmountForAssociates.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getTotalProcessedUCBIAmountForAssociates.fulfilled, (state, action) => {
          state.totalProcessedUCBIAmountForAssociates = action.payload
          state.isLoading = false
      })
      .addCase(getTotalProcessedUCBIAmountForTeachers.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getTotalProcessedUCBIAmountForTeachers.fulfilled, (state, action) => {
          state.totalProcessedUCBIAmountForTeachers = action.payload
          state.isLoading = false
      })
      .addCase(getAllAssociatesWithProcessedUcbis.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getAllAssociatesWithProcessedUcbis.fulfilled, (state, action) => {
          state.allAssociatesWithProcessedUcbis = action.payload
          state.isLoading = false
      })
      .addCase(getAllTeachersWithProcessedUcbis.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getAllTeachersWithProcessedUcbis.fulfilled, (state, action) => {
          state.allTeachersWithProcessedUcbis = action.payload
          state.isLoading = false
      })
      .addCase(getUcbiHistory.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getUcbiHistory.fulfilled, (state, action) => {
          state.ucbiHistory = action.payload
          state.isLoading = false
      })
    }
  },
);

export const {setRemoveAssociatePrompt, 
             setUcbiUserDetails,
             setShowUcbiDetailModal,
             setShowUcbiHistoryListModal,
             setShowUcbiTransactionModal,
             setUcbiTransactionDetails,
             setMarkedAsProcessed
            } = ucbiSlice.actions
export default ucbiSlice;
