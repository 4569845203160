import React from "react";

export default function B2B({ toggleAddCitiesModal }) {
  return (
    <div>
      <div className="container-fluid">
        <div className="w-full mb-2 flex justify-between">
          <div>Market Overview</div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {/* <div className="col-lg-10"> */}
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="col-lg-12 bg-white pl-2 pt-4 pb-4">
                      <div className="col-12">
                        <h5 className="mb-4">0</h5>
                        <h6 className="">Teachers</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-lg-12 bg-white pl-2 pt-4 pb-4">
                      <div className="col-12">
                        <h5 className="mb-4">0</h5>
                        <h6 className="">Teachers</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-lg-12 mt-4">
                      <div className="p-4 bg-success1">
                        <h6 className="text-white">
                          Global individual customer base: <br /> 2,0000,000
                        </h6>
                      </div>
                      <div className="mt-3">
                        <div className="table-responsive bg-white">
                          <table className="table table-hover table-striped">
                            <thead className="bg-success1 text-white">
                              <tr>
                                <th>Country</th>
                                <th>Customers</th>
                                <th>Admin</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Nigeria</td>
                                <td>10,000</td>
                                <td>James Brown</td>
                              </tr>
                              <tr>
                                <td> Spain</td>
                                <td> 20,000</td>
                                <td>Uche Kalu</td>
                              </tr>
                              <tr>
                                <td>Nigeria</td>
                                <td>20,000</td>
                                <td>James Brown</td>
                              </tr>
                              <tr>
                                <td>Nigeria</td>
                                <td>20,000</td>
                                <td>James Brown</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="p-4 bg-success1 ">
                  <h6 className="text-white">Active Subscription </h6>
                </div>{" "}
                <div className="col-12 bg-white pt-3 pb-2">
                  <h5 className="mb-3">0</h5>
                  <p className="">Education advisory</p>
                </div>
                <div className="col-12 bg-white pt-3 pb-2">
                  <h5 className="mb-3">0</h5>
                  <p className="">Study finance advisory</p>
                </div>
                <div className="col-12 bg-white pt-3 pb-2">
                  <h5 className="mb-3">0</h5>
                  <p className="">Recruitment mgmt</p>
                </div>
                <div className="col-12 bg-white pt-3 pb-2">
                  <h5 className="mb-3">0</h5>
                  <p className="">Leadership mgmt</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
