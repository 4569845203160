import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { usePaystackPayment } from "react-paystack";
import { setUser } from "../../redux/slices/userSlice";
import currencyFormatter from 'currency-formatter';
import PromoCodeConfirmationModal from "../campaigns/steka/modals/PromoCodeConfirmationModal";
import PromoCodeInputBox from "../campaigns/steka/modals/PromoCodeInputBox";
import AddCardIcon from '@mui/icons-material/AddCard';
import { Layout, Menu, Dropdown, Space, Checkbox } from "antd";
import Icon, {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { Popover } from "antd";
import { useSelector } from "react-redux";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";
import Toast from "../../util/toast";
import { getUpdatedDataOfUser } from "../../util/services";
import DarkOverlay from "../DarkOverlay";
import LoadingServices from "../LoadingServices";
import prices from "../../util/prices.json";
import DropDownMenu from "../reusable/DropDownMenu";
import ClickAwayListener from "react-click-away-listener";
import OnlineUser from "../reusable/OnlineUser";
import SmallScreenDrawer from "../SmallScreenDrawer";
import NewServiceCard from "./NewServiceCard";
import CustomSiderMenu from "../reusable/CustomSiderMenu";
// for (const key in prices) {
// }

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const handleClick = ({ key }) => {
  //you can perform setState here
};

const Full = (props) => {
  const [collapse, setCollapse] = useState(false);
  const [preferredPackage, setPreferredPackage] = useState(null);
  const [introducedByTeacher, setIntroducedByTeacher] = useState(false);
  const [teacherCode, setTeacherCode] = useState(null);
  const [duration, setDuration] = useState(null);
  const [careerPath, setCareerPath] = useState(null);
  const [fields, setFields] = useState(null);
  const user = useSelector((state) => state.user.user);
  const subService = {
    serviceType:"tcms"
  };
  const dispatch = useDispatch();
  const [services, setServices] = useState([]);
  const [openSubModal, setopenSubModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [showSmallScreenDrawer, setShowSmallScreenDrawer] = useState(false);
  const [showPromoCodeConfirmationmodal, setShowPromoCodeConfirmationmodal] = useState(false);
  const [showPromoCodeInputBox, setShowPromoCodeInputBox] = useState(false);
  const [showPopOverContent, setShowPopOverContent] = useState(true);
  const [servicePriceDetails, setServicePriceDetails] = useState(null);
  const [singleService, setSingleService] = useState(null);
  const [subscriptionArray, setSubscriptionArray] = useState([]);
  const [onPayStackSuccess, setOnPayStackSuccess] = useState(false);
  const [popOverOpen, setPopOverOpen] = useState(false);
  const toggleSubModal = () => setopenSubModal((prev) => !prev);

  //TCMS sub logic
  const [noOfSelectedTeachers, setnoOfSelectedTeachers] = useState(0);
  const [teachers, setTeachers] = useState(null);
  const [idOfSelectedTeachers, setidOfSelectedTeachers] = useState(new Array());
  //TCMS sub logic ends

  const subscribeToService = async (service, referenceId) => {
    // return alert(JSON.stringify(teacherCode));
    if (introducedByTeacher && String(teacherCode).length < 1) {
      return Toast("Ploase enter Teacher code", "error");
    }
    const route = `/api/subscription/subscribe/userId/${user?.userId}/serviceId/${service?.serviceId}`;
    const data = {
      paid: true,
      duration: duration,
      referenceId: referenceId,
      serviceType: service.serviceType,
      careerPath: careerPath,
      preferredPackage: preferredPackage,
      teacherCode: teacherCode,
      staff: idOfSelectedTeachers,
    };
    const resp = await AxiosInstance.post(route, { data });
    if (resp.data.error) {
      setopenSubModal(false);
      return Toast(resp.data.message, "error");
    }
    setopenSubModal(false);
    Toast("Subscribed successfully!", "success");
    const u = await getUpdatedDataOfUser(user._id);
    if (u && u?.user) {
      // dispatch.setUser(u.user);
      dispatch(setUser(u.user));
    }
  };

  const config = {
    reference: new Date().getTime().toString(),
    email: user.email,
    amount:
      subService?.serviceType !== "tcms"
        ? calculateCost(prices, subService, user, duration) * 100
        : calculateCost(prices, subService, user, duration) *
        noOfSelectedTeachers *
        100, //* 100 converts sub amount from naira to to kobo
    publicKey: "pk_test_7b545e0d7a1aaa0e39782e7d5aa7e9595a8082fc",
  };
  // you can call this function anything

  const paystackOnSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    const data = {
      reference: reference.reference,
    };
    AxiosInstance.post("/api/subscription/subscribe/reference", { data })
      .then((response) => {
        //Reference is first saved so that it could be verified before creating a subscription document
        subscribeToService(subService, reference.reference).catch((error) => {
          return Toast(error.message, "error");
        });
      })
      .catch((error) => Toast(error.message, "error"));
  };

  // const paymentSucessAction = async () => {
  //   // Implementation for whatever you want to do with reference and after success call.
  //   await subscribeToService(subService, reference.reference);
  // };

  // you can call this function anything
  const paystackOnClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
  };

  // paystack payment function

  const initializePayment = usePaystackPayment(config);
  const paystackCheckout = async (service) => {
    if (!duration) {
      return Toast("Please select Subscription duration!", "error");
    }
    if (service.serviceType === "eas" && !careerPath) {
      return Toast("Please select your preferred career path!", "error");
    }
    if (service.serviceType === "dlds" && !preferredPackage) {
      return Toast("Please select your preferred Package!", "error");
    }
    try {
      initializePayment(paystackOnSuccess, paystackOnClose);
    } catch (error) {
      Toast(error.message, "error");
    } finally {
      setDuration(null);
      setCareerPath(null);
      setPreferredPackage(null);
    }
  };

  const onChangeSelectedTeachers = (e) => {
    // Two possible actions to take when any checkbox state changes. These actions are mutually exclusive though.
    // ACTION 1:
    if (e.target.checked && !idOfSelectedTeachers.includes(e.target.value)) {
      // if user checks a teacher that's not added to the list yet, then add it
      idOfSelectedTeachers.push(e.target.value);
      setnoOfSelectedTeachers(idOfSelectedTeachers.length);
    }
    // ACTION 2:

    if (
      e.target.checked === false &&
      idOfSelectedTeachers.includes(e.target.value)
    ) {
      // if user unselect or deselect a teacher that's already added to the list, remove the teachers id from list
      const filteredIds = idOfSelectedTeachers.filter(
        (teacher) => teacher !== e.target.value
      );
      setidOfSelectedTeachers(filteredIds);
      setnoOfSelectedTeachers(filteredIds.length);
    }
  };

  useEffect(() => {
    const isSubscribed = async () => {
      const url = `api/update/check-subscription?userId=${user?._id}`
      const { data } = await AxiosInstance.get(url)
      console.log(data.result,"result")
      setSubscriptionArray(data.result)
    }
    isSubscribed()
  }, [onPayStackSuccess])

  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      const url = "/api/service/get-services"
      const resp = await AxiosInstance.get(url);
      if (resp.data && resp.data.services) {
        const formatService = resp?.data?.services.filter(service => service.serviceCategory === user?.accountType)
        setServices(formatService);
        setLoading(false);
      }
    };
    fetchServices();
  }, []);

  useEffect(() => {
    const fetchFields = async () => {
      setLoading(true);
      const resp = await AxiosInstance.get("/api/user/get-fields");
      if (resp.data && resp.data.fields) {
        setFields(resp.data.fields);
        setLoading(false);
      }
    };
    fetchFields();
  }, []);
  // Fetch my school (org) Teachers
  useEffect(() => {
    const fetchTeachers = async () => {
      setLoading(true);
      const route = "/api/user/get-confirmed-teachers";
      const { data } = await AxiosInstance.post(route);
      if (data && data.staff) {
        setTeachers(data.staff);
        setLoading(false);
      }
    };

    if (user.userType === "school") {
      fetchTeachers();
    }
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  function disableCheckoutBtn() {
    return (
      subService.serviceType === "tcms" &&
      user.userType === "school" &&
      noOfSelectedTeachers < 1
    );
  }
  return (
    <Layout
      style={{ minHeight: "100vh", maxHeight: "100%", flexWrap: "nowrap" }}
    >
      <Sider
        trigger={null}
        className="sidebar-bg hidden md:block"
        collapsible
        collapsed={collapse}
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <CustomSiderMenu
          collapsed={collapse}
          handleClick={handleClick}
          user={user}
        />
      </Sider>
      <Layout>
        <Header className="row pt-2 head">
          {React.createElement(
            collapse ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3 hidden md:block",
              onClick: () => setCollapse(!collapse),
            }
          )}
          {/* This will only show for small devices  ////////////////////////*/}
          <div
            onClick={() => {
              setShowSmallScreenDrawer(!showSmallScreenDrawer);
            }}
            className="min-w-min h-full flex items-center cursor-pointer md:hidden"
          >
            <MenuFoldOutlined className="text-lg mb-2 mr-2" />
          </div>
          {/* ////////////////////////////////////////////////////// */}
          <h3 className="mt-2 wel">Subscription</h3>
          <ClickAwayListener onClickAway={() => setShowAccountMenu(false)}>
            <div className="ml-auto">
              <DropDownMenu user={user} show={showAccountMenu} />
              <div onClick={() => setShowAccountMenu(!showAccountMenu)}>
                <OnlineUser user={user} />
              </div>
            </div>
          </ClickAwayListener>
        </Header>
        <Content
          className="relative"
          style={{
            margin: "24px 16px",
            padding: 10,

            // background: "#fff",
            minHeight: 280,
          }}
        >
          <div className="container-fluid">
            <div className="w-full bg-white rounded-md p-4 mb-4">
              <div className="text-sm md:text-3xl text-[#00AFEF]  py-4 px-3">
                Premium Service Subscriptions
              </div>
              <div className="  mx-auto border-b border-b-[#588a9c] w-[98%]"></div>
              <div className="mt-4 text-xs  md:text-sm">
                All our premium services are aligned to this unique mission is
                to bridge the divides that are based on strong education that
                equips people with capability to create value and the
                competencies of people in leveraging knowledge and skills
                through learning into actual creation of value or lack of it.
              </div>
            </div>
            <div className="">
              {
                services.map((service, index) => {
                  return (
                    <div key={index} className="bg-white rounded-md h-full w-full py-2 mb-[70px] flex-row md:flex md:justify-between px-2">
                      <div className="mt-1 mx-3 flex-1">
                        <img className="w-auto h-auto" src={service.image} alt={service.title} />
                      </div>
                      <div className="mt-2 flex-1 items-end">
                        <div className="text-[#00AFEF] text-lg font-bold mb-2">{service.title}</div>
                        <div>{service.description}</div>
                        <div className="h-[100px] mt-2 mb-2 flex justify-end items-end">

                          {subscriptionArray?.indexOf(service?._id) === -1 ?
                            <ClickAwayListener onClickAway={() => {}}>
                              <div>
                                <Popover
                                  placement="left"
                                  trigger="click"
                                  content={
                                    showPopOverContent && (
                                      showPromoCodeConfirmationmodal
                                        ?
                                        <div className="shadow-xl">
                                          <PromoCodeConfirmationModal
                                            setShowPromoCodeConfirmationmodal={setShowPromoCodeConfirmationmodal}
                                            setShowPromoCodeInputBox={setShowPromoCodeInputBox}
                                            setShowPopOverContent={setShowPopOverContent}
                                            serviceId={service?._id}
                                            user={user}
                                            servicePriceDetails={servicePriceDetails}
                                            setOnPayStackSuccess={setOnPayStackSuccess}
                                          />
                                        </div>
                                        :
                                        <div className="min-w-min min-h-min flex flex-col items-start justify-start space-y-2 ">

                                          {service.prices.map(price => {
                                            return (

                                              <>
                                                <div className="flex space-x-2 menu-item  items-center bg-sky-200 mt-1 h-8 px-2 w-full">
                                                  <AddCardIcon size={20} />
                                                <button
                                                  onClick={() => {
                                                    
                                                    setSingleService(service)
                                                    setServicePriceDetails(price)
                                                    setShowPopOverContent(true)
                                                    if(service._id==="6504e998ee56fc4b181aed13" || service._id==="6504e998ee56fc4b181aecf5"){
                                                      setopenSubModal(true)
                                                      window.scrollTo({
                                                        top: 0,
                                                        left: 0,
                                                        behavior: "smooth",
                                                      });
                                                    }else{
                                                    setShowPromoCodeConfirmationmodal(true)
                                                    }

                                                  }
                                                  }
                                                  data-modal-target="staticModal" data-modal-toggle="staticModal"
                                                  // className="px-2 outline-none focus:outline-none rounded-full bg-slate-600 text-white transition-all duration-500 hover:-translate-y-1 hover:bg-slate-500 hover:text-white"
                                                >
                                                  {price.duration}  -  {currencyFormatter.format(price.price, { locale: 'en-NG' })}
                                                </button>
                                                </div>
                                                
                                              </>


                                            )

                                          })}
                                        </div>)
                                  }
                                >

                                  <div className="ml-2 cursor-pointer rounded py-1 px-2 bg-slate-600 text-white w-auto">
                                    Subscribe
                                  </div>
                                </Popover>

                              </div>
                            </ClickAwayListener>
                            : ""
                          }

                        </div>
                      </div>
                    </div>
                  )
                })
              }

              {
                showPromoCodeInputBox &&
                <div>
                  <PromoCodeInputBox
                    setShowPromoCodeInputBox={setShowPromoCodeInputBox}
                    setShowPopOverContent={setShowPopOverContent}
                    servicePriceDetails={servicePriceDetails}
                    user={user}
                    serviceId={singleService?.id}
                    setOnPayStackSuccess={setOnPayStackSuccess}
                  />
                </div>
              }

            

            </div>


            {/* {!loading && services && services.length > 0 ? (
              services.map((service) => (
                <NewServiceCard
                  key={service?.name}
                  service={service}
                  showSubModal={() => {
                    toggleSubModal();
                    window &&
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      });
                  }}
                />
              ))
            ) : (
              <div className={`w-full grid grid-cols-3 gap-4 mx-auto`}>
                {[...Array(9).fill(1)].map((_, i) => (
                  <LoadingServices key={i.toString()} />
                ))}
              </div>
            )} */}

            {services.length <= 0 && (
              <div className={`w-full grid grid-cols-3 gap-4 mx-auto`}>
                {[...Array(9).fill(1)].map((_, i) => (
                  <LoadingServices key={i.toString()} />
                ))}
              </div>)
            }
          </div>
        </Content>
      </Layout >
      {openSubModal && (
        <DarkOverlay density={70}>
          <div className="w-10/12 min-h-min md:w-1/3 mx-auto bg-white rounded-lg mt-8 p-4">
            <div className="w-full h-full ">
              {/* duration */}
              <div className="w-10/12 mx-auto flex justify-between mb-4">
                <div className="font-bold ">Select staff</div>
                <CloseCircleTwoTone onClick={toggleSubModal} />
              </div>
              <div className="w-full space-y-3">
                <div className="w-10/12 mx-auto mb-4 rounded-lg ">
                  {duration && (
                    <div className="w-full">
                      Subscription cost: N
                
                      { calculateCost(prices, subService, user, duration) *
                        1 *
                        noOfSelectedTeachers}
                    </div>
                  )}
                </div>
                <div className="w-10/12 mx-auto shadow-md p-2 rounded-lg">
                  <select
                    onChange={(e) => setDuration(e.target.value)}
                    name="duration"
                    id=""
                    required={true}
                    className="peer w-full outline-none"
                  >
                    {/* NB: rmsi-> Recruitment Management service for company does not have option for monthly duration  */}
                    <option className="invisible peer-invalid:visible text-red-700 font-light">
                      **select duration**
                    </option>
                    {/* <option>--select duration--</option> */}
                    <option value="annual">Annual</option>
                    <option value="biannual">Biannual</option>
                    <option value="quarterly">Quarterly </option>
                  
                  </select>
                </div>
                {/* tcms logic  */}
                {subService.serviceType === "tcms" &&
                  user.userType === "school" && 
                  (
                    <div className="w-10/12 mx-auto p-2  rounded-lg">
                      <div className="w-full h-full flex flex-wrap">
                        {teachers && Array.from(teachers).length > 0 ? (
                          Array.from(teachers).map((teacher) => (
                            <div className="w-[48%] min-h-max mt-2 flex items-center space-x-2 ">
                              <input
                                value={teacher._id}
                                type="checkbox"
                                checked={idOfSelectedTeachers.includes(
                                  teacher._id
                                )}
                                onChange={onChangeSelectedTeachers}
                                className="bg-blue-400"
                              />
                              <div>{teacher.name}</div>
                            </div>
                          ))
                        ) : (
                          <div className="text-red-500">
                            No staff in your org, yet!
                          </div>
                        )}
                        {/* <div className="w-[48%] flex items-center ">
                          <input type="checkbox" name="" />
                          <div>Name of Teacher</div>
                        </div>
                        <div className="w-[48%] flex items-center ">
                          <input type="checkbox" name="" />
                          <div>Name of Teacher</div>
                        </div> */}
                      </div>
                    </div>
                  )}

                {/* EAS  sub logic 
                {subService.serviceType === "eas" && (
                  <>
                    <div className="w-10/12 mx-auto shadow-md p-2 rounded-lg ">
                      <select
                        name="careerPath"
                        id=""
                        onChange={(e) => setCareerPath(e.target.value)}
                        className="w-full outline-none "
                      >
                        <option value="">
                          --select preferred career path --
                        </option>

                        {fields?.map((field) => (
                          <option value={field?._id}>{field?.name}</option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
                {subService.serviceType === "dlds" && (
                  <div className="w-10/12 mx-auto shadow-md p-2 rounded-lg">
                    <select
                      name="preferredPackage"
                      id=""
                      className="w-full outline-none"
                      onChange={(e) => setPreferredPackage(e.target.value)}
                    >
                      <option value="">--select preferred package --</option>
                      <option value="Package I">Package I</option>
                      <option value="Package II">Package II </option>
                      <option value="Package III">Package III</option>
                    </select>
                  </div>
                )}
                {(subService.serviceType === "eas" ||
                  subService.serviceType === "dlds") && (
                    <>
                      <div className="w-10/12 mx-auto flex items-center space-x-2">
                        <Checkbox
                          onChange={(e) =>
                            setIntroducedByTeacher(e.target.checked)
                          }
                        />
                        <div className="text-xs">
                          I was introduced to this service by a teacher
                        </div>
                      </div>

                      {introducedByTeacher && (
                        <div className="w-10/12 mx-auto flex items-center">
                          <input
                            name="teacherName"
                            placeholder="Enter Teacher's code e.g adah22"
                            id=""
                            onChange={(e) => setTeacherCode(e.target.value)}
                            className="w-full outline-none px-2 py-2 rounded-lg shadow-md"
                          ></input>
                        </div>
                      )}
                    </>
                  )} */}
              </div>
              {/* checkout  */}
              <div className="w-10/12 mx-auto mt-10 flex pb-10 ">
                <button
                  onClick={async () => {
                    await paystackCheckout(subService);
                  }}
                  disabled={disableCheckoutBtn()}
                  className={`w-full py-2 rounded-lg text-white ${disableCheckoutBtn() ? "bg-gray-300" : "bg-blue-400"
                    }  outline-none ring-0 hover:shadow-lg hover:shadow-blue-400 transition-all`}
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </DarkOverlay>
      )}
      {
        showSmallScreenDrawer && (
          <SmallScreenDrawer
            setShowSmallScreenDrawer={setShowSmallScreenDrawer}
            user={user}
          />
        )
      }
      
    </Layout >
    
  );
};

export default Full;

//calculate cost
function calculateCost(prices, subService, user, duration) {
  //    "annual"    // "biannual" // "quarterly" // "monthly"
  if (!duration) return;
  const price = prices[subService.serviceType];

  if (duration === "annual") {
    if (subService.serviceType === "tcms" && user.userType === "school") {
      //applying 50% discount for any school subscribing to tcms
      return price.annual * 0.5;
    }
    return price.annual;
  }

  if (duration === "biannual") {
    if (subService.serviceType === "tcms" && user.userType === "school") {
      //applying 50% discount for any school subscribing to tcms
      return price.biannual * 0.5;
    }
    return price.biannual;
  }

  if (duration === "quarterly") {
    if (subService.serviceType === "tcms" && user.userType === "school") {
      //applying 50% discount for any school subscribing to tcms
      return price.quarterly * 0.5;
    }
    return price.quarterly;
  }

  if (duration === "monthly") {
    if (subService.serviceType === "tcms" && user.userType === "school") {
      //applying 50% discount for any school subscribing to tcms
      return price.monthly * 0.5;
    }
    return price.monthly;
  }
}
