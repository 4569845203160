import { useState, useEffect } from "react";
import AxiosInstance from "../../../../AxiosInstance/AxiosInstance";
import currencyFormatter from 'currency-formatter';
import { usePaystackPayment } from "react-paystack";
import Toast from "../../../../util/toast";

const PromoCodeInputBox = ({ setShowPromoCodeInputBox,
    setShowPopOverContent,
    servicePriceDetails,
    serviceId,
    user,
    setOnPayStackSuccess

    }) => {

    const servicePrice = servicePriceDetails?.price

    const [promoCode, setPromoCode] = useState(null);
    const [promoCodeDetails, setPromoCodeDetails] = useState(null);
    const [loadSpinner, setLoadSpinner] = useState(false);
    const [isPromoCodeCorrect, setIsPromoCodeCorrect] = useState(false);
    const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState(null);
    const [promoPrice, setPromoPrice] = useState(null);


    const config = {
        reference: new Date().getTime().toString(),
        email: user?.email,
        amount: promoPrice * 100, //* 100 converts sub amount from naira to to kobo
        publicKey: "pk_test_7b545e0d7a1aaa0e39782e7d5aa7e9595a8082fc",
    };

    const paystackOnClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
    };

    const paystackOnSuccess = (reference) => {
        setOnPayStackSuccess(true)
        const data = {
            updateServiceId: serviceId,
            user: user?._id,
            price: promoPrice,
            isSubsidized: true,
            duration: servicePriceDetails?.duration,
            isActive: true
        }
        AxiosInstance.post(`api/update/create-update-subscription`, data)
        
        AxiosInstance.patch(`api/update/update-promo-code/${promoCodeDetails?._id}`,{status: "invalid"})
    };

    const initializePayment = usePaystackPayment(config);
    const paystackCheckout = async (service) => {
        if (!isPromoCodeCorrect) {
            return Toast("Please, Check Your Subvention/Subsidy Code!", "error");
        }
        try {
            initializePayment(paystackOnSuccess, paystackOnClose);
            setPromoCode("")
        } catch (error) {
            Toast(error.message, "error");
        } finally {
            //   setDuration(null);
            //   setCareerPath(null);
            //   setPreferredPackage(null);
        }
    };

    const handlePromoCodeSubmit = async (code) => {
        setLoadSpinner(true);
        AxiosInstance.get(`/api/update/get-promo-code/${code}`)
            .then(res => {
                setLoadSpinner(false);
                setIsPromoCodeCorrect(true)
                setPromoCodeErrorMessage(null)
                setPromoCodeDetails(res?.data?.promoCodeInfo)
                setPromoPrice(Math.trunc(servicePrice - ((res?.data?.promoCodeInfo?.percentage / 100) * servicePrice)))
                
            }).catch(err => {
                setPromoCodeErrorMessage(err?.response?.data?.message)
                setLoadSpinner(false);
                setIsPromoCodeCorrect(false)
            });
    };

    useEffect(() => {
        const promoCodeCount = promoCode && promoCode.split('').length;

        if (promoCodeCount === 10) {
            handlePromoCodeSubmit(promoCode);
        }
        else {
            setLoadSpinner(false);
            setIsPromoCodeCorrect(false)
        }
    }, [promoCode]);

    return (
        <div class="w-[400px] h-aut0 fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 border-2 border-slate-600 shadow-xl rounded-md overflow-x-hidden overflow-y-hidden z-50">
            <div class="relative w-full max-w-md max-h-full">
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="w-full flex justify-between items-center px-6 lg:px-8">
                        <div class=" text-xl font-medium text-gray-900 dark:text-white">Enter Subvention/Subsidy Code</div>
                        <div class="cursor-pointer text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm w-3 h-3 dark:hover:text-white" data-modal-hide="authentication-modal">
                            <svg onClick={() => {
                                setShowPromoCodeInputBox(false)
                                setShowPopOverContent(true)
                            }} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                        </div>
                    </div>
                    <div class="px-6 py-6 lg:px-8">
                        {
                            promoCodeErrorMessage && (
                                <span className="mb-1 py-0 text-red-700"> {promoCodeErrorMessage} </span>
                            )
                        }
                        <form class="space-y-6" action="#">
                            <div className="flex items-center">
                                <input type="text" name="code" id="code" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white mb-2"
                                    placeholder="1afheyu5uj"
                                    required
                                    maxlength="10"
                                    onChange={(e) => setPromoCode(e.target.value)} />
                                    <div>
                                {loadSpinner ?
                                    <div role="status" className=" ml-2">
                                        <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    : isPromoCodeCorrect ?
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="green" class="bi bi-check-circle w-8 h-8 ml-2" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                        </svg>
                                        : ""
                                }
                                </div>
                            </div>
                            {
                                servicePrice && (
                                    <div>
                                        Subscription Price  -  {currencyFormatter.format(servicePrice, { locale: 'en-NG' })}
                                    </div>
                                )
                            }
                            {
                                promoPrice && (
                                    <div>
                                        Subscription Price After {promoCodeDetails?.percentage}% Subvention - {currencyFormatter.format(promoPrice, { locale: 'en-NG' })}
                                    </div>
                                )
                            }
                            <div
                                type="submit"
                                onClick={() => {
                                    paystackCheckout()
                                }}
                                class="cursor-pointer my-2 bg-slate-600 text-white hover:bg-slate-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Subscribe</div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
        // <div class="fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
        //     <div class="relative w-full max-w-md max-h-full">
        //         <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        //             <button type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
        //                 <svg onClick={() => setShowPromoCodeInputBox(false)} class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        //                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
        //                 </svg>
        //             </button>
        //             <div class="px-6 py-6 lg:px-8">
        //                 <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">Sign in to our platform</h3>
        //                 <form class="space-y-6" action="#">
        //                     <div>
        //                         <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
        //                         <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required />
        //                     </div>
        //                     <div>
        //                         <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
        //                         <input type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
        //                     </div>
        //                     <div class="flex justify-between">
        //                         <div class="flex items-start">
        //                             <div class="flex items-center h-5">
        //                                 <input id="remember" type="checkbox" value="" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required />
        //                             </div>
        //                             <label for="remember" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember me</label>
        //                         </div>
        //                         <a href="#" class="text-sm text-blue-700 hover:underline dark:text-blue-500">Lost Password?</a>
        //                     </div>
        //                     <button type="submit" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login to your account</button>
        //                     <div class="text-sm font-medium text-gray-500 dark:text-gray-300">
        //                         Not registered? <a href="#" class="text-blue-700 hover:underline dark:text-blue-500">Create account</a>
        //                     </div>
        //                 </form>
        //             </div>
        //         </div>
        //     </div>
        // </div> 

    )
}

export default PromoCodeInputBox

