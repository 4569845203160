import React from "react";

function AddBtn({ setEditAcct }) {
  return (
    <span>
      <button
        className="bg-white text-esgrown my-1 border-esgrown border px-2 rounded-lg outline-none focus:outline-none transition-all duration-500"
        onClick={() => {
          setEditAcct(true);
        }}
      >
        add
      </button>
    </span>
  );
}

export default AddBtn;
