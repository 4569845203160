
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Toast from "../../../../../util/toast";
import allcountries from "../../../../../util/allcountries";
import MemoModal from "./memoModal";
import AxiosInstance from "../../../../../AxiosInstance/AxiosInstance";

export default function CountryView(props) {
  const [display, setDisplay] = useState(false);
  const [loco, setLoco] = useState(props.props);
  const [users, setUsers] = useState();
  const [guve, setGuve] = useState(null);
  const user = useSelector((state) => state.user.user);
  //console.log(props.props.field);
  const notify = (message, type) => {
    return Toast(message, type);
  };

  function groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
        if (!memo[x[property]]) { memo[x[property]] = []; }
        memo[x[property]].push(x);
        return memo;
    }, {});
  };

  function reduce(arr) {
    return arr.reduce(function (r, a) {
      r[a.state] =  r[a.state] || [];
      r[a.state].push(a);
      return r;
    }, Object.create(null));
  }

  useEffect(() => {
    const getGuvenor = async() => {
      const guve = await AxiosInstance.post('/api/user/user/guvenor', {
        country: props.props
      });
      if(guve.data) {
        console.log(guve.data);
        setGuve(guve.data.guvenor[0].name);
      } else {
        return alert("No Guvenor Found");
      }
    }
    const getCustomUsers = async() => {
      if(props !== null) {
        const rasp = await AxiosInstance.post("/api/user/user/country", {
          country: props.props
        });
        if(rasp.data) {
         // console.log(rasp.data);
          const customer = rasp.data.countryUsers;
          let result = {};
          customer.forEach((x) => {
            result[x.state] = result[x.state] || 0;
            result[x.state]++
          });
          //console.log(result);
          setUsers(result);
        }
      } else {
        return alert("No Value Yet");
      }
    }
    getCustomUsers();
    getGuvenor();
  }, []);
  
  ////////////////////////////////
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // maskClosable="false"
      // closeable="false"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="w-full flex flex-column justify-between items-start ml-2">
            <label className = "text-2xl font-bold">{props != null ? props.props : "Global"} Market</label>
            <label className="text-xl font-semibold">Guvenor: {guve ? guve : "Jack Copland"}</label>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-4">
        {/* <div className="container ">
        {/* onSubmit={this.onSubmit} */}
        <div className="">
          <div className="table-responsive bg-white border-b-2 border-b-blue-800">
            <table className="table table-hover table-striped">
              <thead className="bg-success1 text-white">
                <tr>
                  <th>States / Provinces</th>
                  <th>Customers</th>
                </tr>
              </thead>
              <tbody>
                {users &&
                    Object.entries(users).map(([key, value]) => {
                      return (
                        <tr>
                          <td>{key}</td>
                          <td>{value}</td>
                        </tr>
                      )
                    })
                }
              </tbody>
            </table>
            <div className="w-full">
              <button className = "text-blue-800 font-bold py-2 px-2 rounded mb-3 hover:bg-blue-300">Previous</button>
              <button className = "text-blue-800 font-bold py-2 px-2 rounded mb-3 hover:bg-blue-300">Next</button>
            </div>
          </div>
          <div className="mt-4 table-responsive bg-white">
            <table className = "table table-hover table-striped">
                <thead>
                  <tr>
                    {/*<th colSpan={2}>Total Country Customer Base</th>*/}
                  </tr>
                </thead>
                <tbody>
                 {/* <tr>
                    <td>Weekly Market Growth: 8%</td>
                    <td>Monthly Market Growth: 3%</td>
                    </tr>*/}
                  <tr>
                    <td><button className="text-blue-800 font-bold py-2 px-2 rounded hover:bg-blue-300">Call the Guvenor</button></td>
                    <td><button onClick={() => setDisplay(true)} className="text-blue-800 font-bold py-2 px-2 rounded hover:bg-blue-300">Send a Memo</button></td>
                  </tr>
                </tbody>
            </table>
            <MemoModal 
              show={display}
              onHide={() => setDisplay(false)}
            />
          </div>
        </div>
        {/* </div>
        </div> */}
      </Modal.Body>
    </Modal>
  );
}

function renderCountries() {
  return allcountries.map((item) => {
    return <option value={item.country}>{item.country}</option>;
  });
}
