import React from "react";
import { Popover } from "antd";
import PaymentRequestModal from "./modals/PaymentRequestModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import currencyFormatter from 'currency-formatter';
import { 
  getTotalProcessedUCBIAmountForTeachers,
  getAllTeachersWithProcessedUcbis,
  getAllAssociatesWithProcessedUcbis,
  setUcbiUserDetails,
  setShowUcbiDetailModal,
  getTotalProcessedUCBIAmountForAssociates,
  setShowUcbiHistoryListModal,
  getUcbiHistory
 } from "../../../redux/slices/ucbiSlice";
import { useEffect } from "react";
import UCBIDetailsModal from "../../campaigns/steka/modals/UcbiDetailModal";
import UcbiHistoryList from "./ucbi-history-list";

export default function UcbiHistory({ processedPayments }) {
  const [openUCBIPaymentReqModal, setOpenUCBIPaymentReqModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [loading, setLoading] = useState(false);
  const [month, setmonth] = useState("");
  const [month2, setmonth2] = useState("");

  const dispatch = useDispatch()
  const {totalProcessedUCBIAmountForTeachers,
         allTeachersWithProcessedUcbis,
         allAssociatesWithProcessedUcbis,
         totalProcessedUCBIAmountForAssociates,
         ucbiHistory
  } = useSelector((state) => state.ucbiSlice);

  const {showUcbiDetailModal,
         showUcbiHistoryListModal
  } = useSelector((state) => state.ucbiSlice.componentsStates);

  console.log("ucbiHistory", ucbiHistory);

  const months = [  "January", "February", "March", "April", 
                    "May", "June", "July", "August", "September", 
                    "October", "November", "December" ]

  useEffect(()=>{
    dispatch(getTotalProcessedUCBIAmountForTeachers())
    dispatch(getAllTeachersWithProcessedUcbis(month))
  },[getTotalProcessedUCBIAmountForTeachers, getAllTeachersWithProcessedUcbis])
  
  useEffect(()=>{
    dispatch(getAllTeachersWithProcessedUcbis(month))
  },[month, showUcbiDetailModal])

  useEffect(()=>{
   dispatch(getAllAssociatesWithProcessedUcbis(month2))
   dispatch(getTotalProcessedUCBIAmountForAssociates())
  },[month2, showUcbiDetailModal])

  return (
    <div className="w-full h-full relative ">
      <div className="">
        <div className="">
          <div className="">
            <div className="">
              <div className="col-lg-12 mt-4">
                <div className="mt-3">
                  <div class="flex justify-between p-3 items-center">
                    <div class="h-2 mb-3 font-bold text-lg">
                      Conditional Earned Basic Income Payout Requests for Teachers
                    </div>
                    <div >
                      <select 
                          className="form-control"
                          required
                          type = 'text'
                          name = "month"
                          value = {month}
                          onChange={(e)=>setmonth(e.target.value)}
                        >
                          <option value="">All Months</option>
                          {months &&
                            months.map((month, i) => (
                              <option 
                                key={i.toString()}
                                value={month}
                                className="font-semibold text-gray"
                              >
                                {`${month}`}
                              </option>
                            ))

                          }
                        </select>
                    </div>
                    <div class="font-bold text-lg">({currencyFormatter.format(totalProcessedUCBIAmountForTeachers, { locale: 'en-NG' })})</div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped table-auto">
                      <thead className="bg-success1 text-white">
                        <tr>
                          <th className="w-10/12">Payee</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allTeachersWithProcessedUcbis && allTeachersWithProcessedUcbis.length > 0 ? (
                          allTeachersWithProcessedUcbis.map((teacher) => (
                            <tr key={teacher._id}>
                              <td>{teacher.user_id?.name}</td>
                              <td>
                                      <button
                                        onClick={() => {
                                          dispatch(setUcbiUserDetails(teacher));
                                          dispatch(setShowUcbiHistoryListModal(true));
                                          // dispatch(getUcbiHistory(teacher.user_id._id, month))
                                        }}
                                        className="px-2 outline-none focus:outline-none text-blue rounded-full transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                                      >
                                        View
                                      </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="bg-white">
                            <td >No withdrawal history for teachers 👏</td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="">
          <div className="">
            <div className="">
              <div className="col-lg-12 mt-4">
                <div className="mt-3">
                  <div class="flex justify-between p-3 items-center">
                    <div class="h-2 mb-3 font-bold text-lg">
                     Earned Income Payout Requests  for Associate SDP Advisors
                    </div>
                    <div >
                      <select 
                          className="form-control"
                          required
                          type = 'text'
                          name = "month2"
                          value = {month2}
                          onChange={(e)=>setmonth2(e.target.value)}
                        >
                          <option value="">All Months</option>
                          {months &&
                            months.map((month, i) => (
                              <option 
                                key={i.toString()}
                                value={month}
                                className="font-semibold text-gray"
                              >
                                {`${month}`}
                              </option>
                            ))

                          }
                        </select>
                    </div>
                    <div class="font-bold text-lg">({currencyFormatter.format(totalProcessedUCBIAmountForAssociates, { locale: 'en-NG' })})</div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-hover table-striped">
                      <thead className="bg-success1 text-white">
                        <tr>
                          <th className="w-10/12">Payee</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allAssociatesWithProcessedUcbis && allAssociatesWithProcessedUcbis.length > 0 ? (
                          allAssociatesWithProcessedUcbis.map((associates) => (
                            <tr key={associates._id}>
                              <td>{associates.user_id?.name}</td>
                              <td>
                                      <button
                                        onClick={() => {
                                          console.log("associates.user_id._id", associates.user_id._id);
                                          dispatch(setUcbiUserDetails(associates));
                                          dispatch(setShowUcbiHistoryListModal(true));
                                          // dispatch(getUcbiHistory(associates.user_id._id, month))
                                        }}
                                        className="px-2 outline-none focus:outline-none text-blue rounded-full transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                                      >
                                        View
                                      </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="bg-white">
                            <td>No withdrawal history for associates 👏</td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showUcbiHistoryListModal && (
      <div
         className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}
      >
        <UcbiHistoryList />
      </div>
      )}
    </div>
  );
}
