import React, { useState } from "react";
import { Link } from "react-router-dom";
import md5 from "md5";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Row, Col, Card, Alert } from "react-bootstrap";
import "../App.css";
import allcountries from "../util/allcountries";
import toast from "../util/toast";

import circle from "../img/check-circle.svg";
import logo from "../img/logo_esgrown.png";
import logo_dash from "../img/esgrown_dash.png";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/slices/userSlice";
import Toast from "../util/toast";
import { MediaPath } from "../App";
import { Avatar } from "antd";
import AxiosInstance from "../AxiosInstance/AxiosInstance";

const IndividualSignUp = ({ history }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [individualData, setIndividualData] = useState({
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
    gender: "",
    dob: "",
    status: "individual",
    userType: "",
    orgName: "",
    show: "none",
    loading: false,
  });

  function onChangeFullname(e) {
    setIndividualData({ ...individualData, fullname: e.target.value });
  }

  function onChangeEmail(e) {
    // setState({
    //   email: e.target.value,
    // });
    setIndividualData({ ...individualData, email: e.target.value });
  }

  function onChangePassword(e) {
    // this.setState({
    //   password: e.target.value,
    // });
    setIndividualData({ ...individualData, password: e.target.value });
  }

  function onChangeGender(e) {
    // this.setState({
    //   gender: e.target.value,
    // });
    setIndividualData({ ...individualData, gender: e.target.value });
  }

  function onChangePhone(e) {
    // this.setState({
    //   phone: e.target.value,
    // });
    setIndividualData({ ...individualData, phone: e.target.value });
  }

  function onChangeDOB(date) {
    // this.setState({
    //   dob: date.target.value,
    // });
    setIndividualData({ ...individualData, dob: date.target.value });
  }

  function onChangeCountry(e) {
    // this.setState({
    //   country: e.target.value,
    // });
    setIndividualData({ ...individualData, country: e.target.value });
  }

  function onChangeState(e) {
    // this.setState({
    //   state: e.target.value,
    // });
    setIndividualData({ ...individualData, state: e.target.value });
  }

  function onChangeCorpType(e) {
    setIndividualData({ ...individualData, userType: e.target.value });
  }

  function onchangeConfirmPassword(e) {
    // this.setState({
    //   org_name: e.target.value,
    // });
    setIndividualData({ ...individualData, confirmPassword: e.target.value });
  }

  function onSubmit(e) {
    e.preventDefault();
    // this.setState({ loading: true });
    setIndividualData({ ...individualData, loading: true });
    const user = {
      email: individualData.email,
      name: individualData.fullname,
      password: individualData.password,
      gender: individualData.gender,
      dob: individualData.dob,
      userType: individualData.userType,
      orgName: individualData.orgName,
    };

    if (individualData.password !== individualData.confirmPassword) {
      setIndividualData({ ...individualData, loading: false });
      return Toast("Passwords must match!", "error");
    }
    if (
      !user.email ||
      !user.name ||
      !user.password ||
      !user.dob ||
      !user.gender ||
      !user.userType ||
       user.orgName
    ) {
      console.log(user);
      setIndividualData({ ...individualData, loading: false });
      return Toast("All fields are required!", "error");
    }

    AxiosInstance
      .post(`/api/user/signup`, { data: user })
      .then((res) => {
        if (!res.data.error) {
          const Global_User = {
            ...res.data.user,
            token: res.data.token,
          };

          // Empty state
          setIndividualData({
            fullname: "",
            email: "",
            password: "",
            gender: "",
            dob: new Date(),
            loading: false,
          });

          dispatch(setUser(Global_User));
          axios.defaults.headers.common = {
            Authorization: "Bearer " + Global_User.token,
          };
          toast("Registration successful!", "success");
          history.push("/home");
        } else {
          // server respond with error
          setIndividualData({ ...individualData, loading: false });
          return toast(res.data.message, "error");
        }
      })
      .catch((err) => {
        setIndividualData({ ...individualData, loading: false });
        toast(err.message, "error");
      });
  }

  return (
    <div className="w-full h-full flex flex-col md:flex-row-reverse space-y-4 md:space-y-0">
      {/* /////////////////Sign Up form////////////////       */}
      <div className="w-full md:w-1/2 bg-white ">
        {/* <div> */}
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div className="w-full md:hidden">
            <img src={logo_dash} className="mx-auto" />
          </div>
          <h3 className="mt-3 mb-1 text-lg font-bold">
            Create new Individual Account
          </h3>
          <p className="mb-2">
            Already have an account? <Link to="/">Sign in</Link>
          </p>
        </div>
        <div>
          <div className="justify-center flex mb-4">
            <div className="in row">
              <div className="in-1">Individual</div>
              <div className="in-2 ml-3">
                <Link to="/signup_corporate" className="in-3">
                  Coporate
                </Link>
              </div>
            </div>
          </div>
          <form onSubmit={onSubmit}>
            <div className="w-11/12 mx-4">
              {/* <label>Full Name:</label> */}
              <div className="input-group">
                <input
                  className="form-control"
                  required
                  type="text"
                  onChange={onChangeFullname}
                  value={individualData.fullname}
                  placeholder="Enter Fullname..."
                ></input>
              </div>
              <div className="w-full flex mt-3 space-x-8">
                <div className="flex  w-1/2">
                  <div className="form-group mb-3 w-full">
                    <select
                      className="custom-select w-full"
                      id="inputGroupSelect01"
                      required
                      onChange={onChangeCorpType}
                    >
                      <option value="">Select Account Type</option>
                      <option value="student">Student</option>
                      <option value="teacher">Teacher</option>
                      <option value="companyStaff">Company Staff</option>
                      <option value="companyLeader">Business Leader</option>
                      <option value="private">Private</option>
                    </select>
                  </div>
                </div>
                <div className="flex w-1/2 ">
                  <div className="form-group mb-3 w-full">
                    <input
                      className="form-control"
                      required
                      type="email"
                      onChange={onChangeEmail}
                      value={individualData.email}
                      placeholder="Enter Email..."
                    ></input>
                  </div>
                </div>
              </div>

              {/* /////////// */}
              <div className="w-full flex mt-3 space-x-8">
                <div className="flex  w-1/2">
                  <div className="form-group mb-3 w-full">
                    {open ? <input
                      type="date"
                      className="form-control"
                      onChange={onChangeDOB}
                      title="Date of Birth"
                      onBlur={() => setOpen(false)}
                      style={{ width: "100%" }}
                      required
                    /> : 
                    <input 
                      className="form-control"
                      required
                      type="text"
                      onFocus={() => setOpen(true)}
                      placeholder="Enter Date of Birth..."
                    />}
                  </div>
                </div>
                <div className="flex w-1/2 ">
                  <div className="form-group mb-3 w-full">
                    <select
                      className="custom-select w-full"
                      id="inputGroupSelect01"
                      required
                      onChange={onChangeGender}
                      value={individualData.gender}
                    >
                      <option value="">Select Gender...</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* /////////// */}

              {/* ///////////////// */}

              <div className="form-group">
                {/* <label>Password:</label> */}
                <input
                  className="form-control"
                  required
                  type="password"
                  onChange={onChangePassword}
                  value={individualData.password}
                  placeholder="Enter Password"
                ></input>
              </div>

              <div className="row mt-3">
                <div className={`col`}>
                  <input
                    className="form-control"
                    required={true}
                    type="password"
                    name="confirmPassword"
                    onChange={onchangeConfirmPassword}
                    value={individualData.confirmPassword}
                    placeholder="Confirm Password"
                  ></input>
                </div>
              </div>

              <br />
              <Button
                className="btn btn-primary text-[#53A6E7] w-100"
                type="submit"
              >
                {individualData.loading ? (
                  <i className="fa fa-spinner fa-spin ml-2"></i>
                ) : (
                  "Sign Up"
                )}
              </Button>
              <br />
              {/* </div> */}
            </div>
          </form>
          <div className="w-full p-2 flex justify-center items-center">
            Have an account ?
            <button
              onClick={() => {
                history.push("/home");
              }}
              className="ml-1 px-2 rounded-lg focus:outline-none hover:border border-[#53A6E7]"
            >
              Login
            </button>
          </div>
        </div>
        {/* </div> */}
      </div>
      {/* Info section  */}
      <div className="w-full md:w-1/2 ind-bg h-full px-20 py-6">
        <img src={logo} className="hidden md:block" />
        <div className="mt-10">
          <h1 className="s-1">
            <span className="free">Premium</span> Services
          </h1>
          <p className="text-white text-2xl">on Esgrown</p>
        </div>
        {/* first row  */}
        <div className="flex mt-10">
          <div className="mr-4">
            <img src={circle} />
          </div>
          <div>
            <h3 className="text-white">Educational Advisory</h3>
            <p className="text-white">
              Align your education to real life career dream
            </p>
          </div>
        </div>

        {/* second row  */}

        <div className="flex mt-4">
          <div className="mr-4">
            <img src={circle} />
          </div>
          <div>
            <h3 className="text-white">Education Finance Advisory</h3>
            <p className="text-white">
              Access Scholarship for education you need for your dream career
            </p>
          </div>
        </div>

        <div className="flex mt-4">
          <div className="mr-4">
            <img src={circle} />
          </div>
          <div>
            <h3 className="text-white">Digital Literacy Development</h3>
            <p className="text-white">
              Build your capacity to use digital tools for living, learning,
              work and business
            </p>
          </div>
        </div>

        {/* third row  */}
        <div className="flex mt-4">
          <div className="mr-4">
            <img src={circle} />
          </div>
          <div>
            <h3 className="text-white">Employment Advisory</h3>
            <p className="text-white">
              Connect and join the workforce of industry that needs your
              expertise for business
            </p>
          </div>
        </div>
      </div>
      {/* <div className="w-20 h-20">
        <img
          className="w-full h-full object-cover"
          src={MediaPath + "default.png"}
        ></img>
      </div> */}
    </div>
    // </div>
  );
};

export default IndividualSignUp;

// Render all Countries
// function renderCountries() {
//   return allcountries.map((item) => (
//     <option value={item.country}>{item.country}</option>
//   ));
// }
