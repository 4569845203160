
import { CloseCircleTwoTone } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
         setShowOrganizationPopOver,
         removeSponsoringOrganization,
         setShowListOfOrganizations,
         setRemoveOrganizationPrompt,
         setShowSupportingOrganizationDetailsModal
        } from "../../../../redux/slices/sponsoringOrganizationSlice";

function RemoveOrganizationConfirmationModal (){
  const { 
    organizationDetail,
   } = useSelector(state => state.sponsoringOrganizationSlice )
   
  const dispatch = useDispatch()

  console.log("organizationDetail", organizationDetail._id);
return(
  <div class="absolute self-center content-center removeAssociateModal">
    <CloseCircleTwoTone className="closeRemoveAssociate"
            onClick={() => dispatch(setRemoveOrganizationPrompt(false)) }
            color="red"
    />
    <div class=" bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 md:relative">
      <div class="md:flex items-center">
        <div class=" md:mt-0 md:ml-6 text-center md:text-left">
          <p class="font-bold">Remove Sponsoring Organization</p>
          <p class="text-sm text-gray-700 mt-1">Do you want to remove this career field?
          </p>
        </div>
      </div>
      <div class="text-center md:text-right mt-4 md:flex md:justify-end">
        {/* <button class="block w-full md:inline-block md:w-auto px-4 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">Remove Associate</button> */}
        <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm mt-4
          md:mt-0 md:order-1 mr-2"
          onClick={()=>{
            dispatch(removeSponsoringOrganization(organizationDetail._id))
            dispatch(setRemoveOrganizationPrompt(false))
            dispatch(setShowListOfOrganizations(true))
            dispatch(setShowOrganizationPopOver(false))
            dispatch(setShowSupportingOrganizationDetailsModal(false))
        }}
          >Remove</button>
        <button class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-[#1D6DAE] rounded-lg text-white font-semibold text-sm mt-4
          md:mt-0 md:order-1" onClick={()=> {
            dispatch(setRemoveOrganizationPrompt(false))
            dispatch(setShowOrganizationPopOver(false))
            dispatch(setShowListOfOrganizations(true))
        }}>
            Cancel
            </button>
      </div>
    </div>
  </div>
)}

export default RemoveOrganizationConfirmationModal