import React, { useState, useEffect } from "react";
import './store.css';
import Icon, {
    StarFilled,
    CloseCircleFilled,
    CloseOutlined
  } from "@ant-design/icons";
import AxiosInstance from "../../../../AxiosInstance/AxiosInstance";
const StoreAdd = ({
    showState,
    refresh
}) =>{

    const [iname,setIname]=useState("");
    const [iprice,setIprice]=useState("");
    const [icdoe,setIcode]=useState("");
    const [idesc,setIdesc]=useState("");
    const [itype,setItype]=useState("");
    const [icat,setIcat]=useState("");
    const [quant,setQ]=useState("1");
    const [images,setImages]=useState([]);

    const saveItem = async () => {
        const url = "/api/store/add"
        const req = await AxiosInstance.post(url,{
            name:iname,
            cost:iprice,
            description:idesc,
            code:icdoe,
            images:images,
            type:itype,
            category:icat,
            quantity:quant
        })
        console.log(req);
        showState(false);
        refresh();
      };
      function validateFile(file) {
        const oneMB = 1024 * 1024;
        const fileName = file.name;
        const fileSize = file.size;
        if (fileSize > oneMB) return {};
        return { name: file.name, file };
      }
      const uploadImage= async (image) => {
        //setLoadingProfilePix(true);
        try {
          const formData = new FormData();
          // formData.append("photo", userPix);
          formData.append("upload", image);
    
          const { data } = await AxiosInstance.post(
            "/api/user/user/upload-file",
            formData
          );
          console.log(data.status,data.file);
          if (data.status === "success") {
            var nimages=[...images];
             nimages.push(data.file);
             setImages(nimages);
             console.log(nimages);
            //dispatch(setUser(updatedUser));
          }
        } catch (error) {
          // return Toast(error.message, "error");
        } finally {
          //setLoadingProfilePix(false);
        }
      };
      const renderImages=()=>{
        var allimg=[];
        images.forEach((image)=>{
        allimg.push(
            <img src={image} key={image} />
        )
        })
        return allimg;
      }
    return (
        <div className="store-details">
            <div className="inner">
                <div style={{display:"flex",justifyContent:"space-between",width:"100%",marginBottom:"20px"}}>
                <h1 style={{fontSize:"20px",fontWeight:"bold"}}>Add Item</h1>
                <div><CloseOutlined onClick={()=>{
                    showState(false)
                }}/></div>
                </div>
                <div className="uimages">
                {renderImages()}
                </div>
                <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              const fileObj = validateFile(e.target.files[0]);
                              if (Boolean(fileObj.name)) {
                                // process further
                                uploadImage(fileObj.file);
                              } else {
                                alert("invalid");
                              }
                            }}
                           
                          />
                <input type="text" placeholder="Name" onChange={(e)=>{
                    setIname(e.target.value)
                }} />
                <input type="text" placeholder="Price" onChange={(e)=>{
                    setIprice(e.target.value)
                }} />
                <input type="text" placeholder="Code" onChange={(e)=>{
                    setIcode(e.target.value)
                }} />
                <input type="text" placeholder="Description" onChange={(e)=>{
                    setIdesc(e.target.value)
                }} />
                <input type="text" placeholder="Quantity" onChange={(e)=>{
                    setQ(e.target.value)
                }} />
                <select onChange={(e)=>{
                 setItype(e.target.value)
                }}>
                    <option selected disabled>Select Theme</option>
                    <option>Education</option>
                    <option>Buiness</option>
                </select>
                <select onChange={(e)=>{
                 setIcat(e.target.value)
                }}>
                    <option selected disabled>Select Category</option>
                    <option>Books</option>
                    <option>T-shirt</option>
                    <option>Mugs</option>
                    <option>Backpacks</option>
                </select>
                <button onClick={()=>{
                    saveItem()
                }}>Save</button>
            </div>
        </div>
    )
}
export default StoreAdd;