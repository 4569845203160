import { Avatar, Badge, Tooltip } from "antd";
import React from "react";

function OnlineUser({ user }) {
  const defaultProfilePix =
    "https://esgrown.fra1.digitaloceanspaces.com/esgrown%201669801277885.jpg";
  return (
    <Badge
      count={1}
      size="small"
      className="mr-2 border-2 border-gray-200 animate-pulse rounded-full cursor-pointer"
    >
      <Avatar
        shape="circle"
        size="medium"
        src={user && user.profilePix ? user.profilePix : defaultProfilePix}
      />
    </Badge>
  );
}

export default OnlineUser;
