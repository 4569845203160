import React from "react";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { DatePicker } from "antd";
import { useState } from "react";
import { Popover } from "antd";

import Toast from "../../../util/toast";
import AxiosInstance from "../../../AxiosInstance/AxiosInstance";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice";

function RecruitNow({ history, toggleRecruitNow }) {
  const user = useSelector(selectUser);
  const [dateOpen, setDateOpen] = useState(false);
  const [appDeadlineDate, setAppDeadlineDate] = useState(null);
  const [requiredSkills, setRequiredSkills] = useState([]);
  const [skill, setSkill] = useState("");
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [numOfOpenings, setNumOfOpenings] = useState(0);
  const [jobDescription, setJobDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const postNewVacancy = async () => {
    setLoading(true);
    if (requiredSkills.length < 1) {
      setLoading(false);
      return Toast("Please enter required skills for this position!", "error");
    }
    if (
      !title ||
      !location ||
      !location ||
      !jobDescription ||
      !appDeadlineDate ||
      numOfOpenings < 1
    ) {
      setLoading(false);
      return Toast("All fields are required!", "error");
    }

    const jobData = {
      title,
      location,
      numOfOpenings,
      deadline: appDeadlineDate,
      jobDescription,
      requiredSkills
    };

    try {
      const { data } = await AxiosInstance.post("/api/jobs/create", jobData);
      if (!data.error) {
        //success
        Toast("Vacancy posted successfully!", "success");
        //toggleRecruitNow();
      } else {
        return Toast(data.message, "error");
      }
    } catch (error) {
      return Toast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="absolute inset-0 w-full">
      <div
        className="w-[90%] mx-auto bg-white  rounded-xl shadow-lg p-4"
        style={{ minHeight: 450, zIndex: 500 }}
      >
        <div className="w-full flex justify-end mr-2">
          <CloseCircleTwoTone onClick={toggleRecruitNow} />
        </div>
        <div className="w-full flex justify-center text-xl text-esgrown font-bold">
          Publish New Vacancy
        </div>
        {/* <div className="my-2 border-b "></div> */}
        <div className="w-full px-3 mt-3">
          <div className="w-full flex justify-between">
            <input
              type="text"
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Job Title"
              className="w-[48%] px-2 py-2 border-2 rounded-lg outline-esgrown "
            />
            <input
              type="text"
              onChange={(e) => setLocation(e.target.value)}
              placeholder="Job Location"
              className="w-[48%] px-2 py-2 border-2 rounded-lg outline-esgrown "
            />
          </div>

          <div className="w-full flex justify-between mt-3 relative">
            <input
              type="number"
              onChange={(e) => setNumOfOpenings(e.target.value)}
              placeholder="Number of Openings"
              className="w-[48%] px-2 py-2 border-2 rounded-lg outline-esgrown "
            />

            <input
              type="text"
              value={appDeadlineDate ? appDeadlineDate : ""}
              onClick={() => {
                setDateOpen(true);
              }}
              onBlur={() => {
                setDateOpen(false);
              }}
              placeholder="Select Application Deadline"
              className="w-[48%] px-2 py-2 border-2 rounded-lg outline-esgrown "
            />
            <DatePicker
              open={dateOpen}
              className={`invisible absolute top-0 right-0 `}
              onChange={(date) => {
                setAppDeadlineDate(new Date(date).toLocaleDateString());
                setDateOpen(false);
              }}
            />
          </div>
          <div className="w-full mt-3">
            <textarea
              placeholder="Job Description"
              onChange={(e) => setJobDescription(e.target.value)}
              type="text"
              className="w-full p-3 border-2 rounded-lg outline-esgrown "
            />
          </div>
          {/* Required skills scetion  */}
          <div className="w-full mt-3 relative">
            <Popover
              placement="right"
              trigger="click"
              content={
                <div>
                  <div className="p-2 w-fit h-fit flex flex-col ">
                    <input
                      type="text"
                      value={skill}
                      onChange={(e) => setSkill(e.target.value)}
                      placeholder="Enter a skill"
                      className=" w-[400px] px-2 py-1.5 rounded-lg bg-gray-200 outline-esgrown"
                    />
                    <button
                      onClick={() => {
                        if (skill) {
                          setRequiredSkills((prev) => [...prev, skill]);
                          setSkill("");
                        } else {
                          alert("Please enter a skill!");
                        }
                      }}
                      className="mt-3 w-fit self-end outline outline-esgrown px-3 py-0.5 rounded-lg focus:outline-esgrown hover:translate-y-1 duration-500 transition-all"
                    >
                      Add
                    </button>
                  </div>
                </div>
              }
            >
              <button className="px-3 py-1.5 mr-1 text-base text-white rounded-lg focus:outline-none bg-slate-400 font-semibold">
                Add Required Skill +
              </button>
            </Popover>
          </div>
          <div
            className={`w-full ${
              requiredSkills.length > 0 ? "" : "hidden"
            } bg-white text-gray-500 mt-2 rounded-lg px-2 py-3 flex flex-wrap shadow-sm mx-auto`}
          >
            {requiredSkills.map((skill, index) => {
              return (
                <div
                  className="font-semibold bg-sky-200 p-2 rounded m-2"
                  key={index.toString()}
                >
                  {skill}
                </div>
              );
            })}
          </div>
        </div>
        {requiredSkills.length < 1 && (
          <div className="my-3 border-b  w-[96%] mx-auto "></div>
        )}
        <div className="flex w-full justify-end items-center px-3 mt-3">
          <button
            onClick={postNewVacancy}
            disabled={loading}
            className={` ${
              loading && "animate-pulse "
            } px-3 py-1.5 mr-1 text-xl text-esgrown rounded-lg outline outline-esgrown font-bold`}
          >
            Publish Vacancy
          </button>
        </div>
      </div>
    </div>
  );
}

export default RecruitNow;
