import { createSlice } from "@reduxjs/toolkit";

const initState = {
  goal1: false,
  goal2: false,
  goal3: false,
  goal4: false,
  goal5: false,
  goal6: false,
  goal7: false,
  goal8: false,
  goal9: false,
  goal10: false,
  goal11: false,
  goal12: false,
  goal13: false,
  goal14: false,
  goal15: false,
  goal16: false,
  goal17: false,
  noOfStudents: null,
  noOfTeachers: null,
  advisorCode: null,
  TCAccepted: false,
  referredByAdvisor: false,
  showStekaForm: false,
};
export const formSlice = createSlice({
  name: "form",
  initialState: initState,
  reducers: {
    changeInput: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    setShowStekaForm: (state, action) => {
      return { ...state, showStekaForm: action.payload };
    },
    resetForm: (state) => {
      return { ...initState, showStekaForm: state.showStekaForm };
    },
  },
});
// Expoted actions
export const { changeInput, setShowStekaForm, resetForm } = formSlice.actions;

//Expoted selectors
// export const selectUser = (state) => state.form;

export default formSlice;
