// export default CampaignHome;

import React, { useState, useEffect, useRef } from "react";
import ClickAwayListener from "react-click-away-listener";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
// import { home } from "../svg/Home.svg";
// import { Logo } from "../../img/logoo.png";
import { Link } from "react-router-dom";

import { Layout, Menu, Dropdown, Space, Image, Avatar } from "antd";
import Icon, {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  DatabaseOutlined,
  ScheduleOutlined,
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  EditOutlined,
  CameraOutlined,
  UserAddOutlined,
  FundOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import DropDownMenu from "../reusable/DropDownMenu";
import OnlineUser from "../reusable/OnlineUser";
import { MediaPath } from "../../App";
import StekaCampain from "./steka/StekaCampain";
import EngrosCampaign from "./engros/EngrosCampain";
import DarkOverlay from "../DarkOverlay";
import CreateFields from "../admin/dashboards/fields/CreateFields";
import SponsoringOrg from "./steka/modals/SponsoringOrg";
// import Toast from "../../util/toast";
// import { setUser } from "../../redux/slices/userSlice";

// import { useUser } from "../../context/UserContext";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const CampaignHome = ({ history }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [campaignToShow, setCampaignToShow] = useState("steka");
  const [openSponsoringOrg, setOpenSponsoringOrg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openCraeteField, setopenCreateField] = useState(false);
  const toggleCreateFieldModal = () => setopenCreateField((prev) => !prev);
  const toggleAddSponsoringOrg = () => setOpenSponsoringOrg((prev) => !prev);

  const user = useSelector((state) => state.user.user);

  const handleClick = ({ key }) => {
    // console.log(key);
    //you can perform setState here
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        maxHeight: "100%",
        flexWrap: "nowrap",
        position: "relative",
      }}
    >
      <Sider
        trigger={null}
        className="sidebar-bg"
        collapsible
        collapsed={collapsed}
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <Menu
          mode="inline"
          // theme="dark"
          className="sidebar-bg f-2"
          defaultSelectedKeys={["8"]}
          onClick={handleClick}
        >
          <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
            <Link to="/AdminDashboard" />
            <span>Home</span>
          </Menu.Item>

          <Menu.Item key="2" icon={<ShoppingCartOutlined />} className="font-2 mt-2">
            <Link to="/adminStore" />
            <span>Store</span>
          </Menu.Item> 
          <Menu.Item key="3" icon={<ShoppingCartOutlined />}>
            <Link to="/adminOrders" />
            <span>Store Orders</span>
          </Menu.Item> 
          <Menu.Item key="4" icon={<ScheduleOutlined />}>
            <Link to="/Admin_Page" />
            <span>Admin</span>
          </Menu.Item>
          <Menu.Item key="5" icon={<ScheduleOutlined />}>
            <Link to="/UCBI" />
            <span>UCBI</span>
          </Menu.Item>
          <Menu.Item key="6" icon={<ScheduleOutlined />}>
            <Link to="/update" />
            <span>Updates</span>
          </Menu.Item>
          {/* <Menu.Item
            onClick={toggleCreateServiceModal}
            key="7"
            icon={<ScheduleOutlined />}
          >
            <span>create service</span>
          </Menu.Item> */}
          <Menu.Item key="8" icon={<ScheduleOutlined />}>
            <Link to="/campaigns" />
            <span>Campaigns</span>
          </Menu.Item>
          <Menu.Item key="10" icon={<ScheduleOutlined />}>
            <Link to="/exercises" />
            <span>Exercises</span>
          </Menu.Item>

          <Menu.Item key="9" icon={<LoginOutlined />}>
            <Link to="/logout" />
            <span>Logout</span>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className="row pt-2 head">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
          {/* <h3 className="mt-1 ">Profile</h3> */}
          <div className="flex  min-h-min justify-center">
            <button
              autoFocus={true}
              onClick={() => setCampaignToShow("steka")}
              className="mr-2  focus:outline-none focus:border-b-2 focus:border-b-blue-500"
            >
              STEKA
            </button>
            <button
              onClick={() => setCampaignToShow("engros")}
              className="focus:outline-none focus:border-b-2 focus:border-b-blue-500"
            >
              ENGROS
            </button>
          </div>
          {/* <ClickAwayListener onClickAway={() => setShowAccountMenu(false)}>
            <div className="ml-auto">
              <DropDownMenu user={user} show={showAccountMenu} />
              <div onClick={() => setShowAccountMenu(!showAccountMenu)}>
                <span className="font-semibold">{user.name}</span>{" "}
                <OnlineUser user={user} />
              </div>
            </div>
          </ClickAwayListener> */}
          <div className="ml-auto flex items-center ">
            <div>
              <OnlineUser user={user} />
            </div>
            <div className="ml-auto transition hover:translate-x-1 hover:duration-500">
              {user && user.name}
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 10,
            // background: "#fff",
            minHeight: 400,
          }}
        >
          <div className="flex flex-col w-full h-[800px] pl-10 bg-inherit">
            {/* //Content  */}

            {campaignToShow === "steka" && (
              <StekaCampain
                toggleCreateFieldModal={toggleCreateFieldModal}
                toggleAddSponsoringOrg={toggleAddSponsoringOrg}
              />
            )}
            {campaignToShow === "engros" && <EngrosCampaign />}
          </div>
        </Content>
      </Layout>
      {/* //Create or add career fields */}
      {openCraeteField && (
        <>
          <CreateFields toggleCreateFieldModal={toggleCreateFieldModal} />
          <DarkOverlay density={70} z="z-0" />
        </>
      )}
      {openSponsoringOrg && (
        <>
          <SponsoringOrg toggleAddSponsoringOrg={toggleAddSponsoringOrg} />
          <DarkOverlay density={70} z="z-0" />
        </>
      )}
    </Layout>
  );
};

export default CampaignHome;
