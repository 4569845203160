import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setCountry,
  setCountryAssociates,
  setStekasInCountry,
} from "../../../redux/slices/campaignSlice";
import Toast from "../../../util/toast";
import CreateFields from "../../admin/dashboards/fields/CreateFields";
import DarkOverlay from "../../DarkOverlay";
import AssociatesInCountry from "./AssociatesInCountry";
import CareerPathView from "./modals/CareerPathView";
import OrganizationListModal from "./modals/OrganizationListModal";
import SponsoringorgView from "./modals/SponsoringOrgView";
import ProcessedAssociates from "./ProcessedAssociates";
import ProcessedSchools from "./ProcessedSchools";
import SchoolsInCountry from "./SchoolsInCountry";
import SponsoringOrg from "./modals/SponsoringOrg";
import {
  getProcessedAssociates,
  setShowListOfProccessedAssociates,
  getAllAssociatesProcessedByCountry,
  getAllInProcessingAssociatesByCountry,
  setOpenProcessedAssModal,
  setShowListOfInProcessingAssociates,
  getprocessedAssociatesUniqueCountries,
  setAssociatesCountry
} from "../../../redux/slices/associateSlice";

import {
  getProcessedStekaSchools,
  getProcessedStekaSchoolsCountries
} from "../../../redux/slices/stekaSlice";

import {
  getCareerFieldsByCountry,
  getCareerFields,
  setShowListOfCareerFields,
  setCareerFieldCountry,
  setRemoveCareerFieldPrompt
} from "../../../redux/slices/careerFieldSlice";

import {
  getSponsoringOrganizationByCountry,
  setShowListOfOrganizations,
  setSponsoringOrganizationCountry,
  setShowOrganizationCreateModal,
  getAllSponsoringOrganization,
  setRemoveOrganizationPrompt,
  getGlobalSponsorinOrg
} from "../../../redux/slices/sponsoringOrganizationSlice";
import AddSchoolSessionDateModal from "../../admin/dashboards/super-admin/components/AddSchoolSessionDateModal";
import AxiosInstance from "../../../AxiosInstance/AxiosInstance";

function StekaCampain({ toggleCreateFieldModal, toggleAddSponsoringOrg }) {
  const dispatch = useDispatch();
  const [setShowListOfSchools, setSetShowListOfSchools] = useState(false);
  const [allAssociatesByCountry, setAllAssociatesByCountry] = useState(null);
  const [allSchools, setAllSchools] = useState(null);
  const [weeklycaptures, setWeeklycaptures] = useState(null);
  const [allStudents, setAllStudents] = useState(null);
  const [allTeachers, setAllTeachers] = useState(null);
  const [processedStekaSchools, setProcessedStekaSchools] = useState([]);
  const [processedStekaSchoolsCountryId, setProcessedStekaSchoolsCountryId] = useState();
  const [processedStekaSchoolsModal, setProcessedStekaSchoolsModal] = useState(false);
  // const [fieldsFromDb, setfieldsFromDb] = useState(null);
  const [inProcessingRequestTabClicked, setInProcessingRequestTabClicked] = useState(true);
  const [countryId, setCountryId] = useState("");
  const [stekasSignupRequest, setStekasSignupRequest] = useState(true);
  const [deleteFlashMessage, setDeleteFlashMessage] = useState(true);
  const [showAddSchoolSessionDateModal, setShowAddSchoolSessionDateModal] = useState(false);
  const [field, setField] = useState({
    name: null,
    subjects: null,
    service: null,
    loading: false,
  });
  const [sponsors, setSponsors] = useState(null);
  const [modals, setModals] = useState(false);
  const [modal, setModal] = useState(false);
  //
  const [associateRequests, setAssociateRequests] = useState([]);
  const [openProcessedSchModal, setOpenProcessedSchModal] = useState(false);
  //
  const [requests, setRequests] = useState(null);
  const [loading, setLoading] = useState(false);
  const [globalCareerField, setGlobalCareerField] = useState([]);
  const campaign = useSelector((state) => state.campaign.refreshCampaign);
  const country = useSelector((state) => state.campaign.country);
  const associateProcessed = useSelector(state => state.associateProcessed.allProcessedassociates)
  const { showListOfProccessedAssociates,
    showListOfInProcessingAssociates,
  } = useSelector(state => state.associateProcessed.componentsStates)
  const { associatesProcessedByCountry,
    isLoading, } = useSelector(state => state.associateProcessed)

  const associatesStats = useSelector(state => state.associateProcessed.allInProcessingAssociatesByCountry)

  const processed = useSelector(state => state.stekaSlice.processedSchools)
  const countryCaptures = useSelector(state => state.stekaSlice.processedStekaSchoolsCountries)

  const { allCareerFieldsByCountry } = useSelector(state => state.careerFieldSlice)


  const { showListOfCareerFields } = useSelector(state => state.careerFieldSlice.componentsStates)

  const { allSponsoringOrganizationByCountry,
    allGlobalSponsoringOrg
  } = useSelector(state => state.sponsoringOrganizationSlice)
  const { showListOfOrganizations,
    showOrganizationCreateModal
  } = useSelector(state => state.sponsoringOrganizationSlice.componentsStates)

  const filterSTEKADataOfACountry = async (country) => {
    const route = `/api/steka/get-all-requests-from-country?country=${country}`;
    try {
      const { data } = await AxiosInstance.post(route);
      if (!data.error) {
        dispatch(setStekasInCountry(data.stekas));
      } else {
        Toast(data.message, "error");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchCountryAssociates = async (country) => {
    setLoading(true);
    const route = `/api/associate/requets-from-a-country?country=${country}`;
    try {
      const { data } = await AxiosInstance.post(route);
      if (data && data.associates) {
        const associatesInCountry =
          data.associates?.length > 0
            ? Array.from(data.associates).map((ass) => ass)
            : [];
        dispatch(setCountryAssociates(associatesInCountry));
      } else {
        Toast(data.message, "error");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };


  // const removeAssociate = async (associateId) => {
  //   const route = `/api/user/remove-associate/${associateId}`;
  //   const { data } = await AxiosInstance.delete(route);
  //   if (data) {
  //     setDeleteFlashMessage(data.message);
  //   }
  // };

  useEffect(() => {
    dispatch(getProcessedAssociates())
    dispatch(getAllAssociatesProcessedByCountry())
    dispatch(getAllInProcessingAssociatesByCountry())
    // dispatch(setShowListOfProccessedAssociates(false))
    dispatch(getProcessedStekaSchools())
    dispatch(getProcessedStekaSchoolsCountries())
    dispatch(getCareerFieldsByCountry())
    dispatch(setShowListOfCareerFields(false))
    dispatch(getCareerFields())
    dispatch(getSponsoringOrganizationByCountry())
    dispatch(getAllSponsoringOrganization())
    dispatch(getprocessedAssociatesUniqueCountries())
    dispatch(getGlobalSponsorinOrg())
  }, [getProcessedAssociates,
    getAllAssociatesProcessedByCountry,
    getCareerFields,
    getAllSponsoringOrganization,
    getAllInProcessingAssociatesByCountry,
    getprocessedAssociatesUniqueCountries,
    getGlobalSponsorinOrg
  ])

  useEffect(() => {
    dispatch(setRemoveCareerFieldPrompt(false))
  }, [])




  useEffect(() => {
    const fetchAllSchoolsOnSteka = async () => {
      setLoading(true);
      const route = `/api/steka/get-all-schools`;
      const { data } = await AxiosInstance.post(route);
      if (data && data.schools) {
        setAllSchools(data.schools);
        setLoading(false);
      }
    };
    fetchAllSchoolsOnSteka();
  }, [campaign]);

  useEffect(() => {
    const fetchWeeklyCaptures = async () => {
      setLoading(true);
      const route = `/api/steka/get-weekly-captures`;
      // const route= `/api/user/confirm-teachers-paginated?page=${page}&limit=${4}`;
      const { data } = await AxiosInstance.post(route);
      if (data && data.schools) {
        setWeeklycaptures(data.schools);
        setLoading(false);
      }
    };
    fetchWeeklyCaptures();
  }, [campaign]);

  useEffect(() => {
    const fetchProcessedStekaByCountry = async () => {
      setLoading(true);
      const route = `/api/steka/processed-schools-by-countries`;
      const { data } = await AxiosInstance.get(route);
      if (data && data.schools) {
        setProcessedStekaSchools(data.schools);
        setLoading(false);
      }
    };
    fetchProcessedStekaByCountry();
  }, [campaign]);


  useEffect(() => {
    const fetchAllStudents = async () => {
      setLoading(true);
      const route = `/api/user/get-all-students`;
      // const route= `/api/user/confirm-teachers-paginated?page=${page}&limit=${4}`;
      const { data } = await AxiosInstance.post(route);
      if (data && data.students) {
        setAllStudents(data.students);
        setLoading(false);
      }
    };
    fetchAllStudents();
  }, [campaign]);

  useEffect(() => {
    const fetchAllTeachers = async () => {
      setLoading(true);
      const route = `/api/user/get-all-teachers`;
      // const route= `/api/user/confirm-teachers-paginated?page=${page}&limit=${4}`;
      const { data } = await AxiosInstance.post(route);
      if (data && data.teachers) {
        setAllTeachers(data.teachers);
      }
      setLoading(false);
    };
    fetchAllTeachers();
  }, [campaign]);

  useEffect(() => {
    const fetchAllRequests = async () => {
      setLoading(true);
      const route = `/api/steka/get-all-requests`;
      const { data } = await AxiosInstance.post(route);
      if (data && data.steka) {
        setRequests(data.steka);
      }
      setLoading(false);
    };
    fetchAllRequests();
  }, [campaign]);

  useEffect(() => {
    const getAllAssociatesRequests = async () => {
      setLoading(true);
      const route = `/api/associate/all-associates-requests`;
      try {
        const { data } = await AxiosInstance.post(route);
        if (data && data.associates) {
          setAssociateRequests(data.associates);
        } else {
          // console.log(data.message);
        }
      } catch (error) {
        // console.log(error);
      }
    };
    getAllAssociatesRequests();
  }, []);

  const fieldModal = (e) => {
    if (e.target.name == "career" && e.target.value == "new") {
      toggleCreateFieldModal();
      e.target.value = ""
    }
    else if (e.target.name == "career") {
      setModals(true);
      e.target.value = ""
    }
    else if (e.target.name == "orgs") {
      setModal(true);
      e.target.value = ""
    }
  }

  useEffect(() => {
    const fetchOrgs = async () => {
      const reps = await AxiosInstance.post("/api/user/fetch-sponsoring-org");
      if (reps.data) {
        console.log(reps.data);
        setSponsors(reps.data.orgs);
      }
    }
    fetchOrgs();
  }, []);

  useEffect(() => {
    const globalCareerField = async () => {
      const reps = await AxiosInstance.get("/api/user/global-careerfields");
      if (reps.data) {
        setGlobalCareerField(reps.data.globalField);
      }
    }
    globalCareerField();
  }, []);

  return (
    <div className="relative h-full ">
      <div className="w-full flex mb-2 justify-between">
        <div className="text-lg font-bold ">Campaigns</div>
        <button
          onClick={() => {
            setShowAddSchoolSessionDateModal(true)
          }}
          className="bg-esgrown text-white drop-shadow-2xl px-2 py-2 ml-1 rounded-lg border border-esgrown transition-all duration-500 hover:-translate-y-1 focus:outline-none"
        >
          School Session Date
        </button>
      </div>
      <div className="flex justify-between w-full h-24 pb-2 ">
        <div className="bg-white rounded h-full w-[46%] pl-6 flex justify-center flex-col">
          <div className="text-lg font-semibold">Weekly Captures</div>
          <div className="text-lg font-semibold">
            {weeklycaptures && Array.from(weeklycaptures).length}
          </div>
        </div>
        <div className="bg-white rounded h-full w-[46%] pl-6 flex justify-center flex-col">
          <div className="text-lg font-semibold">Total Captured Schools</div>
          <div className="text-lg font-semibold">
            {allSchools && Array.from(allSchools).length}
          </div>
        </div>
      </div>
      <div className="flex justify-between w-full h-[85%]  mt-3 rounded-lg">
        <div className=" h-full w-[70%] flex flex-col rounded-lg">
          <div className="w-full h-[47%] bg-white rounded-lg shadow-md relative overflow-y-scroll">
            <div className="h-16 bg-[#D9D9D9] flex justify-between items-center rounded-t-lg sticky top-0">
              <p className="ml-10  font-bold flex items-center">
                <button
                  onClick={() => setStekasSignupRequest(true)}
                  className={`${stekasSignupRequest ? "underline text-lg text-[#1D6DAE] focus:outline-none" : ""}`}
                >
                  STEKA Sign Up Requests
                </button>
                <span className="h-[20px] w-[20px] ml-1 mt-[1px] rounded-full flex items-center justify-center text-xs animate-pulse bg-amber-50">
                  {requests && Array.from(requests).length}
                </span>
              </p>
              <p className="mr-3 mt-1  font-bold flex">
                <button
                  onClick={() => setStekasSignupRequest(false)}
                  className={`${!stekasSignupRequest ? "underline text-lg text-[#1D6DAE] focus:outline-none" : ""}`}
                >
                  Processed
                </button>
                <span className="h-[20px] w-[20px] ml-1 mt-[1px] rounded-full flex items-center justify-center text-xs bg-green-50">
                  {processed && Array.from(processed).length}
                </span>
              </p>
            </div>
            <div className="h-1 bg-[#1D6DAE] w-full"></div>


            {stekasSignupRequest ?
              <div className="min-h-min bg-inherit pl-1 px-0 overflow-y-scroll ">
                {countryCaptures && Array.from(countryCaptures).length > 0 ? (
                  Array.from(countryCaptures).map((country, index) => (
                    <div
                      key={index.toString()}
                      className="w-full even:bg-[#D9D9D9] flex justify-between h-10 mt-1.5 "
                    >
                      <div className="w-[50%] pl-3 flex justify-between items-center">
                        <p>{country._id} </p>
                        <p>{country.total}</p>
                      </div>
                      <div className="w-[15%] pr-3 flex justify-between items-center ">
                        <button
                          onClick={() => {
                            setSetShowListOfSchools(true);
                            setProcessedStekaSchoolsCountryId(country._id);
                            dispatch(setCountry(country._id));
                          }}
                          className="text-[#1D6DAE] focus:outline-none transition-all hover:-translate-y-1 duration-500"
                        >
                          view
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-full flex justify-center h-10 mt-1.5 ">
                    <div>No pending request 👏 </div>
                  </div>
                )}
              </div>
              :
              processedStekaSchools ?
                <div className="min-h-min bg-inherit pl-1 px-0 overflow-y-scroll ">
                  {processedStekaSchools && Array.from(processedStekaSchools)?.length > 0 ? (
                    Array.from(processedStekaSchools).map((country, index) => (
                      <div
                        key={index.toString()}
                        className="w-full even:bg-[#D9D9D9] flex justify-between h-10 mt-1.5 "
                      >
                        <div className="w-[50%] pl-3 flex justify-between items-center">
                          <p>{country._id} </p>
                          <p>{country.total}</p>
                        </div>
                        <div className="w-[15%] pr-3 flex justify-between items-center ">
                          <button
                            onClick={() => setProcessedStekaSchoolsModal(true)}
                            className="text-[#1D6DAE] focus:outline-none transition-all hover:-translate-y-1 duration-500"
                          >
                            view
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="w-full flex justify-center h-10 mt-1.5 ">
                      <div>No pending request 👏 </div>
                    </div>
                  )}
                </div>
                :
                <div className="w-full flex justify-center h-10 mt-1.5 ">
                  <div>No processed schools 👏 </div>
                </div>
            }
          </div>
          {/* Associates Signup requests */}
          <div className="w-full h-[47%] bg-white mt-4 shadow-md rounded-lg relative overflow-y-scroll z-0">
            <div className="h-16 bg-[#D9D9D9] flex justify-between items-center rounded-t-lg sticky top-0 z-0">
              <p className={"ml-10 font-bold  flex items-center"}>
                <button
                  onClick={() => setInProcessingRequestTabClicked(true)}
                  className={`${inProcessingRequestTabClicked ? "underline text-lg text-[#1D6DAE] p-1 focus:outline-none" : ""}`}
                >
                  In-Processing Requests
                </button>
                <span className="h-[20px] w-[20px] ml-1 mt-[1px] rounded-full flex items-center justify-center text-xs animate-pulse bg-amber-50">
                  {associateRequests?.length}
                </span>
              </p>
              <p className="mr-3 mt-1 font-bold flex justify-center">
                <button
                  onClick={() => {
                    dispatch(setOpenProcessedAssModal(true))
                    setInProcessingRequestTabClicked(false)
                    // dispatch(setShowListOfInProcessingAssociates(true));
                  }}
                  className={`${!inProcessingRequestTabClicked ? "underline text-lg text-[#1D6DAE] p-1 focus:outline-none" : ""}`}
                >
                  Associate
                </button>
                <span className="h-[20px] w-[20px] ml-1 mt-[1px] rounded-full flex items-center justify-center text-xs bg-green-50">
                  {associateProcessed?.length}
                </span>
              </p>
            </div>
            <div className="h-1 bg-[#1D6DAE] w-full"></div>

            {inProcessingRequestTabClicked ?
              <div className="min-h-min bg-inherit pl-1 px-0 overflow-y-scroll ">
                {associatesStats && associatesStats.length > 0 ? (
                  Array.from(associatesStats).map((country, index) => (
                    <div
                      key={index.toString()}
                      className="w-full even:bg-[#D9D9D9] flex justify-between h-10 mt-1.5 "
                    >
                      <div className="w-[50%] pl-3 flex justify-between items-center">
                        <p>{country._id} </p>
                        <p>{country.total}</p>
                      </div>
                      <div className="w-[15%] pr-3 flex justify-between items-center ">
                        <button
                          onClick={() => {
                            dispatch(setShowListOfInProcessingAssociates(true));
                            // dispatch(setCountry(country._id));
                            fetchCountryAssociates(country._id);
                            setCountryId(country._id)

                          }}
                          className="text-[#1D6DAE] focus:outline-none transition-all hover:-translate-y-1 duration-500"
                        >
                          view
                        </button>
                        <p>: :</p>
                      </div>
                    </div>
                  ))
                )
                  : (
                    <div className="w-full flex justify-center h-10 mt-1.5 ">
                      <div>No pending request 👏 </div>
                    </div>
                  )}
              </div>

              :

              <div className="min-h-min bg-inherit pl-1 px-0 overflow-y-scroll ">
                {!isLoading && associatesProcessedByCountry && associatesProcessedByCountry?.length > 0 ? (
                  Array.from(associatesProcessedByCountry).map((country, index) => (
                    <div
                      key={index.toString()}
                      className="w-full even:bg-[#D9D9D9] flex justify-between h-10 mt-1.5 "
                    >
                      <div className="w-[50%] pl-3 flex justify-between items-center">
                        <p>{country._id} </p>
                        <p>{country.total}</p>
                      </div>
                      <div className="w-[15%] pr-3 flex justify-between items-center ">
                        <button
                          onClick={() => {
                            dispatch(setShowListOfProccessedAssociates(true))
                            fetchCountryAssociates(country._id);
                            dispatch(setAssociatesCountry(country._id))
                          }}
                          className="text-[#1D6DAE] focus:outline-none transition-all hover:-translate-y-1 duration-500"
                        >
                          view
                        </button>
                      </div>
                    </div>
                  ))
                )
                  : (
                    <div className="w-full flex justify-center h-10 mt-1.5 ">
                      <div>No processed associates 👏 </div>
                    </div>
                  )}
              </div>
            }
          </div>
        </div>
        {/* Tragest audience  */}
        <div className="h-fit pb-20 w-[26%] flex flex-col rounded-lg ">
          <div onClick={toggleCreateFieldModal} className="w-full h-20 bg-[#1D6DAE] text-center text-white rounded-t-lg  flex justify-center items-center text-lg font-bold cursor-pointer">
            Career Paths / Areas of Knowledge Need
          </div>
          <div className="w-full h-[270px] bg-white shadow-md rounded-lg relative overflow-y-scroll z-0">
            <div className="min-h-min bg-inherit pl-1 px-0 overflow-y-scroll ">
                <div
                  className="w-full even:bg-[#D9D9D9] flex justify-between h-10 mt-1.5 "
                >
                  <div className="w-[50%] pl-3 flex justify-between items-center">
                    <p>Global </p>
                    <p>{globalCareerField?.length}</p>
                  </div>
                  <div className="w-[15%] pr-3 flex justify-between items-center ">
                    <button
                      onClick={() => {
                        dispatch(setShowListOfCareerFields(true))
                        dispatch(setCareerFieldCountry('global'))
                        dispatch(setRemoveCareerFieldPrompt(false))
                      }}
                      className="text-[#1D6DAE] focus:outline-none transition-all hover:-translate-y-1 duration-500"
                    >
                      view
                    </button>
                  </div>
                </div>
                {!isLoading && allCareerFieldsByCountry && allCareerFieldsByCountry.length > 0 ? (
                  Array.from(allCareerFieldsByCountry).map((field, index) => (
                    <>
                      {field._id != null ?
                        <div
                          key={index.toString()}
                          className="w-full even:bg-[#D9D9D9] flex justify-between h-10 mt-1.5 "
                        >
                          <div className="w-[50%] pl-3 flex justify-between items-center">
                            <p>{field._id} </p>
                            <p>{field.total}</p>
                          </div>
                          <div className="w-[15%] pr-3 flex justify-between items-center ">
                            <button
                              onClick={() => {
                                dispatch(setShowListOfCareerFields(true))
                                dispatch(setCareerFieldCountry(field._id))
                                dispatch(setRemoveCareerFieldPrompt(false))
                              }}
                              className="text-[#1D6DAE] focus:outline-none transition-all hover:-translate-y-1 duration-500"
                            >
                              view
                            </button>
                          </div>
                        </div>
                        : ""}
                    </>
                  ))
                )
                  : (
                    <div className="w-full flex justify-center h-10 mt-1.5">
                      <div>No Career Paths / Areas of Knowledge Need 👏 </div>
                    </div>
                  )}
              </div>
            </div>

            <div onClick={() => dispatch(setShowOrganizationCreateModal(true))} className="w-full h-20 bg-[#1D6DAE] text-center text-white rounded-t-lg  flex justify-center items-center text-lg font-bold cursor-pointer mt-2">
              UN SDG 4 Support Organizations
            </div>
            <div className="w-full h-[270px] bg-white shadow-md rounded-lg relative overflow-y-scroll z-0">
              <div className="min-h-min bg-inherit pl-1 px-0 overflow-y-scroll ">
                <div
                  key={"index.toString()"}
                  className="w-full even:bg-[#D9D9D9] flex justify-between h-10 mt-1.5 "
                >
                  <div className="w-[50%] pl-3 flex justify-between items-center">
                    <p>Global</p>
                    <p>{allGlobalSponsoringOrg?.length > 0 ? allGlobalSponsoringOrg?.length : 0}</p>
                  </div>
                  <div className="w-[15%] pr-3 flex justify-between items-center ">
                    <button
                      onClick={() => {
                        dispatch(setShowListOfOrganizations(true))
                        dispatch(setRemoveOrganizationPrompt(false))
                        dispatch(setSponsoringOrganizationCountry("Global"))
                      }}
                      className="text-[#1D6DAE] focus:outline-none transition-all hover:-translate-y-1 duration-500"
                    >
                      view
                    </button>
                  </div>
                </div>
                {!isLoading && allSponsoringOrganizationByCountry && allSponsoringOrganizationByCountry.length > 0 ? (
                  Array.from(allSponsoringOrganizationByCountry).map((organization, index) => (
                    <>
                      {organization._id != null ?
                        <div
                          key={index.toString()}
                          className="w-full even:bg-[#D9D9D9] flex justify-between h-10 mt-1.5 "
                        >
                          <div className="w-[50%] pl-3 flex justify-between items-center">
                            <p>{organization?._id} </p>
                            <p>{organization.total}</p>
                          </div>
                          <div className="w-[15%] pr-3 flex justify-between items-center ">
                            <button
                              onClick={() => {
                                dispatch(setShowListOfOrganizations(true))
                                dispatch(setRemoveOrganizationPrompt(false))
                                dispatch(setSponsoringOrganizationCountry(organization._id))
                              }}
                              className="text-[#1D6DAE] focus:outline-none transition-all hover:-translate-y-1 duration-500"
                            >
                              view
                            </button>
                          </div>
                        </div>
                        : ""}
                    </>
                  ))
                )
                  : (
                    <div className="w-full flex justify-center h-10 mt-1.5">
                      <div>No UN SDG 4 Support Organizations 👏 </div>
                    </div>
                  )}
              </div>
            </div>
            {/* <div onClick={toggleAddSponsoringOrg} className="w-full h-20 bg-[#1D6DAE] text-center text-white rounded-t-lg flex justify-center items-center text-lg font-bold mt-4">
            UN SDG 4 Support Organizations
          </div>
          <div className="w-full bg-white h-28 space-y-3 px-2 text-gray-600  flex flex-col text-xl font-semibold">
            <div className="w-full flex flex-row mt-2 space-evenly justify-center">  
              <label className="text-black mt-1 w-2/5 text-sm">{sponsors && Array.from(sponsors).length}</label>
              <button name = 'orgs' onClick = {fieldModal} className="text-blue-800 w-1/2 text-sm outline-0">
                  View
              </button>
            </div>
            {modal ? <SponsoringorgView 
                show={modal}
                onHide = {() => setModal(false)}
              />: ""}
           </div> */}
          </div>
        </div>
        {setShowListOfSchools && (
          <div
            className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}
          >
            <SchoolsInCountry
              setSetShowListOfSchools={setSetShowListOfSchools}
              filterSTEKADataOfACountry={filterSTEKADataOfACountry}
              countryCaptures={countryCaptures}
            />
          </div>
        )}
        {showListOfInProcessingAssociates && !isLoading && (
          <div
            className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}
          >
            <AssociatesInCountry
              countryId={countryId}
            />
          </div>
        )}
        {showListOfProccessedAssociates && !isLoading && (
          <div
            className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}
          >
            <ProcessedAssociates
              countryId={countryId}
            // removeAssociate={removeAssociate}
            />
          </div>
        )}

        {processedStekaSchoolsModal && (
          <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
            <ProcessedSchools
              setProcessedStekaSchoolsModal={setProcessedStekaSchoolsModal}
              processed={processed}
              processedStekaSchoolsCountryId={processedStekaSchoolsCountryId}
            />
          </div>
        )}

        {!isLoading && showListOfCareerFields && (
          <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
            <CareerPathView />
          </div>
        )}

        {!isLoading && showListOfOrganizations && (
          <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
            <OrganizationListModal />
          </div>
        )}

        {!isLoading && showOrganizationCreateModal && (
          <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
            <SponsoringOrg />
          </div>
        )}

        {showAddSchoolSessionDateModal &&
          (<div>
            <AddSchoolSessionDateModal
              setShowAddSchoolSessionDateModal={setShowAddSchoolSessionDateModal}
            />
            {/* <DarkOverlay density={70} z="z-0" /> */}
          </div>)
        }
      </div>
      );
}

      export default StekaCampain;
