import React, { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { useDispatch } from "react-redux";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";
import { Link } from "react-router-dom";

import { Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

import DropDownMenu from "../reusable/DropDownMenu";
import OnlineUser from "../reusable/OnlineUser";
import Toast from "../../util/toast";
import { setUser } from "../../redux/slices/userSlice";
import UCBIPayementModal from "../UCBIPayementModal";
import SmallScreenDrawer from "../SmallScreenDrawer";
import CustomSiderMenu from "../reusable/CustomSiderMenu";
import { useEffect } from "react";

const { Header, Sider, Content } = Layout;

const AssociateDashboard = ({ history }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [openUCBI, setOpenUCBI] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [dashData,setDashData] =useState(null);
  const [showSmallScreenDrawer, setShowSmallScreenDrawer] = useState(false);
  const [leader,setLeader] = useState("");

  const user = useSelector((state) => state.user.user);

  const handleClick = ({ key }) => {
    //you can perform setState here
  };
  const getData = async (id="")=>{
  const nid = (id===""?null:id);
  const data = await AxiosInstance.post("/api/campaign/data",{id:nid});
  console.log(data.data.data);
  setDashData(data.data.data);
  }
  useEffect(()=>{
    getData();
  },[setDashData])
  const defaultProfilePix =
    "https://esgrown.fra1.digitaloceanspaces.com/esgrown%201669801277885.jpg";

  return (
    <Layout
      style={{
        minHeight: "100vh",
        maxHeight: "100%",
        flexWrap: "nowrap",
        position: "relative",
      }}
    >
      {openUCBI && (
        <UCBIPayementModal setOpenUCBI={setOpenUCBI} ucbi={user.ucbi[0]} />
      )}

      <Sider
        trigger={null}
        className="sidebar-bg hidden md:block"
        collapsible
        collapsed={collapsed}
      >
        <div className="logo mt-3 mb-2 ml-5" />
        <CustomSiderMenu
          collapsed={collapsed}
          handleClick={handleClick}
          user={user}
        />
      </Sider>
      <Layout>
        <Header className="row pt-2 head">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3 hidden md:block",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          {/* This will only show for small devices  ////////////////////////*/}
          <div
            onClick={() => {
              setShowSmallScreenDrawer(!showSmallScreenDrawer);
            }}
            className="min-w-min h-full flex items-center cursor-pointer md:hidden"
          >
            <MenuFoldOutlined className="text-lg mb-3 mr-2" />
          </div>
          {/* ////////////////////////////////////////////////////// */}
          <h3 className="mt-1 ">Profile</h3>

          <ClickAwayListener onClickAway={() => setShowAccountMenu(false)}>
            <div className="ml-auto">
              <DropDownMenu
                user={user}
                show={showAccountMenu}
                bgColor="bg-white/30"
              />
              <div onClick={() => setShowAccountMenu(!showAccountMenu)}>
                <OnlineUser user={user} />
              </div>
            </div>
          </ClickAwayListener>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 10,
            // background: "#fff",
            minHeight: 280,
          }}
        >
          <div className="w-full mx-auto md:w-[94%] h-full flex flex-col relative ">
            {/* top section */}
            <div className="w-full flex flex-col p-3  bg-white rounded-lg mb-4">
              <div className="w-full flex justify-start items-center space-x-3">
                <div className="w-[100px] h-[100px]">
                  <img
                    src="/sdgs.PNG"
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="w-[78%] border-b pb-4 mt-3 flex justify-between ">
                  <div className="text-esgrown text-2xl font-bold ">
                    Your UN SDG 4 Campaign Status
                  </div>
                  {/* <select className=" px-2 py-2 border-2 rounded-lg focus:border-esgrown ">
                    <option value="">Select Job Vacancy</option>
                  </select> */}
                  <div className="flex justify-between items-center space-x-2">
                    <div className="text-lg text-bold">State:</div>
                    <div className="text-lg mt-1">{dashData!=null?dashData.levelInfo.state:''}</div>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-between px-4 mt-3">
                
                {
                  dashData!=null?(<>
                  {["Associate First","Praetor Lieutenant","Aedile","Praetor"].includes(dashData.rank)?(<>
                    <div className="w-[28%] text-xl">
                  <span className="font-bold">League:</span> {dashData!=null?dashData.others.league.name:''}
                </div>
                </>):(<></>)}
                  </>):(<></>)
                }
                {
                  dashData!=null?(<>
                  {["Associate First","Praetor Lieutenant","Aedile"].includes(dashData.rank)?(<>
                    <div className="w-[28%] text-xl">
                  <span className="font-bold">Guard:</span> {dashData!=null?dashData.others.guard.name:''}
                </div>
                </>):(<></>)}
                  </>):(<></>)
                }
                {
                  dashData!=null?(<>
                  {["Associate First","Praetor Lieutenant"].includes(dashData.rank)?(<>
                    <div className="w-[28%] text-xl">
                  <span className="font-bold">Team:</span> {dashData!=null?dashData.others.team.name:''}
                </div>
                </>):(<></>)}
                  </>):(<></>)
                }
                {
                  dashData!=null?(<>
                  {dashData.rank==="Praetor Lieutenant"?(<>
                    <select className="w-[28%] px-2 py-2 border-2 rounded-lg focus:border-esgrown" onChange={(e)=>{
                      console.log(e.target.value);
                      getData(e.target.value)
                    }}>
  <option value="">All personnel</option>
  {dashData.others.team.members.map((data) => (
    <option key={data.uniqueId} value={data.uniqueId}>
      {data.name}
    </option>
  ))}
</select>
                </>):(<></>)}
                  {dashData.rank==="Aedile"?(<div style={{width:"200px"}}>
                    <select className="w-[100%] px-2 py-2 border-2 rounded-lg focus:border-esgrown" onChange={(e)=>{
                       console.log(e.target.value);
                       let sl = e.target.value;
                       if(sl.includes("---")){
                         sl = sl.split("---");
                         getData(sl[0]);
                         setLeader(sl[1]);
                         return;
                       }
                       getData(e.target.value)
                       setLeader("");
                    }}>
  <option value="">All Teams</option>
  {dashData.others.guard.members.map((data) => (
    <option key={data.uniqueId} value={data._id +"---"+ data.name}>
      {data.orgName}
    </option>
  ))}
</select>
                </div>):(<></>)}
                  {dashData.rank==="Praetor"?(<div style={{width:"200px"}}>
                    <select className="w-[100%] px-2 py-2 border-2 rounded-lg focus:border-esgrown" onChange={(e)=>{
                      console.log(e.target.value);
                      let sl = e.target.value;
                      if(sl.includes("---")){
                        sl = sl.split("---");
                        getData(sl[0]);
                        setLeader(sl[1]);
                        return;
                      }
                      getData(e.target.value)
                      setLeader("");
                    }}>
  <option value="">All Guards</option>
  {dashData.others.league.members.map((data) => (
    <option key={data.uniqueId} value={data._id +"---"+ data.name}>
      {data.orgName}
    </option>
  ))}
</select>
<div>{leader}</div>
                </div>):(<></>)}
                  </>):(<></>)
                }
                
              </div>
              <div className="w-full border-b my-3"></div>
              <div className="w-full flex justify-between pr-4 pl-8 gap-6">
            
                  <div className="w-[25%] shadow rounded-lg h-[90%] flex flex-col items-center justify-between py-4">
                    <p className="text-xl font-bold">Daily Captures</p>
                    <p className="text-xl font-semibold">{dashData!=null?dashData.todayCaptures:''} </p>
                  </div>
                  <div className="w-[25%] shadow rounded-lg h-[90%] flex flex-col items-center justify-between py-4">
                    <p className="text-xl font-bold">Weekly Captures</p>
                    <p className="text-xl font-semibold">{dashData!=null?dashData.weekCaptures:''} </p>
                  </div>
                  <div className="w-[25%] shadow rounded-lg h-[90%] flex flex-col items-center justify-between py-4">
                    <p className="text-xl font-bold">Monthly Captures</p>
                    <p className="text-xl font-semibold">{dashData!=null?dashData.monthCaptures:''} </p>
                  </div>
                  <div className="w-[25%] shadow rounded-lg h-[90%] flex flex-col items-center justify-between py-4">
                    <p className="text-xl font-bold">Total Captures</p>
                    <p className="text-xl font-semibold">{dashData!=null?dashData.totalCaptures:''} </p>
                  </div>
                
              </div>
              <div className="w-full flex space-x-4 md:space-x-20 pr-4 md:px-16 mt-10">
                
                {
                  dashData!=null?(<>
                  {dashData.rank==="Associate First"?(<>
                    <div className="text-xl">
                    <progress value={dashData.monthCaptures} max="40"></progress> {/* invite message at stake*/}
                    <div style={{fontSize:"0.6em"}}>Monthly Progress {dashData.monthCaptures} of 40 basic target entity captures</div>
                </div>
                </>):(<></>)}
                  </>):(<></>)
                }
                {
                  dashData!=null?(<>
                  {["Aedile","Praetor Lieutenant","Praetor"].includes(dashData.rank)?(<>
                    <div className="text-xl">
                  Asigned Formation Advisors:
                  <span className="font-bold">{dashData!=null?dashData.others.team.members.length:''}</span>
                </div>
                </>):(<></>)}
                  </>):(<></>)
                }
                {
                  dashData!=null?(<>
                  {dashData.rank==="Praetor Lieutenant"?(<>
                    <div className="text-xl">
                Team Weekly Captures:
                  <span className="font-bold">{dashData!=null?dashData.others.allTeam.month:''}</span>
                </div>
                </>):(<></>)}
                  </>):(<></>)
                }
                
              </div>
              <div className="w-full flex space-x-4 md:space-x-20 pr-4 md:px-16 mt-10">
                
                {
                  dashData!=null?(<>
                  {["Aedile","Praetor Lieutenant","Praetor"].includes(dashData.rank)?(<>
                    <div className="text-xl">
                  My Captures Today:
                  <span className="font-bold">{dashData!=null?dashData.mydata.today:''}</span>
                </div>
                </>):(<></>)}
                  </>):(<></>)
                }
                {
                  dashData!=null?(<>
                  {["Aedile","Praetor Lieutenant","Praetor"].includes(dashData.rank)?(<>
                    <div className="text-xl">
                  My Weekly Captures:
                  <span className="font-bold">{dashData!=null?dashData.mydata.week:''}</span>
                </div>
                </>):(<></>)}
                  </>):(<></>)
                }
                {
                  dashData!=null?(<>
                  {["Aedile","Praetor Lieutenant","Praetor"].includes(dashData.rank)?(<>
                    <div className="text-xl">
                  My Monthly Captures:
                  <span className="font-bold">{dashData!=null?dashData.mydata.month:''}</span>
                </div>
                </>):(<></>)}
                  </>):(<></>)
                }
                
              </div>
            </div>
            {/* second section  */}
            {
           dashData!=null?( <div className="w-full flex flex-col  bg-white rounded-lg p-3">
              <div className="w-full flex justify-start items-center ">
                <div className="w-12 h-[44px] ml-8 rounded-full border-4 border-esgrown "></div>
                <div className="w-full border-b pb-4 mt-3 text-2xl font-bold flex ml-10" style={{justifyContent:"space-between"}}>
                  <div className="text-esgrown">
                    {dashData.rank==="Associate First"?"Campaign Vault":""}
                    {dashData.rank==="Praetor Lieutenant"?"City Campaign Statistics":""}
                    {dashData.rank==="Aedile"?"State Campaign Statistics":""}
                    {dashData.rank==="Praetor"?"Country Campaign Statistics":""}
                  </div>
                  <div className="text-esgrown">
                  {["Praetor Lieutenant"/*,"Aedile","Praetor"*/].includes(dashData.rank)?"Your Campaign Vault":""}
                  </div>
                </div>
              </div>
              
              <div className="w-full flex justify-between py-3">
                {/* first panel  */}
                {
                  dashData.rank==="Associate First"?(<>
                  <div className="w-[46%] rounded-lg h-[144px] flex flex-col pl-3 justify-between py-10">
                  <div className="font-semibold text-lg">
                    Earned Credit: N00000
                  </div>
                </div>
                  </>):(<></>)
                }
                {
                  dashData.rank==="Praetor Lieutenant"?(<>
                  <div className="w-[46%] rounded-lg h-[144px] flex flex-col pl-3 justify-between py-10">
                  <div className="font-semibold text-lg">
                    Earned Credit: N00000
                  </div>
                  <div className="font-semibold text-lg">
                    Leadership Emolument: N0000
                  </div>
                </div>
                  </>):(<></>)
                }
                {
                  dashData.rank==="Praetor"?(<>
                  <div className="w-[46%] rounded-lg  flex flex-col pl-3 justify-between py-10">
                  <div className="font-semibold text-lg">
                  Number of Active Leagues: {dashData.others.totalLeagues}
                  </div>
                  <div className="font-semibold text-lg">
                  Weekly Target entity captures:    100 
                  </div>
                  <div className="font-semibold text-lg">
                  Monthly Target  entity captures:   1000  
                  </div>
                  <div className="font-semibold text-lg">
                  Total Target Entity Captures:     50,000 
                  </div>
                  <div className="font-semibold text-lg">
                  Country Campaign Objective:           650,000  
                  </div>
                  
                </div>
                  </>):(<></>)
                }
                {
                  dashData.rank==="Praetor Lieutenant"?(<>
                  <div className="w-[46%] rounded-lg  flex flex-col pl-3 justify-between py-10">
                  <div className="font-semibold text-lg">
                  Number of Active Teams:{dashData.others.totalTeams}
                  </div>
                  <div className="font-semibold text-lg">
                  Weekly Target entity captures:    100 
                  </div>
                  <div className="font-semibold text-lg">
                  Monthly Target  entity captures:   1000  
                  </div>
                  <div className="font-semibold text-lg">
                  Total Target Entity Captures:     50,000 
                  </div>
                  
                </div>
                  </>):(<></>)
                }
                {
                  dashData.rank==="Aedile"?(<>
                  <div className="w-[46%] rounded-lg  flex flex-col pl-3 justify-between py-10">
                  <div className="font-semibold text-lg">
                  Number of Active Guards: {dashData.others.totalGuards}
                  </div>
                  <div className="font-semibold text-lg">
                  Weekly Target entity captures:    100 
                  </div>
                  <div className="font-semibold text-lg">
                  Monthly Target  entity captures:   1000  
                  </div>
                  <div className="font-semibold text-lg">
                  Total Target Entity Captures:     50,000 
                  </div>
                  
                </div>
                  </>):(<></>)
                }

                {/* second panel  */}
               {
                ["Associate First","Praetor Lieutenant"].includes(dashData.rank)?(<>
                 <div className="w-[46%] rounded-lg h-[144px] flex flex-col pl-3 items-end pr-4 justify-between py-6">
                  <div className="px-4 py-2 w-fit border-b-2 border-b-gray-200">
                    N{dashData.balance.toLocaleString()}
                  </div>
                  <button className="px-4 py-2 w-fit bg-green-300 text-white rounded-lg focus:outline-none hover:translate-x-1 duration-500 transition-all"
                        onClick={() => setOpenUCBI(true)}
                        disabled={user?.ucbi[0]?.payRequested}
                         >
                        {user?.ucbi[0]?.payRequested
                          ? "Processing.."
                          : "withdraw"}
                      </button>
                
                </div>
                </>):(<></>)
               }
              </div>
            </div>):(<></>)}
          </div>
        </Content>
      </Layout>
      {showSmallScreenDrawer && (
        <SmallScreenDrawer
          setShowSmallScreenDrawer={setShowSmallScreenDrawer}
          user={user}
        />
      )}
      {openUCBI && (
        <UCBIPayementModal setOpenUCBI={setOpenUCBI} ucbi={user.ucbi[0]} />
      )}
    </Layout>
  );
};

export default AssociateDashboard;
