import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Menu } from "antd";
import { BiCircle } from 'react-icons/bi';
import { Popover } from "antd";
import Avatar from 'react-avatar';
import Toast from "../util/toast";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import {
  getPostsForStudents,
  getPostsForTeachers
} from "../redux/slices/postSlice";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  ScheduleOutlined,
  RedoOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import {
  imgCompany,
  imgGeneric,
  imgPrivate,
  imgSchool,
  imgStudent,
  imgTeacher,
} from "../img";
import UpdateContainer from "./UpdateContainer";
import DropDownMenu from "./reusable/DropDownMenu";
import OnlineUser from "./reusable/OnlineUser";
import ClickAwayListener from "react-click-away-listener";
import SmallScreenDrawer from "./SmallScreenDrawer";
import { getUpdatedDataOfUser } from "../util/services";
import { setCountry, setUser } from "../redux/slices/userSlice";
import SocialButtons from "./reusable/SocialButtons";
import { useLayoutEffect } from "react";
import { paystackConfig } from "./paystack/paystackConfig";
import { usePaystackPayment } from "react-paystack";
import CustomSiderMenu from "./reusable/CustomSiderMenu";
import moment from "moment";
import AxiosInstance from "../AxiosInstance/AxiosInstance";
import DeletePostConfirmationModal from "./campaigns/steka/modals/DeletePostConfirmationModal";
import _ from "lodash"


const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;


const IndividualPage = ({ history }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [updates, setUpdates] = useState();
  const [showAccountMenu, setshowAccountMenu] = useState(false);
  const [showSmallScreenDrawer, setShowSmallScreenDrawer] = useState(false);
  const user = useSelector((state) => state.user.user);
  const country = useSelector((state) => state.user.country);
  const [files, setFiles] = useState([]);
  const [postDescription, setPostDescription] = useState(null);
  const [showAllPosts, setShowAllPosts] = useState(true);
  const [showAllUpdates, setShowAllUpdates] = useState(true);
  const [postCategory, setPostCategory] = useState();
  const [postObject, setPostObject] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isPostDeleted, setIsPostDeleted] = useState(false);
  const [isPostCreated, setIsPostCreated] = useState(false);
  const [isPostEdited, setIsPostEdited] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [showDeletePostModal, setShowDeletePostModal] = useState(false);
  const [postID, setPostID] = useState();
  const [allBasicUpdates, setAllBasicUpdates] = useState([]);
  const [userPremiumUpdate, setUserPremiumUpdate] = useState([]);
  const [allUpdates, setAllUpdates] = useState([]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const getUpdates = async () => {
    const url = "/api/update/get-all-basic-updates"
    const basicUpdates = await AxiosInstance.get(url)
    const { data } = basicUpdates
    //setAllBasicUpdates(data?.updates)
  };

  const getUserPremiumUpdate = async () => {
    const url = `/api/update/get-premium-updates?user=${user?._id}`
    const premium = await AxiosInstance.get(url)
    const { data } = premium
    //setUserPremiumUpdate(data?.premiumUpdates)
  };

  //let allUpdates = [];
  if (userPremiumUpdate?.length > 0 || allBasicUpdates?.length > 0 ) {
    //allUpdates = [...userPremiumUpdate, ...allBasicUpdates]
  }
  else {
    //allUpdates = []
  }


  useEffect(() => {
    getUpdates()
    getUserPremiumUpdate()
    // setAllUpdates([...userPremiumUpdate, ...allBasicUpdates])
  }, [])


  useEffect(() => {
    if (editMode) {
      // setFiles([...postObject?.images, ...postObject?.videos])
      setPostCategory(postObject?.postCategory)
      setPostDescription(postObject?.postDescription)
    }
  }, [editMode])


  const fixedUCBIActivationCost = 5000;
  const config = paystackConfig(user.email, fixedUCBIActivationCost);

  // you can call this function anything
  const paystackOnClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const paystackOnSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    AxiosInstance.post("/api/ucbi/activate-staff-ucbi")
      .then((response) => {
        console.log(response.data);
        if (response.data && response.data?.user) {
          const user = { ...response.data.user, loggeIn: true };
          dispatch(setUser(user));
          Toast("UCBI Successfully activated for staff!", "success");
        }
      })
      .catch((error) => Toast(error.message, "error"));
  };

  // const initializePayment = usePaystackPayment(config);

  // const paystackCheckout = async (service) => {
  //   try {
  //     initializePayment(paystackOnSuccess, paystackOnClose);
  //   } catch (error) {
  //     console.log(error);
  //     Toast(error.message, "error");
  //   }
  // };

  const toggle = () => {
    setCollapsed(!collapsed);
  };


  const fileRef = useRef(null);

  const handFileChange = (event) => {
    event.preventDefault()
    setFiles([...files, event.target.files[0]]);
  };

  const handlePost = async (e) => {
    e.preventDefault()
    setIsCreating(true)
    const fd = new FormData()
    for (let i = 0; i < files.length; i++) {
      fd.append('file', files[i])
    }
    fd.append('postDescription', postDescription)
    fd.append('postCategory', postCategory)
    if (!postCategory) {
      setIsCreating(false)
      return Toast("Please select who can view post", "error");
    }
    if (!postDescription) {
      setIsCreating(false)
      return Toast("Post body cannot be empty.", "error");
    }
    await AxiosInstance.post("/api/post/create-new-post", fd)
      .then(res => {
        setPostCategory("")
        setPostDescription("")
        setFiles([])
        setIsPostCreated(!isPostCreated)
        setShowAllUpdates(false)
        setShowAllPosts(true)
        setIsCreating(false)
        Toast("Post has been uploaded successfully.", "success");
      })
      .catch(error => {
        if (error) {
          Toast("Please check your network connectivity", "error");
        }
      })

  }


  const handlePostEdit = async (e) => {
    e.preventDefault()
    setIsCreating(true)
    const data = {
      postCategory,
      postDescription
    }
    await AxiosInstance.patch(`/api/post/edit-post/${postObject?._id}`, data)
      .then(res => {
        setPostCategory("")
        setPostDescription("")
        // setFiles([])
        setIsCreating(false)
        setEditMode(false)
        setIsPostEdited(!isPostEdited)
        Toast("Post has been edited successfully.", "success");
      })
      .catch(error => {
        if (error) {
          Toast("Please check your network connectivity", "error");
        }
      })

  }

  const handlePostDelete = async (id) => {
    await AxiosInstance.delete(`/api/post/delete-post/${id}`)
      .then(res => {
        Toast("Post has been deleted", "success");
      })
      .catch(error => {
        if (error) {
          Toast("Post was not deleted", "error");
        }
      })

  }

  const { postsForStudents, postsForTeachers } = useSelector(state => state.postSlice)
  const postToDisplay = user?.userType == 'teacher' ? postsForTeachers : user?.userType == 'student' ? postsForStudents : ""
  const handleClick = ({ key }) => {
    // console.log(key);
    //you can perform setState here
  };

  useEffect(() => {
    const refreshUserData = async () => {
      const u = await getUpdatedDataOfUser(user._id);
      if (u && u?.user) {
        dispatch(setUser(u.user));
      }
    };
    refreshUserData();
  }, []);

  useEffect(() => {
    dispatch(getPostsForStudents());
    dispatch(getPostsForTeachers());
  }, [isPostDeleted, isPostCreated, isPostEdited]);

  useLayoutEffect(() => {
    const getGeoInfo = async () => {
      AxiosInstance.get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          dispatch(setCountry(data.country_name));
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getGeoInfo();
  }, []);

  const getUpdate = async () => {
    if (user.isAdmin == false) {
      const update = await AxiosInstance.post('/api/update/get-updates', {
        country: user.country,
        state: user.state,
        region: user.province,
        rank: user.rank,
        sub: user.UCBIActive
      });
      console.log(update,"MYLOG")
      if (update) {
        //allUpdates=[...update.data.update];
        setAllUpdates(update.data.update)
        //setUpdates(update.data.update);
       // setAllBasicUpdates(allUpdates)
        console.log(allUpdates,"allnew")
      }
    }
  };
  useEffect(() => {
    
    getUpdate();
  }, []);

  useLayoutEffect(() => {
    if (user && user.name) {
      if (user.isAdmin) {
        return history.push("/adminDashboard");
      }
      if (user.sponsoringOrg) {
        return history.push("/sponsoring-orgs");
      }
    }
  }, []);

  console.log("allUpdates", allUpdates)

  return (
    <Layout
      style={{
        minHeight: "100vh",
        maxHeight: "100%",
        flexWrap: "nowrap",
        position: "relative",
      }}
    >
      <Sider
        trigger={null}
        className="sidebar-bg hidden md:block"
        collapsible
        collapsed={collapsed}
      >
        <div className="logo mt-3 mb-2 ml-5" />
        <CustomSiderMenu
          collapsed={collapsed}
          handleClick={handleClick}
          user={user}
        />
      </Sider>
      <Layout>
        <Header className="row pt-2 head bg-gray-300">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3 hidden md:block",
              onClick: toggle,
            }
          )}

          {/* This will only show for small devices  ////////////////////////*/}
          <div
            onClick={() => {
              setShowSmallScreenDrawer(!showSmallScreenDrawer);
            }}
            className="min-w-min h-full flex items-center cursor-pointer md:hidden"
          >
            <MenuFoldOutlined className="text-lg mt-2 mr-2" />
          </div>
          {/* ////////////////////////////////////////////////////// */}
          <div className="md:mt-1 font-bold sm:text-base  md:text-xl">
            Welcome, {user?.name}
          </div>

          <ClickAwayListener onClickAway={() => setshowAccountMenu(false)}>
            <div className="ml-auto">
              <DropDownMenu
                user={user}
                show={showAccountMenu}
                bgColor="bg-white"
              />
              <div onClick={() => setshowAccountMenu(!showAccountMenu)}>
                <OnlineUser user={user} />
              </div>
            </div>
          </ClickAwayListener>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 10,
            // background: "#fff",
            minHeight: 280,
            width: "100%",
          }}
        >


          <div className="w-full container-fluid ">
            <div className="w-full flex pb-10 mb-5">
              <div className="w-full col-lg-8">
                <div className="intro pb-5  rounded-md">
                  {/* <div className="logo mb-2 " /> */}
                  {/* <div className="text-white text-xs md:text-sm  text-justify ">
                    As a unit of {country}’s education system, we are commitment
                    is helping you ensure strengthening quality and
                    functionality education in your school in adequately equips
                    students with real life success, in line with the UN
                    Sustainable Development Goals for countries on quality
                    education that meets knowledge and skill needs for
                    addressing present world economic, social and every.
                  </div> */}
                  {user?.userType === "school" &&
                    <div className="text-white text-xs md:text-sm  text-justify ">
                     Weclome to our world community. The world is literally incomplete without you. 
                     We highly regard you as unit of the world education system. Our commitment is 
                     to help you be at your very best in delivery of functional education that builds 
                     appreciation for shared positive values and healthy communal instincts on which 
                     the chance of sustainable world peace and social order depends, while also equipping 
                     successive generations of learners with the practical knowledge and skills needed for 
                     sustainably addressing real world needs and in pushing the boundaries of human progress on all fronts
                    </div>
                  }
                  {user?.userType === "company" &&
                    <div className="text-white text-xs md:text-sm  text-justify ">
                      Welome to Esgrown world community. Our world and, really, the world is literally
                      incomplete without you. We highly regard you as unit of the world education system.
                      Our commitment is to help you be at your very best in delivery of functional education
                      that builds appreciation for shared positive values and healthy communal instincts on which
                      the chance of sustainable world peace and social order depends, while also equipping successive
                      generations of learners with the practical knowledge and skills needed for sustainably addressing
                      real world needs and in pushing the boundaries of human progress on all fronts
                    </div>
                  }
                  {(user?.userType === "individual" || user?.userType === "teacher") &&
                    <div className="text-white text-xs md:text-sm  text-justify ">
                      Welcome to Esgrown world community. Our world and, really, the world is literally incomplete without you.
                      Our commitment is to support become your very best, and to attain real life success by progressively building
                      your knowledge and skills, and continuously advancing your capacity in applying knowledge, skills and healthy
                      attitude in pushing forward the boundary of your success on all fronts.
                    </div>
                  }
                </div>

                {/* {user.userType === "school" && !user.UCBIActive && ( */}
                {!user?.profileUpdated && (
                  <div className="w-full  bg-white rounded-lg p-2 mt-2 shadow-sm ">
                    {/* update profile  */}
                    <div className="text-xs md:text-sm">
                      Update your profile to start subscription for Esgrown
                      Premium Services
                    </div>
                    <button
                      onClick={() => {
                        history.push("/profile");
                      }}
                      className="my-2 border rounded-lg text-xs md:text-sm px-2 py-1 hover:bg-[#1D6DAE] hover:text-white transition-all duration-700"
                    >
                      Update Profile
                    </button>
                  </div>
                )}
                {/* )} */}
                <form>
                  <div className="w-full items-center flex justify-between mt-4 ">
                    <div className="flex">
                      <h5 className="mr-2 font-semibold">Updates</h5>
                      <RedoOutlined className="max-w-fit p-1 text-white cursor-pointer rounded-full bg-gray-400 transition-all duration-500 ease-out hover:rotate-180 " />
                    </div>
                    <div className="flex mr-2">
                      <div
                        className={`font-bold cursor-pointer ${showAllUpdates ? 'text-[#1D6DAE] border-b-2 border-[#1D6DAE]' : ''}`}
                        onClick={() => {
                          setShowAllUpdates(true)
                          setShowAllPosts(true)
                        }}
                      >
                        All Updates
                      </div>
                      <div className="mr-2 ml-2 font-semibold"> | </div>
                      <div
                        className={`font-bold cursor-pointer ${showAllPosts ? 'text-[#1D6DAE] border-b-2 border-[#1D6DAE]' : ''}`}
                        onClick={() => {
                          setShowAllUpdates(false)
                          setShowAllPosts(true)
                        }}
                      >
                        Post
                      </div>
                    </div>
                  </div>

                  {showAllPosts &&
                    <div className="w-full items-center flex justify-end mt-1 ">
                      <div className="flex items-center">
                        <div className="">Share thoughts and encouragement with:</div>
                        <div className="p-1">
                          <select
                            id="select"
                            className="mySelect form-control p-0 m-0 text-xs"
                            value={postCategory}
                            onChange={(e) => {
                              setPostCategory(e.target.value)
                            }}
                          >
                            <option value="">Who can see post?</option>
                            <option>Teachers and Students</option>
                            <option>Teachers</option>
                            <option>Students</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  }

                  {/* text area */}
                  {showAllPosts &&
                    <div className="bg-white rounded h-auto w-full flex-col pt-2 mt-3 mb-5">
                      <div className="flex px-3 mt-2 w-full space-x-3 justify-between">
                        <textarea
                          name="post description"
                          onChange={(e) => setPostDescription(e.target.value)}
                          value={postDescription}
                          // placeholder={applicableService == "" ? "" :
                          //             applicableService == "Education Advisory" ? "Add comma separated list of required subjects (Example, Basic Science, Mathematics, Physics, Civics, Social Studies)" 
                          //   :"Add comma separated list of subjects or modules (HTML, CSS, JavaScript)"}
                          className="w-full h-60 resize-none bg-gray-200 outline-none rounded-lg px-2 py-2 text-xs"
                        ></textarea>
                      </div>
                      <div className='flex justify-between mt-4 p-0 mr-0 pb-4'>
                        <div className="px-3 flex">
                          {!editMode ?
                            <div>
                              <button
                                onClick={(e) => {
                                  e.preventDefault()
                                  fileRef.current.click()
                                }}
                                className="bg-success1 text-white outline-0 outline-none px-2 py-1 rounded mr-2 text-xs"
                              >Add Image</button>
                              <button
                                onClick={(e) => {
                                  e.preventDefault()
                                  fileRef.current.click()
                                }}
                                className="bg-success1 text-white outline-0 outline-none px-2 py-1 rounded text-xs"
                              >Add Video</button>
                            </div>
                            : ""}
                        </div>
                        <div className="px-3 flex justify-end">
                          <div>
                            {
                              isCreating ? <Spin indicator={antIcon} /> :
                                <button
                                  onClick={(e) => {
                                    !editMode ? handlePost(e) : handlePostEdit(e)
                                  }}
                                  className="bg-success1 text-white outline-0 outline-none px-3 py-1 rounded"
                                >{!editMode ? "Share" : "Edit"}</button>
                            }
                          </div>
                        </div>
                      </div>
                      {
                        files ?
                          files.map((file, index) => <div className="border-2 w-auto mb-1 border-esgrown px-3" key={index}>{file?.name}</div>) : ""
                      }
                    </div>
                  }
                </form>


                {showAllUpdates ?
                  <div className="">
                    {
                      allUpdates.map(update => {
                        return (
                          <div className="bg-white rounded-md h-auto w-full flex-col pt-2 mt-5 mb-1">
                            <div className="flex justify-between items-center mx-3 my-3 border-b border-[#244060]">
                              <div className="flex justify-start items-center">
                                <div className="mr-2"><BiCircle size={35} color="#1D6DAE" strokeWidth={2} /></div>
                                <div className="flex-cols">
                                  <div className="flex text-lg font-semibold text-[#1D6DAE]">
                                    <div>{_.startCase(update?.title)}</div>
                                    {update?.subject ?
                                      <>
                                        <p className="font-semibold mx-1"> :: </p>
                                        <div>{_.startCase(update?.subject)}</div>
                                      </>
                                      : ""}
                                  </div>
                                  <div className="flex text-xs text-slate-400">{update?.postCategory} :: {update?.serviceId?.title} </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-end items-center mx-3 mb-2 italic text-xs">{moment(update?.createdAt).fromNow()}</div>
                            <div className="mt-3">{update?.description}</div>
                            <div className="mt-1">
                              {update?.images?.map(image => {
                                return <img className="w-full h-[400px] border-b-2 border-red-500 mt-2" src={image} alt={image} />
                              })
                              }
                              {update?.videos?.map(video => {
                                return (
                                  <video controls className="w-full mt-2">
                                    <source src={video} type="video/mp4" />
                                    <source src={video} type="video/webm" />
                                  </video>
                                )
                              })
                              }
                            </div>
                            {update?.externalURLS === null || update?.externalURLS === "" ? "" : (<div className="mt-3 mx-3"> <i>reference: <a href={update?.externalURLS} target="_blank"><span className="text-esgrown">{update?.externalURLS}</span></a></i></div>)}
                            <div className="mt-2 border-4 mx-[300px] border-b border-[#244060]"></div>
                          </div>
                        )
                      })
                    }
                  </div>
                  :
                  <div className="">
                    {
                      postToDisplay.length > 0 && postToDisplay?.map(post => {
                        return (
                          <div className="bg-white rounded-md h-auto w-full flex-col pt-2 mt-5 mb-1 relative">
                            <div className="flex justify-between items-center mx-3 my-3 border-b border-[#244060] relative">
                              <div className="flex justify-start items-center mb-1">
                                <Avatar name={post?.postedBy?.name} size="80" round={true} />
                                <div className="flex-cols">
                                  <div className="flex text-lg font-semibold ml-2 text-[#1D6DAE]">
                                    <div>{post?.postedBy?.name}</div>
                                  </div>
                                </div>
                              </div>
                              {/* {!removeOrganizationPrompt && !showOrganizationPopOver && ( */}

                              {user._id == post?.postedBy?.id ? <Popover
                                placement="left"
                                trigger="clicking"
                                content={showDeletePostModal ?

                                  <div className="">
                                    <DeletePostConfirmationModal
                                      showDeletePostModal={showDeletePostModal}
                                      setShowDeletePostModal={setShowDeletePostModal}
                                      id={postID}
                                      handlePostDelete={handlePostDelete}
                                      setIsPostDeleted={setIsPostDeleted}
                                      isPostDeleted={isPostDeleted}
                                    />
                                  </div>

                                  :
                                  <div className="min-w-min min-h-min flex flex-col items-start justify-start space-y-2 ">
                                    <button
                                      onClick={() => {
                                        setPostObject(post)
                                        setEditMode(true)
                                        window &&
                                          window.scrollTo({
                                            top: 150,
                                            left: 0,
                                            behavior: "smooth",
                                          });
                                      }}
                                      className="px-2 outline-none  focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={() => {
                                        setPostObject(post)
                                        setPostID(post._id)
                                        setShowDeletePostModal(true)
                                      }
                                      }
                                      className="px-2 outline-none focus:outline-none rounded-full text-red-400 transition-all duration-500 hover:-translate-y-1 hover:bg-red-300 hover:text-white"
                                    >
                                      Delete
                                    </button>
                                  </div>}
                              >
                                <div className="actionButton cursor-pointer"></div>
                              </Popover> : ""
                              }
                            </div>
                            <div className="flex justify-end items-center mx-3 mb-2 italic text-xs">{moment(post?.createdAt).fromNow()}</div>
                            <div className="mt-3 mx-3">{post?.postDescription}</div>
                            <div className="mt-1">
                              {post?.images?.map(image => {
                                return <img className="w-full h-[400px] border-b-2 border-red-500 mt-2" src={image} alt={image} />
                              })
                              }
                              {post?.videos?.map(video => {
                                return (
                                  <video controls className="w-full mt-2">
                                    <source src={video} type="video/mp4" />
                                    <source src={video} type="video/webm" />
                                  </video>
                                )
                              })
                              }

                            </div>
                            <div className="mt-2 border-4 mx-[300px] border-b border-[#244060]"></div>

                          </div>
                        )
                      })
                    }

                  </div>
                }

                <div className="w-full mt-1.5 ">
                  {updates &&
                    updates.length > 0 &&
                    updates.map((update, i) => (
                      <UpdateContainer key={i.toString()} update={update} />
                    ))}
                </div>

                {/* UCBI  */}
                {/*<div className="w-full flex flex-col  bg-white rounded-lg p-2 pb-3 mt-2 shadow-sm ">
                  <div className="font-semibold flex">
                    <div
                      className="w-6 h-6 border-[#1D6DAE] rounded-full mr-2 "
                      style={{ borderWidth: 4 }}
                    ></div>
                    <span className="text-xs md:text-base">
                      Universal Conditional Basic Income Opportunity for all
                      Teachers
                    </span>
                  </div>
                  <div className="text-justify text-xs md:text-sm mt-3">
                    Providing your teachers a means of increased earning so as
                    to reduce their financial anxiety, which may be affecting
                    their commitment to their important job, is one the 17
                    target sub-goals of STEKA, implemented in several countries
                    under a public/private partnership framework that Esgrown is
                    part of. This goal is served on Esgrown through a Universal
                    Conditional Basic Income opportunity for teachers
                  </div>
                  <div className="w-full h-[300px] mt-3 rounded">
                    <img
                      className="w-full h-full rounded"
                      src="/activateUCBI.PNG"
                      alt=""
                    />
                  </div>
                  {user.userType === "school" && !user.UCBIActive && (
                    <button
                      onClick={paystackCheckout}
                      className="flex self-end  bg-[#1D6DAE] mt-1 w-fit px-2 py-1 rounded-lg text-white hover:shadow-md hover:shadow-[#1DA1F2]"
                    >
                      Activate UCBI
                    </button>
                  )}
                  </div>*/}

                {/* City Best Teacher Dinner  */}
                {/*<div className="w-full flex flex-col  bg-white rounded-lg p-2 pb-3 mt-2 shadow-sm ">
                  <div className="font-semibold flex">
                    <div
                      className="w-6 h-6 border-[#1D6DAE] rounded-full mr-2 "
                      style={{ borderWidth: 4 }}
                    ></div>
                    City Best Teachers of the Term Dinner
                  </div>

                  <div className="w-full h-[300px] mt-3 rounded">
                    <img
                      className="w-full h-full rounded"
                      src="/bestTeacherOfTheTermDinner.PNG"
                      alt=""
                    />
                  </div>
                  </div>*/}
              </div>
              <div className="col-lg-4 w-full h-full  hidden lg:flex flex-col top-4 right-0 sticky">
                <img
                  src={
                    user?.userType === "student"
                      ? imgStudent
                      : user.userType === "company"
                        ? imgCompany
                        : user.userType === "private"
                          ? imgPrivate
                          : user.userType === "teacher"
                            ? imgTeacher
                            : user.userType === "school"
                              ? imgSchool
                              : imgGeneric
                  }
                  className="w-full  h-full object-fill rounded-md"
                />
                <div className="w-full text-center align-middle h-[80px] p-3 bg-white rounded mt-3">
                  <div className="">BWP Utvecklingsbolag &copy;2019</div>
                  <div className="text-xs">
                    All rights reserved. Campaigns | Term | Policy
                  </div>
                </div>
              </div>
            </div>
          </div>
          <input
            type="file"
            name="file"
            accept=".jpg,.jpeg,.png,.mp4,.webm"
            className="invisible"
            ref={fileRef}
            multiple={true}
            onChange={(e) => { handFileChange(e) }}
          />
        </Content>
      </Layout>
      {showSmallScreenDrawer && (
        <SmallScreenDrawer
          setShowSmallScreenDrawer={setShowSmallScreenDrawer}
          user={user}
        />
      )}

      {/* //Social buttons  */}
      <div className="min-w-fit h-[40px] fixed bottom-2 right-8 ">
        <SocialButtons />
      </div>
    </Layout>
  );
};

export default IndividualPage;

