import { CloseCircleTwoTone } from "@ant-design/icons";
import React from "react";

function PaymentRequestModal({ setOpenUCBIPaymentReqModal, request }) {
  return (
    <div
      style={{ minHeight: 120 }}
      className=" absolute top-10 left-4 rounded-lg w-10/12 md:w-[40%] pt-3 pb-10 px-3 bg-white"
    >
      <div className="w-full flex justify-end mr-2 mt-1 mb-3 ">
        <CloseCircleTwoTone
          onClick={() => setOpenUCBIPaymentReqModal(false)}
          className=" cursor-pointer transition-all duration-500 ease-out hover:rotate-90"
        />
      </div>
      <div className="w-full flex justify-between  mt-1">
        <div className="w-1/2 font-bold">Amount Requested</div>
        <div className="text-left w-1/2">
          <span className="line-through decoration-double">N</span>{" "}
          {request.amount}
        </div>
      </div>
      <div className="w-full flex justify-between  mt-1">
        <div className="w-1/2 font-bold">Bank Name</div>
        <div className="text-left w-1/2">{request.bankName}</div>
      </div>
      <div className="w-full flex justify-between  mt-1">
        <div className="w-1/2 font-bold">Account Name</div>
        <div className="text-left w-1/2">{request.accountName}</div>
      </div>

      <div className="w-full flex justify-between  mt-1">
        <div className="w-1/2 font-bold">Account Number</div>
        <div className="text-left w-1/2">{request.accountNumber}</div>
      </div>
      <div className="w-full flex justify-between  mt-1">
        <div className="w-1/2 font-bold">Account Type</div>
        <div className="text-left w-1/2">{request.bankAccountType}</div>
      </div>
    </div>
  );
}

export default PaymentRequestModal;
