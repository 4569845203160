import "antd/dist/antd.css";
import "./App.css";
import "./styles/main.css";
import "./styles/side-panel.scss";

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Steka2 from "./components/steka2";
import Home from "./components/home";
import SignUp_Individual from "./components/signup_individual";
import SignUp_Corporate from "./components/signup_corporate";
import Services from "./components/services";
import IndividualPage from "./components/individual.jsx";

import profile from "./components/Individual/profile.jsx";
import Storex from "./components/Individual/store.jsx";
import subscribe from "./components/Individual/subscription";
import exercises from "./components/Individual/exercises";
import EAS from "./components/Individual/easServices";
import LM from "./components/Individual/lmServices";
import RMServicesInd from "./components/Individual/RMServicesInd";
import RmCorperate from "./components/Individual/RMServicesCorp";
import EFA from "./components/Individual/efaServices";
import CM from "./components/Individual/cmServices";

import Logout from "./components/logout";

import AdminDashboardNew from "./components/admin/dashboards/super-admin/dashboard_main.jsx";
import AdminStore from "./components/admin/dashboards/super-admin/store";
import AdminOrders from "./components/admin/dashboards/super-admin/storeOrders.jsx";
import B2p from "./components/admin/dashboards/super-admin/b2b.jsx";
import AdminPage from "./components/admin/dashboards/admin/admin.jsx";
import UCBI from "./components/admin/ucbi/ucbi";
import UCBIPage from "./components/admin/ucbi/ucbi-page";
import ProtectedRoute from "./ProtectedRoute";
import AdminProtectedRoute from "./AdminProtectedRoute";
import { store, persiststore } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Updates from "./components/admin/updates/Updates";
import CampaignHome from "./components/campaigns/CampaignHome";
import Axios from "axios";
import CreateService from "./components/admin/services/serviceModal/CreateService";
import SDGSCampaignHome from "./components/Individual/SDGSCampaignHome";
import SponsooringOrgRoute from "./SponsoringOrgRouted";
import SponsoringOrgHome from "./components/admin/dashboards/sponsoringOrg/SponsoringOrgHome";
import NewServiceCard from "./components/Individual/NewServiceCard";
import AssociateDashboard from "./components/Individual/AssociateDashboard";
import Objectives from "./components/admin/dashboards/sponsoringOrg/Objectives";
import Programs from "./components/admin/dashboards/sponsoringOrg/Programs";
import StekaCampain from "./components/campaigns/steka/StekaCampain";
import EducationAdvisory from "./components/Individual/EducationAdvisory";
import StudyFinanceAdvisory from "./components/Individual/StudyFinanceAdvisory";
import StaffCompetence from "./components/Individual/StaffCompetence";
import DigitalLiteracy from "./components/Individual/DigitalLiteracy";
import JobAndEmployment from "./components/Individual/JobAndEmployment";
import BusinessLeadership from "./components/Individual/BusinessLeadership";
import AxiosInstance from "./AxiosInstance/AxiosInstance";

const App = () => {
  useEffect(async () => {
    let userData = window.localStorage.getItem("persist:user");
    if (userData) {
      userData = await JSON.parse(userData);
      console.log(userData)
      const token = userData.token;
      AxiosInstance.defaults.headers.common = {
        Authorization: "Bearer " + token,
      };
    }
  }, []);

  return (
    <Provider store={store}>
      
      <PersistGate persistor={persiststore}>
        <Router>
          <Route path="/" exact component={Home} />
          <Route
            path="/signup_individual"
            exact
            component={SignUp_Individual}
          />
          <Route path="/signup_corporate" exact component={SignUp_Corporate} />
          <ProtectedRoute path="/store-build" exact component={Storex} />
          <ProtectedRoute path="/home" exact component={IndividualPage} />
          <ProtectedRoute path="/profile" exact component={profile} />
          <ProtectedRoute path="/UCBI" exact component={UCBI} /> 
          <ProtectedRoute path="/steka" exact component={Steka2} />
          <ProtectedRoute path="/EAS" exact component={EAS} />
          <ProtectedRoute path="/LM" exact component={LM} />
          <ProtectedRoute path="/EFA" exact component={EFA} />
          <ProtectedRoute path="/CM" exact component={CM} />
          <ProtectedRoute path="/study-finance-advisory" exact component={StudyFinanceAdvisory} />
          <ProtectedRoute path="/edu-advisory-corperate" exact component={EducationAdvisory} />
          <ProtectedRoute path="/staff-competence-mgt" exact component={StaffCompetence} />
          <ProtectedRoute path="/digital-literacy" exact component={DigitalLiteracy} />
          <ProtectedRoute path="/job-and-employment" exact component={JobAndEmployment} />
          <ProtectedRoute path="/business-leadership" exact component={BusinessLeadership} />
          <ProtectedRoute path="/rm-corperate" exact component={RmCorperate} />
          <ProtectedRoute
            path="/rm-individual"
            exact
            component={RMServicesInd}
          />
          <ProtectedRoute path="/exercises" exact component={exercises} />
          <ProtectedRoute
            path="/associate-dashboard"
            exact
            component={AssociateDashboard}
          />

          <ProtectedRoute path="/store" exact component={subscribe} />
          <Route path="/subscribe2" exact component={NewServiceCard} />

          {/* campaigns */}
          <ProtectedRoute path="/campaigns" exact component={CampaignHome} />

          <ProtectedRoute
            path="/sdg-campaigns"
            exact
            component={SDGSCampaignHome}
          />
          <ProtectedRoute path="/services" exact component={Services} />
          <ProtectedRoute path="/logout" exact component={Logout} />
          <AdminProtectedRoute
            path="/adminDashboard"
            exact
            component={AdminDashboardNew}
          />
          <AdminProtectedRoute
            path="/adminStore"
            exact
            component={AdminStore}
          />
          <AdminProtectedRoute
            path="/adminOrders"
            exact
            component={AdminOrders}
          />
          <AdminProtectedRoute path="/B2p" exact component={B2p} />
          <AdminProtectedRoute path="/Admin_Page" exact component={AdminPage} />
          {/* <AdminProtectedRoute path="/UCBI" exact component={UCBI} /> */}
          <AdminProtectedRoute path="/UCBI_Page" exact component={UCBIPage} />
          <AdminProtectedRoute path="/update" exact component={Updates} />
          <AdminProtectedRoute path="/sv" exact component={CreateService} />
          <SponsooringOrgRoute
            path="/sponsoring-orgs"
            exact
            component={SponsoringOrgHome}
          />
          <SponsooringOrgRoute
            path="/objectives"
            exact
            component={Objectives}
          />
          <SponsooringOrgRoute path="/programs" exact component={Programs} />
        </Router>
      </PersistGate>
    </Provider>
  );
};

export const MediaPath = "";

export default App;

//Digital Ocean name servers
// ns1.digitalocean.com;
// ns2.digitalocean.com;
// ns3.digitalocean.com;
