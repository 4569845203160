
import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { CloseCircleTwoTone, DownOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import RemoveCareerFieldsModal from "./RemoveCareerFieldsModal";
import CareerFieldDetails from "./CareerFieldDetails";
import UpdateCareerFieldModal from "./UpdateCareerFieldModal";
import SupportingOrganizationDetailsModal from "./SupportingOrganizationDetailsModal";
import RemoveOrganizationConfirmationModal from "./RemoveOrganizationConfirmationModal";
import SupportedSubGoalsModal from "./SupportedSubGoalsModal";

import {
  getAllSponsoringOrganization,
  getSponsoringOrganizationByCountry,
  setShowListOfOrganizations,
  setSponsoringOrganizationCountry,
  setRemoveOrganizationPrompt,
  setShowOrganizationPopOver,
  setOrganizationDetail,
  setShowUpdateOrganizationModal,
  setShowSupportingOrganizationDetailsModal,
  setEditSupportingOrganizationDetailsModal,
  setShowOrganizationCreateModal,
  removeSponsoringOrganization,
  setShowSupportedSubGoalsModal
 } from "../../../../redux/slices/sponsoringOrganizationSlice";

function OrganizationViewModal() {
  const {
    allSponsoringOrganization,
    sponsoringOrganizationCountry,
    isLoading,
    allSponsoringOrganizationByCountry,
    organizationDetail
   } = useSelector(state => state.sponsoringOrganizationSlice )
  
   const { 
    removeOrganizationPrompt,
    showOrganizationPopOver,
    showUpdateOrganizationModal,
    showListOfOrganizations,
    showSupportedSubGoalsModal,
    showSupportingOrganizationDetailsModal
   } = useSelector(state => state.sponsoringOrganizationSlice.componentsStates )
   
   const dispatch = useDispatch()

  useEffect(() => {
    window &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  }, [allSponsoringOrganization.length]);

  useEffect(()=>{
    dispatch(getSponsoringOrganizationByCountry())
  },[dispatch, getAllSponsoringOrganization, getSponsoringOrganizationByCountry])

  useEffect(()=>{
    dispatch(getAllSponsoringOrganization(sponsoringOrganizationCountry))
  },[sponsoringOrganizationCountry])

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="h-[20%] pb-1 border-b border-b-[#1D6DAE] w-full">
        <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
          <CloseCircleTwoTone
            onClick={() => {
              dispatch(setShowListOfOrganizations(false))
              dispatch(setRemoveOrganizationPrompt(true))
            }}
          />
        </div>
        <div className="flex justify-between items-end pb-1 w-full h-[60%] ">
          <div className="w-[20%] ">
            <select
                name=""
                id=""
                value={sponsoringOrganizationCountry}
                onChange={(e) => {
                  dispatch(setSponsoringOrganizationCountry(e.target.value));
                }}
                className="w-full py-1 focus:border-[#1D6DAE] rounded-lg focus:outline-none border-2"
                >
                <option value="">
                    All Countries
                </option>
                <option>
                    Global
                </option>
                {!isLoading &&
                  allSponsoringOrganizationByCountry.map((organization, index) => (
                    <>
                      {organization?._id? 
                        <option key={index} >
                          {organization?._id }
                        </option>
                        :""}
                    </>
                  ))}
              </select>
            </div>
            <div className="w-full ml-6">
                <p class="text-[#1D6DAE] font-bold text-lg">UN SDG 4 Support Organizations</p>
             </div> 
          {/* State  */}
          {/* <div className="w-[20%] ">
            <select
              name=""
              id=""
              className="w-full py-1 focus:border-[#1D6DAE] rounded-lg focus:outline-none border-2"
            >
              <option value="">All States</option>
              <option value="">Abia</option>
              <option value="">Adamawa</option>
            </select>
          </div> */}
          {/* City  */}
          {/* <div className="w-[20%]">
            <select
              name=""
              id=""
              className="w-full py-1 focus:border-[#1D6DAE] rounded-lg  focus:outline-none border-2"
            >
              <option value="">All Cities</option>
              <option value="">City 1</option>
              <option value="">City 2</option>
            </select>
          </div> */}
          {/* Search  */}
          {/* <div className="w-[30%] relative">
            <input
              type="text"
              className="w-full py-1 rounded-full pl-3 pr-6 focus:outline-none  focus:border-[#1D6DAE] border-2"
            ></input> */}
            {/* <span
              className="absolute top-0  right-2 cursor-pointer"
              style={{ zIndex: 120 }}
            >
              <SearchOutlined className="text-lg transition-all hover:-translate-y-0.5" />
            </span> */}
          {/* </div> */}
        </div>
      </div>
      {/* list of Associates  */}
      <div className="h-[70%] border-b border-b-blue-500 overflow-y-scroll">
        {allSponsoringOrganization?.length > 0 ? (
          allSponsoringOrganization?.map((organization, index) => (
            <div
              key={index.toString()}
              className="odd:bg-gray-200 mt-1 h-10 text-gray-600 font-semibold px-4 flex justify-between"
            >
              <p className=" ">{organization.name}</p>

              {!removeOrganizationPrompt && !showOrganizationPopOver && (
              <Popover
                placement="left"
                trigger="click"
                content={
                  <div className="min-w-min min-h-min flex flex-col items-start justify-start space-y-2 ">
                    <button
                      onClick={() => {
                        dispatch(setOrganizationDetail(organization));
                        dispatch(setShowOrganizationPopOver(true))
                        dispatch(setShowSupportingOrganizationDetailsModal(true))
                      }}
                      className="px-2 outline-none  focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                    >
                      View Profile
                    </button>
                    <button
                      onClick={() => {
                        dispatch(setOrganizationDetail(organization));
                        dispatch(setShowOrganizationPopOver(true))
                        dispatch(setShowSupportedSubGoalsModal(true))
                      }}
                      className="px-2 outline-none  focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                    >
                      Supported SDP Sub-goals
                    </button>
                    <button
                      onClick={() => {
                        dispatch(setOrganizationDetail(organization))
                        dispatch(setShowOrganizationPopOver(true))
                        dispatch(setEditSupportingOrganizationDetailsModal(true))
                        dispatch(setShowOrganizationCreateModal(true))

                        // dispatch(setShowOrganizationPopOver(true))
                        // dispatch(setOrganizationDetail(organizationDetail))
                        // dispatch(setEditSupportingOrganizationDetailsModal(true))
                        // dispatch(setShowOrganizationCreateModal(true))
                      }}
                      className="px-2 outline-none  focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                    >
                      Edit Campaign Support Area
                    </button>
                    
                    <button
                      onClick={() => {
                       
                        dispatch(setShowOrganizationPopOver(true))
                        dispatch(setRemoveOrganizationPrompt(true))
                        dispatch(setOrganizationDetail(organization))
                      }
                      }
                      className="px-2 outline-none focus:outline-none rounded-full text-red-400 transition-all duration-500 hover:-translate-y-1 hover:bg-red-300 hover:text-white"
                    >
                      Delete
                    </button>
                  </div>
                }
              >
                <button className="text-[#1D6DAE] focus:outline-none transition-all hover:translate-x-1 duration-500">
                  Actions
                </button>
              </Popover>
              )}
            </div>
          ))
        ) : (
          <div className="w-full flex justify-center h-10 mt-1.5 ">
            <div>No Sponsoring Organization👏 </div>
          </div>
        )}
      </div>
      {/* <div className="flex h-[10%] justify-end items-center ">
        <button className="px-3 py-1 rounded-lg font-semibold border-2 border-[#1D6DAE] transition-all duration-700 text-[#1D6DAE] hover:bg-[#1D6DAE] hover:text-white ">
          Next
        </button>
      </div> */}
      {removeOrganizationPrompt && (
      <RemoveOrganizationConfirmationModal/>
      )}

    {/* {showListOfCareerFieldsSubjects && (
      <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
        <CareerFieldDetails 
        />
        </div>
      )} */}

    {showUpdateOrganizationModal && (
      <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
        <UpdateCareerFieldModal 
        />
        </div>
      )}

    {showSupportingOrganizationDetailsModal && (
      <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
        <SupportingOrganizationDetailsModal/>
        </div>
      )}
    {showSupportedSubGoalsModal && (
      <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
        <SupportedSubGoalsModal/>
        </div>
      )}
    </div>
  );
}


export default OrganizationViewModal;


