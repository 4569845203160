import { createSlice } from "@reduxjs/toolkit";

const initState = {
  refreshCampaign: false,
  refreshListOfAssociates: false,
  countryAssociates: null,
  selectedAssociate: null,
  stekasInCountry: [],
  country: null,
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState: initState,
  reducers: {
    setRefreshCampaign: (state, action) => {
      state.refreshCampaign = !state.refreshCampaign;
    },
    setRefreshListOfAssociates: (state, action) => {
      state.refreshListOfAssociates = !state.refreshListOfAssociates;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setCountryAssociates: (state, action) => {
      state.countryAssociates = action.payload;
    },
    setSelectedAssociate: (state, action) => {
      state.selectedAssociate = action.payload;
    },
    setStekasInCountry: (state, action) => {
      state.stekasInCountry = action.payload;
    },
  },
});
// Expoted actions
export const {
  setRefreshCampaign,
  setRefreshListOfAssociates,
  setCountry,
  setCountryAssociates,
  setSelectedAssociate,
  setStekasInCountry,
} = campaignSlice.actions;

//Expoted selectors

export default campaignSlice;
