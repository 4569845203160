import React, { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import { Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  ScheduleOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

import DropDownMenu from "../reusable/DropDownMenu";
import OnlineUser from "../reusable/OnlineUser";
import Toast from "../../util/toast";
import { setUser } from "../../redux/slices/userSlice";
import UCBIPayementModal from "../UCBIPayementModal";
import SmallScreenDrawer from "../SmallScreenDrawer";
import {
  imgCompany,
  imgGeneric,
  imgPrivate,
  imgSchool,
  imgStudent,
  imgTeacher,
} from "../../img";
import EngageApplicants from "./modals/EngageApplicants";
import { scrollUserToTop } from "../../util/services";
import RecruitNow from "./modals/RecruitNow";
import CustomSiderMenu from "../reusable/CustomSiderMenu";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const RMServiceCorp = ({ history }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [openUCBI, setOpenUCBI] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [engangeApplicants, setEngangeApplicants] = useState(false);
  const [recruitNow, setRecruitNow] = useState(false);
  const toggleEngageApplicants = () => setEngangeApplicants((prev) => !prev);
  const toggleRecruitNow = () => setRecruitNow((prev) => !prev);
  const [showSmallScreenDrawer, setShowSmallScreenDrawer] = useState(false);

  const user = useSelector((state) => state.user.user);

  const handleClick = ({ key }) => {
    //you can perform setState here
  };

  const defaultProfilePix =
    "https://esgrown.fra1.digitaloceanspaces.com/esgrown%201669801277885.jpg";

  return (
    <Layout
      style={{
        minHeight: "100vh",
        maxHeight: "100%",
        flexWrap: "nowrap",
        position: "relative",
      }}
    >
      {openUCBI && (
        <UCBIPayementModal setOpenUCBI={setOpenUCBI} ucbi={user.ucbi[0]} />
      )}

      <Sider
        trigger={null}
        className="sidebar-bg hidden md:block"
        collapsible
        collapsed={collapsed}
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <CustomSiderMenu
          collapsed={collapsed}
          handleClick={handleClick}
          user={user}
        />
      </Sider>
      <Layout>
        <Header className="row pt-2 head">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3 hidden md:block",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          {/* This will only show for small devices  ////////////////////////*/}
          <div
            onClick={() => {
              setShowSmallScreenDrawer(!showSmallScreenDrawer);
            }}
            className="min-w-min h-full flex items-center cursor-pointer md:hidden"
          >
            <MenuFoldOutlined className="text-lg mb-3 mr-2" />
          </div>
          {/* ////////////////////////////////////////////////////// */}
          <h3 className="mt-1 ">Recruitment Management Service</h3>

          <ClickAwayListener onClickAway={() => setShowAccountMenu(false)}>
            <div className="ml-auto">
              <DropDownMenu
                user={user}
                show={showAccountMenu}
                bgColor="bg-white/80"
              />
              <div onClick={() => setShowAccountMenu(!showAccountMenu)}>
                <OnlineUser user={user} />
              </div>
            </div>
          </ClickAwayListener>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 10,
            // minHeight: 280,
            position: "relative",
            display: "flex",
          }}
          className="justify-between"
        >
          <div className=" w-full min-h-min  md:w-[70%] bg-white rounded-lg p-3">
            <div className="w-full flex justify-start items-center">
              <div
                className="w-[50px] h-[50px]  border-[#1D6DAE] text-[#00AFEF] rounded-full mr-2 flex justify-center items-center font-semibold text-sm"
                style={{ borderWidth: 4 }}
              >
                SDG8
              </div>
              <div className="text-xl font-bold">
                Building Efficient Workforce
              </div>
            </div>
            <div className="w-full my-3 text-base text-justify">
              There are individuals with competences that meets you company’s
              knowledge and skills needs to drive your business success looking
              to join your workforce. Recruiting based of these competence helps
              to drive growth, as a unit of the economy of your country, in line
              the sustainable development goal decent jobs and economic growth.
            </div>
            <div className="w-full h-[300px]">
              <img
                src="/Satisfying-decent-jobs.PNG"
                alt="satisfying-decent-jobs"
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
            <div className="w-full flex justify-between items-center my-3">
              <div className="flex items-center">
                <button
                  onClick={() => {
                    toggleEngageApplicants();
                    window && scrollUserToTop(window);
                  }}
                  className="px-3 py-1.5 bg-slate-100 text-gray-600 rounded-lg text-lg focus:outline-none hover:bg-esgrown hover:text-white transition-all duration-700"
                >
                  Engage applicants
                </button>
                <div className="text-lg text-gray-400 ml-2">100</div>
              </div>
              <button
                onClick={() => {
                  toggleRecruitNow();
                  window && scrollUserToTop(window);
                }}
                className="px-3 py-1.5 bg-slate-100 text-gray-600 rounded-lg text-lg focus:outline-none hover:bg-esgrown hover:text-white transition-all duration-700"
              >
                Recruit Now
              </button>
            </div>
          </div>
          <div className=" w-[26%] h-[320px] hidden lg:flex flex-col top-4 right-0 sticky">
            <img
              src={
                user?.userType === "student"
                  ? imgStudent
                  : user.userType === "company"
                  ? imgCompany
                  : user.userType === "private"
                  ? imgPrivate
                  : user.userType === "teacher"
                  ? imgTeacher
                  : user.userType === "school"
                  ? imgSchool
                  : imgGeneric
              }
              className="w-full  h-full object-fill rounded-md"
            />
            <div className="w-full text-center align-middle h-[80px] p-3 bg-white rounded mt-3">
              <div className="">BWP Utvecklingsbolag &copy;2019</div>
              <div className="text-xs">
                All rights reserved. Campaigns | Term | Policy
              </div>
            </div>
          </div>
          {engangeApplicants && (
            <EngageApplicants toggleEngageApplicants={toggleEngageApplicants} />
          )}
          {recruitNow && <RecruitNow toggleRecruitNow={toggleRecruitNow} />}
        </Content>
      </Layout>

      {showSmallScreenDrawer && (
        <SmallScreenDrawer
          setShowSmallScreenDrawer={setShowSmallScreenDrawer}
          user={user}
        />
      )}
    </Layout>
  );
};

export default RMServiceCorp;
