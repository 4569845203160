import Axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Toast from "../../../../../util/toast";
import allcountries from "../../../../../util/allcountries";
import AxiosInstance from "../../../../../AxiosInstance/AxiosInstance";

export default function MemoModal(props) {
  const [adminType, setAdminType] = useState("");
  const [memo, setMemo] = useState({
    title: "",
    message: "",
    recipient: ""
  });
  const [checkedStd, setCheckedStd] = useState(false);
  const [checkedCtry, setCheckedCtry] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  
  const notify = (message, type) => {
    return Toast(message, type);
  };
  const handleChange = (e) => {
    setMemo({ ...memo, [e.target.name]: e.target.value });
  };

  const sendMemo = async(e) => {
    e.preventDefault();
    try {
        AxiosInstance.post('/api/memo/sendmemo', {
           title: memo.title,
           message: memo.message,
           recipient: memo.recipient 
        }).then((res) => {
            setMemo({
                title: "",
                message: "",
                recipient: ""
            });
            Toast("Memo Sent Successfully", "success");
        });
    } catch (error) {
        console.log(error);
        return;
    }
  }
  
  //
  const onChangeStd = (e) => {
    setCheckedStd(e.target.checked);
    setAdminType(e.target.checked && e.target.value);
  };
  const onChangeCtry = (e) => {
    setCheckedCtry(e.target.checked);
    setAdminType(e.target.checked && e.target.value);
  };

  ////////////////////////////////
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // maskClosable="false"
      // closeable="false"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="w-full flex flex-column justify-between items-start ml-2">
            <label className = "text-2xl font-bold">Memo</label>
            <label className="text-xl font-semibold">To: Guvenor / Country Operations Manager</label>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-4">
        {/* <div className="container ">
        {/* onSubmit={this.onSubmit} */}
         <div className="mt-5">
            <form onSubmit={sendMemo} method="POST" className = "w-full bg-white flex flex-col justify-around items-start">
                <input 
                    value = {memo.title}
                    name = "title"
                    placeholder="Title of the Memo"
                    className="w-full mb-3 h-12 py-2 px-2 text-start font-semibold border rounded border-slate-500 text-lg"
                    onChange={handleChange}
                />
                <textarea 
                    value = {memo.message}
                    name = "message"
                    onChange={handleChange}
                    placeholder="Memo Content"
                    className="w-full mb-3 h-60 py-2 px-2 resize-none text-start border rounded border-slate-500 font-normal text-base"
                />
                <input 
                    type = "submit"
                    name = "submit"
                    value = "Send Memo"
                    className="w-full h-10 text-white bg-blue-800 font-normal rounded border border-slate-500 text-lg text-center hover:bg-black hover:text-white"
                />
            </form>
            </div>
        {/* </div>
        </div> */}
      </Modal.Body>
    </Modal>
  );
}

function renderCountries() {
  return allcountries.map((item) => {
    return <option value={item.country}>{item.country}</option>;
  });
}
