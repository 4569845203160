import { createSlice } from "@reduxjs/toolkit";

const initState = {
  user: null,
  loggeIn: false,
  country: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.loggeIn = true;
    },
    signOut: (state) => {
      state.user = null;
      state.loggeIn = false;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
  },
});
// Expoted actions
export const { setUser, signOut, setCountry } = userSlice.actions;

//Expoted selectors
export const selectUser = (state) => state.user;

export default userSlice;
