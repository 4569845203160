import { CloseCircleTwoTone, SearchOutlined } from "@ant-design/icons";
// import Axios from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedAssociate } from "../../../redux/slices/campaignSlice";
import { setShowListOfInProcessingAssociates } from "../../../redux/slices/associateSlice";
// import Toast from "../../../util/toast";
import AssociateSelectedForProcessing from "./AssociateSelectedForProcessing";

function AssociatesInCountry({countryId }) {
  const dispatch = useDispatch();
  const [showSelectedAssociate, setShowSelectedAssociate] = useState(false);
  const [refreshCountryAssList, setrefreshCountryAssList] = useState(false);
  const toggleRefreshConutryAssList = () =>
    setrefreshCountryAssList((prev) => !prev);

  const [loading, setLoading] = useState(false);
  const countryAssociates = useSelector(
    (state) => state.campaign.countryAssociates
  );
  useEffect(() => {
    window &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  }, [countryAssociates.length]);

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="h-[20%] pb-1 border-b border-b-[#1D6DAE] w-full">
        <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
          <CloseCircleTwoTone
            onClick={() => dispatch(setShowListOfInProcessingAssociates(false))}
            color="red"
          />
        </div>
        <div className="flex justify-between items-end pb-1 w-full h-[60%] ">
          <div className="text-xl font-semibold  w-[10%]">
            {/* //All the schools that made it here are in the same county, */}
            {countryId}:
          </div>
          {/* State  */}
          <div className="w-[20%] ">
            <select
              name=""
              id=""
              className="w-full py-1 focus:border-[#1D6DAE] rounded-lg focus:outline-none border-2"
            >
              <option value="">All States</option>
              <option value="">Abia</option>
              <option value="">Adamawa</option>
            </select>
          </div>
          {/* City  */}
          <div className="w-[20%]">
            <select
              name=""
              id=""
              className="w-full py-1 focus:border-[#1D6DAE] rounded-lg  focus:outline-none border-2"
            >
              <option value="">All Cities</option>
              <option value="">City 1</option>
              <option value="">City 2</option>
            </select>
          </div>
          {/* Search  */}
          <div className="w-[30%] relative">
            <input
              type="text"
              className="w-full py-1 rounded-full pl-3 pr-6 focus:outline-none  focus:border-[#1D6DAE] border-2"
            ></input>
            <span
              className="absolute top-0  right-2 cursor-pointer"
              style={{ zIndex: 120 }}
            >
              <SearchOutlined className="text-lg transition-all hover:-translate-y-0.5" />
            </span>
          </div>
        </div>
      </div> 
      {/* list of Associates  */}
      <div className="h-[70%] border-b border-b-blue-500 overflow-y-scroll">
        {Array.from(countryAssociates).length > 0 ? (
          Array.from(countryAssociates).map((associate, index) => (
            <div
              key={index.toString()}
              className="odd:bg-gray-200 mt-1 h-10 text-gray-600 font-semibold px-4 flex justify-between"
            >
              <p className=" ">{associate.user?.name}</p>
              <p>
                <button
                  onClick={() => {
                    dispatch(setSelectedAssociate(associate));
                    setShowSelectedAssociate(true);
                  }}
                  className="text-[#1D6DAE] px-2 py-0.5 focus:outline-none hover:bg-[#1D6DAE] hover:text-white rounded-full transition-all duration-700 hover:translate-x-1"
                >
                  Process
                </button>
              </p>
            </div>
          ))
        ) : (
          <div className="w-full flex justify-center h-10 mt-1.5 ">
            <div>All requests have been processed 👏 </div>
          </div>
        )}
      </div>
      <div className="flex h-[10%] justify-end items-center ">
        <button className="px-3 py-1 rounded-lg font-semibold border-2 border-[#1D6DAE] transition-all duration-700 text-[#1D6DAE] hover:bg-[#1D6DAE] hover:text-white ">
          Next
        </button>
      </div>
      {/* Selected Associate  */}
      {showSelectedAssociate && (
        <div className="w-full h-full absolute inset-0" style={{ zIndex: 200 }}>
          <AssociateSelectedForProcessing
            setShowSelectedAssociate={setShowSelectedAssociate}
            toggleRefreshConutryAssList={toggleRefreshConutryAssList}
          />
        </div>
      )}
    </div>
  );
}

export default AssociatesInCountry;
