import {
    CloseCircleTwoTone,
  } from "@ant-design/icons";
  import { Popover } from "antd";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import Toast from "../../../../util/toast";
  import moment from "moment";
  import RemoveOrganizationConfirmationModal from "./RemoveOrganizationConfirmationModal";
  import {setucbiTransactionDetails,
          setShowUcbiDetailModal,
          getAllTeachersWitUcbis,
          setShowUcbiTransactionModal
   } from "../../../../redux/slices/ucbiSlice"; 
import CreateAdmin from "../../../admin/dashboards/admin/modal/createAdminModal";
import RemoveAdminConfirmationModal from "./RemoveAdminConfirmationModal";
  
  function UcbiTransactionDetailsModal({setRequests}) {
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [request, setRequest] = useState([]);
    
    const {
      ucbiTransactionDetails
    } = useSelector((state) => state.ucbiSlice);

    useEffect(()=>{
      dispatch(getAllTeachersWitUcbis())
    },[request])

    return (
      <div
        className="w-full h-full flex bg-white flex-col items-end relative z-auto"
        // style={{ zIndex: 200 }}
      >
        <div className="h-[20%] pb-1 w-full">
          <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
            <CloseCircleTwoTone
              onClick={() => {
                dispatch(setShowUcbiTransactionModal(false))
            }}
              color="red"
            />
          </div>
          <div className="flex flex-col min-h-min  ">
            {/* section 2*/}
            <div class="font-bold text-lg">{ucbiTransactionDetails?.user_id?.name} </div>
            <div className=" mt-1 flex p-2 justify-between">
              <div className="w-[20%] h-[150px] rounded-lg bg-green-400 font-semibold text-base flex row items-center justify-center">
                {/* Image  */}
                <img
                  // rounded
                  src="{}"
                  className="w-full h-full object-cover rounded-lg"
                  // style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div className="w-[86%] px-[10%]  font-semibold text-base">
                {/* details  */}
                <div className="w-full flex justify-between ">
                  <div>Transaction Date:</div>
                  <div className="text-left w-1/2">
                    {moment(ucbiTransactionDetails?.createdAt).format("DD-MMMM,YYYY")}
                  </div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>Time:</div>
                  <div className="text-left w-1/2">{moment(ucbiTransactionDetails?.createdAt).format("h:mm a")}</div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>Transaction ID:</div>
                  <div className="text-left w-1/2">
                    {ucbiTransactionDetails?._id}
                  </div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>Beneficiary Bank:</div>
                  <div className="text-left w-1/2">
                    {ucbiTransactionDetails?.bankName}
                  </div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>Beneficiary Name:</div>
                  <div className="text-left w-1/2">
                    {ucbiTransactionDetails?.user_id?.name}
                  </div>
                </div>
                <div className="w-full flex justify-between border-b border-b-[#1D6DAE] pb-2">
                  <div>Beneficiary Account Number:</div>
                  <div className="text-left w-1/2">
                    {ucbiTransactionDetails?.accountNumber}
                  </div>
                </div>
                <div className="w-full flex justify-end pt-4">
                  <div>Payment authorized by:</div>
                  <div className="ml-3 font-bold">
                    {ucbiTransactionDetails?.authorizedBy?.name}
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
      </div>
    );
  }
  
  export default UcbiTransactionDetailsModal;
  