import React, { useEffect, useState } from "react";
import { CloseCircleTwoTone, EditOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import AxiosInstance from "../../../../AxiosInstance/AxiosInstance";
import Toast from "../../../../util/toast";
import ctries from "../../../../util/ctryModified.json";
import { 
  setShowUpdateFieldModal,
  setShowCareerFieldPopOver
 } from "../../../../redux/slices/careerFieldSlice";

function UpdateCareerFieldModal() {
  const { 
    careerFieldDetail,
   } = useSelector(state => state.careerFieldSlice )

  const [edit, setEdit] = useState(false);
  const [fieldsFromDb, setfieldsFromDb] = useState(null);
  const [applicableService, setApplicableService] = useState(careerFieldDetail.service);
  const [subjects, setSubjects] = useState(careerFieldDetail?.subjects?.join());
  const [careerPath, setCareerPath] = useState(careerFieldDetail.name);
  const [country, setCountry] = useState(careerFieldDetail.country);
  const [field, setField] = useState({
    name: null,
    subjects: null,
    service: null,
    loading: false,
  });

  const { 
    showUpdateFieldModal,
   } = useSelector(state => state.careerFieldSlice.componentsStates )

   const dispatch = useDispatch()
  
  const handleSubmit = async(e) => {
    e.preventDefault();
      const field = {
        fieldId: careerFieldDetail._id,
        country,
        applicableService,
        name: careerPath,
        subjects
      }
      console.log("field", field);

      if (!country || !careerPath || !subjects || !applicableService) {
        return Toast("Career field, Country, Applicable service and subjects are required!", "error");
      }
      const resp = await AxiosInstance.post("/api/user/edit-field", field);
      if (resp.data.status == "success"){
      setCountry("")
      setApplicableService("")
      setCareerPath("")
      setSubjects("")
      return Toast("Field updated successsfully!", "success");
      }
    }
  
  return (
    <div className="absolute top-5 z-50 md:left-[20%] p-8 pb-20 md:w-2/3 w-10/12 h-min bg-white text-gray-600 shadow-md rounded-lg ">
      <div className="h-[20%] pb-1 border-b border-b-[#1D6DAE] w-full flex justify-between px-2 mb-6"> 
        <div className="text-base font-semibold text-[#1D6DAE]">
        Career Paths and Areas of Knowledge and Skill Needs
        </div>
        <CloseCircleTwoTone onClick={()=>{
          dispatch(setShowUpdateFieldModal(false))
          dispatch(setShowCareerFieldPopOver(false))
          }} />
      </div>
      <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
        <select
            name="service"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            placeholder="Education Advisory / Digital Skill"
            className="w-full border-2 border-gray-100 outline-none rounded-lg px-2 py-2 "
          >
            <option value="">Select Country</option>
            {ctries.length > 0 && ctries.map((country,i) =>{
              return <option key={i}>{country.country}</option>
            })}
          </select>
        <select
            name="service"
            value={applicableService}
            onChange={(e)=>setApplicableService(e.target.value)}
            placeholder="Education Advisory / Digital Skill"
            className="w-full border-2 border-gray-100 outline-none rounded-lg px-2 py-2 "
          >
            <option value="">Select Applicable Service</option>
            <option value="Education Advisory">Education Advisory</option>
            <option value="Digital Literacy and Life Development">Digital Literacy and Life Development</option>
          </select>
      </div>
      <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
      {applicableService && (
        <input
          name="name"
          onChange={(e)=> setCareerPath(e.target.value)}
          value={careerPath}
          placeholder={applicableService == "" ? "" : 
                        applicableService == "Education Advisory" ? "Career Path (Example, Mechatronics)" : "Skill or framework (Example, ESSATS1 Web Programming)"}
          className="w-full border-2 border-gray-100 outline-none rounded-lg px-2 py-2 "
        ></input>)}
      </div>
      <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
        <textarea
          name="subjects"
          onChange={(e)=>setSubjects(e.target.value)}
          value={subjects}
          placeholder={applicableService == "" ? "" :
                      applicableService == "Education Advisory" ? "Add comma separated list of required subjects (Example, Basic Science, Mathematics, Physics, Civics, Social Studies)" 
            :"Add comma separated list of subjects or modules (HTML, CSS, JavaScript)"}
          className="w-full h-60 resize-none bg-gray-200 outline-none rounded-lg px-2 py-2 "
        ></textarea>
      </div>
      <div className="flex px-2 mt-2 w-full justify-end">
        {!edit && (
          // <button onClick={createField} className="custom-primary-btn w-full">
          <button onClick={(e)=>handleSubmit(e)} className="custom-primary-btn w-full mt-3">
            Update Career Path/Country Area of Knowledge and Skill Needs
          </button>
        )}
      </div>
      
    </div>
  );
}

export default UpdateCareerFieldModal;
