import {
    CloseCircleTwoTone,
    DownOutlined,
    SearchOutlined,
  } from "@ant-design/icons";
  import { Avatar, Checkbox, Dropdown, Image, Popover, Spin } from "antd";
  import AxiosInstance from "../../../../AxiosInstance/AxiosInstance";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import {
    setCountryAssociates,
    setRefreshCampaign,
  } from "../../../../redux/slices/campaignSlice";
  import {
    setHidePopOver,
  } from "../../../../redux/slices/associateSlice";
  import Toast from "../../../../util/toast";
  import { MediaPath } from "../../../../App";
  import ctries from "../../../../util/allcountries";
  
  function ReAssignAssociateModal({
    setShowSelectedAssociate,
    toggleRefreshConutryAssList,
    setShowSelectedAssociateDetails,
    associateDetails
  }) {

    const dispatch = useDispatch();
  
    const [rank, setRank] = useState(null);
    const [name, setName] = useState(null);
    const [state, setState] = useState(null);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(null);
    const [loadingCities, setLoadingCities] = useState(false);
    const [loading, setLoading] = useState(null);
    const [teamsInACity, setTeamsInACity] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [cityAction, setCityAction] = useState("CREATE_GUARD");
  
    // const associateDetails = useSelector(
    //   (state) => state.campaign.associateDetails
    // );
  
    const campaign = useSelector((state) => state.campaign.refreshCampaign);
    const country = useSelector((state) => state.user.country);
  
    //Assign Praetor Rank
    const assignPraetorRank = async (name, country, state, associateId) => {
      setLoading(true);
      if (!name || !country || !state || !associateId) {
        setLoading(false);
        return Toast(
          "Name, country, State and Associate Id are required!",
          "error"
        );
      }
      if (!rank) {
        return Toast("Please select a rank!", "error");
      }
      // return alert(country);
      const reqData = { name, country, state, associateId };
      console.log("Req Data: ", reqData);
      const route = `api/associate/assign-praetor-rank`;
      try {
        const { data } = await AxiosInstance.post(route, reqData);
        console.log(data);
        if (!data.error) {
          Toast("Praetor rank assigned successfully!", "success");
          await fetchCountryAssociates(country);
          dispatch(setRefreshCampaign(!campaign));
          setShowSelectedAssociate(false);
          toggleRefreshConutryAssList();
        } else {
          Toast(data.message, "error");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
  
    //Assign Praetor Rank
    const assignAedileRank = async (name, country, state, city, associateId) => {
      setLoading(true);
      if (!name || !country || !state || !city || !associateId) {
        setLoading(false);
        return Toast(
          "Guard name, country, State, city and Associate Id are required!",
          "error"
        );
      }
      if (!rank) {
        return Toast("Please select a rank!", "error");
      }
      // Assign Aedile Rank
      const reqData = { name, country, state, city, associateId };
      console.log("Req Data: ", reqData);
      const route = `api/associate/assign-aedile-rank`;
      try {
        const { data } = await AxiosInstance.post(route, reqData);
        console.log(data);
        if (!data.error) {
          Toast("Aedile rank assigned successfully!", "success");
          await fetchCountryAssociates(country);
          dispatch(setRefreshCampaign(!campaign));
          setShowSelectedAssociate(false);
          toggleRefreshConutryAssList();
        } else {
          Toast(data.message, "error");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    //Assign Praetor Lieutenant Rank
    const assignPraetorLiutenantRank = async (
      country,
      state,
      city,
      associateId
    ) => {
      setLoading(true);
      if (!country || !state || !city || !associateId) {
        setLoading(false);
        return Toast(
          "Team name, country, State, city and Associate Id are required!",
          "error"
        );
      }
      if (!rank) {
        return Toast("Please select a rank!", "error");
      }
      const name = `Team ${teamsInACity.length + 1} (${city})`;
      // Assign Praetor Lieutenant Rank
      const reqData = { name, country, state, city, associateId };
      console.log("Req Data: ", reqData);
      const route = `api/associate/assign-praetor-lieutenant-rank`;
      try {
        const { data } = await AxiosInstance.post(route, reqData);
        console.log(data);
        if (!data.error) {
          Toast("Praetor Lieutenant rank assigned successfully!", "success");
          await fetchCountryAssociates(country);
          dispatch(setRefreshCampaign(!campaign));
          setShowSelectedAssociate(false);
          toggleRefreshConutryAssList();
        } else {
          Toast(data.message, "error");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
  
    const assignAssociateFirstRank = async (
      country,
      state,
      city,
      associateId
    ) => {
      setLoading(true);
      if (!country || !state || !city || !associateId) {
        setLoading(false);
        return Toast(
          "Team name, country, State, city and Associate Id are required!",
          "error"
        );
      }
      if (!rank) {
        setLoading(false);
        return Toast("Please select a rank!", "error");
      }
      const teamLeaderId = selectedTeam.teamLeader._id;
      if (!teamLeaderId) {
        setLoading(false);
        return Toast("Please select a team to add associate!", "error");
      }
      // Assign Associate First Rank
      const reqData = { country, state, city, associateId, teamLeaderId };
      console.log("Req Data: ", reqData);
      const route = `api/associate/assign-associate-first-rank`;
      try {
        const { data } = await AxiosInstance.post(route, reqData);
        console.log(data);
        if (!data.error) {
          Toast("Associate First rank assigned successfully!", "success");
          await fetchCountryAssociates(country);
          dispatch(setRefreshCampaign(!campaign));
          setShowSelectedAssociate(false);
          toggleRefreshConutryAssList();
        } else {
          Toast(data.message, "error");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
  
    const fetchCities = async(state) => {
      console.log("state: " + state);
      const s = String(state).trim();
      setLoadingCities(true)
      //console.log(s);
      try {
        const { data } = await AxiosInstance.post(
          `/api/province/get-all-cities`, {
            state: s
          }
        );
        console.log(data);
        if (!data.error) {
          setCities(data.cities);
          console.log(data.cities);
        } else {
          console.log(data.message);
          setCities([]);
        }
      } catch (error) {
        Toast("Could not fetch cities", "error");
      } finally {
        setLoadingCities(false);
      }
    };
  
    // handle click confirm
    const confirmClicked = (name, country, state, city, associateId) => {
      if (rank === "Praetor") {
        return assignPraetorRank(
          name,
          // associateDetails.country,
          country,
          state,
          // associateDetails.user._id
          associateId
        );
      } else if (rank === "Aedile") {
        return assignAedileRank(name, country, state, city, associateId);
      } else if (rank === "Praetor Lieutenant") {
        return assignPraetorLiutenantRank(country, state, city, associateId);
      } else if (rank === "Associate First") {
        return assignAssociateFirstRank(country, state, city, associateId);
      }
    };
    //Return
  
    // Get All Teams in a city
    const fetchAllTeamsInACity = async (country, state, city) => {
      setLoading(true);
  
      if (!country || !state || !city) {
        setLoading(false);
        return Toast("Country, State, and City are required!", "error");
      }
      const reqData = {
        country: String(country).trim(),
        state: String(state).trim(),
        city: String(city).trim(),
      };
      const route = `/api/associate/get-all-teams-in-a-city`;
      try {
        const { data } = await AxiosInstance.post(route, reqData);
        console.log("City Teams", data.teams);
        if (!data.error) {
          setTeamsInACity(data.teams);
        } else {
          Toast(`Warning: Colud not fetch teams in a city!`, "error");
          console.log(data.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
  
    function renderCitiesGuard(cities) {
      console.log(cities);
      if (cities === null) return <option>City</option>;
      const sortedCities = Array.from(cities).sort((a, b) => a - b);
      console.log("Sorted cities: ", sortedCities);
  
      return sortedCities.map((city, i) => {
        console.log(city);
        // E.g  Guard 1 (Otukpo) or Team 1 (Otukpo) depending on the action
        const value = `Guard = ${i + 1} = ${city}`;
        const label = `Guard ${i + 1} (${city})`;
  
        return (
          <option key={i.toString()} value={value}>
            {label}
          </option>
        );
      });
    }
    function renderCitiesTeam(cities) {
      console.log(cities);
      if (cities === null) return <option>City</option>;
      const sortedCities = Array.from(cities).sort((a, b) => a - b);
      console.log("Sorted cities: ", sortedCities);
  
      return sortedCities.map((city, i) => {
        return (
          <option key={i.toString()} value={city}>
            {city}
          </option>
        );
      });
    }
  
    const fetchCountryAssociates = async () => {
      setLoading(true);
      const route = `/api/associate/requets-from-a-country?country=${country}`;
      try {
        const { data } = await AxiosInstance.post(route);
        const associatesInCountry =
          data.associates.length > 0
            ? Array.from(data.associates).map((ass) => ass)
            : [];
        dispatch(setCountryAssociates(associatesInCountry));
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <div
        className="w-full h-full flex bg-white flex-col mb-4"
        style={{ zIndex: 200 }}
      >
        <div className="h-[20%] pb-1 w-full">
          <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
            <CloseCircleTwoTone
              onClick={() => {
                setShowSelectedAssociate(false)
                setShowSelectedAssociateDetails(false)
                dispatch(setHidePopOver(false))
            }}
              color="red"
            />
          </div>
          <div className="flex flex-col min-h-min  ">
            {/* section 1 */}
            <div className="text-3xl font-semibold  w-full flex items-center justify-center pb-2 border-b border-b-[#1D6DAE] ">
            Associate Education Quality and Functionality Advisor In-processing
            </div>
            {/* section 2*/}
            <div className=" mt-2 flex justify-between">
              <div className="w-[20%] rounded-lg bg-green-400">
                {/* Image  */}
                <img
                  // rounded
                  src={associateDetails?.user?.profilePix}
                  className="w-full h-full object-cover rounded-lg"
                  // style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div className="w-[86%] px-[10%] mt-3 font-semibold text-base">
                {/* details  */}
                <div className="w-full flex justify-between ">
                  <div>Name:</div>
                  <div className="text-left w-1/2">
                    {associateDetails?.user?.name}
                  </div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>SDG Support Organization:</div>
                  <div className="text-left w-1/2">
                    {associateDetails?.sponsoringOrg}
                  </div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>City:</div>
                  <div className="text-left w-1/2">{associateDetails?.user?.province}</div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>State:</div>
                  <div className="text-left w-1/2">
                    {associateDetails?.user?.state}
                  </div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>Country:</div>
                  <div className="text-left w-1/2">
                    {associateDetails?.user?.country}
                  </div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>AIDC:</div>
                  <div className="text-left w-1/2">
                    {associateDetails?.uniqueId}
                  </div>
                </div>
                {associateDetails?.user?.email && (
                <div className="w-full flex justify-between ">
                  <div>Email:</div>
                  <div className="text-left w-1/2">
                    {associateDetails?.user?.email}
                  </div>
                </div>
                )}
                {associateDetails?.user?.phone && (
                <div className="w-full flex justify-between ">
                  <div>Phone:</div>
                  <div className="text-left w-1/2">
                    {associateDetails?.user?.phone}
                  </div>
                </div>
                )}
              </div>
            </div>
            <div className="mt-2 schoolDivider"></div>
            {/* section 3 */}
           
           {/* section 3 */}
          <div className="w-full mt-3 mb-50 flex flex-col justify-between z-[5000] ">
            <div className="w-full flex justify-end items-center px-[15%]">
              <button className="px-6 text-lg bg-red-500 text-white py-2 rounded-lg hover:translate-y-1 transition-all duration-700"
              onClick={() => {
                setShowSelectedAssociate(false)
                setShowSelectedAssociateDetails(false)
                dispatch(setHidePopOver(false))
            }}
              >
                Decline
              </button>
            </div>
            <div className="w-full flex justify-between items-center mt-6 px-[15%]">
              <select
                onChange={(e) => {
                  setRank(e.target.value);
                  if (e.target.value === "Aedile") {
                    setCityAction("CREATE_GUARD");
                  }
                  if (e.target.value === "Praetor Lieutenant") {
                    setCityAction("CREATE_TEAM");
                  }
                }}
                className="w-1/3 outline outline-gray-300 rounded-lg md:w-[26%] flex flex-col space-y-4 py-2"
              >
                <option value="">Assign Rank</option>
                <option value="Praetor">Praetor</option>
                <option value="Aedile">Aedile</option>
                <option value="Praetor Lieutenant">Praetor Lieutenant</option>
                <option value="Associate First">Associate First</option>
                <option value="Functus Officio">Functus Officio</option>
              </select>
              {Boolean(rank) && (
                <select
                  onChange={(e) => {
                    let state = String(e.target.value).split("=")[2];
                    let index = String(e.target.value).split("=")[1];
                    setName(`League ${index} (${state})`);
                    setState(String(state).trim());
                    console.log(state);
                    fetchCities(state);
                  }}
                  className="w-1/3 outline outline-gray-300 rounded-lg md:w-[26%] flex flex-col space-y-4 py-2"
                >
                  <option value="">State</option>
                  {renderStates(associateDetails.country)}
                </select>
              )}
              {Boolean(rank) && rank !== "Praetor" && (
                <select
                  onChange={(e) => {
                    if (String(e.target.value).length > 1) {
                      if (rank === "Aedile") {
                        let city = String(e.target.value).split("=")[2];
                        let index = String(e.target.value).split("=")[1];
                        let unitName = String(e.target.value).split("=")[0];
                        setName(`${unitName} ${index} (${city})`);
                        setCity(String(city).trim());
                      } else {
                        const city = String(e.target.value).trim();
                        setCity(city);
                        // return alert(city);
                        fetchAllTeamsInACity(
                          associateDetails.country,
                          state, // stete must have been set!
                          city
                        );
                      }
                    }
                  }}
                  className="w-1/3 outline outline-gray-300 rounded-lg md:w-[26%] flex flex-col space-y-4 py-2"
                >
                  <option value="">City</option>
                  {cities && rank === "Aedile" && renderCitiesGuard(cities)}
                  {cities && rank !== "Aedile" && renderCitiesTeam(cities)}
                </select>
              )}
            </div>
            {rank === "Associate First" && (
              <div className="w-full flex justify-end items-center mt-2 px-[15%]">
                <select
                  onChange={(e) => {
                    setSelectedTeam(JSON.parse(e.target.value));
                    // alert(JSON.parse(e.target.value).teamLeader.email);
                  }}
                  className="w-1/3 outline outline-gray-300 rounded-lg md:w-[26%] flex flex-col space-y-4 py-2"
                >
                  <option value="">Select Team</option>
                  {teamsInACity.map((team, i) => (
                    <option
                      key={i.toString()}
                      value={JSON.stringify(team)}
                      className="w-full flex justify-between"
                    >
                      {team.name} {`[${team.members.length}]`}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="w-full flex justify-between items-center mt-2 px-[15%]">
              {rank === "Praetor" && (
                <div className="text-xs bg-amber-100 px-1 py-0.5 rounded-lg animate-pulse">
                  Only one Associate can be assigned to a League (State) as a
                  praetor!
                </div>
              )}
              {rank === "Aedile" && (
                <div className="text-xs bg-amber-100 px-1 py-0.5 rounded-lg animate-pulse">
                  Only one Aedile can be assigned to a Guard (City)
                </div>
              )}
              {rank === "Praetor Lieutenant" && (
                <div className="text-xs bg-amber-100 px-1 py-0.5 rounded-lg animate-pulse">
                  A maximum of 15 Praetor Lieutenants can be assigned to a Guard
                  (City)
                </div>
              )}

              {rank === "Associate First" && (
                <div className="text-xs bg-amber-100 px-1 py-0.5 rounded-lg animate-pulse">
                  A maximum of 10 Associate First can be assigned to a Team
                  (City)
                </div>
              )}
              {rank === "Functus Officio" && (
                <div className="text-xs bg-amber-100 px-1 py-0.5 rounded-lg animate-pulse">
                  Functus Officio does not have a fixed rank!
                </div>
              )}
            </div>
            {/* confirm  */}
            <div className="w-full flex justify-between items-center mt-2 px-[15%]">
              <button
                onClick={() =>
                  confirmClicked(
                    name,
                    associateDetails.country,
                    state,
                    city,
                    associateDetails.user._id
                  )
                }
                disabled={rank === "Associate First" && !selectedTeam?.name}
                className="flex justify-center disabled:bg-gray-300 items-center w-[100%] py-2 mx-auto outline-none outline-gray-300 rounded-lg hover:bg-green-400 hover:text-white hover:outline-none transition-all duration-700"
              >
                Confirm
                {loading && <Spin className="text-white ml-2" size="small" />}
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default ReAssignAssociateModal;
  
  function renderStates(country) {
    console.log("Country supplied: " + country);
    let states = null;
    for (const obj of ctries) {
      if (obj.country === country) {
        console.log(obj.states.length);
        states = obj.states;
      }
    }
    if (states === null) return <></>;
    return states.map((state, i) => {
      return (
        <option
          key={i.toString()}
          value={`League = ${i + 1} = ${state}`}
        >{`League ${i + 1} (${state})`}</option>
      );
    });
  }
  
  // function createGuardName(city, index) {
  //   let guardName = "The ";
  //   if ((index === 1 || String(index).lastIndexOf("1") === -1) && index > 20) {
  //     // 1st 21 st 31 st but 11 should be 11th
  //     guardName += `${index} st Guard (${city})`;
  //   }
  //   if ((index === 2 || String(index).lastIndexOf("2") === -1) && index > 20) {
  //     //  21 st 31 st but 11 should be 11th
  //     guardName += `${index} nd Guard (${city})`;
  //   }
  //   if ((index === 1 || String(index).lastIndexOf("1") === -1) && index > 20) {
  //     // 1st 21 st 31 st but 11 should be 11th
  //     guardName += `${index} st Guard (${city})`;
  //   }
  // }
  