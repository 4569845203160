import React, { useState, useEffect } from "react";
import './store.css';
import { Carousel } from 'antd';
import Icon, {
    StarFilled,
    CloseCircleFilled,
    CloseOutlined
  } from "@ant-design/icons";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";
import { addToCart, countCart } from "./storeFunction";
const StoreDetails = ({
    id,showState, cart
}) =>{
    const [details,setDetails] = useState();
    const [itemss,setItems] = useState([]);
    const onSChange = (currentSlide) => {
        console.log(currentSlide);
      }
     
   

      const getRecItems = async () => {
          const url = "/api/store/all-items?rec=3"
          const req = await AxiosInstance.get(url)
          console.log(req);
          setItems(req.data.data);
        };
    const getItems = async () => {
        const url = "/api/store/item"
        const req = await AxiosInstance.post(url,{
            id:id
        })
        console.log(req);
        setDetails(req.data.data)
      };

      useEffect(()=>{
        getItems();
        getRecItems();
      },[])
    const imageCarousel =(items)=>{
        console.log(items);
        var item=[];
        items.forEach((itemx)=>{
        item.push(
            <img src={itemx} alt="" />
            )})
            return (<Carousel afterChange={onSChange} className="images">
            {item}
        </Carousel>)
    }
    const recommeded =()=>{
        var item=[];
        itemss.forEach((itemx)=>{
        item.push(
            <a  onClick={()=>{
                showState({show:false,id:"0"});
                setTimeout(()=>{
                    showState({show:true,id:itemx?._id});
                },500);
            }}><img src={itemx?.images[0]} alt="" className="mainimg" /></a>
                 
            )})
            return item;
    }
    return (
        <div className="store-details">
            <div className="inner">
                <div>
                  <div className="mainimg">
                  {details!=null?imageCarousel(details?.images):""}
                  </div>
                   <div style={{margin:"10px 0px",fontWeight:"500"}}>Other similar Merchandises</div>
                  <div className="similar">
                  {recommeded()}
                  </div>
                  <div className="copy">
                    <div>Copy and hare link to this merchandise with friends</div>
                    <button>Copy</button>
                  </div>
                  <div  className="review">
                    <button>Review this merchandise</button>
                  </div>
                  </div>
                <div>
                    <div className="breadcrumbx">
                        <div>{details!=null?details.name:""}</div>
                        <div><CloseOutlined onClick={()=>{
                            showState({show:false,id:"0"});
                        }} /></div>
                    </div>
                    <div style={{fontWeight:"500",fontSize:"1.1em",margin:"10px 0"}}>
                    Stock Id Code: {details!=null?details.code:""}
                    </div>
                    <div className="title">
                    {details!=null?details.name:""}  
                    </div>
                    <div className="price-review">
                         <div>NGN {details!=null?details.cost:""}</div>
                         <div>
                            <StarFilled/>
                            <StarFilled/>
                            <StarFilled/>
                            <StarFilled/>
                            <StarFilled/>
                         </div>
                         <div>
                            Reviews 17
                         </div>

                    </div>
                    <div className="desc">
                    {details!=null?details.description:""}
                    </div>
                    <div className="option">
                        <select>
                            <option selected disabled>Size</option>
                        </select>
                        <div>
                        Eddish Royal Standard
                        </div>
                    </div>
                    <div className="cart">
                                <button onClick={()=>{
       if(details!=null){
        addToCart(details?._id);
        cart(countCart())
       }
    }}>Add to Cart</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default StoreDetails;