import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Container, Col, Row } from "react-bootstrap";

export default class Services extends Component {
  render() {
    return <div>Services</div>;
  }
}
