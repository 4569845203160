import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";

export const removeSponsoringOrganization = createAsyncThunk("removeSponsoringOrganization", async (orgId)=>{
    const url = `/api/user/remove-sponsoring-org/${orgId}`;
    const { data } = await AxiosInstance.delete(url);
    return data
})

export const getAllSponsoringOrganization = createAsyncThunk("get/SponsoringOrganization",  async (countryQuery) => {
      const url = `/api/user/fetch-sponsoring-org?country=${countryQuery}`;
      try {
        const { data } = await AxiosInstance.post(url);
        return data.orgs
      }catch(err){

      }    
})
export const getSponsoringOrganizationByCountry = createAsyncThunk("get/SponsoringOrganizationByCountry",  async () => {
          const url = `/api/user/sponsoring-org-by-countries`;
          try {
            const { data } = await AxiosInstance.get(url);
            return data.orgs
          }catch(err){

          }    
})

export const getSupportedSubGoals = createAsyncThunk("get/SupportedSubGoals",  async () => {
          const url = `/api/user/deliverable-sub-goals`;
          try {
            const { data } = await AxiosInstance.get(url);
            return data.deliverableSubGoal
          }catch(err){

          }    
})

export const getGlobalSponsorinOrg = createAsyncThunk("get/GlobalSponsorinOrg",  async () => {
          const url = `/api/user/global-sponsoring-org`;
          try {
            const { data } = await AxiosInstance.get(url);
            return data.orgs
          }catch(err){

          }    
})


const initState = {
  componentsStates: {
    removeOrganizationPrompt: false,
    showListOfOrganizations: false,
    showOrganizationPopOver: false,
    showUpdateOrganizationModal: false,
    showOrganizationCreateModal: false,
    showSupportingOrganizationDetailsModal: false,
    editSupportingOrganizationDetailsModal: false,
    showSupportedSubGoalsModal: false
  },
  allSponsoringOrganization: [],
  allSponsoringOrganizationByCountry: [],
  sponsoringOrganizationCountry:"",
  isLoading: false,
  organizationDetail: "",
  allSupportedSubGoals: [],
  allGlobalSponsoringOrg: []
};

export const sponsoringOrganizationSlice = createSlice({
  name: "sponsoringOrganizationSlice",
  initialState: initState,
  reducers:{
    setShowListOfOrganizations:(state, action)=>{
        state.componentsStates.showListOfOrganizations = action.payload
    },
    setRemoveOrganizationPrompt:(state, action)=>{
        state.componentsStates.removeOrganizationPrompt = action.payload
    },
    setSponsoringOrganizationCountry:(state, action)=>{
        state.sponsoringOrganizationCountry = action.payload
    },
    setShowOrganizationPopOver:(state, action)=>{
        state.componentsStates.showOrganizationPopOver = action.payload
    },
    setOrganizationDetail:(state, action)=>{
        state.organizationDetail = action.payload
    },
    setShowUpdateOrganizationModal:(state, action)=>{
        state.showUpdateOrganizationModal = action.payload
    },
    setShowOrganizationCreateModal:(state, action)=>{
        state.componentsStates.showOrganizationCreateModal = action.payload
    },
    setShowSupportingOrganizationDetailsModal:(state, action)=>{
        state.componentsStates.showSupportingOrganizationDetailsModal = action.payload
    },
    setEditSupportingOrganizationDetailsModal:(state, action)=>{
        state.componentsStates.editSupportingOrganizationDetailsModal = action.payload
    },
    setShowSupportedSubGoalsModal:(state, action)=>{
        state.componentsStates.showSupportedSubGoalsModal = action.payload
    },
  },
  extraReducers:(builder) => {
    builder
      .addCase(removeSponsoringOrganization.pending, (state) => {
          state.isLoading = true
      })
      .addCase(removeSponsoringOrganization.fulfilled, (state, action) => {
          state.allSponsoringOrganization = state.allSponsoringOrganization.filter(org=> org._id != action.payload.deletedOrg._id) 
        //   state.allFilteredProcessedassociates = state.allFilteredProcessedassociates.filter(associate=> associate._id != action.payload.deletedAssociate._id) 
          state.isLoading = false

      })
      .addCase(getSponsoringOrganizationByCountry.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getSponsoringOrganizationByCountry.fulfilled, (state, action) => {
          state.allSponsoringOrganizationByCountry = action.payload
          state.isLoading = false
      })
      .addCase(getAllSponsoringOrganization.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getAllSponsoringOrganization.fulfilled, (state, action) => {
          state.allSponsoringOrganization = action.payload
          state.isLoading = false
      })
      .addCase(getSupportedSubGoals.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getSupportedSubGoals.fulfilled, (state, action) => {
          state.allSupportedSubGoals = action.payload
          state.isLoading = false
      })
      .addCase(getGlobalSponsorinOrg.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getGlobalSponsorinOrg.fulfilled, (state, action) => {
          state.allGlobalSponsoringOrg = action.payload
          state.isLoading = false
      })
    }
  },
);

export const {
              setShowListOfOrganizations,
              setSponsoringOrganizationCountry,
              setRemoveOrganizationPrompt,
              setShowOrganizationPopOver,
              setOrganizationDetail,
              setShowUpdateOrganizationModal,
              setShowOrganizationCreateModal,
              setShowSupportingOrganizationDetailsModal,
              setEditSupportingOrganizationDetailsModal,
              setShowSupportedSubGoalsModal
            } = sponsoringOrganizationSlice.actions

export default sponsoringOrganizationSlice;
