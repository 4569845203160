import { CloseCircleTwoTone, DownOutlined } from "@ant-design/icons";
import React, { useState , useEffect, useContext} from "react";
import { Popover } from "antd";
import RemoveAssociate from "./modals/RemoveAssociateModal";
import ViewAssociateModal from "./modals/ViewAssociateModal";
import ReAssignAssociateModal from "./modals/ReAssignAssociateModal";
import { AssociateContext } from "../../../context/AssociateContext";
import { useDispatch, useSelector } from "react-redux";
import { setRemoveAssociatePrompt,
         setShowListOfProccessedAssociates,
         setShowViewAssociateModal,
         setHidePopOver,
         getProcessedAssociates,
         getprocessedAssociatesUniqueCountries,
         setAssociatesCountry,
        } from "../../../redux/slices/associateSlice";

function ProcessedAssociates() {
  const [showSelectedAssociate, setShowSelectedAssociate] = useState(false);
  const [showSelectedAssociateDetails, setShowSelectedAssociateDetails] = useState(false);
  const [refreshCountryAssList, setrefreshCountryAssList] = useState(false);
  const [associateDetails, setAssociateDetails] = useState(null);
  const [associateContactDropDown, setAssociateContactDropDown] = useState(false);
  const toggleRefreshConutryAssList = () =>
    setrefreshCountryAssList((prev) => !prev);
  const {removeAssociatePrompt, 
         showViewAssociateModal, 
         hidePopOver
        } = useSelector(state => state.associateProcessed.componentsStates )
  const associateProcessed  = useSelector(state=> state.associateProcessed.allProcessedassociates)
  const { allProcessedassociates, 
          processedAssociatesUniqueCountries,
          associatesCountry,
          isLoading }  = useSelector(state=> state.associateProcessed)


  const dispatch = useDispatch()

  useEffect(() => {
    window &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  }, [associateProcessed.length]);

  // useEffect(() => {
  //   dispatch(getProcessedAssociates())
  // }, []);


  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="h-[20%] pb-1 border-b border-b-[#1D6DAE] w-full">
        <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
          <CloseCircleTwoTone
            onClick={() => {
              dispatch(setShowListOfProccessedAssociates(false))
            }
                    }
            color="red"
          />
        </div>
        <div className="flex justify-between items-end pb-1 w-full h-[60%] ">

        <div className="w-[20%] ">
            <select
              name=""
              id=""
              // value={associatesCountry}
              onChange={(e)=>{setAssociatesCountry(e.target.value)}}
              className="w-full py-1 focus:border-[#1D6DAE] rounded-lg focus:outline-none border-2"
            >
              {!isLoading && processedAssociatesUniqueCountries.map(eachCountry=>{
                  return <option > {eachCountry} </option>
                })}
            </select>
          </div>

          
          {/* State  */}
          <div className="w-[20%] ">
            <select
              name=""
              id=""
              className="w-full py-1 focus:border-[#1D6DAE] rounded-lg focus:outline-none border-2"
            >
              <option value="">All States</option>
              <option value="">Abia</option>
              <option value="">Adamawa</option>
            </select>
          </div>
          {/* City  */}
          <div className="w-[20%]">
            <select
              name=""
              id=""
              className="w-full py-1 focus:border-[#1D6DAE] rounded-lg  focus:outline-none border-2"
            >
              <option value="">All Cities</option>
              <option value="">City 1</option>
              <option value="">City 2</option>
            </select>
          </div>
          {/* Search  */}
          <div className="w-[30%] relative">
            <input
              type="text"
              className="w-full py-1 rounded-full pl-3 pr-6 focus:outline-none  focus:border-[#1D6DAE] border-2"
            ></input>
            {/* <span
              className="absolute top-0  right-2 cursor-pointer"
              style={{ zIndex: 120 }}
            >
              <SearchOutlined className="text-lg transition-all hover:-translate-y-0.5" />
            </span> */}
          </div>
        </div>
      </div>
      {/* list of Associates  */}
      <div className="h-[70%] border-b border-b-blue-500 overflow-y-scroll">
        {!isLoading && Array.from(allProcessedassociates).length > 0 ? (
          Array.from(allProcessedassociates).map((associate, index) => (
            <div
              key={index.toString()}
              className="odd:bg-gray-200 mt-1 h-10 text-gray-600 font-semibold px-4 flex justify-between"
            >
             
              <p className=" ">{associate.user?.name}</p>
              {/* <p className="text-center"> {associate.user?.rank}</p> */}

              {!removeAssociatePrompt && !hidePopOver && (
              <Popover
                placement="left"
                trigger="click"
                content={
                  <div className="min-w-min min-h-min flex flex-col items-start justify-start space-y-2 ">
                    <button
                      onClick={() => {
                        dispatch(setShowListOfProccessedAssociates(true))
                        dispatch(setHidePopOver(true))
                        setAssociateDetails(associate)
                        dispatch(setShowViewAssociateModal(true))
                      }}
                      className="px-2 outline-none  focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                    >
                      View
                    </button>
                    <button
                      onClick={() => {
                        dispatch(setShowListOfProccessedAssociates(true))
                        setShowSelectedAssociateDetails(true)
                        dispatch(setHidePopOver(true))
                        setAssociateDetails(associate)
                      }}
                      className="flex items-center px-2 outline-none focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                    >
                      Re-assign
                    </button>
                    <button
                      className="px-2 outline-none focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                      onClick={()=>{
                        setAssociateContactDropDown(!associateContactDropDown)
                        setAssociateDetails(associate)
                      }}
                    >
                      Contact
                      <span className="mb-1 ml-0.5">
                        <DownOutlined size="small" style={{ fontSize: 10 }} />
                      </span>
                    </button>
                    {associateContactDropDown && (
                      <div className="w-[80%] min-h-min mx-auto">
                        <div className="flex flex-col">
                          <p className="font-semibold">Email:</p>
                          <p className="text-xs">{associate?.user.email}</p>
                        </div>
                        {associate?.user.phone &&
                        <div className="flex flex-col">
                          <p className="font-semibold">Phone:</p>
                          <p className="text-xs">{associate?.user.phone}</p>
                        </div>
                         }
                      </div>
                    )}
                    <button
                      // onClick={() => removeStaff(teacher.email)}
                      onClick={() => {
                        dispatch(setRemoveAssociatePrompt(true))
                        setAssociateDetails(associate)
                      }}
                      className="px-2 outline-none focus:outline-none rounded-full text-red-400 transition-all duration-500 hover:-translate-y-1 hover:bg-red-300 hover:text-white"
                    >
                      Remove
                    </button>
                  </div>
                }
              >
                <button className="text-[#1D6DAE] focus:outline-none transition-all hover:translate-x-1 duration-500">
                  Actions
                </button>
              </Popover>
              )}
            </div>
          ))
        ) : (
          <div className="w-full flex justify-center h-10 mt-1.5 ">
            <div>All requests have been processed 👏 </div>
          </div>
        )}
      </div>
      <div className="flex h-[10%] justify-end items-center ">
        <button className="px-3 py-1 rounded-lg font-semibold border-2 border-[#1D6DAE] transition-all duration-700 text-[#1D6DAE] hover:bg-[#1D6DAE] hover:text-white ">
          Next
        </button>
      </div>

      { removeAssociatePrompt && (
      <RemoveAssociate 
          setShowSelectedAssociate={setShowSelectedAssociate}
          // removeAssociate={removeAssociate}
          associateDetails = {associateDetails}
      />
      )}
      {showViewAssociateModal && (
      <div className="w-full h-full absolute inset-0" style={{ zIndex: 200 }}>
        <ViewAssociateModal
          setShowSelectedAssociate={setShowSelectedAssociate}
          setShowSelectedAssociateDetails={setShowSelectedAssociateDetails}
          toggleRefreshConutryAssList={toggleRefreshConutryAssList}
          associateDetails = {associateDetails}
          // removeAssociate = {removeAssociate}
        />
      </div>
      )}
      {showSelectedAssociateDetails && (
      <div className="w-full h-full absolute inset-0" style={{ zIndex: 200 }}>
        <ReAssignAssociateModal
          setShowSelectedAssociate={setShowSelectedAssociate}
          setShowSelectedAssociateDetails={setShowSelectedAssociateDetails}
          toggleRefreshConutryAssList={toggleRefreshConutryAssList}
          associateDetails = {associateDetails}
        />
      </div>
      )}
      
    </div>
  );
}


export default ProcessedAssociates;
