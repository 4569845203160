import { toast } from "react-toastify";

export function addToCart(id){
var items = localStorage.getItem("cartitem");
if(items!=null){
    var js = JSON.parse(items);
    js.push(id);
    localStorage.setItem("cartitem",JSON.stringify(js));
}else{
    localStorage.setItem("cartitem",JSON.stringify([id]));
}
toast.success("Item added to cart");
}

export function countCart(){
    var items = localStorage.getItem("cartitem");
if(items!=null){
    var js = JSON.parse(items);
    return js.length;
}else{
    return 0;
}
}

export function getCarts(){
    var items = localStorage.getItem("cartitem");
if(items!=null){
    var js = JSON.parse(items);
    return js;
}else{
    return [];
}
}
export function removeCart(index){
    var items = localStorage.getItem("cartitem");
if(items!=null){
    var js = JSON.parse(items);
    js.splice(index,1)
    localStorage.setItem("cartitem",JSON.stringify(js));
    return js;
}else{
    return [];
}
}
export function clearCart(i){
    var items = localStorage.getItem("cartitem");
if(items!=null){
    localStorage.removeItem("cartitem")
    return [];
}else{
    return [];
}
}