import React, { useEffect, useState } from "react";
import { CloseCircleTwoTone, EditOutlined } from "@ant-design/icons";
import AxiosInstance from "../../../../AxiosInstance/AxiosInstance";
import Toast from "../../../../util/toast";
import ctries from "../../../../util/ctryModified.json";

function CreateFields({ toggleCreateFieldModal }) {
  const [edit, setEdit] = useState(false);
  const [fieldsFromDb, setfieldsFromDb] = useState(null);
  const [applicableService, setApplicableService] = useState("");
  const [subjects, setSubjects] = useState("");
  const [careerPath, setCareerPath] = useState("");
  const [country, setCountry] = useState("");
  const [field, setField] = useState({
    name: null,
    subjects: null,
    service: null,
    loading: false,
  });
  const [module, setModule] = useState(false);
  
  const handleSubmit = async(e) => {
    e.preventDefault();
    // setField({ ...field, [e.target.name]: e.target.value });
    // if(e.target.name == 'service') {
    //   if(e.target.value == "digital literacy / life skills") {
    //     setModule(true);
    //   }
    //   const dataset = e.target.value;
    //   setField({
    //     service: dataset
    //   });
      //console.log(e.target.value);
      const field = {
        country,
        applicableService,
        name: careerPath,
        subjects
      }

      if (!country || !careerPath || !subjects || !applicableService) {
        return Toast("Career field, Country, Applicable service and subjects are required!", "error");
      }
      const resp = await AxiosInstance.post("/api/user/create-field", field);
      if (resp.data.status == "success"){
      setCountry("")
      setApplicableService("")
      setCareerPath("")
      setSubjects("")
      return Toast("Field created successsfully!", "success");
      }

      // const resp = await AxiosInstance.post("/api/user/get-fields", {
      //   dataset
      // });
      // //alert(resp.data);
      // if (resp.data && resp.data.fields) {
      //   setfieldsFromDb(resp.data.fields);
      //   setField({ ...field, loading: false });
      // }
    }
  
  const createField = async(e) => {
    e.preventDefault();
    // return alert(JSON.stringify(field));
    // setField({ ...field, loading: true });
    alert(field.service);

    if (!field.name || !field.subjects || !field.service) {
      setField({ ...field, loading: false });
      return Toast("Name and subjects are required!", "error");
    }
    const resp = await AxiosInstance.post("/api/user/create-field", field);
    //console.log(resp.data);
    if (resp.data.error) {
      setField({ ...field, loading: false });
      return Toast(resp.data.message, "error");
    } else {
      setField({
        name: null,
        subjects: null,
        service: null
      });
      setField({ subjects: "", name: "", service: "", loading: false });
      return Toast("Field created successsfully!", "success");
    }
  };

  //update fields

  const updateField = async() => {
    setField({ ...field, loading: true });
    // return alert(field.subjects);
    const data = {
      fieldId: field._id,
      name: field.name,
      subjects: field?.subjects,
      service: field.service
    };
    try {
      const resp = await AxiosInstance.post(`/api/user/edit-field`, data);
      console.log(resp.data);
      if (resp.data && resp.data.name) {
        setField({
          name: resp.data.name,
          subjects: resp.data?.subjects,
          service: resp.data.service,
          loading: false,
        });
      }
      return Toast("Field updated successfully", "success");
    } catch (error) {
      setField({ ...field, loading: false });
      return Toast(error.message, "error");
    }
  }

  return (
    <div className="absolute top-12 z-50 md:left-[20%] p-8 pb-20 md:w-2/3 w-10/12 h-min bg-white text-gray-600 shadow-md rounded-lg ">
      {/* <div className=" underline underline-offset-8">  */}
      <div className="h-[20%] pb-1 border-b border-b-[#1D6DAE] w-full flex justify-between px-2 mb-6">
        <div className="text-base font-semibold text-[#1D6DAE]">
        Career Paths and Areas of Knowledge and Skill Needs
          {/* <EditOutlined
            className="ml-2"
            onClick={() => {
              setEdit(!edit);
              setField({ name: "", subjects: "" });
            }}
          /> */}
        </div>
        <CloseCircleTwoTone onClick={toggleCreateFieldModal} class="absolute top-3 right-3" />
      </div>
      {/* <div class="schoolDivider pr-2 w-full h-5"></div> */}
      <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
        <select
            name="service"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="w-full border-2 border-gray-100 outline-none rounded-lg px-2 py-2 "
          >
            <option value="">Select Country</option>
            <option value="global">All Countries</option>
            {ctries.length > 0 && ctries.map((country,i) =>{
              return <option key={i}>{country.country}</option>
            })}
          </select>
        <select
            name="service"
            value={applicableService}
            onChange={(e)=>setApplicableService(e.target.value)}
            placeholder="Education Advisory / Digital Skill"
            className="w-full border-2 border-gray-100 outline-none rounded-lg px-2 py-2 "
          >
            <option value="">Select Applicable Service</option>
            <option value="Education Advisory">Education Advisory</option>
            <option value="Digital Literacy and Life Development">Digital Literacy and Life Development</option>
          </select>
      </div>
      {/* {edit && (
        <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
          <select
            value={field.name}
            onChange={(e) => {
              const value = JSON.parse(e.target.value);
              console.log(value);
              setField(value);
            }}
            className="w-full bg-gray-200 outline-none rounded-lg px-2 py-2 "
          >
            <option value="">-- select field to edit</option>
            {fieldsFromDb?.map((field, i) => (
              <option key={i?.toString()} value={JSON.stringify(field)}>
                {field?.name}
              </option>
            ))}
          </select>
        </div>
      )} */}
      <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
        {applicableService && (
          <input
          name="name"
          onChange={(e)=> setCareerPath(e.target.value)}
          value={careerPath}
          placeholder={applicableService == "" ? "" : 
                        applicableService == "Education Advisory" ? "Career Path (Example, Mechatronics)" : "Skill or framework (Example, ESSATS1 Web Programming)"}
          className="w-full border-2 border-gray-100 outline-none rounded-lg px-2 py-2 "
        ></input>
        )}
      </div>
      <div className="flex px-2 mt-2 w-full space-x-3 justify-between">
        <textarea
          name="subjects"
          onChange={(e)=>setSubjects(e.target.value)}
          value={subjects}
          placeholder={applicableService == "" ? "" :
                      applicableService == "Education Advisory" ? "Add comma separated list of required subjects (Example, Basic Science, Mathematics, Physics, Civics, Social Studies)" 
            :"Add comma separated list of subjects or modules (HTML, CSS, JavaScript)"}
          className="w-full h-60 resize-none bg-gray-200 outline-none rounded-lg px-2 py-2 "
        ></textarea>
      </div>
      <div className="flex px-2 mt-2 w-full justify-end">
        {!edit && (
          // <button onClick={createField} className="custom-primary-btn w-full">
          <button onClick={(e)=>handleSubmit(e)} className="custom-primary-btn w-full mt-3">
            Add Career Path/Country Area of Knowledge and Skill Needs
          </button>
        )}


        {/* {edit && (
          <button
            onClick={() => {
              setEdit(!edit);
              setField({ name: "", subjects: "" });
            }}
            className="text-[#1D6DAE] font-semibold  mr-4"
          >
            cancel
          </button>
        )}
        {edit && (
          <button onClick={updateField} className="custom-primary-btn">
            Update Field
          </button>
        )} */}
      </div>
    </div>
  );
}

export default CreateFields;
