import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";

export const removeAdmin = createAsyncThunk("remove/Admin", async (id)=>{
    const url = `/api/user/admin/${id}`;
    const { data } = await AxiosInstance.delete(url);
    return data
})

export const getAllAdmins = createAsyncThunk("get/AllAdmins",  async (countryQuery="") => {
      const url = `/api/user/get-admins-and-filter?country=${countryQuery}`;
      try {
        const { data } = await AxiosInstance.get(url);
        return data.admins
      }catch(err){

      }    
})
export const getCitiesAndFilter = createAsyncThunk("get/CitiesAndFilter",  async (city="") => {
      const url = `/api/province/get-all-cities-and-filter?state=${city}`;
      try {
        const { data } = await AxiosInstance.get(url);
        return data.cities.cities
      }catch(err){

      }    
})
export const countAdminsByCountry = createAsyncThunk("count/AdminsByCountry",  async () => {
          const url = `/api/user/count-admins-by-country`;
          try {
            const { data } = await AxiosInstance.get(url);
            return data.adminCount
          }catch(err){

          }    
})

export const getAdminTypes = createAsyncThunk("get/AdminTypes",  async () => {
          const url = `/api/user/admin-types`;
          try {
            const { data } = await AxiosInstance.get(url);
            return data.admins
          }catch(err){

          }    
})


const initState = {
  componentsStates: {
    removeAdminPrompt: false,
    showListOfAdmins: false,
    showAdminPopOver: false,
    showUpdateOrganizationModal: false,
    showOrganizationCreateModal: false,
    showAdminDetailsModal: false,
    editAdminDetailsModal: false,
  },
  allAdmins: [],
  allAdminsByCountry: [],
  sponsoringOrganizationCountry:"",
  isLoading: false,
  adminCountry: "",
  adminDetails: "",
  allCities: [],
  allAdminTypes: [],
};

export const adminSlice = createSlice({
  name: "sponsoringOrganizationSlice",
  initialState: initState,
  reducers:{
    setShowListOfAdmins:(state, action)=>{
        state.componentsStates.showListOfAdmins = action.payload
    },
    setRemoveAdminPrompt:(state, action)=>{
        state.componentsStates.removeAdminPrompt = action.payload
    },
    setAdminCountry:(state, action)=>{
        state.adminCountry = action.payload
    },
    setShowAdminPopOver:(state, action)=>{
        state.componentsStates.showAdminPopOver = action.payload
    },
    setAdminDetails:(state, action)=>{
        state.adminDetails = action.payload
    },
    setShowUpdateOrganizationModal:(state, action)=>{
        state.showUpdateOrganizationModal = action.payload
    },
    setShowOrganizationCreateModal:(state, action)=>{
        state.componentsStates.showOrganizationCreateModal = action.payload
    },
    setShowAdminDetailsModal:(state, action)=>{
        state.componentsStates.showAdminDetailsModal = action.payload
    },
    setEditAdminDetailsModal:(state, action)=>{
        state.componentsStates.editAdminDetailsModal = action.payload
    },
  },
  extraReducers:(builder) => {
    builder
      .addCase(removeAdmin.pending, (state) => {
          state.isLoading = true
      })
      .addCase(removeAdmin.fulfilled, (state, action) => {
          state.allAdmins = state.allAdmins.filter(admin=> admin._id != action.payload.deletedAdmin._id) 
          state.isLoading = false

      })
      .addCase(getAllAdmins.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getAllAdmins.fulfilled, (state, action) => {
          state.allAdmins = action.payload
          state.isLoading = false
      })
      .addCase(countAdminsByCountry.pending, (state) => {
          state.isLoading = true
      })
      .addCase(countAdminsByCountry.fulfilled, (state, action) => {
          state.allAdminsByCountry = action.payload
          state.isLoading = false
      })
      .addCase(getCitiesAndFilter.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getCitiesAndFilter.fulfilled, (state, action) => {
          state.allCities = action.payload
          state.isLoading = false
      })
      .addCase(getAdminTypes.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getAdminTypes.fulfilled, (state, action) => {
          state.allAdminTypes = action.payload
          state.isLoading = false
      })
    }
  },
);

export const {
              setShowListOfAdmins,
              setAdminCountry,
              setRemoveAdminPrompt,
              setAdminDetails,
              setShowUpdateOrganizationModal,
              setShowOrganizationCreateModal,
              setShowAdminDetailsModal,
              setEditAdminDetailsModal,
              setAdminCounter,
              setShowAdminPopOver
            } = adminSlice.actions

export default adminSlice;
