
import React, { useState } from "react";
import { useEffect } from "react";
import { Modal, ModalBody, ModalTitle, ModalHeader } from 'react-bootstrap';
import CountryView from "../admin/modal/countryView";
import AddSubventionCode from "./components/AddSubventionCode";
import AddSchoolSessionDateModal from "./components/AddSchoolSessionDateModal";
import DarkOverlay from "../../../DarkOverlay";
import Axios from "axios";
import AxiosInstance from "../../../../AxiosInstance/AxiosInstance";

export default function B2P({ toggleAddCitiesModal }) {
  const [visible, setVisible] = useState(false);
  const [subscribersCount, setSubscribersCount] = useState([]);
  const [users, setUsers] = useState({
    teachers: 0,
    students: 0,
    businessleaders: 0,
    companystaff: 0,
    privates: 0,
    individuals: 0,
  });
  const [prop, setProp] = useState(null);
  const [country, setCountry] = useState(null);
  const [reps, setReps] = useState(0);
  const [showAddSubventionCodeModal, setShowAddSubventionCodeModal] = useState(false);
  const separateOut = subs => {
    if (!subs?.length) {
      return [];
    }
    const resp = {};
    const keys = Object.keys(subs[0]);
    keys.forEach(key => {
      subs.forEach(el => {
        if (resp.hasOwnProperty(key)) {
          resp[key].push(el[key])
        } else {
          resp[key] = [el[key]];
        }
      })
    });
    return resp;
  }

  const removeDuplicates = arr => {
    return arr.filter((item, index) => arr.indexOf(item) === index)
  }

  function elementCount(arr, element) {
    return arr.filter((currentElement) => currentElement == element).length;
  };


  const countValuesByKey = (arr, key) => arr.reduce((r, c) => {
    r[c[key]] = (r[c[key]] || 0) + 1
    return r
  }, {});


  useEffect(() => {
    const findValue = (arr, key, value) => arr.filter(x => x[key] === value);
    const getAllUsers = async () => {
      const resp = await AxiosInstance.post('/api/user/users');
      //console.log(resp.data.users);
      if (resp.data) {
        var res = resp.data.users;

        var indUser = res?.reduce(function (n, person) {
          return n + (person.accountType == 'individual');
        }, 0);
        var numStd = res?.reduce(function (n, person) {
          return n + (person.userType == 'student');
        }, 0);
        var numSch = res?.reduce(function (n, person) {
          return n + (person.userType == 'school');
        }, 0);
        var numTch = res?.reduce(function (n, person) {
          return n + (person.userType == 'teacher');
        }, 0);
        var numPrv = res?.reduce(function (n, person) {
          return n + (person.userType == 'private');
        }, 0);
        var numCom = res?.reduce(function (n, person) {
          return n + (person.userType == 'company');
        }, 0);
        var numStf = res?.reduce(function (n, person) {
          return n + (person.userType == 'companyStaff');
        }, 0);
        var numLed = res?.reduce(function (n, person) {
          return n + (person.userType == 'companyLeader');
        }, 0);
        var numAdv = res?.reduce(function (n, person) {
          return n + (person.userType == 'advisor');
        }, 0);
        var numAsc = res?.reduce(function (n, person) {
          return n + (person.userType == 'associate');
        }, 0);
        //console.log(numEx);
        setUsers({
          teachers: numTch,
          students: numStd,
          businessleaders: numLed,
          companystaff: numStf,
          privates: numPrv,
          individuals: indUser
        })
        return;
      }
    }
    getAllUsers();
    const getCustomUsers = async () => {
      const rep = await AxiosInstance.post("/api/user/user/custom");
      console.log(rep.data);
      if (rep.data) {
        let found = rep.data.users;
        let find = (separateOut(found)).country;
        //console.log(find);
        const count = {};
        for (var i = 0; i < find?.length; i++) {
          var element = find[i];
          if (count[element]) {
            count[element] += 1;
          } else {
            count[element] = 1;
          }
        }
        // console.log(count);
        const cnames = Object.keys(count);
        const cvalues = Object.values(count);
        //console.log(cvalues);
        //console.log(cnames);
        setCountry(cnames);
        setReps(cvalues);

      }
    }
    getCustomUsers();


    const getSubscribersCount = async () => {
      const rep = await AxiosInstance.get("/api/update/count-subscribers");
      setSubscribersCount(rep?.data?.subscribers);
    }
    getSubscribersCount()
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="w-full mb-2 flex justify-between">
          <div className="font-bold text-lg">Global Market</div>
          <div>
            <button
              onClick={toggleAddCitiesModal}
              className="bg-esgrown text-white drop-shadow-2xl py-2 px-2 rounded-lg border border-esgrown transition-all duration-500 hover:-translate-y-1 focus:outline-none"
            >
              Add Cities
            </button>
            <button
              onClick={() => {
                setShowAddSubventionCodeModal(true)
              }}
              className="bg-esgrown px-2 py-2 ml-1 rounded-lg border border-esgrown transition-all duration-500 hover:-translate-y-1 focus:outline-none text-white drop-shadow-2xl"
            >
              Subvention/Subsidy Code
            </button>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-12">
            <div className="row justify-between">
              <div className="col-lg-2">
                <div className=" bg-white pl-2 pt-4 pb-4">
                  <h5 className="mb-4">{users.teachers}</h5>
                  <h6 className="">Teachers</h6>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="bg-white pl-2 pt-4 pb-4">
                  <h5 className="mb-4">{users.students}</h5>
                  <h6 className="">Students</h6>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="bg-white pl-2 pt-4 pb-4">
                  <h5 className="mb-4">{users.businessleaders}</h5>
                  <h6 className="">Business Leaders</h6>
                </div>
              </div>
              <div className="col-lg-2">
                <div className=" bg-white pl-2 pt-4 pb-4">
                  <h5 className="mb-4">{users.companystaff}</h5>
                  <h6 className="">Company Staff</h6>
                </div>
              </div>
              <div className="col-lg-2">
                <div className=" bg-white pl-2 pt-4 pb-4">
                  <h5 className="mb-4">{users.privates}</h5>
                  <h6 className="">Privates</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-8 mt-4">
                <div className="p-4 bg-success1">
                  <h6 className="text-white">
                    Global individual customer base: <br /> {users.individuals}
                  </h6>
                </div>
                <div className="mt-3">
                  <div className="table-responsive bg-white">
                    <table className="table table-hover table-striped">
                      <thead className="bg-success1 text-white">
                        <tr>
                          <th>Country</th>
                          <th>Customers</th>
                          <th>Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {country && country?.length > 0 &&
                          country.map((field, i) => (
                            field!=="" && field!=='undefined' ?(<tr>
                              <td>{field}</td>
                              <td>{reps[i]}</td>
                              <td><button onClick={() => (
                                setVisible(true),
                                setProp(field)
                                //console.log(field)
                              )} className="font-bold text-blue-800">View::</button></td>
                            </tr>):(<></>)
                          ))
                        }
                        {visible &&
                          <CountryView
                            show={visible}
                            props={prop}
                            onHide={() => setVisible(false)}
                          />
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="w-full mt-3 border-b-2 border-blue-800 flex flex-row justify-between items-center">
                    <button className="mx-2 text-blue-800 font-bold py-2 px-2 rounded-lg hover:bg-blue-300">Previous</button>
                    <button className="mx-2 text-blue-800 font-bold py-2 px-2 rounded-lg hover:bg-blue-300">Next</button>
                  </div>
                  <div className="w-full table-responsive border-b-2 border-blue-800 bg-white mt-3 flex flex-row justify-start items-center">
                    <table className="table table-hover table-striped">
                      <thead className="bg-success1 text-white">
                        <tr>
                          <th colSpan={3}>Incoming Memos</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Spain</td>
                          <td>2</td>
                          <td><button className="font-bold text-blue-800">View::</button></td>
                        </tr>
                        <tr>
                          <td>Nigeria</td>
                          <td>2</td>
                          <td><button className="font-bold text-blue-800">View::</button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="w-full table-responsive border-b-2 border-blue-800 bg-white mt-3 flex flex-row justify-start items-center">
                    <table className="table table-hover table-striped">
                      <thead className="bg-success1 text-white">
                        <tr>
                          <th colSpan={3}>Processed Memos</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Spain</td>
                          <td>2</td>
                          <td><button className="font-bold text-blue-800">View::</button></td>
                        </tr>
                        <tr>
                          <td>Nigeria</td>
                          <td>2</td>
                          <td><button className="font-bold text-blue-800">View::</button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mt-4">
                <div className="p-4 bg-success1">
                  <h6 className="text-white">Active Subscription </h6>
                </div>
                {subscribersCount?.length > 0 && subscribersCount.map((count, index) => {
                  return (
                    <div key={index} className="flex w-full bg-white pt-3 pb-2 pl-3">
                      <p className="mr-2">{count.total}</p>
                      <p className="">{count._id[0]!==undefined ? count._id[0].substring(0, 30):''}...</p>
                    </div>
                  )
                })
                }
                {/* <div className="col-12 bg-white pt-3 pb-2">
                  <h5 className="mb-3">0</h5>
                  <p className="">Education advisory</p>
                </div>
                <div className="col-12 bg-white pt-3 pb-2">
                  <h5 className="mb-3">0</h5>
                  <p className="">Study finance advisory</p>
                </div>
                <div className="col-12 bg-white pt-3 pb-2">
                  <h5 className="mb-3">0</h5>
                  <p className="">Recruitment mgmt</p>
                </div>
                <div className="col-12 bg-white pt-3 pb-2">
                  <h5 className="mb-3">0</h5>
                  <p className="">Leadership mgmt</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>

      </div>
      {showAddSubventionCodeModal &&
        (<div>
          <AddSubventionCode
            setShowAddSubventionCodeModal={setShowAddSubventionCodeModal}
          />
          {/* <DarkOverlay density={70} z="z-0" /> */}
        </div>)
      }
    </div>
  );
}
