import React, { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import { Layout, Menu, Tabs } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  ScheduleOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

import DropDownMenu from "../reusable/DropDownMenu";
import OnlineUser from "../reusable/OnlineUser";

import UCBIPayementModal from "../UCBIPayementModal";

import SmallScreenDrawer from "../SmallScreenDrawer";
import SDG4 from "./SDG4";
import SDG8 from "./SDG8";
import CaptureSchool from "../steka_modals/CaptureSchool";
import RenderStekaForm from "./modals/RenderStekaForm";

const { Header, Sider, Content } = Layout;
const { TabPane } = Tabs;
const { SubMenu } = Menu;

const Full = ({ history }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [openUCBI, setOpenUCBI] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showSmallScreenDrawer, setShowSmallScreenDrawer] = useState(false);
  const [openCapSchoolModal, setopenCapSchoolModal] = useState(false);
  const toggleCapSchoolModal = () => setopenCapSchoolModal((prev) => !prev);

  const user = useSelector((state) => state.user.user);

  const handleClick = ({ key }) => {
    //you can perform setState here
  };

  return (
    <React.Fragment>
      {/* <RenderStekaForm>
        <CaptureSchool toggleCapSchoolModal={toggleCapSchoolModal} />
      </RenderStekaForm> */}
      <Layout
        style={{
          minHeight: "100vh",
          maxHeight: "100%",
          flexWrap: "nowrap",
          position: "relative",
        }}
      >
        {openUCBI && (
          <UCBIPayementModal setOpenUCBI={setOpenUCBI} ucbi={user.ucbi[0]} />
        )}

        <Sider
          trigger={null}
          className="sidebar-bg hidden md:block"
          collapsible
          collapsed={collapsed}
        >
          <div className="logo mt-3 mb-2 ml-5" />

          <Menu
            mode="inline"
            // theme="dark"
            className="sidebar-bg f-2"
            // defaultSelectedKeys={["2"]}
            onClick={handleClick}
          >
            <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
              <Link to="/home" />

              <span>Home</span>
            </Menu.Item>

            <Menu.Item
              key="2"
              icon={<ProfileOutlined />}
              className="font-2 mt-2"
            >
              <Link to="/profile" />
              <span>Profile</span>
            </Menu.Item>
            <Menu.Item key="3" icon={<FileDoneOutlined />}>
              <Link to="/subscribe" />
              <span>Subscription</span>
            </Menu.Item>
            <SubMenu
              key="4"
              title={<span>Services</span>}
              icon={<DatabaseOutlined />}
              className="px-0"
            >
              <Menu.Item key="sub1" className="text-[11px] ml-[-20px] pr-0 mr-0">
                <Link
                  to={
                    user.accountType === "individual"
                      ? "/edu-advisory-corperate"
                      : "/edu-advisory-corperate"
                  }
                />
                Education Advisory
              </Menu.Item>
              <Menu.Item key="sub1" className="text-[11px] ml-[-20px] pr-0 mr-0">
                <Link
                  to={
                    user.accountType === "individual"
                      ? "/digital-literacy"
                      : "/digital-literacy"
                  }
                />
                Digital Literacy and Life Skills
              </Menu.Item>
              <Menu.Item key="sub1" className="text-[11px] ml-[-20px] pr-0 mr-0">
                <Link
                  to={
                    user.accountType === "individual"
                      ? "/study-finance-advisory"
                      : "/study-finance-advisory"
                  }
                />
                Study Finance Advisory
              </Menu.Item>
              <Menu.Item key="sub1" className="text-[11px] ml-[-20px] pr-0 mr-0">
                <Link
                  to={
                    user.accountType === "individual"
                      ? "/job-and-employment"
                      : "/job-and-employment"
                  }
                />
                Jobs and Employment MGT
              </Menu.Item>
              <Menu.Item key="sub1" className="text-[11px] ml-[-20px] pr-0 mr-0">
                <Link
                  to={
                    user.accountType === "individual"
                      ? "/staff-competence-mgt"
                      : "/staff-competence-mgt"
                  }
                />
                Staff Competence MGT
              </Menu.Item>
              <Menu.Item key="sub1" className="text-[11px] ml-[-20px] pr-0 mr-0">
                <Link
                  to={
                    user.accountType === "individual"
                      ? "/business-leadership"
                      : "/business-leadership"
                  }
                />
                Business Leadership MGT
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="5" icon={<ScheduleOutlined />}>
              <Link to="/exercises" />
              <span>Exercises</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header className="row pt-2 head">
            {/* <MenuUnfoldOutlined
            style={{ zIndex: 500 }}
            className="text-red-600 text-lg"
            onClick={() => alert("Here!")}
          /> */}
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className:
                  "trigger always-ontop nav-but mr-4 pt-1.5 text-lg  hidden md:block",
                onClick: () => setCollapsed(!collapsed),
              }
            )}

            {/* This will only show for small devices  ////////////////////////*/}
            <div
              onClick={() => {
                setShowSmallScreenDrawer(!showSmallScreenDrawer);
              }}
              className="min-w-min h-full flex items-center cursor-pointer md:hidden"
            >
              <MenuFoldOutlined className="text-lg mb-3 mr-2 z-50" />
            </div>
            {/* ////////////////////////////////////////////////////// */}

            <ClickAwayListener onClickAway={() => setShowAccountMenu(false)}>
              <div className="ml-auto" style={{ zIndex: 100 }}>
                <DropDownMenu
                  user={user}
                  show={showAccountMenu}
                  bgColor="bg-white"
                />
                <div onClick={() => setShowAccountMenu(!showAccountMenu)}>
                  <OnlineUser user={user} />
                </div>
              </div>
            </ClickAwayListener>
          </Header>
          <Content
            style={{
              margin: "24px 16px",
              padding: 10,
              // background: "#fff",
              minHeight: 280,
            }}
          >
            <div className="tab-but w-full h-full">
              <Tabs defaultActiveKey="1">
                <TabPane tab="SD4" key="1">
                  <SDG4 />
                </TabPane>
                <TabPane tab="SDG8" key="2">
                  <SDG8 />
                </TabPane>
              </Tabs>
            </div>
          </Content>
        </Layout>

        {showSmallScreenDrawer && (
          <SmallScreenDrawer
            setShowSmallScreenDrawer={setShowSmallScreenDrawer}
            user={user}
          />
        )}
      </Layout>
    </React.Fragment>
  );
};

export default Full;

function validateFile(file) {
  const oneMB = 1024 * 1024;
  const fileName = file.name;
  const fileSize = file.size;
  if (fileSize > oneMB) return {};
  return { name: file.name, file };
}
