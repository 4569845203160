import React, { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { useDispatch } from "react-redux";

import { Layout } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { ShoppingCartOutlined } from "@ant-design/icons";
import DropDownMenu from "../reusable/DropDownMenu";
import OnlineUser from "../reusable/OnlineUser";
import Toast from "../../util/toast";
import { setUser } from "../../redux/slices/userSlice";
import UCBIPayementModal from "../UCBIPayementModal";
import SmallScreenDrawer from "../SmallScreenDrawer";
import CreateNewExercise from "./modals/CreateNewExercise";
import CustomSiderMenu from "../reusable/CustomSiderMenu";
import { Menu, Dropdown, Space, Tabs, Drawer } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined, ScheduleOutlined, LoginOutlined } from "@ant-design/icons";
const { Header, Sider, Content } = Layout;

const ExercisesCorperate = ({ history }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [openUCBI, setOpenUCBI] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [createExercise, setCreateExercise] = useState(false);
  const toggleCreateExercise = () => setCreateExercise((prev) => !prev);

  const [showSmallScreenDrawer, setShowSmallScreenDrawer] = useState(false);

  const user = useSelector((state) => state.user.user);

  const handleClick = ({ key }) => {
    //you can perform setState here
  };

  const defaultProfilePix =
    "https://esgrown.fra1.digitaloceanspaces.com/esgrown%201669801277885.jpg";

  return (
    <Layout
      style={{
        minHeight: "100vh",
        maxHeight: "100%",
        flexWrap: "nowrap",
        position: "relative",
      }}
    >
      {openUCBI && (
        <UCBIPayementModal setOpenUCBI={setOpenUCBI} ucbi={user.ucbi[0]} />
      )}

<Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onBreakpoint={(broken) => setCollapsed(broken)}
        className="hideOnMobile sidebar-bg"
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <Menu
          mode="inline"
          className="sidebar-bg f-2"
          defaultSelectedKeys={["10"]}
          onClick={handleClick}
        >
          <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
            <Link to="/AdminDashboard" />
            <span>Home</span>
          </Menu.Item>
          <Menu.Item key="2" icon={<ShoppingCartOutlined />} className="font-2 mt-2">
            <Link to="/adminStore" />
            <span>Store</span>
          </Menu.Item> 
          <Menu.Item key="3" icon={<ShoppingCartOutlined />}>
            <Link to="/adminOrders" />
            <span>Store Orders</span>
          </Menu.Item> 
          <Menu.Item key="4" icon={<ScheduleOutlined />}>
            <Link to="/Admin_Page" />
            <span>Admin</span>
          </Menu.Item>
          <Menu.Item key="5" icon={<ScheduleOutlined />}>
            <Link to="/UCBI" />
            <span>UCBI</span>
          </Menu.Item>
          <Menu.Item key="6" icon={<ScheduleOutlined />}>
            <Link to="/update" />
            <span>Updates</span>
          </Menu.Item>
          {/* <Menu.Item
            onClick={toggleCreateServiceModal}
            key="7"
            icon={<ScheduleOutlined />}
          >
            <span>create service</span>
          </Menu.Item> */}
          <Menu.Item key="8" icon={<ScheduleOutlined />}>
            <Link to="/campaigns" />
            <span>Campaigns</span>
          </Menu.Item>
          <Menu.Item key="10" icon={<ScheduleOutlined />}>
            <Link to="/exercises" />
            <span>Exercises</span>
          </Menu.Item>

          <Menu.Item key="9" icon={<LoginOutlined />}>
            <Link to="/logout" />
            <span>Logout</span>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className="row pt-2 head">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3 hidden md:block",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          {/* This will only show for small devices  ////////////////////////*/}
          <div
            onClick={() => {
              setShowSmallScreenDrawer(!showSmallScreenDrawer);
            }}
            className="min-w-min h-full flex items-center cursor-pointer md:hidden"
          >
            <MenuFoldOutlined className="text-lg mb-3 mr-2" />
          </div>
          {/* ////////////////////////////////////////////////////// */}
          <h3 className="mt-1 ">Profile</h3>

          <ClickAwayListener onClickAway={() => setShowAccountMenu(false)}>
            <div className="ml-auto">
              <DropDownMenu
                user={user}
                show={showAccountMenu}
                bgColor="bg-white/30"
              />
              <div onClick={() => setShowAccountMenu(!showAccountMenu)}>
                <OnlineUser user={user} />
              </div>
            </div>
          </ClickAwayListener>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 10,
            // background: "#fff",
            minHeight: 280,
          }}
        >
          <div className="w-full h-full flex flex-col relative">
            <div className="w-full p-3 rounded-lg bg-white">
              <div className="text-xl font-bold">Exercises</div>
              <div className="my-3 border-b "></div>
              <div className="text-lg">
                Exercises enable you assess applicants' strength and
                suitability. Hire the right apllicant!
              </div>
              <div className="w-full flex justify-end items-center mr-2 mt-3">
                <div className="relative mr-8">
                  <button
                    onClick={toggleCreateExercise}
                    className="px-2 py-1 rounded-lg text-white text-lg bg-esgrown  outline-none focus:outline-none "
                  >
                    Create New Exercise
                  </button>
                </div>
                <div className="flex text-lg text-esgrown px-3 py-1 mr-3 rounded-lg">
                  Total Exercises created
                  <span className="h-[24px] w-[24px] ml-1 mt-[1px] rounded-full flex items-center justify-center text-xs text-red-500 font-bold bg-slate-100">
                    16
                  </span>
                </div>
              </div>
            </div>

            {/* //New exercise modal  */}
            {createExercise && (
              <CreateNewExercise toggleCreateExercise={toggleCreateExercise} />
            )}
          </div>
        </Content>
      </Layout>
      {showSmallScreenDrawer && (
        <SmallScreenDrawer
          setShowSmallScreenDrawer={setShowSmallScreenDrawer}
          user={user}
        />
      )}
    </Layout>
  );
};

export default ExercisesCorperate;
