import { usePaystackPayment } from "react-paystack";
import Toast from "../../../../util/toast";
import AxiosInstance from "../../../../AxiosInstance/AxiosInstance";

function PromoCodeConfirmationModal({
  setShowPromoCodeInputBox,
  setShowPromoCodeConfirmationmodal,
  setShowPopOverContent,
  serviceId,
  user,
  servicePriceDetails,
  setOnPayStackSuccess,
}) {

  const servicePrice = servicePriceDetails?.price

  const config = {
    reference: new Date().getTime().toString(),
    email: user?.email,
    amount: servicePrice * 100, //* 100 converts sub amount from naira to to kobo
    publicKey: "pk_test_7b545e0d7a1aaa0e39782e7d5aa7e9595a8082fc",
};

const paystackOnClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
};

const paystackOnSuccess = (reference) => {
    setOnPayStackSuccess(true)
    const data = {
        updateServiceId: serviceId,
        user: user?._id,
        price: servicePrice,
        duration: servicePriceDetails?.duration
    }
    AxiosInstance.post(`api/update/create-update-subscription`, data)
};

const initializePayment = usePaystackPayment(config);
const paystackCheckout = async (service) => {
    // if () {
        // return Toast("Please, check your promo code!", "error");
    // }
    try {
        initializePayment(paystackOnSuccess, paystackOnClose);
    } catch (error) {
        Toast(error.message, "error");
    } finally {
        //   setDuration(null);
        //   setCareerPath(null);
        //   setPreferredPackage(null);
    }
};

  return (
    <div class="border-2 border-slate-600 rounded-md border-solid">
      <div class=" bg-white rounded-lg max-w-md mx-auto p-4  inset-x-0 bottom-0 z-50 md:relative ">
        <div class="flex justify-center items-center">
          <div class=" md:mt-0 md:ml-6 md:text-left">
            <div className="flex justify-between">
            <p class="font-bold">His Grace’s 40% Subvention</p>
            <p 
            className="font-bold text-red-600 cursor-pointer border-2 hover:border-red-600 rounded-full px-2"
            onClick={()=> setShowPromoCodeConfirmationmodal(false)}
            >X</p>
            </div>
            <p class="text-sm text-gray-700 mt-1">Is this service subsidized for you by 
            subvention for tool components of world Sustainable 
            Development Goal programs by the royal family of Eddaland, 
            House of Öddström?</p>
          </div>
        </div>
        <div class="text-center mt-4 flex justify-between">
          <div class="cursor-pointer block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-esgrown text-white hover:bg-slate-500 rounded-lg font-semibold text-sm mt-4
          md:mt-0 md:order-1 md:ml-6 md:text-left"
            onClick={() => {
              setShowPromoCodeInputBox(true)
              setShowPromoCodeConfirmationmodal(false)
              setShowPopOverContent(false)
            }}
          >Yes</div>
          <div class="cursor-pointer block w-full md:inline-block md:w-auto px-4 py-3 md:py-2  bg-red-600 hover:bg-red-400 text-white rounded-lg font-semibold text-sm mt-4
          md:mt-0 md:order-1" onClick={() => {
              paystackCheckout()
              setShowPromoCodeConfirmationmodal(false)
            }}>
            No
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromoCodeConfirmationModal