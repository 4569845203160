import axios from 'axios';

const LoggedInUser = JSON.parse(localStorage.getItem('persist:user'))
const AxiosInstance = axios.create({
    //baseURL: 'http://localhost:5000/',
    baseURL: 'https://api.esgrown.com/',
    headers: {
        'Authorization': 'Bearer ' + LoggedInUser?.token
    }
});

export default AxiosInstance;