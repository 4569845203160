import React, { useEffect, useState } from "react";
import { CloseCircleTwoTone } from "@ant-design/icons";
import Toast from "../../../../../util/toast";
import AxiosInstance from "../../../../../AxiosInstance/AxiosInstance";
import { DatePicker } from "antd";


function AddSchoolSessionDateModal({ setShowAddSchoolSessionDateModal }) {
  const [loading, setLoading] = useState(false);
  const [viewClicked, setViewClicked] = useState(false);
  const [generateClicked, setGenerateClicked] = useState(true);
  const [beginningOfTerm, setBeginningOfTerm] = useState(null);
  const [endOfTerm, setEndOfTerm] = useState(null);
  const [term, setTerm] = useState(null);
  const [allSchoolSession, setAllSchoolSession] = useState([]);
  const [schoolSessionAdded, setSchoolSessionAdded] = useState(false);


  // console.log("allSubventionCodes", allSubventionCodes);
  const handleSchoolSessionCreation = async () => {
    setLoading(true);
    if (!endOfTerm) {
      return Toast("Please Enter Beginning Of Term Date", "error");
    }
    if (!beginningOfTerm) {
      return Toast("Please Enter Beginning Of Term Date", "error");
    }
    if (!term) {
      return Toast("Please Select a Term", "error");
    }

    try {
      const { data } = await AxiosInstance.post(`/api/review/add-school-session`, {
        term,
        start_date: beginningOfTerm,
        end_date: endOfTerm
      });
      if (data.error) {
        Toast(data.message, "error");
      } else {
        setTerm("")
        setEndOfTerm("")
        setBeginningOfTerm("")
        setSchoolSessionAdded(true)
        Toast("School Session Has Been Created Successfully", "success");
      }
    } catch (error) {
      Toast(error.message, "error");
    } finally {
      setLoading(false);
      setSchoolSessionAdded(false);
    }
  };

  const getSchoolSession = async () => {
    setLoading(true);
    try {
      const { data } = await AxiosInstance.get(`/api/review/get-school-session`);
      setAllSchoolSession(data.schoolSession)
    } catch (error) {
      Toast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const onChangeBeginningOfTerm = (date, dateString) => {
    setBeginningOfTerm(dateString)
  };
  const onChangeEndOfTerm = (date, dateString) => {
    setEndOfTerm(dateString)
  };


  useEffect(() => {
    getSchoolSession()
  }, [schoolSessionAdded])


  return (
    <div className="flex-col w-[400px] h-[500px] fixed top-4 left-1/2  -translate-x-1/2 border-2 border-esgrown shadow-xl rounded-md overflow-x-hidden overflow-y-hidden z-50 bg-white text-gray-600">
      <div className="flex flex-col h-full justify-around items-center">
        <div className="w-full flex justify-end mr-2 mt-2">
          <CloseCircleTwoTone onClick={() => setShowAddSchoolSessionDateModal(false)} />
        </div>
        <div className="w-full flex justify-center items-center">
          <div className="text-md text-esgrown flex items-center">
            <p className={`cursor-pointer mr-1 text-esgrown font-extrabold ${generateClicked ? "border-b-2 border-esgrown" : ""}`}
              onClick={() => {
                setGenerateClicked(true)
                setViewClicked(false)
              }}
            >Create</p> |
            <p
              className={`cursor-pointer ml-1 text-esgrown font-extrabold ${viewClicked ? "border-b-2 border-esgrown" : ""}`}
              onClick={() => {
                setViewClicked(true)
                setGenerateClicked(false)
              }}
            >View</p>
          </div>
        </div>

        {generateClicked &&
          <div className="w-full flex flex-col items-center justify-center my-auto ">
            <div className="w-auto flex flex-col items-center">
              <div className="w-full flex justify-between">
                <div className="px-1">
                  <DatePicker
                    onChange={onChangeBeginningOfTerm}
                    // style={{ width: 200 }}
                    className="rounded-lg"
                    placeholder="Beginning Of Term"
                  />
                </div>
                <div className="px-1">
                  <DatePicker
                    onChange={onChangeEndOfTerm}
                    // style={{ width: 200 }}
                    className="rounded-lg"
                    placeholder="End Of Term"
                  />
                </div>
              </div>
              
              <select
                name="term"
                className="px-2 py-2 w-full rounded-lg border-2 border-gray-200 my-2"
                onChange={(e)=>setTerm(e.target.value)}
              >
                <option value="">Select Term</option>
                <option>First</option>
                <option>Second</option>
                <option>Third</option>
              </select>
              <div
                disabled={loading}
                onClick={() => {
                  handleSchoolSessionCreation()
                }}
                className={` ${loading && "animate-pulse"
                  } bg-esgrown cursor-pointer w-full disabled:bg-gray-300 text-white focus:outline-none py-1 rounded-md px-1 mt-1 justify-self-center text-center`}
              >
                Create School Session
              </div>
            </div>


          </div>
        }

        {viewClicked &&
          <div className="w-full flex flex-col items-center justify-center my-auto overflow-y-scroll">
            <table class="align-center table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400 table table-hover table-striped">
              <thead>
                <tr>
                  <th className="sticky top-0 text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">Term</th>
                  <th className="sticky top-0 text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">Starts</th>
                  <th className="sticky top-0 text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">Ends</th>
                </tr>
              </thead>
              <tbody>
                {allSchoolSession?.length > 0 && allSchoolSession.map((session, index) => {
                  return (
                    <tr key={index}>
                    <td>{session?.term} </td>
                    <td>{session?.start_date} </td>
                    <td>{session?.end_date}</td>
                  </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  );
}

export default AddSchoolSessionDateModal;
