
import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { CloseCircleTwoTone, DownOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import RemoveCareerFieldsModal from "./RemoveCareerFieldsModal";
import CareerFieldDetails from "./CareerFieldDetails";
import UpdateCareerFieldModal from "./UpdateCareerFieldModal";
import SupportingOrganizationDetailsModal from "./SupportingOrganizationDetailsModal";
import RemoveOrganizationConfirmationModal from "./RemoveOrganizationConfirmationModal";
import AdminDetailsModal from "./AdminDetailsModal";

import {
  getAllAdmins,
  countAdminsByCountry,
  setShowListOfAdmins,
  setAdminCountry,
  setRemoveAdminPrompt,
  setAdminDetails,
  setShowAdminDetailsModal,
  removeAdmin,
  setShowAdminPopOver
 } from "../../../../redux/slices/adminSlice";

function AdminListModal() {
  const {
    allAdmins,
    adminCountry,
    isLoading,
    allAdminsByCountry,
    organizationDetail
   } = useSelector(state => state.adminSlice )
  
   const { 
    removeOrganizationPrompt,
    showAdminPopOver,
    showUpdateOrganizationModal,
    showListOfOrganizations,
    showSupportingOrganizationDetailsModal,
    showAdminDetailsModal
   } = useSelector(state => state.sponsoringOrganizationSlice.componentsStates )
   
   const dispatch = useDispatch()

  useEffect(() => {
    window &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
  }, [allAdmins.length]);

  useEffect(()=>{
    dispatch(countAdminsByCountry())
    // dispatch(getAllAdmins())
  },[dispatch, getAllAdmins, countAdminsByCountry])

  useEffect(()=>{
    dispatch(getAllAdmins(adminCountry))
  },[adminCountry])

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="h-[20%] pb-1 border-b border-b-[#1D6DAE] w-full">
        <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
          <CloseCircleTwoTone
            onClick={() => {
              dispatch(setShowListOfAdmins(false))
            //   dispatch(setRemoveAdminPrompt(true))
            }}
          />
        </div>
        <div className="flex justify-between items-end pb-1 w-full h-[60%] ">
          <div className="w-[20%] ">
            <select
                name=""
                id=""
                value={adminCountry}
                onChange={(e) => {
                  dispatch(setAdminCountry(e.target.value));
                }}
                className="w-full py-1 focus:border-[#1D6DAE] rounded-lg focus:outline-none border-2"
                >
                <option value="">
                    All Countries
                  </option>
                {!isLoading &&
                  allAdminsByCountry.map((organization, index) => (
                    <>
                      {organization?._id? 
                        <option key={index} >
                          {organization?._id }
                        </option>
                        :""}
                    </>
                  ))}
              </select>
            </div>
            <div className="w-full ml-6">
                <p class="text-[#1D6DAE] font-bold text-lg">Corporate Staff</p>
             </div>
        </div>
      </div>
      {/* list of Associates  */}
      <div className="h-[70%] border-b border-b-blue-500 overflow-y-scroll">
        {allAdmins?.length > 0 ? (
          allAdmins?.map((admin, index) => (
            <div
              key={index.toString()}
              className="odd:bg-gray-200 mt-1 h-10 text-gray-600 font-semibold px-4 flex justify-between"
            >
              <p className=" ">{admin.name}</p>

              {!removeOrganizationPrompt && !showAdminPopOver && (
              <Popover
                placement="left"
                trigger="click"
                content={
                  <div className="min-w-min min-h-min flex flex-col items-start justify-start space-y-2 ">
                    <button
                      onClick={() => {
                        dispatch(setAdminDetails(admin));
                        dispatch(setShowAdminPopOver(true))
                        dispatch(setShowAdminDetailsModal(true))
                        dispatch(setShowListOfAdmins(false))
                      }}
                      className="px-2 outline-none  focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                    >
                      View Profile
                    </button>
                    <button
                      onClick={() => {
                       
                        // dispatch(setShowOrganizationPopOver(true))
                        dispatch(setRemoveAdminPrompt(true))
                        dispatch(setAdminDetails(admin))
                        dispatch(setShowListOfAdmins(true))
                        dispatch(setShowAdminPopOver(true))
                      }
                      }
                      className="px-2 outline-none focus:outline-none rounded-full text-red-400 transition-all duration-500 hover:-translate-y-1 hover:bg-red-300 hover:text-white"
                    >
                      Remove
                    </button>
                  </div>
                }
              >
                <button className="text-[#1D6DAE] focus:outline-none transition-all hover:translate-x-1 duration-500">
                  Actions
                </button>
              </Popover>
              )}
            </div>
          ))
        ) : (
          <div className="w-full flex justify-center h-10 mt-1.5 ">
            <div>No Admin👏 </div>
          </div>
        )}
      </div>
      {/* <div className="flex h-[10%] justify-end items-center ">
        <button className="px-3 py-1 rounded-lg font-semibold border-2 border-[#1D6DAE] transition-all duration-700 text-[#1D6DAE] hover:bg-[#1D6DAE] hover:text-white ">
          Next
        </button>
      </div> */}
      {removeOrganizationPrompt && (
      <RemoveOrganizationConfirmationModal/>
      )}

    {/* {showListOfCareerFieldsSubjects && (
      <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
        <CareerFieldDetails 
        />
        </div>
      )} */}

    {showUpdateOrganizationModal && (
      <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
        <UpdateCareerFieldModal 
        />
        </div>
      )}

    {showAdminDetailsModal && (
      <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}>
        <AdminDetailsModal/>
      </div>
      )}
    </div>
  );
}


export default AdminListModal;


