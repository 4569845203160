import {
    CloseCircleTwoTone,
  } from "@ant-design/icons";
  import { Avatar, Checkbox, Dropdown, Image, Popover, Spin } from "antd";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import Toast from "../../../../util/toast";
  import { MediaPath } from "../../../../App";
  import ctries from "../../../../util/allcountries";
  import RemoveOrganizationConfirmationModal from "./RemoveOrganizationConfirmationModal";
  import {
          setRemoveOrganizationPrompt , 
          setShowOrganizationCreateModal,
          setShowOrganizationPopOver,
          setShowSupportingOrganizationDetailsModal,
          setEditSupportingOrganizationDetailsModal,
          setOrganizationDetail,
          setShowSupportedSubGoalsModal,
          getSupportedSubGoals
          } from "../../../../redux/slices/sponsoringOrganizationSlice";
  
  function SupportedSubGoalsModal() {
    const dispatch = useDispatch();
    const [returnArray, setReturnArray] = useState([])
    
    const {showSupportingOrganizationDetailsModal,
          removeOrganizationPrompt
    } = useSelector(state => state.sponsoringOrganizationSlice.componentsStates )
    
    const {organizationDetail,
          allSupportedSubGoals,
    } = useSelector(state => state.sponsoringOrganizationSlice )
 
    useEffect(()=>{
      dispatch(getSupportedSubGoals())
    },[getSupportedSubGoals])
    const supportedSubGoals = organizationDetail.field.split(",")
    useEffect(()=>{
    const result = allSupportedSubGoals.filter( item => supportedSubGoals.includes(String(item.goal_number)))
    setReturnArray(result)
  },[dispatch])
    return (
      <div
        className="w-fit h-screen flex bg-white flex-col items-end"
        style={{ zIndex: 200 }}
      >
        <div className="h-[20%] pb-1 w-full">
          <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
            <CloseCircleTwoTone
              onClick={() => {
                // setShowSelectedAssociate(false)
                // dispatch(setShowViewAssociateModal(false))
                dispatch(setShowOrganizationPopOver(false))
                dispatch(setShowSupportedSubGoalsModal(false))
            }}
              color="red"
            />
          </div>
          <div className="flex flex-col min-h-min  ">
            {/* section 1 */}
            <div className="text-3xl text-[#1D6DAE] font-semibold  w-full flex items-center justify-center pb-2 border-b border-b-[#1D6DAE] mb-3">
            Scigonia Europiska Supported SDP target sub-goals                                 
            </div>

            <div className="text-sm  w-full flex items-center justify-center pb-2">
              This organization provides supply of tools as products and process as services that meet these target sub-goals a UN SDG 4 aligned Sustainable Development Programme for:                                 
            </div>

            {
              organizationDetail.organizationType != "Business Franchise" ? <h2 class="text-center mt-4 text-lg text-[#1D6DAE]">No Supported SDP target sub-goals</h2> :
            returnArray.map(item=>{
                return(
                  <div className=" mt-4 flex justify-start items-center">
                    <div class="border border-[#1D6DAE] p-2 rounded-md bg-slate-200">{item.goal_number}</div>
                    <div class="ml-5 border border-[#1D6DAE] p-1 rounded-md">{item.goal_aim}</div>
                  </div>
                )
            })
          }
            
            {/* section 3 */}
            <div>
              <div className="w-full flex justify-end mt-5">
                <button className="px-6 text-sm outline outline-gray-300 text-white bg-[grey] py-1 rounded-lg transition-all duration-700 reAssignButton"
                onClick={()=>{
                        dispatch(setShowOrganizationPopOver(false))
                        dispatch(setOrganizationDetail(organizationDetail))
                        dispatch(setShowSupportedSubGoalsModal(false))
                        // dispatch(setEditSupportingOrganizationDetailsModal(true))
                        // dispatch(setShowOrganizationCreateModal(true))
                }}
                >
                  Close
                </button>
                {organizationDetail.organizationType != "Business Franchise" ? "" :
                  <button className="px-6 text-sm bg-[#1D6DAE] text-white py-1 rounded-lg hover:translate-y-1 transition-all duration-700 removeButton"
                    onClick={()=>{
                      dispatch(setShowOrganizationPopOver(true))
                      dispatch(setRemoveOrganizationPrompt(true))
                      dispatch(setOrganizationDetail(organizationDetail))
                    }
                    }
                    >
                      Send Requisition
                    </button>}
              </div>
              </div> 
            </div>
          </div>

          {/* {removeOrganizationPrompt && (<RemoveOrganizationConfirmationModal/>)} */}
      </div>
    );
  }
  
  export default SupportedSubGoalsModal;
  