import React, { useState } from "react";
import { home } from "../svg/Home.svg";
import { Link } from "react-router-dom";

import RmService from "../ind_dashboard_components/subscription-forms/rmform";

import { Layout, Menu, Dropdown, Space } from "antd";
import Icon, {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  DatabaseOutlined,
  ScheduleOutlined,
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const handleClick = ({ key }) => {
  console.log(key);
  //you can perform setState here
};

const Full = () => {
  const [collapsed, setCollapsed] = useState(false);
  const user = useSelector((state) => state.user.user);
  function toggle() {
    setCollapsed(!collapsed);
  }

  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="Profile" icon={<UserOutlined />} className="font-3 mt-2">
        <Link to="/profile" />
        Profile
      </Menu.Item>
      <hr />
      {/* <Menu.Item key="Line">
      </Menu.Item> */}
      <Menu.Item key="Logout" icon={<LogoutOutlined />} className="mb-2 font-3">
        <Link to="/logout" />
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout
      style={{ minHeight: "100vh", maxHeight: "100%", flexWrap: "nowrap" }}
    >
      <Sider
        trigger={null}
        className="sidebar-bg"
        collapsible
        collapsed={collapsed}
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <Menu
          mode="inline"
          // theme="dark"
          className="sidebar-bg f-2"
          defaultSelectedKeys={["10"]}
          onClick={handleClick}
        >
          <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
            {/* <Icon src={home} alt="" /> */}
            {/* <Icon component={() => <img src={home} className="icon" />} /> */}
            {/* <Home /> */}
            {/* <img className="menu-icon" /> */}
            <Link to="/home" />

            <span>Home</span>
          </Menu.Item>

          <Menu.Item key="2" icon={<ProfileOutlined />} className="font-2 mt-2">
            <Link to="/profile" />
            <span>Profile</span>
          </Menu.Item>
          <Menu.Item key="3" icon={<FileDoneOutlined />}>
            <Link to="/subscribe" />
            <span>Subscription</span>
          </Menu.Item>
          <SubMenu
            key="sub3"
            title={<span>Services</span>}
            icon={<DatabaseOutlined />}
          >
            <Menu.Item key="6">
              {" "}
              <Link to="/EAS" />
              EAS Services
            </Menu.Item>
            <Menu.Item key="7">
              {" "}
              <Link to="/EFA" />
              EFA Services
            </Menu.Item>
            <Menu.Item key="8">
              {" "}
              <Link to="/RM" />
              RM Services
            </Menu.Item>
            <Menu.Item key="9">
              <Link to="/LM" />
              LM Services
            </Menu.Item>
            <Menu.Item key="10">
              {" "}
              <Link to="/CM" />
              CM Services
            </Menu.Item>
          </SubMenu>
          {/* <Menu.Item key="4" icon={<HomeOutlined />}>
              <span>Services</span>
            </Menu.Item> */}
          <Menu.Item key="5" icon={<ScheduleOutlined />}>
            <Link to="/exercises" />
            <span>Exercises</span>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className="row pt-2 head">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3",
              onClick: toggle,
            }
          )}
          <h3 className="mt-1 wel">Services</h3>
          <div className="ml-auto account">
            <Dropdown overlay={menu} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Account <DownOutlined style={{ fontSize: "16px" }} />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 10,

            // background: "#fff",
            minHeight: 280,
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="col mb-4 text-bold">EAS Services</h1>
                <div className="rm-body">
                  <div className="col-lg-6">
                    <h3 className="text-bold">Education Advisory Service</h3>

                    <p className="font-2 mt-2">
                      When you subscribe to recruitment management service, you
                      would be able to publish openings for job in the company
                      with description each job and the knowledge and skill
                      needs for it, and set a deadline for application. You
                      would see all applicants who are interested in working in
                      your company. <br />
                      <br />
                      Based on performance of candidates in pre-engagement
                      training and exercises delivered by Esgrown, you would see
                      where each applicant ranks in assessment. That would
                      enable to engage personnel based on competences that meet
                      knowledge and skills needs of your company for
                      effectiveness business operations.
                    </p>
                  </div>
                </div>
                <div className="rm-body mt-4">
                  <div className="row justify-content-center">
                    <div className="col-lg-5">
                      <RmService />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Full;
