import { CloseCircleOutlined } from "@ant-design/icons";
import { Avatar, Rate, Spin } from "antd";
import AxiosInstance from "../../../AxiosInstance/AxiosInstance";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MediaPath } from "../../../App";
import Toast from "../../../util/toast";

function RatingModal({ setOpenRatingModal }) {
  const teacherToRate = useSelector((state) => state.review.teacherToRate);
  const user = useSelector((state) => state.user.user);

  const [pedagogy, setPedagogy] = useState(1);
  const [classControl, setClassControl] = useState(1);
  const [studentTeacherRel, setStudentTeacherRel] = useState(1);
  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState(false);
  console.log(teacherToRate);

  const rate = async () => {
    setLoading(true);
    // console.log(pedagogy  + " " + studentTeacherRel + " " + classControl + " " + remark);
    const data = {
      rating: pedagogy + classControl + studentTeacherRel,
      remark,
      user: teacherToRate?._id, // The teacher being reviewed
      author: user._id, //LoggedIn admin user who is reviewiing teachers
    };
    try {
      const review = await AxiosInstance.post("/api/review/create", { data });
      if (!review.data.error)
        return Toast("Review created successfully!", "success");
      return Toast(review.data.message, "error"); //console.log(review.data.message);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="w-[88%] md:w-[60%] min-h-min p-3 mt-2 mx-auto bg-white rounded-lg">
      <div className="w-full flex text-red-400 cursor-pointer justify-end mr-2 ">
        <CloseCircleOutlined onClick={() => setOpenRatingModal(false)} />
      </div>
      <div className="flex items-center border-b-2 pb-2 text-small md:text-lg font-bold  border-gray-200">
        <Avatar
          size={100}
          className="flex relative mr-1 mx-2 items-center justify-center rounded-full cursor-pointer"
          src={MediaPath + teacherToRate?.profilePix}
        ></Avatar>
        <div className="mr-4">{teacherToRate?.name}</div>
        <div className="text-base">
          <div>
            {user?.userType === "school" ? "Teacher" : "Staff"}'s Weekly
            Performance Rating
          </div>
          <div className="mt-3 ">
            Total Score: {pedagogy + classControl + studentTeacherRel}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-wrap py-2 space-y-2 justify-between ">
        <div className="w-[48%]">
          <div className="font-bold ">
            {user?.userType === "company" ? "Civility" : "Pedagogy"}
          </div>
          <div className="">
            <Rate
              allowClear
              autoFocus
              tooltips={["1", "2", "3", "4", "5"]}
              defaultValue={1}
              onChange={(value) => setPedagogy(value)}
            />
          </div>
        </div>
        <div className="w-[48%]">
          <div className="font-bold ">
            {user?.userType === "company"
              ? "Professionalism"
              : "Teacher/Student Relation"}
          </div>
          <div className="">
            <Rate
              allowClear
              autoFocus
              tooltips={["1", "2", "3", "4", "5"]}
              defaultValue={1}
              onChange={(value) => setStudentTeacherRel(value)}
            />
          </div>
        </div>
        <div className="w-[48%]">
          <div className="font-bold ">
            {user?.userType === "company" ? "Job Performance" : "Class Control"}
          </div>
          <div className="">
            <Rate
              allowClear
              autoFocus
              tooltips={["1", "2", "3", "4", "5"]}
              defaultValue={1}
              onChange={(value) => setClassControl(value)}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex py-2 pb-8 ">
        <div className="font-bold mr-2">Rating Key:</div>
        <div className="flex space-x-2">
          <div>1. Poor</div>
          <div>2. Fair</div>
          <div>3. Good</div>
          <div>4. Very Good</div>
          <div>5. Excellent</div>
        </div>
      </div>
      <div className="w-full flex py-2 pb-8  ">
        <label class="w-full relative block">
          <span class="sr-only">Search</span>
          <span class="absolute inset-y-0 left-0 flex items-center pl-2">
            <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20"></svg>
          </span>
          <textarea
            onChange={(e) => setRemark(e.target.value)}
            class="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
            placeholder="Remark..."
            type="text"
            name="search"
          />
        </label>
      </div>
      <div className="w-full flex justify-center py-2 pb-8  ">
        <button
          onClick={rate}
          disabled={loading || Boolean(remark) === false}
          className="bg-amber-100 disabled:animate-pulse disabled:bg-black/50 text-lg text-gray-700 px-10 py-1 rounded-lg hover:shadow-lg hover:shadow-amber-200 outline-none focus:outline-none transition-all duration-700 hover:translate-y-1"
        >
          Rate
        </button>
      </div>
    </div>
  );
}

export default RatingModal;
