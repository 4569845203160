
import AxiosInstance from "../AxiosInstance/AxiosInstance";
export const getUpdatedDataOfUser = async (id) => {
  try {
    const { data } = await AxiosInstance.post(`/api/user/user?id=${id}`);
    return data;
  } catch (error) {
    return { error: true, message: error.message };
  }
};

export const scrollUserToTop = (window) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};
