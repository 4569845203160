import { CloseCircleOutlined } from "@ant-design/icons";
import { Popover, Spin } from "antd";
import AxiosInstance from "../../../AxiosInstance/AxiosInstance";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setTeacherToRate } from "../../../redux/slices/reviewSclice";
import Toast from "../../../util/toast";

function TeachersList({
  setOpenTeachersList,
  setOpenRatingModal,
  user,
  setRefresh,
  refresh,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [confirmedTeachers, setConfirmedTeachers] = useState(null);
  const [page, setPage] = useState(1);
  const [refreshThisPage, setrefreshThisPage] = useState(false);

  const removeStaff = async (email) => {
    try {
      setLoading(true);
      if (!email) return Toast("Email is required to remove staff!", "error");
      let route = `/api/user/remove-staff?email=${email}`;
      const { data } = await AxiosInstance.post(route);
      console.log(data);
      setrefreshThisPage(!refreshThisPage); // refresh this list
      if (data && data.error) Toast(data.message, "error");
      setRefresh(!refresh);
    } catch (error) {
      return Toast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchTeachers = async () => {
      setLoading(true);
      let route = null;
      if (user?.userType === "school")
        route = `/api/user/confirm-teachers-paginated?page=${page}&limit=${4}`;
      // const route = `/api/user/confirm-teachers-paginated?page=${page}&limit=${4}`;
      if (user?.userType === "company")
        route = `/api/user/get-confirm-compstaff-paginated?page=${page}&limit=${4}`;
      if (!route)
        return Toast(
          "Confirm Staff route(paginated) can not be null!",
          "error"
        );
      const { data } = await AxiosInstance.post(route);
      console.log(data);
      if (data && data.staff) {
        setConfirmedTeachers(data.staff);
        setLoading(false);
      }
    };
    fetchTeachers();
  }, [page, refreshThisPage]);
  return (
    <div className="w-[80%] md:w-[60%] min-h-min p-3 mt-2 mx-auto bg-white rounded-lg">
      {!loading ? (
        <>
          <div className="w-full flex text-red-400 cursor-pointer justify-end mr-2 ">
            <CloseCircleOutlined onClick={() => setOpenTeachersList(false)} />
          </div>
          <div className="border-b-2 pb-2 text-base md:text-lg font-bold border-blue-200">
            {user?.userType === "school"
              ? "School Verified Teachers"
              : "Company Verified Staff"}
          </div>
          <div className="border-b-2 pb-8 border-blue-200 ">
            <table className="w-full">
              <tbody>
                {confirmedTeachers && confirmedTeachers?.length > 0 ? (
                  Array.from(confirmedTeachers).map((teacher) => (
                    <tr className="odd:bg-white even:bg-slate-200 h-12 w-full  flex items-center">
                      <div className="w-full flex justify-between px-1 ">
                        <div>{teacher.name}</div>
                        <div>
                          <Popover
                            placement="top"
                            trigger="click"
                            content={
                              <div className="min-w-min min-h-min flex flex-col items-start justify-start space-y-2 ">
                                <button className="px-2 outline-none  focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white">
                                  View
                                </button>
                                <button
                                  onClick={() => {
                                    // setOpenTeachersList(false);
                                    //NB: if loggedin admin user is a company account, then rate compstaff
                                    dispatch(setTeacherToRate(teacher));
                                    setOpenRatingModal(true);
                                  }}
                                  className="px-2 outline-none focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                                >
                                  Rate
                                </button>
                                <button
                                  onClick={() => removeStaff(teacher.email)}
                                  className="px-2 outline-none focus:outline-none  rounded-full text-red-500 transition-all duration-500 hover:-translate-y-1  hover:bg-red-400 hover:text-white"
                                >
                                  Remove
                                </button>
                              </div>
                            }
                          >
                            <button className="px-3 py-1 bg-inherit shadow-lg font-bold focus:outline-none outline-none ring-blue-400 text-gray-600 rounded-lg transition-all duration-500 hover:translate-x-1">
                              Actions
                            </button>
                          </Popover>
                        </div>
                      </div>
                    </tr>
                  ))
                ) : (
                  <div>There are no more confirmed Staff!</div>
                )}
              </tbody>
            </table>
          </div>
          <div
            className={`w-full py-3 mt-1 flex ${
              page > 1 ? "justify-between" : "justify-end"
            } mr-2`}
          >
            <button
              onClick={() => setPage(page > 1 ? page - 1 : page)}
              className={`px-3 py-1 bg-blue-400 text-white rounded-lg ${
                page === 1 && "hidden"
              }`}
            >
              Previous
            </button>
            <button
              onClick={() => setPage(page + 1)}
              disabled={confirmedTeachers?.length < 1}
              className="px-3 py-1 disabled:hidden bg-blue-400 text-white rounded-lg"
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <div className="w-full h-full mx-auto flex justify-center items-center">
          <Spin size={20} />
        </div>
      )}
    </div>
  );
}

export default TeachersList;
