import React from "react";
import { CloseCircleTwoTone } from "@ant-design/icons";
import Checkbox from "antd/es/checkbox";
import { useState } from "react";
import { useEffect } from "react";
import AxiosInstance from "../../../AxiosInstance/AxiosInstance";

import ctries from "../../../util/ctryModified.json";
import { useDispatch, useSelector } from "react-redux";
import { useLayoutEffect } from "react";
import Toast from "../../../util/toast";
import { setUser } from "../../../redux/slices/userSlice";

function JoinCampaign({ toggleJoinCampaign }) {
  const dispatch = useDispatch();
  const [joinInMyCity, setJoinInMyCity] = useState(false);
  const [otherCity, setOtherCity] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [loadingCities, setLoadingCities] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleJoinCampaignInMyCity = () => joinInMyCity((prev) => !prev);

  const _country = useSelector((state) => state.user.country);
  const user = useSelector((state) => state.user.user);

  const handleChangeState = async (e) => {
    setLoadingCities(true);
    setSelectedState(e.target.value);
    try {
      const { data } = await AxiosInstance.post(
        `/api/province/get-all-cities`, {
          state: e.target.value
        }
      );
      if (!data.error) {
        setCities(data.cities);
      } else {
        setCities([]);
      }
    } catch (error) {
      Toast("Could not fetch cities", "error");
    } finally {
      setLoadingCities(false);
    }
  };

  const joinCampaign = async () => {
    setLoading(true);

    if (!selectedOrg) {
      setLoading(false);
      return Toast("Please select your organization!", "error");
    }
    if (otherCity && (!selectedState || !selectedCity)) {
      setLoading(false);
      return Toast("Please select your preferred state and city!", "error");
    }

    if (!otherCity) {
      // If user prefers the country , state and city already on his document then use them
      if (user.state && user.province) {
        setSelectedState(user.state);
        setSelectedCity(user.province);
      } else {
        //user wants the country, state and city already on his document, but the are empty; users has not updated them
        setLoading(false);
        return Toast(
          "To use your state and city, please update them on your profile.",
          "error"
        );
      }
    }
    //All fine, then ...

    try {
      const { data } = await AxiosInstance.post(
        `/api/associate/request-to-join-steka-campaign?country=${_country}&state=${
          otherCity ? selectedState : user.state
        }&city=${
          otherCity ? selectedCity : user.city
        }&sponsoringOrg=${selectedOrg}&user=${user._id}`
      );

      if (data.error) return Toast(data.message, "error");
      const _user = data.user;
      const updatedUser = {
        isLogged: true,
        ..._user,
      };
      dispatch(setUser(updatedUser));
      // dispatch(setUser(updatedUser));
      Toast(data.message, "success");
      toggleJoinCampaign();
    } catch (error) {
      Toast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (_country) {
      ctries.map(({ country, states }, i) => {
        if (country === _country) {
          setStates(states);
        }
      });
    }
  }, []);

  useEffect(() => {
    const fetchCorpusers = async () => {
      const resp = await AxiosInstance.post(`/api/user/fetch-sponsoring-org`);
      const corpUsers = resp.data;

      if (corpUsers && corpUsers.orgs) {
        setOrgs(corpUsers.orgs);
      }
    };
    fetchCorpusers();
  }, []);

  return (
    <div
      className="absolute inset-0 w-full bg-white rounded-lg shadow-lg p-4"
      style={{ minHeight: 450, zIndex: 500 }}
    >
      <div className="w-full flex justify-end mr-2">
        <CloseCircleTwoTone onClick={toggleJoinCampaign} />
      </div>
      <div className="w-full text-lg text-center font-bold pb-4 border-b border-gray-300 ">
        Reimagine Education Campaign Associate Registration
      </div>
      <div className="w-full  text-justify p-4 border border-gray-500 mt-2 ">
        This campaign is to capture target schools as target entities of STEKA
        in conducted by a coalition associates engaged by Brain Wealth Projects
        Utvecklingsbolag and staff partnering government entities in different
        countries where it is implemented as a national concern, in line with
        United Nations SDG no, 4 for all countries.
      </div>
      <select
        name=""
        onChange={(e) => {
          if (String(e.target.value).length > 0) {
            setSelectedOrg(e.target.value);
          }
        }}
        className={`w-full text-center py-2 rounded-lg border mt-2 focus:outline-none`}
      >
        <option value="">Select Sponsoring Organization</option>
        {orgs &&
          orgs.length > 0 &&
          orgs.map((org) => (
            <option key={org.email} value={org.name}>
              {org.name}
            </option>
          ))}
      </select>

      {/* preferred state and city  */}
      <div
        className={`w-full mt-2 flex flex-col md:flex-row justify-between transition-all duration-1000 translate-y-2 ${
          !otherCity && "hidden"
        }  `}
      >
        <div className="w-full md:w-[48%] ">
          <select
            onChange={handleChangeState}
            className="w-full px-2 py-2 rounded-lg border "
          >
            <option value="">State</option>
            {states &&
              states.length > 0 &&
              states.map((state, index) => (
                <option key={index.toString()} value={state}>
                  {state}
                </option>
              ))}
          </select>
        </div>
        <div className="w-full md:w-[48%]">
          <select
            disabled={!selectedState}
            onChange={(e) => setSelectedCity(e.target.value)}
            className="w-full px-2 py-2 rounded-lg border "
          >
            {loadingCities && <option className="animate-pulse">...</option>}
            <option value="">City</option>
            {cities &&
              cities.length > 0 &&
              cities.map((city, index) => <option value={city}>{city}</option>)}
          </select>
        </div>
      </div>

      <div className="w-full mt-2 flex flex-col md:flex-row  ">
        <div className=" mr-3">
          <Checkbox
            className="text-lg"
            disabled={otherCity === true}
            onChange={(e) => setJoinInMyCity(e.target.checked)}
          />
          <span className="ml-2">
            I would like to join this campaign in my city
          </span>
        </div>
        <div className="">
          <Checkbox
            disabled={joinInMyCity === true}
            onChange={(e) => setOtherCity(e.target.checked)}
            className="text-lg"
          />
          <span className="ml-2">Other city</span>
        </div>
      </div>

      {/* //Join now  */}
      <div className={`w-full text-center mb-0 mt-${otherCity ? 2 : 8}`}>
        <button
          onClick={joinCampaign}
          className={`border mt-3 px-10 py-2 rounded-lg bg-esgrown text-white transition-all duration-500 hover:-translate-y-1 `}
        >
          {!loading ? (
            "Submit"
          ) : (
            <span className="animate-pulse animate-bounce">...</span>
          )}
        </button>
      </div>
      {/* <div className="absolute w-6 h-6 rounded-full bg-[#2a6ed4] -top-1 -left-1 animate-pulse"></div> */}
      {/* <div className="absolute w-4 h-4 rounded-full bg-[#355c96] top-2 left-2 animate-pulse"></div> */}
      {/* <div className="absolute w-2 h-2 rounded-full bg-[#355c96] top-5 left-5 animate-pulse"></div> */}
    </div>
  );
}

export default JoinCampaign;
