import React, { useState, useEffect } from "react";
// import { home } from "../components/svg/Home.svg";
// import { Logo } from ".././img/logoo.png";
import { Link } from "react-router-dom";

import { Layout, Menu, Dropdown, Space, Tabs, Drawer } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ShoppingCartOutlined,
  HomeOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  ScheduleOutlined,
  UserOutlined,
  LogoutOutlined,
  LoginOutlined,
  // SmileOutlined,
} from "@ant-design/icons";

import B2P from "./b2p";
import B2B from "./b2b";
import { useUser } from "../../../../context/UserContext";
import { useSelector } from "react-redux";
// import CreateService from "../../services/serviceModal/CreateService";
import DarkOverlay from "../../../DarkOverlay";
import CreateFields from "../fields/CreateFields";
import OnlineUser from "../../../reusable/OnlineUser";
import AddCities from "./components/AddCities";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const { TabPane } = Tabs;
const onChange = (key) => {
  // console.log(key);
};

const handleClick = ({ key }) => {
  // console.log(key);
  //you can perform setState here
};

const Full = ({ hisotry }) => {
  const [open, setOpen] = useState(true);
  // const [openCraeteService, setopenCreateService] = useState(false);
  const [openCraeteField, setopenCreateField] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [openAddcities, setOpenAddcities] = useState(false);
  const [key, setKey] = useState("");
  const toggle = () => setCollapsed(!collapsed);
  const onClose = () => setCollapsed(false);
  const user = useSelector((state) => state.user.user);

  // const toggleCreateServiceModal = () =>
  //   setopenCreateService(!openCraeteService);
  const toggleCreateFieldModal = () => setopenCreateField(!openCraeteField);
  const toggleAddCitiesModal = () => setOpenAddcities(!openAddcities);

  return (
    <Layout
      style={{
        minHeight: "100vh",
        maxHeight: "100%",
        flexWrap: "nowrap",
        position: "relative",
      }}
    >
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onBreakpoint={(broken) => setCollapsed(broken)}
        className="hideOnMobile sidebar-bg"
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <Menu
          mode="inline"
          className="sidebar-bg f-2"
          defaultSelectedKeys={["1"]}
          onClick={handleClick}
        >
          <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
            <Link to="/AdminDashboard" />
            <span>Home</span>
          </Menu.Item>
          <Menu.Item key="2" icon={<ShoppingCartOutlined />} className="font-2 mt-2">
            <Link to="/adminStore" />
            <span>Store</span>
          </Menu.Item> 
          <Menu.Item key="3" icon={<ShoppingCartOutlined />}>
            <Link to="/adminOrders" />
            <span>Store Orders</span>
          </Menu.Item> 
          <Menu.Item key="4" icon={<ScheduleOutlined />}>
            <Link to="/Admin_Page" />
            <span>Admin</span>
          </Menu.Item>
          <Menu.Item key="5" icon={<ScheduleOutlined />}>
            <Link to="/UCBI" />
            <span>UCBI</span>
          </Menu.Item>
          <Menu.Item key="6" icon={<ScheduleOutlined />}>
            <Link to="/update" />
            <span>Updates</span>
          </Menu.Item>
          {/* <Menu.Item
            onClick={toggleCreateServiceModal}
            key="7"
            icon={<ScheduleOutlined />}
          >
            <span>create service</span>
          </Menu.Item> */}
          <Menu.Item key="8" icon={<ScheduleOutlined />}>
            <Link to="/campaigns" />
            <span>Campaigns</span>
          </Menu.Item>
          <Menu.Item key="10" icon={<ScheduleOutlined />}>
            <Link to="/exercises" />
            <span>Exercises</span>
          </Menu.Item>

          <Menu.Item key="9" icon={<LoginOutlined />}>
            <Link to="/logout" />
            <span>Logout</span>
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout>
        <Header className="row head pt-2">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3",
              onClick: toggle,
            }
          )}
          {/* <h3 className="mt-1 wel col-lg-12">Dashboard</h3> */}

          {/* </div> */}
          <div className="ml-auto flex items-center ">
            <div>
              <OnlineUser user={user} />
            </div>
            <div className="ml-auto transition hover:translate-x-1 hover:duration-500">
              {user && user.name}
            </div>
          </div>
        </Header>

        <Content
          style={{
            margin: "24px 16px",
            padding: 10,
            // background: "#fff",
            minHeight: 280,
          }}
        >
          <div className="tab-but">
            <Tabs defaultActiveKey="1" onChange={onChange}>
              <TabPane tab="B2P" key="1">
                <div className="tab1 mt-4">
                  <B2P toggleAddCitiesModal={toggleAddCitiesModal} />
                </div>
              </TabPane>
              <TabPane tab="B2B" key="2">
                <div className="tab1 mt-4">
                  <B2B toggleAddCitiesModal={toggleAddCitiesModal} />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </Content>
      </Layout>
      {/* {openCraeteService && (
        <>
          <CreateService toggleCreateServiceModal={toggleCreateServiceModal} />
          <DarkOverlay density={70} z="z-0" />
        </>
      )} */}
      {openCraeteField && (
        <>
          <CreateFields toggleCreateFieldModal={toggleCreateFieldModal} />
          <DarkOverlay density={70} z="z-0" />
        </>
      )}
      {openAddcities && (
        <>
          <AddCities toggleAddCitiesModal={toggleAddCitiesModal} />
          <DarkOverlay density={70} z="z-0" />
        </>
      )}
    </Layout>
  );
};

export default Full;
