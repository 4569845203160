import React, { useState, useEffect } from "react";
import ClickAwayListener from "react-click-away-listener";
import { useDispatch } from "react-redux";
import { Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import DropDownMenu from "../reusable/DropDownMenu";
import OnlineUser from "../reusable/OnlineUser";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";
import Toast from "../../util/toast";
import UCBIPayementModal from "../UCBIPayementModal";
import SmallScreenDrawer from "../SmallScreenDrawer";
import QuestionCard from "./QuestionCard";
import CustomSiderMenu from "../reusable/CustomSiderMenu";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const ExercisesIndiviual = ({ history }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [openUCBI, setOpenUCBI] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentApplication, setCurrentApplication] = useState();
  const [allUserApllications, setAllUserApllications] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [start, setStart] = useState(false);
  const [totalTime, setTotalTime] = useState("0");
  const [selectedExercise, setSelectedExercise] = useState(null);

  const toggleStart = () => {
    if (start) {
      const filtered = exercises.filter(
        (excer) => excer._id !== selectedExercise._id
      );
      setExercises(filtered);
    }
    setStart((prev) => !prev);
  };

  const [showSmallScreenDrawer, setShowSmallScreenDrawer] = useState(false);

  const user = useSelector((state) => state.user.user);

  const handleClick = ({ key }) => {
    //you can perform setState here
  };
  useEffect(() => {
    fetchExercises();
    getAllUserApplications();
  }, []);

  const fetchExercises = async () => {
    try {
      setIsLoading(true);
      const { data } = await AxiosInstance.post(
        "/api/excercise/get-all-excersies-for-user"
      );
      if (data.status === "success") {
        setExercises(
          data.excercises.map((exer, i) => {
            return {
              ...exer,
              exerciseNo: i + 1,
            };
          })
        );
        setTotalTime(data.totalExcerciseTime);
        Toast("Excercises fetched successfully", "success");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchExercisesByVacancy = async (e) => {
    if (!Boolean(e.target.value)) {
      fetchExercises();
      return;
    }
    const app = allUserApllications.find(
      (userApp) => userApp._id === e.target.value
    );
    if (app) {
      setCurrentApplication(app);
    }
    const vacancyId = e.target.value;
    try {
      setIsLoading(true);
      const { data } = await AxiosInstance.post(
        "/api/excercise/get-all-excersies-by-vacancy/" + vacancyId
      );
      if (data.status === "success") {
        setExercises(
          data.excercises.map((exer, i) => {
            return {
              ...exer,
              exerciseNo: i + 1,
            };
          })
        );
        setTotalTime(data.totalExcerciseTime);
        Toast("Excercises fetched successfully", "success");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  // get all the jobs that the logged in user has applid to
  const getAllUserApplications = async () => {
    try {
      const { data } = await AxiosInstance.post(
        "/api/application/get-all-user-applications"
      );
      if (data && data.applications?.length > 0) {
        setAllUserApllications(data.applications);
      }
    } catch (error) {
      return Toast(error.message, "error");
    } finally {
    }
  };
  //console.log("fetched exer", exercises);
  const defaultProfilePix =
    "https://esgrown.fra1.digitaloceanspaces.com/esgrown%201669801277885.jpg";

  return (
    <Layout
      style={{
        minHeight: "100vh",
        maxHeight: "100%",
        flexWrap: "nowrap",
        position: "relative",
      }}
    >
      {openUCBI && (
        <UCBIPayementModal setOpenUCBI={setOpenUCBI} ucbi={user.ucbi[0]} />
      )}

      <Sider
        trigger={null}
        className="sidebar-bg hidden md:block"
        collapsible
        collapsed={collapsed}
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <CustomSiderMenu
          collapsed={collapsed}
          handleClick={handleClick}
          user={user}
        />
      </Sider>
      <Layout>
        <Header className="row pt-2 head">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3 hidden md:block",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          {/* This will only show for small devices  ////////////////////////*/}
          <div
            onClick={() => {
              setShowSmallScreenDrawer(!showSmallScreenDrawer);
            }}
            className="min-w-min h-full flex items-center cursor-pointer md:hidden"
          >
            <MenuFoldOutlined className="text-lg mb-3 mr-2" />
          </div>
          {/* ////////////////////////////////////////////////////// */}
          <h3 className="mt-1 ">Profile</h3>

          <ClickAwayListener onClickAway={() => setShowAccountMenu(false)}>
            <div className="ml-auto">
              <DropDownMenu
                user={user}
                show={showAccountMenu}
                bgColor="bg-white/30"
              />
              <div onClick={() => setShowAccountMenu(!showAccountMenu)}>
                <OnlineUser user={user} />
              </div>
            </div>
          </ClickAwayListener>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 10,
            // background: "#fff",
            minHeight: "82%",
          }}
        >
          <div className="w-full h-full flex flex-col relative">
            <div className="w-full p-3 rounded-lg bg-white">
              <div className="text-xl font-bold">Exercises</div>
              <div className="my-3 border-b "></div>
              <div className="text-lg">
                Exercises are designed to help you evaluate your progress on
                premium service where they are applicable
              </div>
              <div className="w-full flex justify-end items-center mr-2 mt-3">
                <div className="relative mr-8">
                  <button className="px-4 py-1 text-lg text-esgrown  outline-none focus:outline-none ">
                    New Exercises
                  </button>
                  {/* //absolutly positioned tag to button  */}
                  <span className=" absolute -top-1 right-2 h-[20px] w-[20px] ml-1 mt-[1px] rounded-full flex items-center justify-center text-xs animate-pulse text-red-500 bg-slate-100">
                    4
                  </span>
                </div>
                <select name="" className="px-3 py-1 mr-3 border-2 rounded-lg">
                  <option value="">Services</option>
                </select>
              </div>
            </div>
            {/* section 2  */}
            <div className="text-xl font-bold">
              Recruitment Management Exercises
            </div>
            <div className="w-full p-3 flex justify-between">
              <div className="w-[38%] flex justify-between items-center">
                <select
                  onChange={fetchExercisesByVacancy}
                  className="w-[98%] px-2 py-2 border-2 rounded-lg focus:border-esgrown "
                >
                  <option value="">All Vacancies</option>
                  {allUserApllications.map((application) => {
                    return (
                      <option name={application._id} value={application._id}>
                        {application?.job.title} - {application?.job.location}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="text-lg font-semibold mr-6">
                Total Time: {totalTime} secs
              </div>
            </div>

            {/* section 3  */}
            <div className="w-full px-3 flex flex-col bg-white rounded-xl mb-4 pt-3 pb-6 ">
              {currentApplication && (
                <div className="my-3 flex justify-end mr-[60px] items-center text-xl font-bold text-esgrown">
                  <span>Score: {currentApplication.PEA}</span>
                  {/* <span className="text-lg mx-3">|</span>
                  <span>Ranking: 12th</span> */}
                </div>
              )}
              {exercises.map((exercise, index) => {
                return (
                  <div
                    key={index}
                    className={` ${
                      start ? "hidden" : ""
                    } w-[90%]  mt-3 bg-slate-100 text-esgrown font-semibold rounded-xl px-8 py-3 mx-auto flex justify-between`}
                  >
                    <div>Question: {exercise.exerciseNo}</div>
                    <div className="flex space-x-3">
                      <button
                        onClick={() => {
                          setSelectedExercise(exercise);
                          toggleStart();
                        }}
                        className="px-3 py-1  text-white bg-esgrown rounded-lg focus:outline-none hover:-translate-y-1 transition-all duration-500"
                      >
                        start
                      </button>
                      <span>{exercise.allotedTime}s</span>
                    </div>
                  </div>
                );
              })}

              {/* //display Question card when a Question is selected  */}
              {start && (
                <QuestionCard
                  exercise={selectedExercise}
                  toggleStart={toggleStart}
                />
              )}
            </div>

            {/* <div className="w-full h-[400px] bg-red-300 "></div> */}
          </div>
        </Content>
      </Layout>

      {showSmallScreenDrawer && (
        <SmallScreenDrawer
          setShowSmallScreenDrawer={setShowSmallScreenDrawer}
          user={user}
        />
      )}
    </Layout>
  );
};

export default ExercisesIndiviual;
