import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";

export const getPostsForStudents = createAsyncThunk("getPostsForStudents", async () => {
  const route = `/api/post/get-posts-for-students`;
  const { data } = await AxiosInstance.get(route);
  return data.getPostsForStudents
})

export const getPostsForTeachers = createAsyncThunk("getPostsForTeachers", async () => {
  const url = `/api/post/get-posts-for-teachers`;
  try {
    const { data } = await AxiosInstance.get(url);
    return data.getPostsForTeachers
  } catch (err) {
  }
})



const initState = {
  componentsStates: {
    // removeAssociatePrompt: false,
    // showListOfProccessedAssociates: false,
  },
  postsForStudents: [],
  postsForTeachers: [],
  isLoading: false
};

export const postSlice = createSlice({
  name: "post",
  initialState: initState,
  reducers: {
    setShowUcbiDetailModal: (state, action) => {
      state.componentsStates.showUcbiDetailModal = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPostsForStudents.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPostsForStudents.fulfilled, (state, action) => {
        state.postsForStudents = action.payload
        state.isLoading = false
      })
      .addCase(getPostsForTeachers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPostsForTeachers.fulfilled, (state, action) => {
        state.postsForTeachers = action.payload
        state.isLoading = false
      })
  }
},
);

export const {
} = postSlice.actions
export default postSlice;
