import React, { useState } from "react";
import { CloseCircleTwoTone } from "@ant-design/icons";
import Toast from "../../../../util/toast";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../../../../redux/slices/userSlice";
import { useHistory } from "react-router-dom";
import Axios from "axios";

function SponsoringOrgLoginModal({ toggleSponsoringOrgLogin }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [y, setY] = useState(window.scrollY);

  //   handling scrolling up even

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        toggleSponsoringOrgLogin();
      } else if (y < window.scrollY) {
        return;
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const login = async () => {
    try {
      setLoading(true);
      const { data } = await Axios.post(`/api/user/login-sponsoring-org`, {
        email,
        password,
      });
      console.log(email, password);

      if (data.org) {
        console.log("Login success!", data.org);
        setEmail("");
        setPassword("");
        const _user = data.org;
        const updatedUser = {
          isLogged: true,
          ..._user,
        };
        dispatch(setUser(updatedUser));
        toggleSponsoringOrgLogin();
        history.push("sponsoring-orgs");
      } else {
        return Toast(data.message, "error");
      }
    } catch (error) {
      Toast(error.message, "error");
    } finally {
      setLoading(false);
    }

    //
    //
  };

  return (
    <div
      style={{ zIndex: 2000 }}
      className="fixed bottom-10 right-3 p-8 pb-20 md:w-1/3 w-8/12 h-min bg-white text-gray-600 shadow-md rounded-lg "
    >
      <div className="w-full h-10 flex justify-between pr-2">
        <div className="pl-[30%] text-sm font-semibold text-[#233438] flex items-center">
          Enter login details
        </div>
        <CloseCircleTwoTone onClick={toggleSponsoringOrgLogin} />
      </div>
      <div className="w-[80%] justify-center flex flex-col mx-auto ">
        <div className="w-full min-h-min ">
          <input
            placeholder="Enter Org Email"
            type="text"
            required={true}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full mx-auto rounded-lg border-2 border-blue-200 outline-blue-200 px-2 py-1"
          />
        </div>
        <div className="w-full min-h-min mt-3 ">
          <input
            placeholder="Enter Passcode"
            type="password"
            required={true}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full mx-auto rounded-lg border-2 border-blue-200 outline-blue-200 px-2 py-1"
          />
        </div>

        <div className="w-full min-h-min mt-3 ">
          <button
            onClick={login}
            className={`w-full  bg-[#233438]  disabled:bg-gray-300 text-white rounded-lg focus:outline-none py-1`}
          >
            {!loading ? (
              <span>Login</span>
            ) : (
              <span className="flex items-center justify-center animate-ping">
                ...
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SponsoringOrgLoginModal;
