import React from "react";

function DarkOverlay(props) {
  const { density, children, z } = props;
  return (
    <div
      className={`absolute w-full h-full bg-black/${density} inset-0 z-${z}`}
      {...props}
    >
      {children}
    </div>
  );
}

export default DarkOverlay;
