import React, { useEffect, useLayoutEffect, useState } from "react";
import { CloseCircleTwoTone } from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox";
import AxiosInstance from "../../../AxiosInstance/AxiosInstance";
import Toast from "../../../util/toast";
import { useSelector } from "react-redux";

function ApplyForJob({
  history,
  toggleApplyForJob,
  requiredSkills = Array.from(new Array(4).fill(0)),
}) {
  const [openVacancies, setOpenVacancies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [searchkey, setSearchkey] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedVacancy, setselectedVacancy] = useState({});
  const [userSkills, setUserSkills] = useState([]);

  const user = useSelector((state) => state.user.user);

  const handleOnChangeSkills = (e) => {
    if (
      e.target.checked &&
      selectedVacancy.title &&
      !userSkills.includes(e.target.value)
    ) {
      setUserSkills([...userSkills, e.target.value]);
    }

    if (Boolean(e.target.checked) === false) {
      //if user unchecked any skill
      //if the skill  has been added to users skills before remove it.
      if (userSkills.includes(e.target.value)) {
        const filteredUserSkills = userSkills.filter(
          (skill) => skill !== e.target.value
        );
        setUserSkills(filteredUserSkills);
      }
    }
  };

  const applyToTheSelectedJobnow = async () => {
    setLoading(true);
    const userSkillRate = calculateUserPercentageOfRequiredSkills(
      selectedVacancy.requiredSkills,
      userSkills
    );
    // toggleApplyForJob();
    // PEA will be added by a middleware on the backend before application is submmitted!

    const applicationData = {
      user: user._id,
      job: selectedVacancy._id,
      skillRate: userSkillRate,
    };

    const route = `api/application/submit`;

    try {
      const { data } = await AxiosInstance.post(route, applicationData);
      if (!data.error) {
        //success
        Toast("Application submitted successfully!", "success");
        toggleApplyForJob();
      } else {
        return Toast(data.message, "error");
      }
    } catch (error) {
      return Toast(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    const getAllVacancies = async () => {
      setLoading(true);

      try {
        const { data } = await AxiosInstance.post("/api/jobs/get-active-jobs");
        console.log("Jobs:", data.jobs);
        if (data && data.jobs.length > 0) {
          setOpenVacancies(data.jobs);
        }
      } catch (error) {
        return Toast(error.message, "error");
      } finally {
        setLoading(false);
      }
    };

    getAllVacancies();
  }, []);

  return (
    <div className="absolute inset-0 w-full">
      <div
        className="w-[90%] mx-auto bg-white  rounded-xl shadow-lg p-4"
        style={{ minHeight: 450, zIndex: 500 }}
      >
        <div className="w-full flex justify-end mr-2">
          <CloseCircleTwoTone onClick={toggleApplyForJob} />
        </div>
        {/* <div className="my-2 border-b "></div> */}
        <div className="w-full px-3 mt-3">
          <div className="w-full relative">
            <input
              type="text"
              onChange={(e) => {
                const key = String(e.target.value).toLowerCase();
                setSearchkey(key);
                const filteredLocs = openVacancies.filter((vac) =>
                  String(vac.location).toLowerCase().includes(key)
                );
                setLocations(filteredLocs);
              }}
              placeholder={`Start typing preferred job location...`}
              className="w-full px-2 py-2 border-2 rounded-lg outline-esgrown "
            />
            {/* absolutely positioned search results */}
            <div
              className={` ${
                searchkey.length > 0 ? "ease-in-out" : "hidden"
              } w-full  absolute top-[50px] left-0 px-2 py-4 rounded-lg transition-all duration-700 bg-white shadow`}
              style={{ minHeight: 100 }}
            >
              <div className="w-full flex justify-between items-center">
                {locations?.length > 0 ? (
                  <div className="w-full flex flex-col">
                    <div
                      className=" w-fit px-3 bg-slate-300 py-2
                     rounded-lg text-white font-semibold"
                    >
                      Jobs are availabe in:
                    </div>
                    {locations.map((vac) => (
                      <div
                        onClick={() => {
                          setselectedVacancy(vac);
                          console.log(vac);
                          setSearchkey("");
                        }}
                        className="mt-2 font-semibold text-gray-500 cursor-pointer"
                      >
                        {vac.location} - {vac.title}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="w-full text-center">
                    Oops! No vacancy found in this location presently.
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* option  */}
          <div className="w-full flex justify-between items-center mt-3 ">
            <select className="w-[48%] px-2 py-2 border-2 rounded-lg focus:border-esgrown ">
              {selectedVacancy?.company && (
                <option selected={selectedVacancy?.company} value="">
                  {selectedVacancy?.company?.name}
                </option>
              )}
              <option value="">Choose Company of interest</option>
            </select>
            <select className="w-[48%] px-2 py-2 border-2 rounded-lg focus:border-esgrown ">
              {selectedVacancy?.company && (
                <option selected={selectedVacancy?.company} value="">
                  {selectedVacancy?.title}
                </option>
              )}
              <option value="">Select Job Vacancy</option>
            </select>
          </div>
          {/* option  */}
          <div className="w-full flex justify-start space-x-2 items-center mt-3">
            <textarea
              placeholder="Company Description"
              value={
                selectedVacancy.title
                  ? selectedVacancy?.company?.description
                  : ""
              }
              type="text"
              className="w-full p-3 border-2 rounded-lg outline-esgrown "
            />
          </div>
          <div className="w-full flex justify-start space-x-2 items-center mt-3">
            <textarea
              placeholder="Job Description"
              value={
                selectedVacancy.title ? selectedVacancy?.jobDescription : ""
              }
              type="text"
              className="w-full p-3 border-2 rounded-lg outline-esgrown "
            />
          </div>
        </div>
        <div className="flex flex-wrap w-full justify-between items-center px-3 mt-3">
          {selectedVacancy.title &&
            selectedVacancy?.requiredSkills.map((skill, index) => {
              return (
                <div key={index.toString()} className="flex space-x-1 mt-2">
                  <div>{skill}</div>
                  <Checkbox
                    name={skill}
                    value={skill}
                    onChange={handleOnChangeSkills}
                  />
                </div>
              );
            })}
        </div>
        <div className="w-full border-b mt-3 "></div>
        <div className="flex w-full justify-end items-center mr-2 mt-3">
          {selectedVacancy.title && (
            <button
              onClick={() => setselectedVacancy({})}
              className="mr-3 text-esgrown border border-esgrown px-2 py-1.5 mt-1 rounded-lg"
            >
              Clear Form
            </button>
          )}
          <button
            onClick={() => {
              applyToTheSelectedJobnow();
            }}
            className="px-3 py-1.5 mr-1 text-xl text-white rounded-lg bg-esgrown font-bold"
          >
            Submit Application
          </button>
        </div>
      </div>
    </div>
  );
}

export default ApplyForJob;

function calculateUserPercentageOfRequiredSkills(requiredSkills, userSkills) {
  const rSkills = requiredSkills.length;
  const uSkills = userSkills.length;
  const percentage = (uSkills / rSkills) * 100;

  // since rating is between 0-5, bound the percentage to this range of 0-5
  const skillRate = (percentage * (5 - 1)) / 100 + 1;
  return skillRate;
}
