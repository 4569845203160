import React from "react";

function EngageApplicantsPopoverContent({ history, applicant }) {
  return (
    <div className="w-fit h-fit p-3 flex flex-col items-start space-y-2 ">
      <button className="px-2 py-1 hover:bg-sky-200 bg-inherit rounded-lg text-gray-500 hover:-translate-y-1 focus:outline-none transition-all duration-500">
        View Applicant
      </button>
      <button className="px-2 py-1 hover:bg-sky-200 bg-inherit rounded-lg text-gray-500 hover:-translate-y-1 focus:outline-none transition-all duration-500">
        Contact
      </button>
      <button className="px-2 py-1 hover:bg-sky-200 bg-inherit rounded-lg text-gray-500 hover:-translate-y-1 focus:outline-none transition-all duration-500">
        Notify
      </button>
    </div>
  );
}

export default EngageApplicantsPopoverContent;
