import {
    CloseCircleTwoTone,
  } from "@ant-design/icons";
  import { Avatar, Checkbox, Dropdown, Image, Popover, Spin } from "antd";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import Toast from "../../../../util/toast";
  import { MediaPath } from "../../../../App";
  import ctries from "../../../../util/allcountries";
  import RemoveOrganizationConfirmationModal from "./RemoveOrganizationConfirmationModal";
  import {
    getAllAdmins,
    countAdminsByCountry,
    setShowListOfAdmins,
    setAdminCountry,
    setRemoveAdminPrompt,
    setAdminDetails,
    setShowAdminDetailsModal,
    setEditAdminDetailsModal
  //   setShowOrganizationCreateModal,
  //   removeSponsoringOrganization
   } from "../../../../redux/slices/adminSlice";
import CreateAdmin from "../../../admin/dashboards/admin/modal/createAdminModal";
import RemoveAdminConfirmationModal from "./RemoveAdminConfirmationModal";
  
  function AdminDetailsModal() {
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    
    const {
      allAdmins,
      adminCountry,
      isLoading,
      allAdminsByCountry,
      adminDetails
     } = useSelector(state => state.adminSlice )
    
     const { 
      removeAdminPrompt,
      showOrganizationPopOver,
      showUpdateOrganizationModal,
      showListOfOrganizations,
      showSupportingOrganizationDetailsModal,
      showAdminDetailsModal,
      editAdminDetailsModal
     } = useSelector(state => state.adminSlice.componentsStates )
  

     console.log("editAdminDetailsModal", editAdminDetailsModal);
     console.log("adminDetail", adminDetails);
    return (
      <div
        className="w-full h-full flex bg-white flex-col items-end relative z-auto"
        // style={{ zIndex: 200 }}
      >
        <div className="h-[20%] pb-1 w-full">
          <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
            <CloseCircleTwoTone
              onClick={() => {
                // setShowSelectedAssociate(false)
                // dispatch(setShowViewAssociateModal(false))
                // dispatch(setShowOrganizationPopOver(false))
                dispatch(setShowAdminDetailsModal(false))
                dispatch(setShowListOfAdmins(true))
            }}
              color="red"
            />
          </div>
          <div className="flex flex-col min-h-min  ">
            {/* section 2*/}
            <div className=" mt-2 flex justify-between ">
              <div className="w-[20%] rounded-lg bg-green-400 font-semibold text-base flex row items-center justify-center">
                {/* Image  */}
                <img
                  // rounded
                  src="{}"
                  className="w-full h-full object-cover rounded-lg"
                  // style={{ width: "100%", height: "100%" }}
                />
                <div className="w-full flex justify-between ">
                  <div>Staff ID:   {adminDetails?.uniqueId}</div>
                </div>
              </div>
              <div className="w-[86%] px-[10%]  font-semibold text-base">
                {/* details  */}
                <div className="w-full flex justify-between ">
                  <div>Name:</div>
                  <div className="text-left w-1/2">
                    {adminDetails?.name}
                  </div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>Unit:</div>
                  <div className="text-left w-1/2">
                    {adminDetails?.unit}
                  </div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>City:</div>
                  <div className="text-left w-1/2">{adminDetails?.city}</div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>State/Province:</div>
                  <div className="text-left w-1/2">
                    {adminDetails?.state}
                  </div>
                </div>
                <div className="w-full flex justify-between ">
                  <div>Country:</div>
                  <div className="text-left w-1/2">
                    {adminDetails?.country}
                  </div>
                </div>
                {/* { adminDetails?.organizationType == "Government Organization" && ( */}
                <>
                  <div className="w-full flex justify-between ">
                    <div>Staff Email:</div>
                    <div className="text-left w-1/2">
                      {adminDetails?.email}
                    </div>
                  </div>
                  <div className="w-full flex justify-between ">
                    <div>Phone:</div>
                    <div className="text-left w-1/2">
                      {adminDetails?.phone}
                    </div>
                  </div>
                </>
                {/* )} */}
                {/* {adminDetails?.organizationType == "Business Franchise" && (
                <div className="w-full flex justify-between ">
                    <div>Supported SDP target sub-goals:</div>
                    <div className="text-left w-1/2">
                      {adminDetails?.supportedSDP}
                    </div>
                </div>
                )} */}
              </div>
            </div>
            {/* section 3 */}
            <div>
              <div className="w-full flex justify-end mt-5">
                <button className="px-6 text-sm outline outline-gray-300 text-white bg-[black] py-1 rounded-lg transition-all duration-700 reAssignButton"
                onClick={()=>{
                        // dispatch(setShowOrganizationPopOver(true))
                        dispatch(setAdminDetails(adminDetails))
                        dispatch(setEditAdminDetailsModal(true))
                        setModal(true)
                        // dispatch(setShowOrganizationCreateModal(true))
                }}
                >
                  Re-assign
                </button>
                <button className="px-6 text-sm bg-red-500 text-white py-1 rounded-lg hover:translate-y-1 transition-all duration-700 removeButton"
                onClick={()=>{
                  // dispatch(setShowOrganizationPopOver(true))
                  dispatch(setRemoveAdminPrompt(true))
                  dispatch(setAdminDetails(adminDetails))
                }
                }
                >
                  Remove
                </button>
              </div>
              </div> 
            </div>
          </div>

          {/* {removeOrganizationPrompt && (<RemoveOrganizationConfirmationModal/>)} */}
          {/* {editAdminDetailsModal && (
          <div className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}> */}
            <CreateAdmin
            show={modal}
            onHide={() => setModal(false)}
            />
          {/* </div>
          )} */}
          
      </div>
    );
  }
  
  export default AdminDetailsModal;
  
  function renderStates(country) {
    console.log("Country supplied: " + country);
    let states = null;
    for (const obj of ctries) {
      if (obj.country === country) {
        console.log(obj.states.length);
        states = obj.states;
      }
    }
    if (states === null) return <></>;
    return states.map((state, i) => {
      return (
        <option
          key={i.toString()}
          value={`League = ${i + 1} = ${state}`}
        >{`League ${i + 1} (${state})`}</option>
      );
    });
  }
  