import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { CheckOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setSubService } from "../../redux/slices/serviceSlice";

function NewServiceCard({
  //   title = "Title of Service",
  //   description = "Description of the service",
  service,
  showSubModal,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const listU = listOfTargetUsers(service?.targetUsers);

  const userServices = user?.subscriptions?.map(
    (subscription) => subscription.serviceId
  );

  //Disable subscription button, if user has already subscribed!
  const disableBtn = userServices?.includes(service?.serviceId);

  //Only allow user to subscribe to service if userType is part of targetUsers of the service
  if (!listU.includes(user.userType)) return <></>;
  return (
    <div className="w-full bg-white rounded-2xl p-4 mb-4">
      <div className="w-full flex flex-row items-center justify-between">
        <div className="text-sm md:text-2xl text-[#00AFEF]  py-4 px-3">
          {service.name}
        </div>
        <div className="flex justify-between items-center space-x-2">
          <select
            name=""
            className="w-[8rem] px-0.5 py-1.5 rounded-lg text-gray-500 outline"
          >
            <option value="">Select duration</option>
            <option value="Monthly">Annual</option>
            <option value="Monthly">Bi-Annual</option>
            <option value="Monthly">Quarterly</option>
            <option value="Monthly">Monthly</option>
          </select>
          <div className="text-[#00AFEF] font-bold text-base md:text-lg">
            N 5000
          </div>
        </div>
      </div>
      <div className="  mx-auto border-b border-b-[#588a9c] w-[98%]"></div>
      <div className="mt-4 text-xs  md:text-lg text-justify">
        {service.description}
      </div>
      <button
        onClick={() => {
          dispatch(setSubService(service));
          showSubModal();
        }}
        className={`w-full font-bold text-lg ${
          disableBtn ? "bg-green-400" : "bg-[#00AFEF]"
        }  text-white hover:shadow-md hover:shadow-green-200 py-2.5 mt-4 mb-3 rounded-xl`}
      >
        {disableBtn ? (
          <div className="flex items-center justify-center space-x-2">
            <span>subscribed already</span> <CheckOutlined className="" />
          </div>
        ) : (
          "subscribe"
        )}
      </button>
    </div>
  );
}

export default NewServiceCard;

const listOfTargetUsers = (strTargetUsers) => {
  return strTargetUsers?.split(",");
};

function serviceDescParagraphs(serviceDesc) {
  return serviceDesc?.split("=");
}
