import { Radio } from "antd";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeInput } from "../../redux/slices/formSlice";
import Toast from "../../util/toast";
import { DatePicker } from "antd";

function StepFour({ setActiveStep, activeStep, toggleCapSchoolModal }) {
  const user = useSelector((state) => state.user.user);
  const stekaData = useSelector((state) => state.form);
  const [introducedByAdvisor, setIntroducedByAdvisor] = useState(false);
  const disableSubmitBtn =
    Boolean(!stekaData.firstTeachersSeminarDate) ||
    Boolean(!stekaData.firstStudentsSeminarDate) ||
    (Boolean(introducedByAdvisor) && Boolean(!stekaData.advisorCode));

  const dispatch = useDispatch();
  const handleChange = (e) => {
    if (e.target.name === "referredByAdvisor") {
      setIntroducedByAdvisor(e.target.value);
    }
    dispatch(changeInput({ name: e.target.name, value: e.target.value }));
  };

  useEffect(() => {
    if (!introducedByAdvisor) {
      dispatch(changeInput({ name: "advisorCode", value: null }));
    }
  }, [introducedByAdvisor]);

  const submitStekaData = async () => {
    const data = { ...stekaData, user: user._id };
    try {
      const resp = await AxiosInstance.post("/api/steka/create", data);
      console.log(resp.data);
      if (resp.data && !resp.data.error) {
        //success
        Toast("Welcome to steka!", "success");
      } else {
        Toast(resp.data.message, "error");
      }
    } catch (error) {
      Toast("Could not save data right now, please try again later", "error");
    }
  };

  const onChangeTeachersSeminarDate = (date, dateString) => {
      setIntroducedByAdvisor(false);
    dispatch(changeInput({ name: 'firstTeachersSeminarDate', value: dateString }));
  };
  const onChangeStudentsSeminarDate = (date, dateString) => {
      setIntroducedByAdvisor(false);
    dispatch(changeInput({ name: 'firstStudentsSeminarDate', value: dateString }));
  };

  return (
    <div className="w-full h-[65%] flex flex-col items-center mt-4">
      <div className="text-xl font-bold text-gray-700 mb-2 px-12">
      Choose two dates for community sustainable development advisors on UN SDG no. 
      to visit your school and deliver training workshop to your teachers and seminar to your students. 
      The theme of the teacher quality and performance: effect on learning outcome and impact on society”. 
      The theme of the seminar for students seminar is: ”Functional Education: Building Knowledge and Skills for 
      Your Dream Life” This workshop has been 40% and 60% paid for by our partnering SDG support organinzations.
      </div>
      <div className="flex justify-between items-center w-full px-12">
        <button
          // onClick={() => setActiveStep(1)}
          className={` bg-blue-500 px-3 py-2.5 rounded-[50%] text-white hover:shadow-xl hover:translate-x-1 duration-500`}
        >
          1
        </button>
        <hr className="w-1/4 border-b border-blue-500 text-gray-700 "></hr>
        <button
          // onClick={() => setActiveStep(2)}
          className="bg-blue-500 px-3 py-2.5 rounded-[50%] text-white hover:shadow-xl hover:translate-x-1 duration-500"
        >
          2
        </button>
        <hr className="w-1/4 border-b border-blue-500"></hr>
        <button
          // onClick={() => setActiveStep(3)}
          className="bg-blue-500 px-3 py-2.5 rounded-[50%] text-white hover:shadow-xl hover:translate-x-1 duration-500"
        >
          3
        </button>
        <hr className="w-1/4 border-b border-blue-500"></hr>
        <button
          // onClick={() => setActiveStep(4)}
          className="bg-blue-500 px-3 py-2.5 rounded-[50%] text-white hover:shadow-xl hover:translate-x-1 duration-500"
        >
          4
        </button>
      </div>
      <div className="overflow-y-scroll h-1/2 md:h-[60%]  w-full">
        <div className="flex justify-between items-center w-full px-12 mt-10 ">
          {/* <input
            onChange={handleChange}
            value={stekaData.noOfStudents}
            name="noOfStudents"
            type="number"
            placeholder="No of students"
            className="w-[46%] px-2 py-2 border-2 focus:outline-blue-400 rounded-lg shadow"
          ></input>
          <input
            onChange={handleChange}
            value={stekaData.noOfTeachers}
            name="noOfTeachers"
            type="number"
            placeholder="No of Teachers"
            className="w-[46%] px-2 py-2 border-2 focus:outline-blue-400 rounded-lg shadow"
          ></input> */}
          <div className="px-1">
            <DatePicker
              onChange={onChangeTeachersSeminarDate}
              name="firstTeachersSeminarDate"
              style={{ width: 250 }}
              className="rounded-lg"
              placeholder="First Teachers Seminar Date"
            />
          </div>
          <div className="px-1">
            <DatePicker
              onChange={onChangeStudentsSeminarDate}
              name="firstStudentsSeminarDate"
              style={{ width: 250 }}
              className="rounded-lg"
              placeholder="First Students Seminar Date"
            />
          </div>
        </div>
        <div className="flex text-xs md:text-md text-justify  items-center w-full px-12 mt-4 ">
          Where you introduced to STEKA as a UN SDG aligned
          national/international concern by an education quality and
          functionality advisor in your community?
        </div>
        <div className="flex  items-center w-full px-12 mt-1 ">
          <Radio.Group
            name="referredByAdvisor"
            onChange={handleChange}
            value={introducedByAdvisor}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
        {introducedByAdvisor === true && (
          <div className="flex  items-center w-full px-12 mt-2 mb-6 md:mt-4 ">
            <input
              onChange={handleChange}
              name="advisorCode"
              type="text"
              placeholder="Enter Advisor Code"
              className="w-1/2 px-2 py-2 border-2 focus:outline-blue-400 rounded-lg shadow"
            ></input>
          </div>
        )}
      </div>
      <div className="flex mt-5 mx-10 w-[90%] justify-between">
        <button
          onClick={() => setActiveStep(3)}
          class="self-end mr-2 px-2 py-2 rounded-lg bg-[#244060] text-xs  text-white hover:translate-x-1 duration-500"
        >
          previous
        </button>
        <button
          onClick={() => {
            toggleCapSchoolModal();
            submitStekaData();
          }}
          disabled={disableSubmitBtn}
          class={`self-end mr-2 px-3 py-2 rounded-lg ${disableSubmitBtn ? "bg-gray-300" : "bg-[#244060] "
            }  text-xs text-white hover:translate-x-1 duration-500`}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default StepFour;
