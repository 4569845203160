import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { RightSquareTwoTone } from "@ant-design/icons";

function UpdateContainer({ update }) {
  const imgSrc = update?.images[0];
  const paragraphs = update?.text.split("=");
  const urls = update?.externalURLS[0].split(",");
  if (Boolean(update.date) && update?.showUpdate !== true) {
    return <></>;
  }

  return (
    <div className="w-full p-2 bg-white h-min shadow mb-8 border-b">
      <div className="font-semibold flex">
        <div
          className="w-6 h-6 border-[#1D6DAE] rounded-full mr-2 "
          style={{ borderWidth: 4 }}
        ></div>
        <span className="text-xs md:text-base">{update?.title}</span>
      </div>
      <div
        hidden={!update.images[0]}
        className=" w-full h-[300px] rounded mt-3 "
      >
        <img
          className="w-full h-full rounded"
          src={update.images[0]}
          alt={update.title}
        />
      </div>

      <div className="text-justify text-xs md:text-sm mt-2">
        {paragraphs.map((p) => (
          <div className=" w-full mt-2 ">
            <p>{p.toString()}</p>
          </div>
        ))}
      </div>

      {/* video resources  */}
      {update.videos?.length > 0 && (
        <div className="w-full h-auto mt-6">
          <video style={{ width: "100%", height: 240 }} controls>
            <source src={update.videos[0]} type="video/mp4" />
            <source src={update.videos[0]} type="video/ogg" />
          </video>
          {/* Your browser does not support the video tag. */}
          {/* </video> */}
        </div>
      )}

      {/* //additional resource  */}
      <div className=" w-full flex flex-col items-start mt-2 text-md  italic space-x-2  ">
        <p className="font-bold ml-2">Additional Resources</p>
        <div className="flex space-x-2">
          {urls.map((url) => (
            <div className="cursor-pointer underline text-[#1D6DAE]">
              <Link to={{ pathname: url }} target="_blank">
                <div className="flex justify-center items-center">
                  <RightSquareTwoTone className="mr-2" />
                  <span className="mb-1">{url.toString()}</span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UpdateContainer;
