import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";

export const removeAssociate = createAsyncThunk("removeAssociate", async (associateId)=>{
    const route = `/api/user/remove-associate/${associateId}`;
    const { data } = await AxiosInstance.delete(route);
    return data
})

export const getProcessedAssociates = createAsyncThunk("get/AllProcessedAssociates",  async (countryQuery="") => {
          const url = `/api/associate/all-associates-processed?country=${countryQuery}`;
          try {
            const { data } = await AxiosInstance.get(url);
            return data.associates
          }catch(err){

          }    
})

export const getAllAssociatesProcessedByCountry = createAsyncThunk("get/AssociatesProcessedByCountry", async () => {
    const url = `/api/associate/associates-processed-by-country`;
    try {
      const { data } = await AxiosInstance.get(url);
      return data.associatesByCountry
    } catch (error) {
    }
  });

export const getAllInProcessingAssociatesByCountry = createAsyncThunk("get/AllInProcessingAssociatesByCountry", async () => {
    const url = `/api/associate/associates-stats`;
    try {
      const { data } = await AxiosInstance.get(url);
      return data.stats
    } catch (error) {
    }
  });

export const getprocessedAssociatesUniqueCountries= createAsyncThunk("get/AssociatesUniqueCountryies", async () => {
    const url = `/api/associate/processed-associates-unique-countries`;
    try {
      const { data } = await AxiosInstance.get(url);
      return data.countries
    } catch (error) {
    }
  });


const initState = {
  componentsStates: {
    removeAssociatePrompt: false,
    showListOfProccessedAssociates: false,
    showViewAssociateModal: false,
    hidePopOver: false,
    openProcessedAssModal: false,
    showListOfInProcessingAssociates: false,
    showProcessedAssociateDetailsModal: false,
    associateContactDropDown: false,
    showSelectedAssociateDetails: false,
  },
  associates: [],
  allProcessedassociates: [],
  associatesProcessedByCountry: [],
  processedAssociatesUniqueCountries: [],
  isLoading: false,
  allInProcessingAssociatesByCountry:[],
  associatesCountry: "",
  associateDetails: ""
};

export const associateSlice = createSlice({
  name: "associates",
  initialState: initState,
  reducers:{
    updatePhotos: (state)=>{
        state.photos = "New Photo"
    },
    setRemoveAssociatePrompt:(state, action)=>{
        state.componentsStates.removeAssociatePrompt = action.payload
    },
    setShowListOfProccessedAssociates:(state, action)=>{
        state.componentsStates.showListOfProccessedAssociates = action.payload
    },
    setShowViewAssociateModal:(state, action)=>{
        state.componentsStates.showViewAssociateModal = action.payload
    },
    setHidePopOver:(state, action)=>{
        state.componentsStates.hidePopOver = action.payload
    },
    setOpenProcessedAssModal:(state, action)=>{
        state.componentsStates.openProcessedAssModal = action.payload
    },
    setShowListOfInProcessingAssociates:(state, action)=>{
        state.componentsStates.showListOfInProcessingAssociates = action.payload
    },
    setAssociatesCountry:(state, action)=>{
        state.associatesCountry = action.payload
    },
    setAssociateDetails:(state, action)=>{
        state.associateDetails = action.payload
    },
    setShowProcessedAssociateDetailsModal:(state, action)=>{
        state.associateDetails = action.payload
    },
    setAssociateContactDropDown:(state, action)=>{
        state.associateContactDropDown = action.payload
    },
    setShowSelectedAssociateDetails:(state, action)=>{
        state.showSelectedAssociateDetails = action.payload
    },
  },
  extraReducers:(builder) => {
    builder
      .addCase(removeAssociate.pending, (state, action) => {
          state.isLoading = true
      })
      .addCase(removeAssociate.fulfilled, (state, action) => {
          state.allProcessedassociates = state.allProcessedassociates.filter(associate=> associate._id != action.payload.deletedAssociate._id) 
          // state.allFilteredProcessedassociates = state.allFilteredProcessedassociates.filter(associate=> associate._id != action.payload.deletedAssociate._id) 
          state.isLoading = false

      })
      .addCase(getProcessedAssociates.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getProcessedAssociates.fulfilled, (state, action) => {
          state.allProcessedassociates = action.payload
          state.isLoading = false
      })
      .addCase(getAllAssociatesProcessedByCountry.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getAllAssociatesProcessedByCountry.fulfilled, (state, action) => {
          state.associatesProcessedByCountry = action.payload
          state.isLoading = false
      })
      .addCase(getprocessedAssociatesUniqueCountries.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getprocessedAssociatesUniqueCountries.fulfilled, (state, action) => {
          state.processedAssociatesUniqueCountries = action.payload
          state.isLoading = false
      })
      .addCase(getAllInProcessingAssociatesByCountry.pending, (state) => {
          state.isLoading = true
      })
      .addCase(getAllInProcessingAssociatesByCountry.fulfilled, (state, action) => {
          state.allInProcessingAssociatesByCountry = action.payload
          state.isLoading = false
      })
    }
  },
);

export const {setRemoveAssociatePrompt, 
              setShowListOfProccessedAssociates, 
              setShowViewAssociateModal,
              setHidePopOver,
              setOpenProcessedAssModal,
              setShowListOfInProcessingAssociates,
              setAssociatesCountry,
              setAssociateDetails,
              setShowProcessedAssociateDetailsModal,
              setAssociateContactDropDown,
              setShowSelectedAssociateDetails
            } = associateSlice.actions
export default associateSlice;
