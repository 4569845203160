import React from "react";
import { Menu } from "antd";
import {
  HomeOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  ScheduleOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
const { SubMenu } = Menu;

function CustomSiderMenu({ collapsed, handleClick, user }) {
  const { pathname } = useLocation();
  let defaultKeyNum = "";
  const myNavs = [
    { name: "/home", key: "1" },
    { name: "/profile", key: "2" },
    { name: "/subscribe", key: "3" },
    { name: "/rm-individual", key: "4" },
    { name: "/rm-corperate", key: "4" },
    { name: "/exercises", key: "5" },
    { name: "/UCBI", key: "6" },
  ];

  for (let nav of myNavs) {
    if (nav.name === pathname) {
      defaultKeyNum = nav.key;
    }
  }

  return (
    <Menu
      mode="inline"
      className="sidebar-bg f-2"
      defaultSelectedKeys={[defaultKeyNum]}
      onClick={handleClick}
      collapsible
      collapsed={collapsed}
    >
      <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
        <Link to="/home" />

        <span>Home</span>
      </Menu.Item>

      <Menu.Item key="2" icon={<ProfileOutlined />} className="font-2 mt-2">
        <Link to="/profile" />
        <span>Profile</span>
      </Menu.Item>
      <Menu.Item key="3" icon={<FileDoneOutlined />}>
        <Link to="/store" />
        <span>Subscription</span>
      </Menu.Item>
      <SubMenu
        key="4"
        title={<span>Services</span>}
        icon={<DatabaseOutlined />}
        className="px-0"
      >
       {user.userType !== "school" &&
        <Menu.Item key="sub1"  className="text-[11px] ml-[-20px] pr-0 mr-0">
           <Link
            to={
              user.accountType === "individual"
                ? "/edu-advisory-corperate"
                : "/edu-advisory-corperate"
            }
          />
          
          Education Advisory
        </Menu.Item> }

        {user.userType !== "school" && <Menu.Item key="sub1" className="text-[11px] ml-[-20px] pr-0 mr-0">
          <Link
            to={
              user.accountType === "individual"
                ? "/digital-literacy"
                : "/digital-literacy"
            }
          />
          Digital Literacy and Life Skills
        </Menu.Item>}

        {user.userType !== "school" && <Menu.Item key="sub1" className="text-[11px] ml-[-20px] pr-0 mr-0">
          <Link
            to={
              user.accountType === "individual"
                ? "/study-finance-advisory"
                : "/study-finance-advisory"
            }
          />
          Study Finance Advisory
        </Menu.Item> }

       <Menu.Item key="sub1" className="text-[11px] ml-[-20px] pr-0 mr-0">
          <Link
            to={
              user.accountType === "individual"
                ? "/job-and-employment"
                : "/job-and-employment"
            }
          />
          Jobs and Employment MGT
        </Menu.Item> 

        <Menu.Item key="sub1" className="text-[11px] ml-[-20px] pr-0 mr-0">
          <Link
            to={
              user.accountType === "individual"
                ? "/staff-competence-mgt"
                : "/staff-competence-mgt"
            }
          />
          Staff Competence MGT
        </Menu.Item>
        {user.userType !== "school" && <Menu.Item key="sub1" className="text-[11px] ml-[-20px] pr-0 mr-0">
          <Link
            to={
              user.accountType === "individual"
                ? "/business-leadership"
                : "/business-leadership"
            }
          />
          Business Leadership MGT
        </Menu.Item> }

      </SubMenu>
      <Menu.Item key="5" icon={<ScheduleOutlined />}>
        <Link to="/exercises" />
        <span>Exercises</span>
      </Menu.Item>
    </Menu>
  );
}

export default CustomSiderMenu;
