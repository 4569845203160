import React, { useState } from "react";
import { Drawer, Menu } from "antd";
import { Link } from "react-router-dom";
import {
  FileDoneOutlined,
  HomeOutlined,
  ProfileOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";

function SmallScreenDrawer({ setShowSmallScreenDrawer, user }) {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      title=""
      placement="left"
      onClose={() => {
        setShowSmallScreenDrawer(false);
      }}
      open={true}
    >
      <Menu
        mode="inline"
        // theme="dark"

        // defaultSelectedKeys={["2"]}
        // onClick={handleClick}
      >
        <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
          <Link to="/home" />

          <span>Home</span>
        </Menu.Item>

        <Menu.Item key="2" icon={<ProfileOutlined />} className="font-2 mt-2">
          <Link to="/profile" />
          <span>Profile</span>
        </Menu.Item>
        <Menu.Item key="3" icon={<FileDoneOutlined />}>
          <Link to="/subscribe" />
          <span>Subscription</span>
        </Menu.Item>
        <Menu.Item key="5" icon={<ScheduleOutlined />}>
          <Link to="/exercises" />
          <span>Exercises</span>
        </Menu.Item>
      </Menu>
    </Drawer>
  );
}

export default SmallScreenDrawer;
