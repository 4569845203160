import React from "react";

function EngrosCampaign() {
  return (
    <>
      <div className="text-xl font-bold mb-3">Campaigns</div>
      <div className="flex justify-between w-full h-[22%] bg-inherit">
        <div className="bg-white rounded h-full w-[46%] pl-6 flex justify-center flex-col">
          {/* <div className="text-xl font-semibold">Weekly Captures</div>
          <div className="text-xl font-semibold">100</div> */}
        </div>
        <div className="bg-white rounded h-full w-[46%] pl-6 flex justify-center flex-col">
          {/* <div className="text-xl font-semibold">Total Capture Schools</div>
          <div className="text-xl font-semibold">5045</div> */}
        </div>
      </div>
      <div className="flex justify-between w-full h-[70%] bg-inherit mt-3 rounded-lg">
        {/* Steka Signup requests */}
        <div className="bg-white h-full w-[70%] flex flex-col rounded-lg">
          {/* <div className="h-[20%] bg-[#D9D9D9] flex justify-between items-center">
            <p className="ml-10 text-lg font-bold text-sky-500">
              STEKA Sign Up Requests
            </p>
            <p className="mr-3 mt-1 font-bold">processed</p>
          </div>
          <div className="h-1 bg-[#1D6DAE] w-full"></div>
          <div className="h-[70%] bg-inherit px-0">
            {new Array(4).fill(0).map((item, index) => (
              <div
                key={index.toString()}
                className="w-full even:bg-[#D9D9D9] flex justify-between h-10 mt-1.5 "
              >
                <div className="w-[50%] pl-3 flex justify-between items-center">
                  <p>Nigeria </p>
                  <p>1000</p>
                </div>
                <div className="w-[15%] pr-3 flex justify-between items-center ">
                  <button className="text-[#1D6DAE] focus:outline-none transition-all hover:-translate-y-1 duration-500">
                    view
                  </button>
                  <p>: :</p>
                </div>
              </div>
            ))}
          </div>

          <div className="h-[9%] w-full px-3 bg-[#D9D9D9] flex justify-end mr-2">
            <button className="mr-1 text-[#1D6DAE] font-semibold px-1 rounded-lg hover:bg-[#1D6DAE] hover:text-white focus:outline-none transition-all hover:-translate-x-1 duration-500">
              previous
            </button>
            <div className="mr-1  text-[#1D6DAE] font-semibold">|</div>
            <button className="text-[#1D6DAE] font-semibold px-3 rounded-lg hover:bg-[#1D6DAE] hover:text-white focus:outline-none transition-all hover:translate-x-1 duration-500">
              next
            </button>
          </div> */}
        </div>
        {/* Tragest audience  */}
        <div className="bg-white h-full w-[26%] flex flex-col rounded-lg ">
          <div className="w-full h-[20%] bg-[#1D6DAE] text-white rounded-t-lg  flex justify-center items-center text-xl font-bold">
            {/* Target Audience */}...
          </div>
          {/* <div className="w-full h-[80%] space-y-3 px-2 text-gray-600  flex flex-col text-xl font-semibold">
            <p>238</p>
            <p className="font-bold">Students</p>
            <p>50</p>
            <p className="font-bold">Teachers</p>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default EngrosCampaign;
