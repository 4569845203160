import React from "react";
import { Popover } from "antd";
import currencyFormatter from 'currency-formatter';
import {
  CloseCircleTwoTone,
} from "@ant-design/icons";
import PaymentRequestModal from "./modals/PaymentRequestModal";
import { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { 
  getTotalProcessedUCBIAmountForTeachers,
  getAllTeachersWithProcessedUcbis,
  getAllAssociatesWithProcessedUcbis,
  setUcbiUserDetails,
  setShowUcbiTransactionModal,
  getTotalProcessedUCBIAmountForAssociates,
  getUcbiHistory,
  setShowUcbiHistoryListModal,
  setUcbiTransactionDetails
 } from "../../../redux/slices/ucbiSlice";
import { useEffect } from "react";
import UCBIDetailsModal from "../../campaigns/steka/modals/UcbiDetailModal";
import UcbiTransactionDetailsModal from "../../campaigns/steka/modals/UcbiTransactionDetailsModal";

export default function UcbiHistoryList({ processedPayments }) {
  const [openUCBIPaymentReqModal, setOpenUCBIPaymentReqModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [loading, setLoading] = useState(false);
  const [month, setmonth] = useState("");
  const dispatch = useDispatch()
  const {totalProcessedUCBIAmountForTeachers,
         allTeachersWithProcessedUcbis,
         allAssociatesWithProcessedUcbis,
         totalProcessedUCBIAmountForAssociates,
         ucbiUserDetails,
         ucbiHistory,
        isLoading
  } = useSelector((state) => state.ucbiSlice);

  const {showUcbiTransactionModal
  } = useSelector((state) => state.ucbiSlice.componentsStates);

  const months = [  "January", "February", "March", "April", 
                    "May", "June", "July", "August", "September", 
                    "October", "November", "December" ]

  useEffect(()=>{
    dispatch(getTotalProcessedUCBIAmountForTeachers())
    dispatch(getAllTeachersWithProcessedUcbis(month))
  },[getTotalProcessedUCBIAmountForTeachers, getAllTeachersWithProcessedUcbis])

  useEffect(()=>{
    dispatch(getUcbiHistory(ucbiUserDetails?.user_id?._id))
  },[getUcbiHistory])

  const totalUCBIAmount = ucbiHistory.map(history=>history?.amount).reduce((total,cur)=>total+cur, 0)
  return (
    <div className="w-full h-full relative ">
       <div className="w-full h-6 flex justify-end mr-2 text-red-500 cursor-pointer">
            <CloseCircleTwoTone
              onClick={() => {
                dispatch(setShowUcbiHistoryListModal(false))
                // dispatch(setShowListOfAdmins(true))
            }}
              color="red"
            />
          </div>
      <div className="">
        <div className="">
          <div className="">
            <div className="">
              <div className="col-lg-12 mt-4">
                <div className="mt-3">
                  <div class="flex justify-between p-3 items-center">
                    <div class="h-2 mb-3 font-bold text-lg">
                      {ucbiUserDetails?.user_id?.name}
                    </div>
                    {/* <div >
                      <select 
                          className="form-control"
                          required
                          type = 'text'
                          name = "month"
                          value = {month}
                          onChange={(e)=>setmonth(e.target.value)}
                        >
                          <option value="">All Months</option>
                          {months &&
                            months.map((month, i) => (
                              <option 
                                key={i.toString()}
                                value={month}
                                className="font-semibold text-gray"
                              >
                                {`${month}`}
                              </option>
                            ))

                          }
                        </select>
                    </div> */}
                    <div class="font-bold text-lg">({currencyFormatter.format(totalUCBIAmount, { locale: 'en-NG' })})</div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped table-auto">
                      <thead className="bg-success1 text-white">
                        <tr className="w-full">
                          <th className="w-2/4">Date</th>
                          <th className="w-[36%]">Payouts</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      {isLoading ? <div>Loading</div> :
                      <tbody>
                        {ucbiHistory && ucbiHistory.length > 0 ? (
                          ucbiHistory.map((paymentHistory, index) => {
                            const date = moment(paymentHistory.createdAt).format("DD-MMMM-YYYY")
                            return( 
                              <tr key={index}>
                              {/* <td className="w-2/4">{date}</td>
                              <td className="w-1/5">{currencyFormatter.format(paymentHistory.amount, { locale: 'en-NG' })}</td>
                              <td className="w-2/5"> */}
                              <td className="">{date}</td>
                              <td className="">{currencyFormatter.format(paymentHistory.amount, { locale: 'en-NG' })}</td>
                              <td className="w-2/5">
                                      <button
                                        onClick={() => {
                                          dispatch(setUcbiTransactionDetails(paymentHistory));
                                          dispatch(setShowUcbiTransactionModal(true));
                                          // dispatch(getUcbiHistory(month,paymentHistory.user_id._id))
                                        }}
                                        className="text-blue-300 outline-none focus:outline-none  rounded-full transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                                      >
                                        View Transaction
                                      </button>
                              </td>
                            </tr>
                          )})
                        ) : (
                          <tr className="bg-white">
                            <td></td>
                            <td></td>
                            <td>No withdrawal history for teachers 👏</td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                     }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showUcbiTransactionModal && (
      <div
         className={`absolute left-0 top-0 w-full h-[500px] rounded-lg bg-white px-4 py-2`}
      >
        <UcbiTransactionDetailsModal />
      </div>
      )}
    </div>
  );
}
