import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import './store.css';
import { Carousel } from 'antd';
import { usePaystackPayment } from "react-paystack";
import Icon, {
    StarFilled,
    CloseCircleFilled,
    CloseOutlined
  } from "@ant-design/icons";
import AxiosInstance from "../../AxiosInstance/AxiosInstance";
import { addToCart, clearCart, countCart, getCarts, removeCart } from "./storeFunction";
import { toast } from "react-toastify";
const Checkout = ({
    showState,cart
}) =>{
    const user = useSelector((state) => state.user.user);
    const [details,setDetails] = useState();
    const [itemss,setItems] = useState([]);
    const [totalcost,setTotalCost]=useState(0);
    const onSChange = (currentSlide) => {
        console.log(currentSlide);
      }
      const config = {
        reference: new Date().getTime().toString(),
        email: user.email,
        amount:totalcost * 100, //* 100 converts sub amount from naira to to kobo
        publicKey: "pk_test_7b545e0d7a1aaa0e39782e7d5aa7e9595a8082fc",
        metadata: {
            order: JSON.stringify(getCarts()),
            userid: user._id
        },
      };
      const initializePayment = usePaystackPayment(config);
     const paysucess=(data)=>{
        showState(false);
        clearCart();
        cart(countCart())
        toast.success("Payment successful");
     }
      const renderCart = async () => {
        const carts = getCarts();
        const promises = carts.map(async (id,index) => {
          const det = await getItems(id);
          console.log(det);
          setTotalCost((prevTotal) => Number(prevTotal) + Number(det.cost));
          return (
            <tr key={id+Math.random().toString()}>
              <td><img src={det?.images[0]} alt="" /></td>
              <td><div>{det?.name}</div></td>
              <td><div>NGN {det?.cost}</div></td>
              <td><div><CloseCircleFilled onClick={()=>{
                removeCart(index);
                setItems(prevItems => {
                    const newItems = [...prevItems];
                    newItems.splice(index, 1);
                    //const updatedTotalCost = newItems.reduce((total, item) => total + (item.props.children[2]?.props.children || 0), 0);
                    //setTotalCost(updatedTotalCost)
                    return newItems;
                  });
                  
              }}/></div></td>
            </tr>
          );
        });
      
        const nitem = await Promise.all(promises);
        setItems(nitem);
      }
      
     
    const getItems = async (id) => {
        const url = "/api/store/item"
        const req = await AxiosInstance.post(url,{
            id:id
        })
        return req.data.data;
      };

      useEffect(()=>{
        renderCart();
      },[])

  
    return (
        <div className="store-details">
            <div className="inner">
               <div className="checkout">
                <div className="ctitle"><div>Checkout</div><CloseOutlined onClick={()=>{
                    showState(false);
                }}/></div>
                <br/>
               <table className="table table-hover table-striped" style={{width:"100%"}}>
                    {itemss}
               </table>
               <div style={{display:"flex",justifyContent:"end"}}>
               <button className="checkout-btn" onClick={()=>{
      // alert(totalcost)
      initializePayment(paysucess,()=>{})
    }}>Proceed to Payment proceed</button>  
               </div>
               </div>
            </div>
        </div>
    )
}
export default Checkout;