import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import CreateAdmin from "../dashboards/admin/modal/createAdminModal";
import { BiCircle } from 'react-icons/bi';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Layout, Menu, Dropdown, Space, Tabs, Drawer } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
import DeleteUpdatesConfirmationModal from "../../campaigns/steka/modals/DeleteUpdatesConfirmationModal";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  ProfileOutlined,
  FileDoneOutlined,
  ScheduleOutlined,
  UserOutlined,
  LogoutOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Popover } from "antd";
import UpdateCard from "./UpdateCard";
import UplaodMedia from "./UplaodMedia";
import UpdateView from "./UpdateView";
import Toast from "../../../util/toast";
import Classes from "./classes";
import moment from "moment";
import { TimePicker, DatePicker, Checkbox, Progress } from "antd";
import { faker } from '@faker-js/faker';
import _ from 'lodash';
import AxiosInstance from "../../../AxiosInstance/AxiosInstance";




const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const { TabPane } = Tabs;

const handleClick = ({ key }) => {
  console.log(key);
  //you can perform setState here
};

const Updates = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [uploadMediaOpen, setUploadMediaOpen] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [modal, setModal] = useState(false);
  const [service, setService] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [update, setUpdate] = useState();
  const [view, setView] = useState(false);
  const [prop, setProp] = useState(null);

  const [imageFiles, setImageFiles] = useState(null);
  const [videoFiles, setVideoFiles] = useState(null);
  const [detailedUpdate, setDetailedUpdate] = useState(false);
  const [careerPath, setCareerPath] = useState(null);
  const [fields, setFields] = useState(null);
  const [fieldsFromdb, setFieldsFromdb] = useState(null);
  const [preferredPackage, setPreferredPackage] = useState(null);
  const [subject, setSubject] = useState(null);
  const [skills, setSkills] = useState(null);
  const [careerPathId, setCareerPathId] = useState(null);
  const [percentUploaded, setPercentUploaded] = useState(0);
  const [updateService, setUpdateService] = useState(null); // The service for which this updated is intended
  const [files, setFiles] = useState([]);
  const [subjects, setSubjects] = useState();
  const [content, setContent] = useState(null);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [postMode, setPostMode] = useState(null);
  const [eduClass, setEduClass] = useState(null);
  const [title, setTitle] = useState(null);
  const [postType, setPostType] = useState(null);
  const [postDescription, setPostDescription] = useState(null);
  const [optionalReference, setOptionalReference] = useState("");
  const [postCategory, setPostCategory] = useState(null);
  const [eduSubject, setEduSubject] = useState(null);
  const [allUpdates, setAllUpdates] = useState([]);
  const [showDeleteUpdatesModal, setShowDeleteUpdatesModal] = useState(false);
  const [updateID, setUpdateID] = useState();
  const [isUpdateDeleted, setIsUpdateDeleted] = useState(false);
  const [updateObject, setUpdateObject] = useState(null);
  const [isUpdatedCreated, setIsUpdatedCreated] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdateEdited, setIsUpdateEdited] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [allservices, setAllservices] = useState([]);
  const [newField, setNewField] = useState([]);


  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const allClasses = [...Classes.primary, ...Classes.secondary, ...Classes.tertiary]
  const onChangeDate = (date, dateString) => {
    setDate(dateString)
  };
  const onChangeTime = (time, timeString) => {
    setTime(timeString)
  };

  const getAdminsUpdates = async () => {
    const url = "/api/update/get-all-updates"
    const allUpdates = await AxiosInstance.get(url)
    const { data } = allUpdates
    setAllUpdates(data?.updates)
  };

  const handleUpdatesDelete = async (id) => {
    await AxiosInstance.delete(`/api/update/delete-update/${id}`)
      .then(res => {
        setIsUpdateDeleted(true)
        Toast("Update has been deleted", "success");
      })
      .catch(error => {
        if (error) {
          Toast("Update was not deleted", "error");
        }
      }).finally(()=>{
        setIsUpdateDeleted(false)
      })

  }

  useEffect(() => {
    getAdminsUpdates()
  }, [isUpdateDeleted, isUpdatedCreated])


  const fileRef = useRef(null);
  //Upload files urls

  const handFileChange = (event) => {
    event.preventDefault()
    setFiles([...files, event.target.files[0]]);
  };

  const handlePost = async () => {
    setIsCreating(true)
    const fd = new FormData()
    for (let i = 0; i < files.length; i++) {
      fd.append('file', files[i])
    }
    console.log("files", files);
    fd.append('serviceId', service)
    fd.append('title', title)
    fd.append('updateType', postType)
    fd.append('postMode', postMode)
    fd.append('postDescription', postDescription)
    fd.append('externalURLS', optionalReference)
    fd.append('postCategory', postCategory || 'People')
    if (service == "6504e998ee56fc4b181aed04") {
      fd.append('educlass', eduClass)
      fd.append('subject', subject)
    }
    else if (service == "6504e998ee56fc4b181aed0e") {
      fd.append('skills', skills)
      fd.append('subject', subject)
    }

    if (!service) {
      setIsCreating(false)
      return Toast("Please select a service type", "error");
    }
    if (service == "6504e998ee56fc4b181aed04") {
      if (!subject) {
        setIsCreating(false)
        return Toast("Please select a subject", "error");
      }
      if (!eduClass) {
        setIsCreating(false)
        return Toast("Please select a class", "error");
      }
    }
    if (service == "6504e998ee56fc4b181aed0e") {
      if (!skills) {
        setIsCreating(false)
        return Toast("Please select a Skills", "error");
      }
      if (!subject) {
        setIsCreating(false)
        return Toast("Please select a subject", "error");
      }
    }
    if (!postType) {
      setIsCreating(false)
      return Toast("Please select updates type", "error");
    }
    if (!postMode) {
      setIsCreating(false)
      return Toast("Please select updates mode", "error");
    }
    if (!postDescription) {
      setIsCreating(false)
      return Toast("Updates body cannot be empty.", "error");
    }
    if (!title) {
      setIsCreating(false)
      return Toast("Updates title cannot be empty.", "error");
    }
    if (!postCategory && isIdCategoryIndividual(service)!==true) {
      setIsCreating(false)
      return Toast("Please select who can view post", "error");
    }
    setIsUpdatedCreated(false)
    await AxiosInstance.post("/api/update/create-new-update", fd)
      .then(res => {
        setIsUpdatedCreated(true)
        setService("")
        setPostType("")
        setPostMode("")
        setPostDescription("")
        setTitle("")
        setPostCategory("")
        setOptionalReference("")
        setIsCreating(false)
        Toast("Update has been posted successfully.", "success");
      }).catch(e => {
        setIsCreating(false)
        Toast("Update not successful.", "error");
      })

  }

  const handleUpdateEdit = async (e) => {
    setIsCreating(true)
    e.preventDefault()
    const data = {
      serviceId: service ? service : null,
      title: title ? title : null,
      postMode: postMode ? postMode : null,
      updateType: postType ? postType : null,
      postDescription: postDescription ? postDescription : null,
      externalURLS: optionalReference ? optionalReference : null,
      postCategory: postCategory ? postCategory : null,
      createdBy: user?._id,
      skills: skills ? skills : null,
      educlass: eduClass ? eduClass : null,
      subject: subject ? subject : null,
    }
    await AxiosInstance.patch(`/api/update/edit-update/${updateObject?._id}`, data)
      .then(res => {
        setIsUpdatedCreated(true)
        setService("")
        setPostType("")
        setPostMode("")
        setPostDescription("")
        setTitle("")
        setPostCategory("")
        setOptionalReference("")
        // setFiles([])
        setIsCreating(false)
        setEditMode(false)
        setIsUpdateEdited(!isUpdateEdited)
        setIsCreating(false)
        Toast("Update has been edited successfully.", "success");
      })
      .catch(error => {
        if (error) {
          Toast("Please check your network connectivity", "error");
          setIsCreating(false)
        }
      })

  }

  useEffect(() =>
    getAllUpdates(), []);

  useEffect(() => {
    if (editMode) {
      setService(updateObject?.serviceId)
      setPostType(updateObject?.updateType)
      setPostMode(updateObject?.postMode)
      setPostDescription(updateObject?.postDescription)
      setTitle(updateObject?.title)
      setPostCategory(updateObject?.postCategory)
      setOptionalReference(updateObject?.externalURLS)
      setSkills(updateObject?.skills)
      setEduClass(updateObject?.educlass)
      setSubject(updateObject?.subject)
    }
  }, [editMode])

  useEffect(async () => {
    const getServices = await AxiosInstance.get(`api/user/get-fields-by-service-type?service=${serviceName}`)
    console.log(getServices)
    getServices?.data?.fields?.length > 0 && getServices?.data?.fields.map(sub=>{
      setNewField([...sub.subjects])
    });
    getServices.data.fields?.length > 0 ? setFields(getServices?.data?.fields[0]) : setFields("")
  }, [service]);

  const getAllUpdates = async () => {
    const getAll = await AxiosInstance.post("/api/update/get-all-admin-updates", { 
      adminRank: user?.adminRank,
      adminRole: user?.adminRole,
      country: user?.country,
      state: user?.state,
      province: user?.province
    });
    if (getAll && getAll.data) {
      console.log(getAll.data.updates);
      setUpdate(getAll.data.updates);
      return;
    }
  }

  const getAllServices = async () => {
    const {data} = await AxiosInstance.get("/api/service/get-services");
    setAllservices(data.services || [])
   console.log("srvices",data)
  }

  useEffect(()=>{
    getAllServices()
  },[])

  function toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }
  function toggle() {
    setCollapsed(!collapsed);
  }
  function onClose() {
    setCollapsed(false);
  }
  function isIdCategoryIndividual(idToCheck) {
    console.log(JSON.stringify(idToCheck))
    return allservices.some(service => service._id === idToCheck && service.serviceCategory === 'individual');
}
  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="Profile" icon={<UserOutlined />} className="font-3 mt-2">
        <Link to="/profile" />
        Profile
      </Menu.Item>
      <hr />
      {/* <Menu.Item key="Line">
    </Menu.Item> */}
    

      <Menu.Item key="Logout" icon={<LogoutOutlined />} className="mb-2 font-3">
        <Link to="/logout" />
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout
      style={{
        zIndex: 1,
        minHeight: "100vh",
        maxHeight: "100%",
        flexWrap: "nowrap",
        overflow: "hidden",
      }}
      hasSider
    >
      <Drawer
        placement="left"
        onClose={onClose}
        closable={false}
        visible={collapsed}
        className="hideOnDesktop"
        width="250px"
        bodyStyle={{
          backgroundColor: "#1D6DAE",
          padding: "0",
        }}
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <Menu
          mode="inline"
          className="sidebar-bg f-2"
          defaultSelectedKeys={["5"]}
        >
          <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
            <Link to="/AdminDashboard" />
            <span>Home</span>
          </Menu.Item>

          <Menu.Item key="2" icon={<ShoppingCartOutlined />} className="font-2 mt-2">
            <Link to="/adminStore" />
            <span>Store</span>
          </Menu.Item> 
          <Menu.Item key="3" icon={<ShoppingCartOutlined />}>
            <Link to="/adminOrders" />
            <span>Store Orders</span>
          </Menu.Item> 
          <Menu.Item key="4" icon={<ScheduleOutlined />}>
            <Link to="/Admin_Page" />
            <span>Admin</span>
          </Menu.Item>
          <Menu.Item key="5" icon={<ScheduleOutlined />}>
            <Link to="/UCBI" />
            <span>UCBI</span>
          </Menu.Item>
          <Menu.Item key="6" icon={<ScheduleOutlined />}>
            <Link to="/update" />
            <span>Updates</span>
          </Menu.Item>
          {/* <Menu.Item
            onClick={toggleCreateServiceModal}
            key="7"
            icon={<ScheduleOutlined />}
          >
            <span>create service</span>
          </Menu.Item> */}
          <Menu.Item key="8" icon={<ScheduleOutlined />}>
            <Link to="/campaigns" />
            <span>Campaigns</span>
          </Menu.Item>
          <Menu.Item key="10" icon={<ScheduleOutlined />}>
            <Link to="/exercises" />
            <span>Exercises</span>
          </Menu.Item>

          <Menu.Item key="9" icon={<LoginOutlined />}>
            <Link to="/logout" />
            <span>Logout</span>
          </Menu.Item>
        </Menu>
      </Drawer>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onBreakpoint={(broken) => setCollapsed(broken)}
        className="hideOnMobile sidebar-bg"
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          // zIndex: 10,

        }}
      >
        <div className="logo mt-3 mb-2 ml-5" />

        <Menu
          mode="inline"
          className="sidebar-bg f-2"
          defaultSelectedKeys={["6"]}
          onClick={handleClick}
        >
          <Menu.Item key="1" className="font-2" icon={<HomeOutlined />}>
            <Link to="/AdminDashboard" />
            <span>Home</span>
          </Menu.Item>

          <Menu.Item key="2" icon={<ShoppingCartOutlined />} className="font-2 mt-2">
            <Link to="/adminStore" />
            <span>Store</span>
          </Menu.Item> 
          <Menu.Item key="3" icon={<ShoppingCartOutlined />}>
            <Link to="/adminOrders" />
            <span>Store Orders</span>
          </Menu.Item> 
          <Menu.Item key="4" icon={<ScheduleOutlined />}>
            <Link to="/Admin_Page" />
            <span>Admin</span>
          </Menu.Item>
          <Menu.Item key="5" icon={<ScheduleOutlined />}>
            <Link to="/UCBI" />
            <span>UCBI</span>
          </Menu.Item>
          <Menu.Item key="6" icon={<ScheduleOutlined />}>
            <Link to="/update" />
            <span>Updates</span>
          </Menu.Item>
          {/* <Menu.Item
            onClick={toggleCreateServiceModal}
            key="7"
            icon={<ScheduleOutlined />}
          >
            <span>create service</span>
          </Menu.Item> */}
          <Menu.Item key="8" icon={<ScheduleOutlined />}>
            <Link to="/campaigns" />
            <span>Campaigns</span>
          </Menu.Item>
          <Menu.Item key="10" icon={<ScheduleOutlined />}>
            <Link to="/exercises" />
            <span>Exercises</span>
          </Menu.Item>

          <Menu.Item key="9" icon={<LoginOutlined />}>
            <Link to="/logout" />
            <span>Logout</span>
          </Menu.Item>
          {/* <Menu.Item
            onClick={toggleCreateServiceModal}
            key="7"
            icon={<ScheduleOutlined />}
          >
            <span>create service</span>
          </Menu.Item> */}
          
        </Menu>
      </Sider>

      <Layout className="site-layout"
        style={{
          marginLeft: 200
        }}
      >
        <Header className="row pt-2 head site-layout">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger nav-but mr-3",
              onClick: toggle,
            }
          )}
          <h3 className="mt-1 wel">Updates</h3>
          {/* </div> */}
          {/* <div className="ml-auto account">Account</div> */}
          <div className="ml-auto account cursor-pointer transition hover:-translate-y-1 hover:duration-500">
            {user?.name && user?.name}
          </div>
        </Header>

        <Content
          style={{
            // marginTop: 24,
            margin: "0px 24px",
            padding: "0px 10px",
            // background: "",
            minHeight: 280,
          }}
          className="bg-gray-100"
        >
          <div className="container-fluid relative">
            {/* contents goes here */}
            <div className="mx-auto w-full h-min px-2 pt-2 pb-10">
              {/* Update card  */}

              {/* <div className="mt-8">
                <button
                  onClick={() => setModal(true)}
                  className="bg-success1 text-white outline-0 outline-none p-2 rounded"
                >
                  Create an Update
                </button>
                <div className="table-responsive bg-white mt-4">
                  <table className="table table-hover table-striped">
                    <thead className="bg-success1 text-white">
                      <tr>
                        <th>Title</th>
                        <th>Service Type</th>
                        <th>Author Rank</th>
                        <th>Country</th>
                        <th>Action</th>
                        <th>Date Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {update && update.length > 0 && update.map((field, i) => (
                        <tr>
                          <td>{field.title}</td>
                          <td className="capitalize">{field.serviceId}</td>
                          <td className="capitalize">{field.adminRank}</td>
                          <td className="capitalize">{field.country}</td>
                          <td>
                            <button
                              onClick={() => (setView(true), 
                                  setProp(field)
                                )}
                              className="text-blue-500 font-bold">
                              View::
                            </button>
                          </td>
                          <td>{new Date(field.createdAt).toISOString().slice(0,10)}</td>
                        </tr>
                      ))
                      }
                      {view &&
                        <UpdateView 
                        show = {view}
                        props = {prop}
                        onHide = {() => (setView(false))}
                      />
                      }
                    </tbody>
                  </table>
                </div>
              </div> */}


              <div className="bg-white rounded h-full w-full flex-col pt-2">
                <div className="flex justify-start">
                  <div className="form-group col-lg-4 h-2 select-wrapper">
                    <select
                      id="select"
                      className="mySelect form-control text-[#1D6DAE] font-bold p-0 m-0 text-xs"
                      value={service}
                      onChange={(e) => {
                        console.log("name", e.target.value);
                        const sv = JSON.parse(e.target.value);
                        setService(sv.id)
                        setServiceName(sv.name)
                      }}
                    >
                      <option value="">Select Service</option>
                      {allservices?.length > 0 && allservices.map((service, index)=>{
                       
                        return <option key={index} value={JSON.stringify({id:service?.id,name:service?.title})} >{service?.title} {service?.serviceCategory === 'corporate' ? "(corporate)" : ""}</option>
                      })}
                      {/* <option value="">Select Service</option>
                      <option>Education Advisory</option>
                      <option>Study Finance Advisory</option>
                      <option value="Digital Literacy and Life Development">Digital Literacy/Life Skills</option>
                      <option>Decent Job/Recruitment</option>
                      <option>Personnel Competence</option>
                      <option>Teachers Competence</option>
                      <option>Business Leadership</option> */}
                    </select>
                  </div>
                  <div className='form-group col-lg-5 text-xs'>
                    <input
                      type="text"
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Title"
                      className="form-control h-8 text-xs"
                    />
                  </div>
                  <div className="form-group">
                    <select
                      className="form-control text-[#1D6DAE] font-bold p-0 m-0 text-xs"
                      value={postType}
                      onChange={(e) => {
                        setPostType(e.target.value)
                      }}
                    >
                      <option value="">Update Type</option>
                      <option value="Basic">Basic</option>
                      <option value="Premium">Premium</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <select
                      className="form-control text-xs text-[#1D6DAE] font-bold p-0 mx-1"
                      value={postMode}
                      onChange={(e) => {
                        setPostMode(e.target.value)
                      }}
                    >
                      <option>Mode</option>
                      <option>Ordered</option>
                      {/* <option>Real Time</option>
                      <option>Scheduled</option> */}
                    </select>
                  </div>
                </div>
                <div className="flex px-3 mt-2 w-full space-x-3 justify-between">
                  <textarea
                    name="post description"
                    onChange={(e) => setPostDescription(e.target.value)}
                    value={postDescription}
                    // placeholder={applicableService == "" ? "" :
                    //             applicableService == "Education Advisory" ? "Add comma separated list of required subjects (Example, Basic Science, Mathematics, Physics, Civics, Social Studies)" 
                    //   :"Add comma separated list of subjects or modules (HTML, CSS, JavaScript)"}
                    className="w-full h-60 resize-none bg-gray-200 outline-none rounded-lg px-2 py-2 text-xs"
                  ></textarea>
                </div>
                <div className='flex justify-end mt-2 p-0 mr-0'>
                  {service && service == "6504e998ee56fc4b181aed04" ?
                    <div className="col-lg-6 flex">
                      <div className="px-1">
                        <select
                          className="form-control text-xs pl-2"
                          value={subject}
                          onChange={async (e) => {
                            setSubject(e.target.value)
                          }}
                        >
                          <option value="">All Subjects</option>
                          {newField?.length > 0 && newField.map((subject, i) => {
                            return (
                              <option key={i}>{subject}</option>
                            )
                          })}
                          {/* {fields?.subjects?.map((subject, i) => {
                            return (
                              <option key={i}>{subject}</option>
                            )
                          })} */}
                        </select>
                      </div>
                      <div className="px-1">
                        <select
                          className="form-control text-xs pl-2"
                          value={eduClass}
                          onChange={async (e) => {
                            setEduClass(e.target.value)
                          }}
                        >
                          <option value="">Class/Level</option>
                          {allClasses?.length > 0 && allClasses.map((eduClass, i) => {
                            return (
                              <option key={i} >{eduClass.text}</option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                    : service && service == "6504e998ee56fc4b181aed0e" ?
                      <div className="col-lg-6 flex">
                        <div className="px-1">
                          <select
                            className="form-control text-sm mr-2"
                            value={skills}
                            onChange={(e) => {
                              setSkills(e.target.value)
                            }}
                          >
                            <option value="">Skills/Framework</option>
                            {fields?.subjects?.length > 0 && fields?.subjects?.map((subject, i) => {
                              return (
                                <option key={i}>{subject}</option>
                              ) 
                            })}
                          </select>
                        </div>
                        <div className="px-1">
                          <select
                            className="form-control text-sm mx-2"
                            value={eduSubject}
                            onChange={async (e) => {
                              setSubject(e.target.value)
                            }}
                          >
                            <option value="">Subjects/Modules</option>
                            <option key={fields._id} >{fields.name}</option>

                          </select>
                        </div>
                      </div> : ""}
                  <div className="col-lg-6">
                    <input
                      type="text"
                      name="optional reference"
                      value={optionalReference}
                      onChange={(e) => setOptionalReference(e.target.value)}
                      placeholder="Optional Reference"
                      className="form-control text-xs"
                    />
                  </div>
                </div>
                <div className='flex justify-between mt-4 p-0 mr-0 pb-4'>
                  <div className="px-3 flex">
                    <div>
                      <button
                        onClick={() => {
                          fileRef.current.click()
                        }}
                        className="bg-success1 text-white outline-0 outline-none px-2 py-1 rounded mr-2 text-xs"
                      >Add Image</button>
                      <button
                        onClick={() => {
                          fileRef.current.click()
                        }}
                        className="bg-success1 text-white outline-0 outline-none px-2 py-1 rounded text-xs"
                      >Add Video</button>
                    </div>

                        {isIdCategoryIndividual(service)!==true?(<div  class="flex items-center  ml-4 items-center">
                    <div class="flex items-center  ml-4 items-center">
                      <div>
                        <input
                          id="default-radio-1" 
                          type="radio"
                          checked={postCategory === "People"}
                          value="People"
                          onChange={(e) => {
                            setPostCategory(e.target.value)
                          }}
                          name="default-radio"
                          class="w-4 h-4 bg-gray-100 border-gray-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                      </div>
                      <div>
                        <label htmlFor="default-radio-1" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">People</label>
                      </div>
                    </div>
                    <div class="flex items-center ml-3">
                      <div>
                        <input
                          id="default-radio-1"
                          type="radio"
                          value="Businesses"
                          checked={postCategory === "Businesses"}
                          onChange={(e) => {
                            setPostCategory(e.target.value)
                          }}
                          name="default-radio"
                          class="w-4 h-4 bg-gray-100 border-gray-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                      </div>
                      <div>
                        <label htmlFor="default-radio-1" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Businesses</label>
                      </div>
                    </div>
                    </div>)
                        :(<></>)}

                    


                    
                    {postMode && postMode == "Scheduled" ?
                      <div class="flex items-center ml-3">
                        <div className="px-1">
                          <DatePicker
                            onChange={onChangeDate}
                            style={{ width: 120 }}
                            className="rounded-lg"
                          />
                        </div>
                        <div>
                          <TimePicker
                            onChange={onChangeTime}
                            defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                            style={{ width: 120 }}
                            className="rounded-lg"
                          />
                        </div>
                      </div>
                      : ""
                    }
                  </div>
                  <div className="px-3 flex justify-end">
                    <div>
                      {
                        isCreating ? <Spin indicator={antIcon} /> :
                          <button
                            onClick={(e) => {
                              !editMode ? handlePost(e) : handleUpdateEdit(e)
                            }}
                            className={`bg-success1 text-white outline-0 outline-none px-3 py-1 rounded ${disableButton ? 'disabled bg-gray-300' : ""}`}
                          >{!editMode ? "Post" : "Edit"}</button>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <UpdateCard
                show={modal}
                onHide={() => setModal(false)}
              />
            </div>
          </div>
        </Content>

        <Content
          style={{
            margin: "0px 24px",
            // marginTop: "0px",
            padding: "0px 10px",
            // background: "",
            minHeight: "280px",
          }}
          className="bg-gray-100"
        >
          <div className="container-fluid relative">
            {/* contents goes here */}
            <div className="mx-auto w-full h-min px-2 pt-2 pb-10">
              {
               allUpdates?.length > 0 && allUpdates?.map(update => {
                  return (
                    <div className="bg-white rounded h-full w-full flex-col pt-2">
                      <div className="mb-[70px]">
                        <div className="flex justify-between items-center mx-3 my-3 border-b border-[#244060]">
                          <div className="flex justify-start items-center">
                            <div className="mr-2"><BiCircle size={35} color="#1D6DAE" strokeWidth={2} /></div>
                            <div className="flex-cols">
                              <div className="flex text-lg font-semibold text-[#1D6DAE]">
                                <div>{_.startCase(update?.title)}</div>

                                {update?.subject ?
                                  <>
                                    <p className="font-semibold mx-1"> :: </p>
                                    <div>{_.startCase(update?.subject)}</div>
                                  </>
                                  : ""}
                              </div>
                              <div className="flex text-xs text-slate-400">{update?.postCategory} :: {update?.serviceId?.title} </div>
                            </div>
                          </div>
                          <Popover
                            placement="left"
                            trigger="click"
                            content={
                              showDeleteUpdatesModal ?
                                <div className="">
                                  <DeleteUpdatesConfirmationModal
                                    showDeleteUpdatesModal={showDeleteUpdatesModal}
                                    setShowDeleteUpdatesModal={setShowDeleteUpdatesModal}
                                    handleUpdatesDelete={handleUpdatesDelete}
                                    id={updateID}
                                  />
                                </div>
                                :
                                <div className="min-w-min min-h-min flex flex-col items-start justify-start space-y-2 ">
                                  <button
                                    onClick={() => {
                                      setUpdateObject(update)
                                      setEditMode(true)
                                      window &&
                                        window.scrollTo({
                                          top: 0,
                                          left: 0,
                                          behavior: "smooth",
                                        });
                                    }}
                                    className="px-2 outline-none  focus:outline-none  rounded-full text-blue-400 transition-all duration-500 hover:-translate-y-1 hover:bg-blue-300 hover:text-white"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() => {
                                      setUpdateID(update._id)
                                      setUpdateObject(update)
                                      setShowDeleteUpdatesModal(true)
                                    }
                                    }
                                    className="px-2 outline-none focus:outline-none rounded-full text-red-400 transition-all duration-500 hover:-translate-y-1 hover:bg-red-300 hover:text-white"
                                  >
                                    Delete
                                  </button>
                                </div>
                            }
                          >
                            <div className="actionButton cursor-pointer"></div>
                          </Popover>
                        </div>
                        <div className="flex justify-end items-center mx-3 mb-2 italic text-xs">{moment(update?.createdAt).fromNow()}</div>
                        <div className="mt-3">{update?.postDescription}</div>
                        <div className="mt-1">
                          {update?.images?.length > 0 && update?.images?.map(image => {
                            return <img className="w-full h-[400px] border-b-2 border-red-500 mt-2" src={image} alt={image} />
                          })
                          }
                          {update?.videos?.length > 0 && update?.videos?.map(video => {
                            return (
                              <video controls className="w-full mt-2">
                                <source src={video} type="video/mp4" />
                                <source src={video} type="video/webm" />
                              </video>
                            )
                          })
                          }
                        </div>
                        {update?.externalURLS === null || update?.externalURLS === "" ? "" : (<div className="mt-3 mx-3"> <i>reference: <a href={update?.externalURLS} target="_blank"><span className="text-esgrown">{update?.externalURLS}</span></a></i></div>)}
                        <div className="mt-2 border-4 mx-[300px] border-b border-[#244060]"></div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <div className="w-[20%] flex h-10 space-x-3 justify-center">
              {/* upload files  */}
              <input
                type="file"
                name="file"
                className="invisible"
                accept=".jpg,.jpeg,.png,.mp4,.webm"
                ref={fileRef}
                multiple
                onChange={(e) => { handFileChange(e) }}
              />
              {/* <input
                  type="file"
                  className="invisible"
                  ref={videoRef}
                  // multiple={true}
                  onChange={handVideoChange}
                /> */}
            </div>
          </div>
        </Content>
      </Layout>
    </Layout >
  );
};

export default Updates;
